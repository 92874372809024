import React, {useState} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {Funnel, UploadSimple} from '@phosphor-icons/react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import {
  previewCSV,
  previewLeaversCSV,
  previewUpdateCSV,
  unsubscribeEA,
  uploadCSV,
  uploadCSVEdit,
} from 'src/state/action_creators/emailAuthorizationsCreator';
import EmailAuthorizationsTable from 'src/pages/email_authorizations/components/EmailAuthorizationsTable';
import {CSVWithPreviewModal} from 'src/pages/email_authorizations/components/modals/CSVWithPreviewModal';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import ButtonSimple from 'src/components/buttons/ButtonSimple';
import {PageHeader} from 'src/components/layout/PageHeader';
import {Loading} from 'src/components/shared/Loading';
import {Layout} from 'src/components/layout/Layout';
import Button from 'src/components/buttons/Button';
import {PartnerSelector} from 'src/types/Partner';
import {RBAC} from 'src/helpers/Rbac';
import {Page} from 'src/types/Page';
import {RootState} from 'src/state';

import {
  formatPreviewEdit,
  formatPreviewNew,
  formatPreviewUnsubscribe,
} from './helpers/previewFormatters';
import {useEmailAuthorizationFiltersForm} from './hooks/useEmailAuthorizationFiltersForm';

export const EmailAuthorizationsPage = ({controllerName}: Page) => {
  const {status, emailAuthorizations, partnersListSelector, total} =
    useSelector((state: RootState) => state.email_authorizations);

  const [isLeaversModalOpen, setIsLeaversModalOpen] = useState(false);
  const [isUploadCSVModalOpen, setIsUploadCSVModalOpen] = useState(false);
  const [isEditMetricsModalOpen, setIsEditMetricsModalOpen] = useState(false);
  const [page, setPage] = useState(1);

  const {
    onSubmitFilters,
    handleClearFilter,
    activeFilters,
    isSubmitting,
    partnerToFilter,
    setPartnerToFilter,
    emailToFilter,
    setEmailToFilter,
  } = useEmailAuthorizationFiltersForm({page, setPage});

  return (
    <Layout>
      <>
        {status === SharedActionType.LOADING ? (
          <Loading />
        ) : (
          <>
            <PageHeader pageTitle="Email authorizations">
              <RBAC controllerName={controllerName} actionName="update">
                <div className="flex items-center justify-center">
                  <Button
                    buttonType={ButtonType.OUTLINE}
                    size="small"
                    onClick={() => setIsUploadCSVModalOpen(true)}>
                    <UploadSimple size={18} /> Authorizations
                  </Button>
                  <Button
                    buttonType={ButtonType.OUTLINE}
                    size="small"
                    onClick={() => setIsEditMetricsModalOpen(true)}>
                    <UploadSimple size={18} /> Metrics
                  </Button>
                  <RBAC controllerName={controllerName} actionName="update">
                    <Button
                      buttonType={ButtonType.OUTLINE}
                      size="small"
                      onClick={() => setIsLeaversModalOpen(true)}>
                      <UploadSimple size={18} /> Leavers
                    </Button>
                  </RBAC>
                </div>
              </RBAC>
            </PageHeader>
            <form className="custom-form height36" onSubmit={onSubmitFilters}>
              <div className="row-selectors">
                <div className="width230 group">
                  <TextField
                    id="email-input"
                    label="Email"
                    type="text"
                    value={emailToFilter}
                    onChange={({target}) => setEmailToFilter(target.value)}
                  />
                </div>
                {partnersListSelector && (
                  <div className="width230 group">
                    <Autocomplete
                      id="partner-selector"
                      options={partnersListSelector}
                      size="small"
                      getOptionLabel={(option: PartnerSelector) => option.name}
                      value={partnerToFilter}
                      style={{width: 230}}
                      onChange={(_, newValue) => setPartnerToFilter(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} label="Partner" />
                      )}
                    />
                  </div>
                )}
                <Button
                  buttonType={ButtonType.SAVE}
                  size="small"
                  type="submit"
                  loading={isSubmitting}
                  disabled={!emailToFilter && !partnerToFilter}>
                  Filter
                </Button>
              </div>
            </form>
            {activeFilters && (
              <div className="flex justify-start gap-4 text-active">
                {activeFilters.emailToFilter && (
                  <ButtonSimple onClick={(e) => handleClearFilter(e, 'email')}>
                    <Funnel /> {activeFilters.emailToFilter}
                  </ButtonSimple>
                )}
                {activeFilters.partnerToFilter && (
                  <ButtonSimple
                    onClick={(e) => handleClearFilter(e, 'partner')}>
                    <Funnel /> {activeFilters.partnerToFilter.name}
                  </ButtonSimple>
                )}
              </div>
            )}
            <EmailAuthorizationsTable
              users={emailAuthorizations}
              total={total}
              page={page}
              setPage={setPage}
            />
          </>
        )}
        <CSVWithPreviewModal
          canSelectPartner
          dispatchPreview={previewCSV}
          dispatchSave={uploadCSV}
          formatPreviewRes={formatPreviewNew}
          isEdit={false}
          setShow={setIsUploadCSVModalOpen}
          show={isUploadCSVModalOpen}
          title="Authorization of new emails"
        />
        <CSVWithPreviewModal
          canSelectPartner={false}
          dispatchPreview={previewUpdateCSV}
          dispatchSave={uploadCSVEdit}
          formatPreviewRes={formatPreviewEdit}
          isEdit
          setShow={setIsEditMetricsModalOpen}
          show={isEditMetricsModalOpen}
          title="Edit metrics"
        />
        <CSVWithPreviewModal
          canSelectPartner={false}
          dispatchPreview={previewLeaversCSV}
          dispatchSave={unsubscribeEA}
          formatPreviewRes={formatPreviewUnsubscribe}
          isEdit={false}
          setShow={setIsLeaversModalOpen}
          show={isLeaversModalOpen}
          title="Process leavers"
        />
      </>
    </Layout>
  );
};
