import {useState} from 'react';

import {useSelector} from 'react-redux';
import {SubmitHandler, useForm} from 'react-hook-form';
import toast from 'react-hot-toast';

import {yupResolver} from '@hookform/resolvers/yup';

import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';
import {Locale} from 'src/types/User';
import {PillArea} from 'src/types/Pill';
import {createPill} from 'src/state/action_creators/pillsCreator';
import {createFormData} from 'src/helpers/form';
import {
  FileType,
  FileTypeStatus,
  fileInit,
} from 'src/components/form/controlled/type';

import {pillNewSchema} from '../../schemas/pillSchema';
import {PillNewFormValues} from '../types';

interface Params {
  closeModal: () => void;
}

export const useNewPillForm = ({closeModal}: Params) => {
  const dispatch = useAppDispatch();
  const {pillsOptions} = useSelector((state: RootState) => state.pills);

  const initialValues: PillNewFormValues = {
    areaId: pillsOptions.areas[0].title,
    typeOfPill: pillsOptions.typeOfPills[0].name,
    logo: null,
    background: null,
    thumbnail: null,
    header: '',
    title: '',
    activePill: [],
    locale: Locale.EN,
    description: '',
  };
  const [fileLogo, setFileLogo] = useState<FileType>(fileInit);
  const [fileBackground, setFileBackground] = useState<FileType>(fileInit);
  const [fileThumbnail, setFileThumbnail] = useState<FileType>(fileInit);

  function getAreaId(id: string): string {
    const areaIds: string[] = pillsOptions.areas
      .filter((a: PillArea) => id.toLowerCase() === a.title.toLowerCase())
      .map((a: PillArea) => a.id);
    if (areaIds.length > 0) return areaIds[0].toString();
    else return '';
  }

  const onSubmit: SubmitHandler<PillNewFormValues> = (data) => {
    const formData = createFormData(data);
    formData.set('areaId', getAreaId(data.areaId));
    formData.delete('activePill');
    if (data.activePill)
      formData.set(
        'active',
        data.activePill[0] === 'Active' ? 'true' : 'false',
      );
    if (fileLogo.status !== FileTypeStatus.ORIGINAL && fileLogo.file)
      formData.set('logo', fileLogo.file);
    if (
      fileBackground.status !== FileTypeStatus.ORIGINAL &&
      fileBackground.file
    )
      formData.set('background', fileBackground.file);
    if (fileThumbnail.status !== FileTypeStatus.ORIGINAL && fileThumbnail.file)
      formData.set('thumbnail', fileThumbnail.file);
    dispatch(createPill(formData)).then((res) => {
      if (res) {
        toast.success('Pill created successfully');
        closeModal();
      }
    });
  };

  const {
    control,
    handleSubmit,
    formState: {isSubmitting, errors},
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(pillNewSchema),
    mode: 'onBlur',
  });

  const valuesHaveChanged = () => {
    const currentValues = watch();

    const areaIdSameValue =
      currentValues.areaId === pillsOptions.areas[0].title;
    const logoSameValue = currentValues.logo === null;
    const backgroundSameValue = currentValues.background === null;
    const thumbnailSameValue = currentValues.thumbnail === null;
    const typeOfPillSameValue =
      currentValues.typeOfPill === pillsOptions.typeOfPills[0].name;
    const headerSameValue = currentValues.header === '';
    const titleSameValue = currentValues.title === '';
    const descriptionSameValue = currentValues.description === '';
    const activePillSameValue = currentValues.activePill.length === 0;

    return (
      areaIdSameValue &&
      logoSameValue &&
      backgroundSameValue &&
      thumbnailSameValue &&
      typeOfPillSameValue &&
      headerSameValue &&
      titleSameValue &&
      descriptionSameValue &&
      activePillSameValue
    );
  };

  return {
    control,
    hasErrors: Object.keys(errors).length > 0,
    isSubmitting,
    onSubmit: handleSubmit(onSubmit),
    setFileLogo,
    setFileBackground,
    setFileThumbnail,
    pillsOptions,
    valuesHaveChanged,
  };
};
