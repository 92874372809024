import React, {useEffect, useState} from 'react';

import {Tab, Tabs} from 'react-bootstrap';

import {PencilLine, Plus} from '@phosphor-icons/react';

import {getAccountTherapist} from 'src/state/action_creators/therapistsCreator';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {Therapist, TherapistAccount} from 'src/types/Therapist';
import {Loading} from 'src/components/shared/Loading';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';

interface Props {
  localeForAttr: string;
  setLocaleForAttr: React.Dispatch<React.SetStateAction<string>>;
  setModalEditAccountAttrsForLocale: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  therapist: Therapist;
}

export const AccountFieldsWithLocale = ({
  therapist,
  setModalEditAccountAttrsForLocale,
  localeForAttr,
  setLocaleForAttr,
}: Props) => {
  const dispatch = useAppDispatch();

  const [values, setValues] = useState({
    bio: '',
    titles: '',
    experience: '',
  });

  const {bio, titles, experience} = values;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (therapist && therapist.account) {
      dispatch(getAccountTherapist(therapist.id, localeForAttr)).then(
        (account: TherapistAccount | false) => {
          if (account) {
            setValues({
              bio: account.bio || '',
              experience: account.experience || '',
              titles: account.titles || '',
            });
          }
          setLoading(false);
        },
      );
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localeForAttr]);

  return (
    <div className="user__section attr_tabs_languages loadingContainer">
      <div className="section_header">
        <h3>Attributes for each language</h3>
        {therapist.account ? (
          <Button
            buttonType={ButtonType.ACTIONS}
            size="small"
            onClick={() => setModalEditAccountAttrsForLocale(true)}>
            <PencilLine size="14" />
          </Button>
        ) : (
          <Button
            buttonType={ButtonType.ACTIONS}
            size="small"
            onClick={() => setModalEditAccountAttrsForLocale(true)}>
            <Plus size="14" />
          </Button>
        )}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex items-start">
          <Tabs
            id="controlled-tab-example"
            activeKey={localeForAttr}
            onSelect={(k) => setLocaleForAttr(k!)}
            className="nav-tab-vertical">
            <Tab eventKey="es" title="Español"></Tab>
            <Tab eventKey="en" title="English"></Tab>
            <Tab eventKey="fr" title="Français"></Tab>
            <Tab eventKey="pt" title="Portuguese"></Tab>
            <Tab eventKey="he" title="Hebrew"></Tab>
          </Tabs>
          <div className="card flex-auto rounded border">
            <div className="form-group">
              <span>Bio</span>
              <p>{bio}</p>
            </div>
            <div className="form-group">
              <span>Titles</span>
              <p>{titles}</p>
            </div>
            <div className="form-group">
              <span>Experience</span>
              <p>{experience}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
