import {Patient, PatientStorage} from 'src/types/Patient';
import {User} from 'src/types/User';

import {isEmptyObject} from '../objectUtils';

import {
  mapApiResponseUser,
  mapApiResponseDisorder,
  mapApiResponsePlanType,
  mapApiResponseSource,
  mapApiResponseEmailNotification,
} from './user';

export function mapApiResponsePatient(jsonBack: PatientStorage) {
  const user: User = mapApiResponseUser(jsonBack);

  const patient: Patient = {
    ...user,
    active: jsonBack.active,
    atPeriodEnd: jsonBack.at_period_end,
    activeEmails: jsonBack.active_emails,
    activeNotifications: jsonBack.active_notifications,
    activeUntil: jsonBack.active_until,
    age: jsonBack.age,
    agent: jsonBack.agent,
    confirmedAt: jsonBack.confirmed_at || null,
    confirmedBy: jsonBack.confirmed_by || null,
    conversationWithSupport: jsonBack.conversation_with_support,
    conversationWithTherapist: jsonBack.conversation_with_therapist,
    currentPlan: jsonBack.current_plan || null,
    deleted: jsonBack.deleted,
    deletedAt: jsonBack.deleted_at,
    useV3Web: jsonBack.use_v3_web || false,
    disorders: jsonBack.disorders
      ? mapApiResponseDisorder(jsonBack.disorders)
      : null,
    emailNotifications: mapApiResponseEmailNotification(
      jsonBack.email_notifications,
    ),
    enabledAudio: jsonBack.enabled_audio,
    exactAge: jsonBack.exact_age,
    gender: jsonBack.gender || null,
    metric: jsonBack.metric,
    note: jsonBack.note,
    partner: jsonBack.partner,
    pastSubscriptions:
      jsonBack.past_subscriptions &&
      !isEmptyObject(jsonBack.past_subscriptions[0])
        ? jsonBack.past_subscriptions
        : null,
    currentSubscription: jsonBack.current_subscription || null,
    planName: jsonBack.plan_name || jsonBack.plan || null,
    planType: jsonBack.plan_type
      ? mapApiResponsePlanType(jsonBack.plan_type)
      : null,
    planVideo: jsonBack.plan_video,
    role: jsonBack.role,
    roles: jsonBack.roles,
    source: mapApiResponseSource(jsonBack.source),
    state: jsonBack.state,
    status: jsonBack.status || null,
    statusText: jsonBack.status_text,
    stripeCustomer: jsonBack.stripe_customer,
    tester: jsonBack.tester,
    therapist: jsonBack.therapist || null,
    therapistId: jsonBack.therapist_id || null,
    therapistsHistoryInitials: jsonBack.therapists_history_initials || null,
    trialOn: jsonBack.trial_on,
  };

  return patient;
}
