import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import dayjs from 'dayjs';

import {
  GRAPHIC_SELECT_OPTIONS,
  GraphicOptionType,
} from 'src/constants/GraphicHomeSelectOptions';
import {PreloadingModal} from 'src/pages/home/modals/PreloadingModal';
import {getHomeData} from 'src/state/action_creators/homeCreator';
import {GraphicBox} from 'src/pages/home/components/GraphicBox';
import {PageHeader} from 'src/components/layout/PageHeader';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {Loading} from 'src/components/shared/Loading';
import {Layout} from 'src/components/layout/Layout';
import {Option} from 'src/components/forms/Option';
import {Select} from 'src/components/forms/Select';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

export const HomePage = () => {
  const dispatch = useAppDispatch();
  const {statusHome, graphicsDay, graphics7days, graphics14days} = useSelector(
    (state: RootState) => state.home,
  );
  const [periodSelected, setPeriodSelected] = useState('last7days');
  const [graphics, setGraphics] = useState<FixMeLater>(null);

  useEffect(() => {
    function fetchHome() {
      if (!graphics7days) {
        dispatch(getHomeData(periodSelected)).then((res) => {
          setGraphics(res);
        });
      } else {
        setGraphics(graphics7days);
      }
    }
    fetchHome();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectChange = ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>) => {
    setPeriodSelected(target.value);
    let newGraphics = graphics;
    if (target.value !== 'last7days') {
      if (!graphics14days || !graphicsDay) {
        dispatch(getHomeData(target.value)).then((res) => {
          setGraphics(res);
        });
      }
      newGraphics =
        target.value === 'last14days' ? graphics14days : graphicsDay;
    } else {
      newGraphics = graphics7days;
    }
    setGraphics(newGraphics);
  };

  return (
    <Layout>
      {statusHome === SharedActionType.LOADING ? (
        <Loading />
      ) : (
        <div className="home__home">
          <PageHeader pageTitle="Reports overview" />
          <div className="header-selector flex items-center">
            <Select value={periodSelected} onChange={handleSelectChange}>
              {GRAPHIC_SELECT_OPTIONS.map(
                (item: GraphicOptionType, i: number) => (
                  <Option value={item.value} key={i}>
                    {item.text}
                  </Option>
                ),
              )}
            </Select>
            <p>{dayjs(new Date()).format('LL')}</p>
          </div>
          {graphics ? (
            <div className="home__home-graphics">
              <div className="graphics-row">
                <GraphicBox
                  periodSelected={periodSelected}
                  total={graphics.registrations.total}
                  graphic_data={graphics.registrations.graphic_data}
                  data_tip="New users registered"
                  title="Registrations"
                />
                <GraphicBox
                  periodSelected={periodSelected}
                  total={graphics.b2b_customers.total}
                  graphic_data={graphics.b2b_customers.graphic_data}
                  data_tip="New B2B users registered"
                  title="B2B customers"
                />
                <GraphicBox
                  periodSelected={periodSelected}
                  total={graphics.b2c_customers.total}
                  graphic_data={graphics.b2c_customers.graphic_data}
                  data_tip="New B2C users registered"
                  title="B2C customers"
                />
              </div>
              <div className="graphics-row">
                <GraphicBox
                  periodSelected={periodSelected}
                  total={graphics.access_support.total}
                  graphic_data={graphics.access_support.graphic_data}
                  data_tip="Access to support chat"
                  title="Support sessions"
                />
                <GraphicBox
                  periodSelected={periodSelected}
                  total={graphics.access_therapy.total}
                  graphic_data={graphics.access_therapy.graphic_data}
                  data_tip="Access to therapy chat"
                  title="Therapy sessions"
                />
                <GraphicBox
                  periodSelected={periodSelected}
                  total={graphics.videocall_validated.total}
                  graphic_data={graphics.videocall_validated.graphic_data}
                  data_tip="Video sessions validated"
                  title="Video sessions"
                />
              </div>
            </div>
          ) : (
            <p>No available for test server</p>
          )}
        </div>
      )}
      <PreloadingModal show={graphics === null} />
    </Layout>
  );
};
