import React, {useEffect, useState} from 'react';

import {useController} from 'react-hook-form';
import toast from 'react-hot-toast';

import {DeleteTrash} from '@ifeelonline/icons';

import {Loading} from 'src/components/shared/Loading';

import {
  ALLOWED_FILE_FORMATS,
  MAX_FILE_SIZE_MB,
} from '../../../constants/Common';
import {EXTERNAL_ROUTES} from '../../../constants/Routes';

import {
  BaseControlledComponentProps,
  FileType,
  FileTypeStatus,
  INPUT_FILE_STATES,
} from './type';
import {ErrorText} from './ErrorText';

interface Props extends BaseControlledComponentProps {
  allowedFormats?: string[];
  isLoading?: boolean;
  labelError?: string;
  setFile: React.Dispatch<React.SetStateAction<FileType>>;
  shouldShowPreview?: boolean;
  urlFile?: string;
}

const imageDefault = `${EXTERNAL_ROUTES.AMAZON_S3_IMAGES}/upload_file.png`;

export function InputFile({
  control,
  name,
  label,
  labelError,
  setFile,
  shouldShowPreview = true,
  urlFile = imageDefault,
  isLoading = false,
  allowedFormats = ALLOWED_FILE_FORMATS,
}: Props) {
  const {field, fieldState} = useController({
    control,
    name,
  });

  const maxFileSize = MAX_FILE_SIZE_MB * 1024 * 1024;
  const [previewUrl, setPreviewUrl] = useState<string>(urlFile);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const isInvalid = fieldState.invalid;

  const removeFile = () => {
    setPreviewUrl(imageDefault);
    setFile({file: null, status: FileTypeStatus.DELETED});
    field.onChange(null);
    field.value = null;
    if (inputRef.current) {
      inputRef.current.type = '';
      inputRef.current.type = 'file';
    }
  };

  const isFileSizeAccepted = (file: File) => file.size < maxFileSize;
  const isFormatAllowed = (file: File) =>
    allowedFormats.includes(file.type.split('/')[1].toLowerCase());

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (!isFormatAllowed(file)) {
        return toast.error('File format not supported');
      }
      field.onChange(file.name);
      setFile({file, status: FileTypeStatus.SELECTED});

      if (file && isFileSizeAccepted(file) && isFormatAllowed(file)) {
        const url = URL.createObjectURL(file);
        setPreviewUrl(url);
      }
      field.value = file;
    }
  };

  const handleOpenFile = () => {
    inputRef.current?.click();
  };

  const supportedFormatsMessage = `Supported Formats: ${allowedFormats
    .join(', .')
    .toLowerCase()}`;

  const [viewInputFileStatus, setViewInputFileStatus] = useState(
    INPUT_FILE_STATES.LOADING,
  );

  useEffect(() => {
    setPreviewUrl(urlFile);
  }, [urlFile]);

  useEffect(() => {
    let status;
    if (isLoading) {
      status = INPUT_FILE_STATES.LOADING;
    } else if (!shouldShowPreview) {
      status = INPUT_FILE_STATES.NO_PREVIEW;
    } else if (previewUrl === imageDefault) {
      status = INPUT_FILE_STATES.PREVIEW_DEFAULT;
    } else {
      status = INPUT_FILE_STATES.PREVIEW_IMAGE;
    }

    setViewInputFileStatus(status);
  }, [previewUrl, isLoading]);

  const isFileWithoutPreviewUploaded =
    viewInputFileStatus === INPUT_FILE_STATES.NO_PREVIEW && field.value;

  return (
    <div className="relative mb-3">
      <label className="mb-1 block">
        {isInvalid && labelError ? labelError : label}
      </label>
      <div
        onClick={handleOpenFile}
        className={`border-brandPrimaryLight-400 bg-brandPrimaryLight-200 group relative flex h-60 min-w-60 cursor-pointer flex-col items-center
             justify-center overflow-hidden rounded-xl border-2
             border-dashed bg-brandPrimary-300/25 p-4 hover:bg-brandPrimary-300/50 ${
               isInvalid
                 ? 'border-brandSecondary-700'
                 : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
             }`}>
        {viewInputFileStatus === INPUT_FILE_STATES.LOADING && <Loading />}

        {(viewInputFileStatus === INPUT_FILE_STATES.PREVIEW_IMAGE ||
          viewInputFileStatus === INPUT_FILE_STATES.PREVIEW_DEFAULT) && (
          <img
            className={`max-h-60 max-w-60 rounded transition-all ${
              previewUrl !== imageDefault ? 'h-auto w-full' : 'h-auto w-30'
            } `}
            src={previewUrl}
            alt="Preview"
          />
        )}

        {viewInputFileStatus === INPUT_FILE_STATES.NO_PREVIEW && (
          <div className="flex w-full flex-col items-center justify-center gap-4 text-center align-middle">
            <p>
              {isFileWithoutPreviewUploaded
                ? `File uploaded: ${field.value}`
                : 'Upload file'}
            </p>
            <p className="ml-1 mt-1 text-3">{supportedFormatsMessage}</p>
          </div>
        )}
        {viewInputFileStatus === INPUT_FILE_STATES.PREVIEW_IMAGE && (
          <div
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-full border border-solid bg-white p-3 opacity-0 transition-all group-hover:opacity-100"
            onClick={(event) => {
              event.stopPropagation();
              removeFile();
            }}>
            <DeleteTrash />
          </div>
        )}
      </div>
      {viewInputFileStatus !== INPUT_FILE_STATES.PREVIEW_IMAGE && (
        <p className="ml-1 mt-1 text-3">{supportedFormatsMessage}</p>
      )}

      {fieldState.error?.message && (
        <ErrorText error={fieldState.error?.message} size="small" />
      )}
      <div className="hidden">
        <input type="file" onChange={handleFileChange} ref={inputRef} />
      </div>
      <div className="mb-2.5 flex items-center justify-start">
        <p
          className={`ml-4 mt-1 text-3 ${
            viewInputFileStatus === INPUT_FILE_STATES.PREVIEW_DEFAULT
              ? 'hover:block'
              : 'hidden'
          }`}></p>
      </div>
    </div>
  );
}
