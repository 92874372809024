import {SharedActionType} from '@ifeelonline/chat-core';

import {MyActionType} from '../action_types/MyActionType';
import {MyAction} from '../actions/my';
import {FixMeLater} from '../interfaces/FixMeLater';

interface MyState {
  my: FixMeLater;
  status: SharedActionType;
}

const initialState = {
  my: [],
  status: SharedActionType.IDLE,
};

export const myReducer = (state: MyState = initialState, action: MyAction) => {
  switch (action.type) {
    case MyActionType.MY_LOADING:
      return {
        status: SharedActionType.LOADING,
        my: state.my,
      };

    case MyActionType.GET_MY_INFO:
      return {
        ...state,
        status: SharedActionType.COMPLETED,
        my: action.payload,
      };

    default:
      return state;
  }
};
