import React from 'react';

import {Layout} from 'src/components/layout/Layout';
import {Page} from 'src/types/Page';

import {PartnersTable} from './PartnersTable';

export const PartnersPage = ({controllerName}: Page) => {
  return (
    <Layout>
      <PartnersTable controllerName={controllerName} />
    </Layout>
  );
};
