import * as Yup from 'yup';

import {blankSpacesRegExp} from 'src/helpers/regularExp';

export const partnersModalAddNewSchema = Yup.object({
  name: Yup.string()
    .required('Name is required')
    .nullable()
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  multiplier: Yup.number().required('Multiplier is required').min(1).nullable(),
  planNumber: Yup.string()
    .required('Plan is required')
    .nullable()
    .min(1, 'You must select a plan'),
  boughtVideoSessions: Yup.number()
    .required('Bought video sessions is required')
    .nullable()
    .min(0, 'Bought video sessions minimum value is 0'),
});

export const partnersModalEditSchema = Yup.object({
  multiplier: Yup.number().required('Multiplier is required').min(1).nullable(),
  boughtVideoSessions: Yup.number()
    .required('Bought video sessions is required')
    .nullable()
    .min(0, 'Bought video sessions minimum value is 0'),
});
