import {useEffect, useState} from 'react';

import {SubmitHandler, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {yupResolver} from '@hookform/resolvers/yup';

import {
  FileType,
  FileTypeStatus,
  fileInit,
} from 'src/components/form/controlled/type';
import {
  getPill,
  getPills,
  updatePill,
} from 'src/state/action_creators/pillsCreator';
import {createFormData} from 'src/helpers/form';
import {useAppDispatch} from 'src/hooks/hooks';
import {Pill, PillArea} from 'src/types/Pill';
import {RootState} from 'src/state';

import {pillEditSchema} from '../../schemas/pillSchema';
import {PillEditFormValues} from '../types';

interface Params {
  closeModal: () => void;
  pillId: number;
}

export const useEditPillForm = ({closeModal, pillId}: Params) => {
  const dispatch = useAppDispatch();
  const {pillsOptions} = useSelector((state: RootState) => state.pills);
  const {languages} = useSelector((state: RootState) => state.settings);
  const initialValues: PillEditFormValues = {
    areaId: pillsOptions.areas[0].title,
    typeOfPill: pillsOptions.typeOfPills[0].name,
    locale: 'English',
    logo: '',
    background: '',
    thumbnail: '',
    header: '',
    title: '',
    activePill: ['Active'],
    description: '',
  };

  const {
    control,
    handleSubmit,
    formState: {isSubmitting, errors, dirtyFields},
    setValue,
    reset,
    getValues,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(pillEditSchema),
    mode: 'onBlur',
  });

  const [fileLogo, setFileLogo] = useState<FileType>(fileInit);
  const [fileBackground, setFileBackground] = useState<FileType>(fileInit);
  const [fileThumbnail, setFileThumbnail] = useState<FileType>(fileInit);

  const [pillEdit, setPillEdit] = useState<Pill | null>(null);
  const [deprecatedArea, setDeprecatedArea] = useState<string | null>(null);
  const [localeName, setLocaleName] = useState<string>('English');
  const [originalValues, setOriginalValues] = useState(initialValues);

  const getPillEdit = async () => {
    const locale = languages?.filter(
      (language) => language.name === languageSelected,
    )[0]?.locale;

    const pill = await dispatch(getPill(pillId, locale || 'en'));

    if (pill) {
      setPillEdit(pill);
      const areaExists = pillsOptions.areas.some(
        (area: PillArea) => area.title === pill.area,
      );

      if (!areaExists) setDeprecatedArea(pill.area);

      setOriginalValues({
        ...initialValues,
        locale: localeName,
        areaId:
          pill.area !== null && areaExists
            ? pill.area
            : pillsOptions.areas[0].title,
        typeOfPill: pill.typeOfPill || pillsOptions.typeOfPills[1].name,
        header: pill.header,
        title: pill.title,
        description: pill.description,
        activePill: pill.active ? ['Active'] : [],
      });

      reset({
        ...initialValues,
        locale: localeName,
        areaId:
          pill.area !== null && areaExists
            ? pill.area
            : pillsOptions.areas[0].title,
        typeOfPill: pill.typeOfPill || pillsOptions.typeOfPills[1].name,
        header: pill.header,
        title: pill.title,
        description: pill.description,
        activePill: pill.active ? ['Active'] : [],
      });
    }
  };

  useEffect(() => {
    getPillEdit();
  }, [pillId, localeName]);

  function getAreaId(id: string): string {
    const areaIds: string[] = pillsOptions.areas
      .filter((a: PillArea) => id.toLowerCase() === a.title.toLowerCase())
      .map((a: PillArea) => a.id);
    if (areaIds.length > 0) return areaIds[0].toString();
    else return '';
  }

  const onSubmit: SubmitHandler<PillEditFormValues> = (data) => {
    const formData = createFormData(data);
    const locale = languages?.filter(
      (language) => language.name === getValues('locale'),
    )?.[0].locale;
    if (locale) formData.set('locale', locale);

    formData.set('areaId', getAreaId(data.areaId));
    if (data.activePill && data.activePill.length > 0) {
      formData.set(
        'active',
        data.activePill[0] === 'Active' ? 'true' : 'false',
      );
    } else {
      formData.set('active', 'false');
    }
    if (fileLogo.status !== FileTypeStatus.ORIGINAL)
      formData.set('logo', fileLogo.file || '');
    else formData.delete('logo');
    if (fileBackground.status !== FileTypeStatus.ORIGINAL)
      formData.set('background', fileBackground.file || '');
    else formData.delete('background');
    if (fileThumbnail.status !== FileTypeStatus.ORIGINAL)
      formData.set('thumbnail', fileThumbnail.file || '');
    else formData.delete('thumbnail');
    dispatch(updatePill(formData, pillId)).then((res) => {
      if (res) {
        toast.success('Pill updated successfully');
        dispatch(getPills({page: 1, orderBy: 'id', orderDesc: true}));
        closeModal();
      }
    });
  };

  const languageSelected =
    languages &&
    languages?.filter((language) => language?.name === getValues('locale'))?.[0]
      ?.name;

  const valuesHaveChanged = () => {
    const currentValues = watch();

    const areaIdSameValue = currentValues.areaId === originalValues.areaId;
    const typeOfPillSameValue =
      currentValues.typeOfPill === originalValues.typeOfPill;
    const logoSameValue = !dirtyFields.logo;
    const backgroundSameValue = !dirtyFields.background;
    const thumbnailSameValue = !dirtyFields.thumbnail;
    const headerSameValue = currentValues.header === originalValues.header;
    const titleSameValue = currentValues.title === originalValues.title;
    const descriptionSameValue =
      currentValues.description === originalValues.description;
    const activePillSameValue =
      currentValues.activePill.length === originalValues.activePill.length;
    const languageSameValue = currentValues.locale === 'English';

    return (
      areaIdSameValue &&
      logoSameValue &&
      backgroundSameValue &&
      thumbnailSameValue &&
      typeOfPillSameValue &&
      headerSameValue &&
      titleSameValue &&
      descriptionSameValue &&
      activePillSameValue &&
      languageSameValue
    );
  };

  return {
    control,
    hasErrors: Object.keys(errors).length > 0,
    isSubmitting,
    onSubmit: handleSubmit(onSubmit),
    setFileLogo,
    setFileBackground,
    setFileThumbnail,
    deprecatedArea,
    setValue,
    setLocaleName,
    getValues,
    pillEdit,
    pillsOptions,
    languages,
    languageSelected,
    valuesHaveChanged,
  };
};
