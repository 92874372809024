import React from 'react';

import {Info} from '@phosphor-icons/react';
import {Tooltip} from '@mui/material';

export const CustomTooltip = ({title}: {title: string}) => {
  return (
    <Tooltip
      sx={{
        background: 'rgb(0 0 0 / 0 %)',
        border: '1px solid #bdbdbd',
        borderRadius: '100%',
        height: '11px',
        width: '11px',
        fontSize: '8px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        boxSizing: 'content-box',
      }}
      title={title}>
      <Info />
    </Tooltip>
  );
};
