import {SharedActionType} from '@ifeelonline/chat-core';

import {Patient} from 'src/types/Patient';
import {PartnerSelector} from 'src/types/Partner';
import {TherapistSelector} from 'src/types/Therapist';

import {PatientsAction} from '../actions/patients';
import {PatientsActionType} from '../action_types/PatientsActionType';

interface PatientsState {
  error: null | string;
  partnersList: PartnerSelector[] | null;
  patients: Patient[] | null;
  status: SharedActionType;
  therapistsList: TherapistSelector[] | null;
  total: number;
}

const initialState = {
  patients: null,
  partnersList: null,
  therapistsList: null,
  total: 0,
  status: SharedActionType.IDLE,
  error: null,
};

export const patientsReducer = (
  state: PatientsState = initialState,
  action: PatientsAction,
) => {
  switch (action.type) {
    case PatientsActionType.PATIENTS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case PatientsActionType.SET_PATIENTS:
      return {
        ...state,
        patients: action.payload.patients,
        total: action.payload.total,
        status: SharedActionType.COMPLETED,
      };

    case PatientsActionType.SET_USERS_OPTIONS:
      return {
        ...state,
        partnersList: action.payload.partnersSelector,
        therapistsList: action.payload.therapistsSelector,
      };

    default:
      return state;
  }
};
