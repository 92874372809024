export enum TherapistsActionType {
  SET_ALL_ATTRIBUTES_THERAPIST = '[therapist] Get all attributes from therapist',
  SET_CITIES = '[therapist] Get cities for a country',
  SET_COUNTRIES = '[therapist] Get countries for select filter',
  SET_THERAPIST = '[therapist] Get therapist',
  SET_THERAPISTS = '[therapists] Get therapists',
  SET_THERAPIST_LIST_SELECTOR = '[therapist] Set therapist list for selector',

  THERAPISTS_LOADING = '[therapists] Get therapists loading',
  THERAPIST_ADD_NEW = '[therapists] Add new therapist',
  THERAPIST_LOADING = '[therapist] Get therapist loading',
}
