import React, {ComponentProps} from 'react';

import {Form} from 'react-bootstrap';

interface Props extends ComponentProps<'div'> {
  checked: boolean;
  isEmptyFilter?: boolean;
  label: string;
  onChange: () => void;
}

export const GenericFiltersToggle = ({
  checked,
  onChange,
  label,
  children,
  isEmptyFilter = false,
}: Props) => {
  return (
    <div>
      <li className="p-2 shadow-modalHeader">
        <Form.Check
          checked={checked}
          onChange={onChange}
          label={label}
          type="checkbox"
        />
      </li>
      {checked && !isEmptyFilter && (
        <div className="bg-grey-soft p-3">{children}</div>
      )}
    </div>
  );
};
