import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {Layout} from 'src/components/layout/Layout';
import {PageHeader} from 'src/components/layout/PageHeader';
import {Loading} from 'src/components/shared/Loading';
import {useAppDispatch} from 'src/hooks/hooks';
import WebhooksTable from 'src/pages/webhooks/WebhooksTable';
import {RootState} from 'src/state';
import {getWebhooks} from 'src/state/action_creators/webhooksCreator';

export default function WebhooksPage() {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);

  const {status, webhooks, total} = useSelector(
    (state: RootState) => state.webhooks,
  );
  useEffect(() => {
    dispatch(getWebhooks(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Layout>
      {status === SharedActionType.LOADING ? (
        <Loading />
      ) : (
        <>
          <PageHeader pageTitle="Webhooks" />
          <WebhooksTable
            webhooks={webhooks}
            total={total}
            page={page}
            setPage={setPage}
          />
        </>
      )}
    </Layout>
  );
}
