import React, {ComponentProps} from 'react';

import {FixMeLater} from 'src/state/interfaces/FixMeLater';

import {DASHBOARD_PERIOD_ENUM} from './Dashboard';

export interface Series {
  x: number;
  y: number; // UNIX timestamp
}
export interface GraphicBoxInterface extends ComponentProps<'div'> {
  annotation?: boolean;
  colors: string[];
  data_tip: string;
  dropDownOption?: ChartOptionsValue;
  gradient?: boolean;
  graphic_data: FixMeLater;
  height: number;
  icon?: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  loadingOptions?: boolean;
  offsetGradient?: number;
  periodSelected: DASHBOARD_PERIOD_ENUM;
  setDropDownOption?: React.Dispatch<React.SetStateAction<ChartOptionsValue>>;
  setLoadingOptions?: React.Dispatch<React.SetStateAction<boolean>>;
  showGrid?: boolean;
  title?: string;
  total: number | string;
}

export enum ChartOptionsValue {
  DELETED = 'deleted',
  INTAKES = 'intakes',
  INTAKES_WAITING_TIME = 'intakes_waiting_time',
  LOGINS = 'logins',
  PATIENTS_PER_THERAPIST = 'patients_per_therapist',
  REGISTERS = 'registers',
  UNREGISTERED = 'unregistered',
}
