import {QueryData} from '@ifeelonline/chat-core';

import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';
import {IncomingPatient} from 'src/types/IncomingPatient';

import {FormValuesIncomingPatients} from '../IncomingPatientsPage';

export function buildRequestParams(
  page: number,
  formValues: GenericTableProps<
    IncomingPatient,
    FormValuesIncomingPatients
  >['formValues'],
): QueryData {
  const newFilterParams: QueryData = {
    page,
  };

  if (formValues?.isHighRisk?.checked && formValues.isHighRisk.value !== null)
    newFilterParams.high_risk = formValues.isHighRisk.value.toString();

  if (
    formValues?.psychologistGuide.checked &&
    formValues?.psychologistGuide.value
  )
    newFilterParams.psychologist_guide_id =
      formValues?.psychologistGuide.value?.toString();

  if (formValues?.unassignedCalls.checked)
    newFilterParams.psychologist_guide_id = 'unassigned';

  if (
    formValues.appointment?.checked &&
    formValues.appointment.value !== null
  ) {
    if (formValues.appointment.value === false) {
      newFilterParams.appointment = false.toString();
    } else if (
      formValues.appointment.value.from &&
      formValues.appointment.value.to
    ) {
      newFilterParams.appointment = {
        from: formValues.appointment.value.from.toISOString(),
        to: formValues.appointment.value.to.toISOString(),
      };
    }
  }
  return newFilterParams;
}
