import React from 'react';

import {useController} from 'react-hook-form';

import {ErrorText} from './ErrorText';
import {BaseControlledComponentProps} from './type';
import {CustomTextField} from './StylesFormControlled';

export function InputText({
  control,
  name,
  label,
  labelError,
  placeholder,
}: BaseControlledComponentProps) {
  const {field, fieldState} = useController({
    control,
    name,
  });

  const isInvalid = fieldState.invalid;

  return (
    <div className="mb-3 min-h-12 w-full max-w-full">
      <label className="mb-1 block">
        {isInvalid && labelError ? labelError : label}
      </label>
      <CustomTextField
        error={isInvalid}
        size="small"
        style={{width: '100%'}}
        inputProps={{
          autoComplete: 'off',
        }}
        placeholder={placeholder}
        type="text"
        {...field}
      />
      {fieldState.error?.message && (
        <ErrorText error={fieldState.error?.message} size="small" />
      )}
    </div>
  );
}
