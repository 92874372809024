import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {SharedActionType} from '@ifeelonline/chat-core';

import {
  getRole,
  getRoles,
  updateRolePermissions,
} from 'src/state/action_creators/rolesCreator';
import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import {areSessionsAndGraphicsChecked} from 'src/helpers/rolesFunctions';
import {TableType} from 'src/helpers/set_classes/tableClasses';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {CustomTable} from 'src/components/tables/CustomTable';
import {parseUnderscore} from 'src/helpers/generalFunctions';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {Loading} from 'src/components/shared/Loading';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {handleRoleCheckboxChange} from '../RolesFunctions';

import {CheckRolesModal} from './CheckRolesModal';

interface Props extends ModalSimpleInterface {
  orderBy: string;
  orderDesc: boolean;
  page: number;
}

export const EditRoleModal = ({
  show,
  setShow,
  page,
  orderBy,
  orderDesc,
}: Props) => {
  const dispatch = useAppDispatch();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const {status, roleActive, controllables, actionsList} = useSelector(
    (state: RootState) => state.roles,
  );
  const [actionListComplete, setActionListComplete] =
    useState<FixMeLater>(null);
  const [rolesPermissionsSelected, setRolesPermissionsSelected] = useState([]);

  useEffect(() => {
    if (show && roleActive) {
      dispatch(getRole(roleActive.id)).then((res: FixMeLater) => {
        setRolesPermissionsSelected(res);
      });
      if (actionListComplete === null) {
        setActionListComplete(['all', ...actionsList]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    if (roleActive && areSessionsAndGraphicsChecked(rolesPermissionsSelected)) {
      setLoadingSubmit(true);
      dispatch(
        updateRolePermissions(roleActive.id, {
          permissions: {roles_permissions: rolesPermissionsSelected},
        }),
      ).then((res: FixMeLater) => {
        if (res) {
          setLoadingSubmit(false);
          setShow!(false);
          toast.success(
            `Role permissions modified successfully for ${roleActive.name}`,
          );
          dispatch(
            getRoles({page, orderBy, orderDir: orderDesc ? 'desc' : 'asc'}),
          );
        }
      });
    } else {
      toast.error(
        'Access to sessions and graphics controller are required at least',
      );
    }
  };

  const closeModal = () => setShow!(false);

  return (
    <ModalLayout
      show={show}
      closeModal={closeModal}
      className="h-fit max-w-2xl">
      {roleActive && actionListComplete !== null ? (
        <form className="container" onSubmit={handleSubmitForm}>
          <ModalLayout.Header>
            <h3>
              Edit permissions for
              <span className="mx-0.5 inline-block max-w-50 overflow-hidden text-ellipsis font-bold">
                {roleActive.name}
              </span>
              role
            </h3>
          </ModalLayout.Header>
          <ModalLayout.Body isBgWhite={true}>
            {status === SharedActionType.LOADING ? (
              <Loading />
            ) : (
              <CustomTable
                tableType={TableType.ROLES}
                className="border shadow-editRoleModal"
                headChildren={
                  <tr className="border-b-3 group border-table-border">
                    <th className="min-w-26">Controller</th>
                    {actionListComplete.map((a: FixMeLater, i: number) => (
                      <th key={i}>{a}</th>
                    ))}
                  </tr>
                }
                bodyChildren={
                  controllables &&
                  controllables.map((v: FixMeLater, index: number) => (
                    <tr key={index} className="group">
                      <td className="capitalize">
                        {parseUnderscore(v.replace('_controller', ''))}
                      </td>
                      {actionListComplete.map((a: FixMeLater, i: number) => (
                        <CheckRolesModal
                          rolesPermissionsSelected={rolesPermissionsSelected}
                          controllerName={v}
                          action={a}
                          handleCheckboxChange={() =>
                            handleRoleCheckboxChange(
                              v,
                              a,
                              rolesPermissionsSelected,
                              setRolesPermissionsSelected,
                              actionsList,
                              btnDisabled,
                              setBtnDisabled,
                            )
                          }
                          key={i}
                        />
                      ))}
                    </tr>
                  ))
                }
              />
            )}
          </ModalLayout.Body>
          <ModalLayout.Footer>
            <ButtonSubmit
              loadingSubmit={loadingSubmit}
              disabled={btnDisabled}
            />
          </ModalLayout.Footer>
        </form>
      ) : (
        <Loading />
      )}
    </ModalLayout>
  );
};
