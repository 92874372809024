import React, {useEffect, useState} from 'react';

import DatePicker from 'react-datepicker';
import {useSelector} from 'react-redux';
import {Form} from 'react-bootstrap';

import {
  getPatient,
  getPatientPlansOptions,
  updatePatient,
} from 'src/state/action_creators/patientCreator';
import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import {getPatientsOptions} from 'src/state/action_creators/patientsCreator';
import {useHasActionPermissions} from 'src/hooks/useHasActionPermissions';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {ControllerNames} from 'src/types/ControllerNames';
import {TherapistSelector} from 'src/types/Therapist';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

interface formValuesInterface {
  active_until: Date | null;
  current_plan: string;
  email: string;
  nickname: string;
  notes: string;
  stripe_customer: string;
  therapist_id: number | '';
}

const initInfo: formValuesInterface = {
  active_until: null,
  current_plan: '',
  email: '',
  nickname: '',
  stripe_customer: '',
  therapist_id: '',
  notes: '',
};

export const PatientEditModal = ({show, setShow}: ModalSimpleInterface) => {
  const dispatch = useAppDispatch();
  const {therapistsList} = useSelector((state: RootState) => state.patients);
  const {patient, plansList} = useSelector((state: RootState) => state.patient);
  const [formValues, setFormValues] = useState<formValuesInterface>(initInfo);
  const {active_until, email, nickname, notes, stripe_customer, therapist_id} =
    formValues;

  useEffect(() => {
    if (!therapistsList) {
      dispatch(getPatientsOptions());
    }
    if (patient && !plansList) {
      dispatch(getPatientPlansOptions(patient.id));
    }
  }, []);

  useEffect(() => {
    if (patient) {
      setFormValues({
        active_until: patient.activeUntil
          ? new Date(patient.activeUntil)
          : null,
        current_plan: patient.currentPlan || '',
        email: patient.email,
        nickname: patient.nickname || '',
        notes: patient.note || '',
        stripe_customer: patient.stripeCustomer || '',
        therapist_id: patient.therapistId || '',
      });
    }
  }, [patient, setFormValues]);

  const handleInputChange = ({
    target,
  }:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleSelectChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleSelectDate = (name: string, date: Date) => {
    setFormValues({
      ...formValues,
      [name]: date,
    });
  };

  const closeModal = () => setShow!(false);

  const handleSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    if (patient) {
      dispatch(updatePatient(patient.id, formValues)).then((res) => {
        if (!res) {
          dispatch(getPatient(patient.id));
        }
      });
      closeModal();
    }
  };

  const hasUpdatePermission = useHasActionPermissions({
    controllerName: ControllerNames.USERS,
    actionName: 'update',
  });

  const valuesHaveChanged = () => {
    const activeUntilSameValue = patient?.activeUntil
      ? active_until === new Date(patient.activeUntil)
      : active_until === null;
    const emailSameValue = patient?.email
      ? email === patient.email
      : email === '';
    const nicknameSameValue = patient?.nickname
      ? nickname === patient.nickname
      : nickname === '';
    const notesPlanSameValue = patient?.note
      ? notes === patient.note
      : notes === '';
    const stripeCustomerSameValue = patient?.stripeCustomer
      ? stripe_customer === patient.stripeCustomer
      : stripe_customer === '';
    const therapistIdSameValue = patient?.therapistId
      ? therapist_id === patient.therapistId
      : therapist_id === '';

    return (
      activeUntilSameValue &&
      emailSameValue &&
      nicknameSameValue &&
      notesPlanSameValue &&
      stripeCustomerSameValue &&
      therapistIdSameValue
    );
  };

  return (
    <ModalLayout show={show} closeModal={closeModal}>
      <form className="allInputsBlock container" onSubmit={handleSubmitForm}>
        <ModalLayout.Header>
          <h3>Edit patient</h3>
        </ModalLayout.Header>
        <ModalLayout.Body>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              required
              disabled={!hasUpdatePermission}
              placeholder="email"
              name="email"
              autoComplete="off"
              value={email}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>Nickname</label>
            <input
              type="text"
              placeholder="Nickname"
              name="nickname"
              autoComplete="off"
              value={nickname}
              onChange={handleInputChange}
            />
          </div>

          {therapistsList && (
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label>Therapist</Form.Label>
              <Form.Control
                as="select"
                name="therapist_id"
                onChange={handleSelectChange}
                value={therapist_id}>
                <option value="">-</option>
                {therapistsList.map((therapist: TherapistSelector) => (
                  <option key={therapist.id} value={therapist.id!}>
                    {therapist.fullName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}

          <div className="form-group">
            <label>Active until</label>
            <DatePicker
              name="active_until"
              selected={active_until}
              className="displayBlock"
              onChange={(date) => handleSelectDate('active_until', date!)}
            />
          </div>
          <div className="form-group">
            <label>Stripe Customer</label>
            <input
              type="text"
              name="stripe_customer"
              autoComplete="off"
              value={stripe_customer}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Notes</label>
            <textarea
              name="notes"
              autoComplete="off"
              value={notes}
              onChange={handleInputChange}
            />
          </div>
        </ModalLayout.Body>
        <ModalLayout.Footer>
          <Button
            buttonType={ButtonType.SAVE}
            size="small"
            type="submit"
            disabled={valuesHaveChanged()}>
            Save
          </Button>
        </ModalLayout.Footer>
      </form>
    </ModalLayout>
  );
};
