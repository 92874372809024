import React from 'react';

import {CHART_GRAPHIC_OPTIONS} from 'src/constants/GraphicHomeSelectOptions';
import {ChartOptionsValue, GraphicBoxInterface} from 'src/types/Graphics';
import BasicTooltip from 'src/components/shared/BasicTooltip';
import {LineChart} from 'src/components/graphics/LineChart';
import {Loading} from 'src/components/shared/Loading';

export const ChartCardDropDown = ({
  periodSelected,
  total,
  graphic_data,
  data_tip,
  colors,
  height,
  showGrid,
  icon,
  gradient = false,
  annotation = false,
  dropDownOption,
  setDropDownOption,
  loadingOptions,
  setLoadingOptions,
  offsetGradient,
  ...rest
}: GraphicBoxInterface) => {
  return (
    <div className="chart-card" {...rest}>
      <div className="chart-card-header">
        {icon && <>{icon}</>}
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <h3>
            {
              CHART_GRAPHIC_OPTIONS.filter(
                (opt) => opt.value === dropDownOption,
              )[0].name
            }
          </h3>
          {!loadingOptions && <span className="total">{total}</span>}
        </div>
        <select
          style={{width: '150px', marginRight: '20px'}}
          value={dropDownOption}
          onChange={(e) => {
            setDropDownOption &&
              setDropDownOption(e.target.value as ChartOptionsValue);
            setLoadingOptions && setLoadingOptions(true);
          }}>
          {CHART_GRAPHIC_OPTIONS.map((opt, index) => (
            <option key={index} value={opt.value}>
              {opt.name}
            </option>
          ))}
        </select>
      </div>
      <div className="tooltip-chart">
        <BasicTooltip title={data_tip} />
      </div>
      {!loadingOptions && graphic_data ? (
        <LineChart
          data={graphic_data}
          colors={colors}
          height={height}
          showGrid={showGrid}
          period={periodSelected}
          gradient={gradient}
          annotation={annotation}
          offsetGradient={offsetGradient}
        />
      ) : (
        <div
          className="flex items-center justify-center"
          style={{height: height + 32}}>
          {loadingOptions ? <Loading /> : 'No data available'}
        </div>
      )}
    </div>
  );
};
