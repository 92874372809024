import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';

import {MyActionType} from '../action_types/MyActionType';
import {FixMeLater} from '../interfaces/FixMeLater';
import {MyAction} from '../actions/my';

type DispatchResult = MyAction | any;

export const getMyInfo = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: MyActionType.MY_LOADING});
    const resp = await fetchWithToken(
      'admin/users/my?timezone=Europe/Madrid&source=web',
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {user} = body;
      dispatch(myInfo(user));
      return user;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};
export const updateMy = (userId: number, fieldsToEdit: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: MyActionType.MY_LOADING});
    const resp = await fetchWithToken(
      `admin/users/${userId}`,
      fieldsToEdit,
      'PUT',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const updatePassword = (userId: number, data: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(`admin/users/${userId}`, data, 'PUT');
    const body = await resp?.json();
    if (body?.success || body?.code) {
      return body;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

const myInfo = (user: FixMeLater) => ({
  type: MyActionType.GET_MY_INFO,
  payload: user,
});
