import * as Yup from 'yup';

import {blankSpacesRegExp} from 'src/helpers/regularExp';

const newUserFormSchema = Yup.object({
  nickname: Yup.string()
    .required('Nickname is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  name: Yup.string()
    .required('Name is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  surname: Yup.string()
    .required('Surname is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  roles: Yup.array().min(1, 'You must select at least one role').required(),
});

const editUserRoleFormSchema = Yup.object({
  roles: Yup.array().min(1, 'You must select at least one role').required(),
});
export {newUserFormSchema, editUserRoleFormSchema};
