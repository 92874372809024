import {useState, useEffect} from 'react';

import {SPECIALITY_LEVELS, Therapist} from 'src/types/Therapist';

import {TherapistSpecialitiesEditModalProps} from '../../TherapistSpecialitiesEditModal';

export const useSpecialities = (therapist: Therapist) => {
  const [specialitiesTable, setSpecialitiesTable] = useState<
    TherapistSpecialitiesEditModalProps['specialitiesTable']
  >([]);

  const [specialitiesTableOther, setSpecialitiesTableOther] = useState<
    TherapistSpecialitiesEditModalProps['specialitiesTableOther']
  >([]);

  useEffect(() => {
    const specialitiesAll = therapist?.account?.specialities || {};

    if (therapist) {
      setSpecialitiesTable(
        Object.entries(specialitiesAll).map(([key, item]) => ({
          ...item,
          originalKey: key,
        })),
      );
      if (therapist.account?.specialitiesOthers) {
        setSpecialitiesTableOther(
          Object.entries(therapist.account?.specialitiesOthers).map(
            ([key, item]) => ({
              ...item,
              originalKey: key,
            }),
          ),
        );
      } else {
        setSpecialitiesTableOther([]);
      }
    }
  }, [therapist]);

  const handleCheckboxChange = (speciality: string, levelOption: string) => {
    const updatedSpecialitiesTable = specialitiesTable.map((value) => {
      if (value.text === speciality) {
        const levelObj = SPECIALITY_LEVELS.find(
          (level) => level.text === levelOption,
        );
        return {...value, level: levelObj ? levelObj.level : -1};
      }
      return value;
    });

    setSpecialitiesTable(updatedSpecialitiesTable);
  };

  const handleCheckboxChangeOther = (
    speciality: string,
    levelOption: string,
  ) => {
    const updatedSpecialitiesTableOther = specialitiesTableOther.map(
      (value) => {
        if (value.text === speciality) {
          const levelObj = SPECIALITY_LEVELS.find(
            (level) => level.text === levelOption,
          );
          return {...value, level: levelObj ? levelObj.level : -1};
        }
        return value;
      },
    );

    setSpecialitiesTableOther(updatedSpecialitiesTableOther);
  };

  const handleDeleteSpeciality = (speciality: string) => {
    const updatedSpecialitiesTable = specialitiesTableOther.filter(
      (item) => item.text !== speciality,
    );

    setSpecialitiesTableOther(updatedSpecialitiesTable);
  };

  return {
    specialitiesTable,
    specialitiesTableOther,
    handleCheckboxChange,
    handleCheckboxChangeOther,
    handleDeleteSpeciality,
    setSpecialitiesTable,
    setSpecialitiesTableOther,
  };
};
