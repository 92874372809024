import React from 'react';

import DatePicker from 'react-datepicker';
import {Form} from 'react-bootstrap';

import {GenericFiltersToggle} from 'src/components/generic_table/filters/GenericFiltersToggle';
import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';
import {IncomingPatient} from 'src/types/IncomingPatient';
import {tomorrow} from 'src/helpers/dates';

import {FormValuesIncomingPatients} from '../../IncomingPatientsPage';

export const AppointmentDateFilter = ({
  formValues,
  updateFormValue,
}: {
  formValues: GenericTableProps<
    IncomingPatient,
    FormValuesIncomingPatients
  >['formValues'];
  updateFormValue: <
    K extends keyof GenericTableProps<
      IncomingPatient,
      FormValuesIncomingPatients
    >['formValues'],
  >(
    field: K,
    subField: keyof GenericTableProps<
      IncomingPatient,
      FormValuesIncomingPatients
    >['formValues'][K],
    value: any,
  ) => void;
}) => {
  return (
    <GenericFiltersToggle
      checked={formValues.appointment?.checked}
      onChange={() =>
        updateFormValue(
          'appointment',
          'checked',
          !formValues.appointment.checked,
        )
      }
      label="Appointment Date">
      {formValues.appointment?.checked && (
        <div className="popover__filter_field">
          <Form.Check
            checked={formValues.appointment.value === false}
            onChange={() => updateFormValue('appointment', 'value', false)}
            inline
            label="Pending scheduling"
            name="appointment"
            type="radio"
            id="inline-radio-1"
          />
          <Form.Check
            checked={formValues.appointment.value !== false}
            onChange={() =>
              updateFormValue('appointment', 'value', {
                from: new Date(),
                to: tomorrow,
              })
            }
            inline
            label="Is between"
            name="appointment"
            type="radio"
            id="inline-radio-2"
          />
          {formValues.appointment?.value !== false && (
            <div className="popover__filter_field">
              <span>is between</span>
              <div className="two_datepicker flex">
                <DatePicker
                  name="appointmentFrom"
                  className="border border-solid border-black"
                  selected={formValues.appointment.value.from}
                  minDate={new Date()}
                  onChange={(date) =>
                    updateFormValue('appointment', 'value', {
                      ...formValues.appointment.value,
                      from: date || new Date(),
                    })
                  }
                />
                <DatePicker
                  name="appointmentTo"
                  className="border border-solid border-black"
                  selected={formValues.appointment.value.to}
                  minDate={new Date()}
                  onChange={(date) =>
                    updateFormValue('appointment', 'value', {
                      ...formValues.appointment.value,
                      to: date || new Date(),
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}
    </GenericFiltersToggle>
  );
};
