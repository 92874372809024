import React from 'react';

import {PaginationComponent} from 'src/components/shared/PaginationComponent';
import {CustomTable} from 'src/components/tables/CustomTable';
import {EmailAuthorization} from 'src/types/EmailAuthorization';

interface EAInterface {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  users: EmailAuthorization[] | [] | null;
}

export default function EmailAuthorizationsTable({
  users,
  total,
  page,
  setPage,
}: EAInterface) {
  return (
    <div>
      <CustomTable
        headChildren={
          <tr>
            <th>Email</th>
            <th>Partner</th>
            <th>Metric</th>
          </tr>
        }
        bodyChildren={
          users &&
          users.map((u, index) => (
            <tr key={index} className="group">
              <td>{u.email}</td>
              <td>{u.partner}</td>
              <td>{u.metric}</td>
            </tr>
          ))
        }
      />
      <PaginationComponent total={total} page={page} setPage={setPage} />
    </div>
  );
}
