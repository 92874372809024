import React, {useEffect, useState} from 'react';

import {Form} from 'react-bootstrap';

import {FixMeLater} from 'src/state/interfaces/FixMeLater';

interface POCInterface {
  columnsVisible: FixMeLater;
  filterSelected: FixMeLater;
  setColumnsVisible: FixMeLater;
  show: boolean;
}

export const PopOverColumns = ({
  show,
  columnsVisible,
  setColumnsVisible,
  filterSelected,
}: POCInterface) => {
  const [formValues, setFormValues] = useState<FixMeLater>({
    email: columnsVisible.includes('email'),
    nickname: columnsVisible.includes('nickname'),
    business: columnsVisible.includes('business'),
    partner: columnsVisible.includes('partner'),
    metric: columnsVisible.includes('metric'),
    therapist: columnsVisible.includes('therapist'),
    status: columnsVisible.includes('status'),
    active_until: columnsVisible.includes('active_until'),
    created_at: columnsVisible.includes('created_at'),
    disorders: columnsVisible.includes('disorders'),
    plan_name: columnsVisible.includes('plan_name'),
  });

  const {
    email,
    id,
    nickname,
    business,
    therapist,
    partner,
    status,
    active_until,
    created_at,
    disorder,
    metric,
    plan_name,
  } = formValues;

  useEffect(() => {
    if (filterSelected !== null) {
      const filtersFiltered = Object.keys(filterSelected).filter(
        (key) => filterSelected[key].checked,
      );
      const fieldFiltersSelected: FixMeLater = [];
      filtersFiltered.forEach((f) => {
        fieldFiltersSelected.push(filterSelected[f].field);
      });
      setColumnsVisible([
        ...new Set([...columnsVisible, ...fieldFiltersSelected]),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelected]);

  const handleCheckChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const newFormValues = {...formValues, [target.name]: target.checked};
    setFormValues(newFormValues);
    const checkedKeys = Object.keys(newFormValues).filter(
      (key) => newFormValues[key],
    );
    setColumnsVisible(checkedKeys);
  };

  return (
    <div className={show ? 'popover-custom on' : 'popover-custom'}>
      <div className="content">
        <form>
          <div className="popover__header">
            <h4>Columns</h4>
          </div>
          <div className="popover__body">
            <div>
              <li>
                <Form.Check
                  checked={columnsVisible.includes('email')}
                  onChange={handleCheckChange}
                  id="popover__columns_email"
                  label="Email"
                  name="email"
                  type="checkbox"
                  disabled={email}
                />
              </li>
              <li>
                <Form.Check
                  checked={id}
                  onChange={handleCheckChange}
                  id="popover__columns_id"
                  label="Id"
                  name="id"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={nickname}
                  onChange={handleCheckChange}
                  id="popover__columns_nickname"
                  label="Nickname"
                  name="nickname"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={business}
                  onChange={handleCheckChange}
                  id="popover__columns_business"
                  label="Business"
                  name="business"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={partner}
                  onChange={handleCheckChange}
                  id="popover__columns_partner"
                  label="Partner"
                  name="partner"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={therapist}
                  onChange={handleCheckChange}
                  id="popover__columns_therapist"
                  label="Therapist"
                  name="therapist"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={status}
                  onChange={handleCheckChange}
                  id="popover__columns_status"
                  label="Status"
                  name="status"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={active_until}
                  onChange={handleCheckChange}
                  id="popover__columns_active_until"
                  label="Active until"
                  name="active_until"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={created_at}
                  onChange={handleCheckChange}
                  id="popover__columns_created_at"
                  label="Created at"
                  name="created_at"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={disorder}
                  onChange={handleCheckChange}
                  id="popover__columns_disorder"
                  label="Disorder"
                  name="disorder"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={metric}
                  onChange={handleCheckChange}
                  id="popover__columns_metric"
                  label="Metric"
                  name="metric"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={plan_name}
                  onChange={handleCheckChange}
                  id="popover__columns_plan_name"
                  label="Plan name"
                  name="plan_name"
                  type="checkbox"
                />
              </li>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
