import React, {ComponentProps} from 'react';

import {
  ArrowCounterClockwise,
  IconProps,
  Note,
  Pause,
  PencilLine,
  Plus,
  XCircle,
} from '@phosphor-icons/react';

import {useSubscription} from 'src/utils/subscriptions-provider';
import ButtonSimple from 'src/components/buttons/ButtonSimple';

interface Props {
  icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
}
const SubscriptionActionButton = ({
  onClick,
  icon: Icon,
  title,
  ...props
}: Props & ComponentProps<'button'>) => (
  <ButtonSimple onClick={onClick} {...props} className="" title={title}>
    <Icon size={16} />
  </ButtonSimple>
);

export const RenderActionsForActivePlan = () => {
  const {
    setIsModalUpdateSubscriptionOpen,
    setIsModalPauseReactivateSubscriptionOpen,
    setIsModalExtendSubscriptionOpen,
    setIsModalCancelSubscriptionOpen,
    setIsModalUpdateSubscriptionNotes,
    setIsModalUndoCancelSubscriptionOpen,
    isPatientB2B,
    isPartnerGympass,
    isSetToCancel,
  } = useSubscription();

  const isEdit = isPatientB2B && !isPartnerGympass && !isSetToCancel;

  return (
    <div className="group-icons-buttons flex items-center justify-center">
      {isEdit && (
        <SubscriptionActionButton
          onClick={() => setIsModalUpdateSubscriptionOpen(true)}
          icon={PencilLine}
          title="Edit subscription"
        />
      )}
      {isSetToCancel ? (
        <SubscriptionActionButton
          onClick={() => setIsModalUndoCancelSubscriptionOpen(true)}
          icon={ArrowCounterClockwise}
          title="Undo cancel subscription"
        />
      ) : (
        <>
          <SubscriptionActionButton
            onClick={() => setIsModalPauseReactivateSubscriptionOpen(true)}
            icon={Pause}
            title="Pause/reactivate subscription"
          />

          <SubscriptionActionButton
            onClick={() => setIsModalExtendSubscriptionOpen(true)}
            icon={Plus}
            title="Extend subscription"
          />
        </>
      )}

      <SubscriptionActionButton
        onClick={() => setIsModalCancelSubscriptionOpen(true)}
        icon={XCircle}
        title="Cancel subscription"
      />
      <SubscriptionActionButton
        onClick={() => setIsModalUpdateSubscriptionNotes(true)}
        icon={Note}
        title="Edit subscription notes"
      />
    </div>
  );
};
