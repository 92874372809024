import Swal from 'sweetalert2';
import {Dispatch} from 'redux';

import {AuthActionType} from 'src/state/action_types/AuthActionType';
import {fetchNoToken, fetchWithToken} from 'src/helpers/fetch';
import {catchApiError} from 'src/helpers/catchApiError';
import {AuthAction} from 'src/state/actions/auth';
import {AuthUser} from 'src/types/Auth';

import {FixMeLater} from '../interfaces/FixMeLater';

type DispatchResult = AuthAction | any;

export const startChecking = () => {
  const idLocalStorage = localStorage.getItem('id') || null;
  const email = localStorage.getItem('email') || null;

  return async (dispatch: Dispatch<AuthAction>) => {
    if (
      email &&
      idLocalStorage &&
      typeof idLocalStorage !== 'undefined' &&
      idLocalStorage !== 'undefined'
    ) {
      const resp = await fetchWithToken(
        `admin/user?id=${idLocalStorage}`,
        null,
      );
      const body: any = await resp?.json();

      if (body && body.success) {
        const {
          id,
          name,
          permissions,
          roles,
        }: {
          id: string;
          name: string;
          permissions: FixMeLater;
          roles: FixMeLater;
        } = body.data.user;

        const authUser: AuthUser = {
          uid: parseInt(id!),
          email,
          name,
          roles,
          rolesPermissions: permissions,
        };
        dispatch({
          type: AuthActionType.AUTH_LOGIN,
          payload: authUser,
        });
      } else {
        Swal.fire({
          title:
            body?.errors?.length > 0 && typeof body.errors[0] === 'string'
              ? body.errors[0]
              : 'Error',
          text:
            body?.errors?.length > 0
              ? body.errors[0]
              : 'There was an error, please contact the administrator',
          icon: 'error',
        });
        localStorage.clear();
        dispatch({type: AuthActionType.AUTH_CHECKING_FINISH});
      }
    } else {
      dispatch({type: AuthActionType.AUTH_CHECKING_FINISH});
    }
  };
};

export const startLogin = (email: string, password: string) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    localStorage.clear();
    const resp = await fetchNoToken(
      'admin/sessions',
      {
        user: {email, password},
      },
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {
        id,
        name,
        permissions,
        roles,
      }: {
        id: string;
        name: string;
        permissions: FixMeLater;
        roles: FixMeLater;
      } = body.data.user;
      localStorage.setItem('email', email);
      localStorage.setItem('id', id);
      localStorage.setItem('token', body.data.auth_token);
      localStorage.setItem('token-init-date', new Date().getTime().toString());

      dispatch({
        type: AuthActionType.AUTH_LOGIN,
        payload: {
          uid: id,
          email: email,
          name: name,
          roles: roles,
          rolesPermissions: permissions,
        },
      });
      return body;
    } else {
      return body;
      // dispatch(catchApiError(body));
    }
  };
};

export const startLogout = () => {
  return async (dispatch: Dispatch<AuthAction | any>) => {
    const resp = await fetchWithToken('admin/sessions', {}, 'DELETE');
    const body = await resp?.json();
    if (body) {
      localStorage.clear();
      dispatch({type: AuthActionType.AUTH_LOGOUT});
    } else {
      if (body?.errors) {
        dispatch(catchApiError(body));
      }
    }
  };
};

export const acceptInvitation = (
  invitation_token: string,
  password: string,
  password_confirmation: string,
) => {
  return async () => {
    const resp = await fetchWithToken(
      'users/invitation',
      {
        user: {
          invitation_token,
          password,
          password_confirmation,
          dashboard: 'true',
        },
      },
      'PATCH',
      true,
    );
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      Swal.fire('Error', body.info ? body.info : 'There was an error', 'error');
      return false;
    }
  };
};
