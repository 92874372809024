import React from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {Spots, SpotsTable} from 'src/types/OperationsDashboard';
import {DonutChart} from 'src/components/graphics/DonutChart';
import {Loading} from 'src/components/shared/Loading';
import {RootState} from 'src/state';

import {OperationsDashboardMainTable} from './OperationsDashboardMainTable';
import {Legend, LegendContainer} from './LegendInfo';
import {PlansTable} from './PlansTable';

export const OperationsDashboardSpotsMain = ({
  spots,
}: {
  spots: Spots | null;
}) => {
  const colorA = '#FFAB90';
  const colorB = '#778BF4';
  const {status} = useSelector(
    (state: RootState) => state.operations_dashboard,
  );
  if (status === SharedActionType.LOADING) {
    return <Loading />;
  }
  if (spots) {
    return (
      <>
        <div className="grid grid-cols-2 gap-7.5">
          <div className="flex h-full items-center justify-center rounded-1.5 bg-text-blueLightLight p-7.5 shadow-sm">
            <div>
              <DonutChart
                series={[spots.free, spots.busy?.total || 0]}
                labels={['Free spots', 'Busy spots']}
                colors={[colorA, colorB]}
              />
            </div>
            <LegendContainer
              className="spots-available flex-1"
              title="Total spots"
              value={spots.totalSpots}
              description={
                <>
                  <Legend
                    color={colorA}
                    title="Free Spots"
                    value={spots.free}
                  />
                  <Legend
                    color={colorB}
                    title="Busy Spots"
                    value={spots.busy.total}
                  />
                </>
              }
            />
          </div>
          <div className="flex rounded-1.5 bg-text-blueLightLight p-7.5 shadow-sm">
            <DonutChart
              series={[spots.busy.b2b, spots.busy.b2c]}
              labels={['B2B', 'B2C']}
              colors={['#FFAB90', '#5C62C5']}
            />
            <LegendContainer
              title="Busy spots"
              value={spots.busy.total}
              description={
                <>
                  <Legend color={colorA} title="B2B" value={spots.busy.b2b} />
                  <Legend color={colorB} title="B2C" value={spots.busy.b2c} />
                </>
              }
            />
          </div>
        </div>
        <div className="mt-5 grid grid-cols-2 gap-7.5 px-5">
          <PlansTable
            title="B2C busy spots"
            plans={spots.subscriptions.b2c}
            color="#F2B351"
          />
          <PlansTable
            title="B2B busy spots"
            plans={spots.subscriptions.b2b}
            color={colorA}
          />
        </div>
        {spots.result && (
          <>
            <h2 className="mt-7.5 w-full">
              Available psychologists
              <span className="ml-1 inline-block font-bold">
                {spots.totalTherapist}
              </span>
            </h2>
            <OperationsDashboardMainTable data={spots.result as SpotsTable[]} />
          </>
        )}
      </>
    );
  } else {
    return <p>There was an error</p>;
  }
};
