import {SharedActionType} from '@ifeelonline/chat-core';

import {Patient} from 'src/types/Patient';
import {Plan} from 'src/types/Plan';

import {PatientAction} from '../actions/patient';
import {PatientActionType} from '../action_types/PatientActionType';

interface PatientState {
  allAttributes: any | null;
  couponsList: string[] | null;
  error: null;
  patient: Patient | null;
  plansList: Plan[] | null;
  status: SharedActionType;
}

const initialState = {
  allAttributes: null,
  couponsList: null,
  error: null,
  patient: null,
  plansList: null,
  status: SharedActionType.IDLE,
};

export const patientReducer = (
  state: PatientState = initialState,
  action: PatientAction,
) => {
  switch (action.type) {
    case PatientActionType.PATIENT_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
        patient: state.patient,
      };

    case PatientActionType.SET_PATIENT:
      return {
        ...state,
        patient: action.payload,
        status: SharedActionType.COMPLETED,
      };

    case PatientActionType.SET_PATIENT_PLANS_OPTIONS:
      return {
        ...state,
        plansList: action.payload.plans,
        couponsList: action.payload.coupons,
      };

    case PatientActionType.SET_ALL_ATTRIBUTES:
      return {
        ...state,
        allAttributes: action.payload,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
