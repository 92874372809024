import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';

import {FixMeLater} from '../interfaces/FixMeLater';
import {SubscriptionFetchData} from '../interfaces/SubscriptionInterface';

type DispatchResult = FixMeLater;

export const createSubscription = (params: SubscriptionFetchData) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const endpoint = `admin/subscriptions?id=${params.id}&stripe_plan=${params.stripePlan}&plan_id=${params.planId}`;
    const resp = await fetchWithToken(endpoint, null, 'POST');
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const updateSubscription = (params: SubscriptionFetchData) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const endpoint = `admin/subscriptions/${params.id}?id=${params.planId}&stripe_plan=${params.stripePlan}`;
    const resp = await fetchWithToken(endpoint, null, 'PUT');

    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const updateSubscriptionNotes = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/subscriptions/update_note`,
      params,
      'PUT',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const unCancelSubscription = (id: number, subId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/subscriptions/uncancel`,
      {id, sub_id: subId},
      'PUT',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const cancelSubscription = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/subscriptions/${params.id}`,
      params,
      'DELETE',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const pauseSubscription = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/subscriptions/paused`,
      params,
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const reactivateSubscription = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/subscriptions/reactivation`,
      params,
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const extendSubscription = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/subscriptions/extension`,
      params,
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};
