import * as React from 'react';

import {ExtendButtonBase, MenuItemTypeMap} from '@mui/material';
import {CaretDown} from '@phosphor-icons/react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import Button from 'src/components/buttons/Button';
import {RBAC} from 'src/helpers/Rbac';

export default function MenuBasic({
  items,
  buttonText,
}: {
  buttonText: string;
  items: ExtendButtonBase<MenuItemTypeMap<any, 'li'>>[];
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        buttonType={ButtonType.SAVE}
        size="small">
        {buttonText}
        <CaretDown size={10} weight="fill" className="ml-1 fill-white" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {items.map((item: any, index: number) =>
          item.controllerName ? (
            <RBAC
              key={index}
              controllerName={item.controllerName}
              actionName={item.actionName}>
              <MenuItem
                key={index}
                onClick={() => {
                  handleClose();
                  item.onClick();
                }}>
                {item.name}
              </MenuItem>
            </RBAC>
          ) : (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose();
                item.onClick();
              }}>
              {item.name}
            </MenuItem>
          ),
        )}
      </Menu>
    </div>
  );
}
