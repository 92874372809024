import React from 'react';

import {Check, Trash} from '@phosphor-icons/react';

import {
  capitalizeFirstLetter,
  parseUnderscore,
} from 'src/helpers/generalFunctions';
import {SPECIALITY_LEVELS} from 'src/types/Therapist';

export const SpecialityRow = ({
  speciality,
  handleCheckboxChange,
  isOthersSpecialities = false,
  handleDeleteSpeciality,
}: {
  handleCheckboxChange: (speciality: string, levelOption: string) => void;
  handleDeleteSpeciality: (speciality: string) => void;
  isOthersSpecialities: boolean;
  speciality: {level: number; text: string};
}) => (
  <tr className="group">
    <td className="min-w-26">
      {capitalizeFirstLetter(parseUnderscore(speciality.text.replace('_', '')))}
    </td>
    {SPECIALITY_LEVELS.map((specialityLevel, index: number) => (
      <td
        key={index + specialityLevel.text + speciality.text}
        onClick={() =>
          handleCheckboxChange(speciality.text, specialityLevel.text)
        }
        className="m-10 w-20 cursor-pointer !text-center">
        {specialityLevel.level === speciality.level && <Check />}
      </td>
    ))}
    {isOthersSpecialities && (
      <td onClick={() => handleDeleteSpeciality(speciality.text)}>
        <span className="btn btn-simple cursor-pointer">
          <Trash />
        </span>
      </td>
    )}
  </tr>
);
