import React from 'react';

import {Warning} from '@phosphor-icons/react';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {Form} from 'src/components/form/Form';

import {useTherapistNewForm} from '../../hooks/useTherapistNewForm';

interface Props {
  closeModal: () => void;
}

export const TherapistNewForm = ({closeModal}: Props) => {
  const {control, onSubmit, isSubmitting, hasErrors, languages} =
    useTherapistNewForm({
      closeModal,
    });
  return (
    <Form
      onSubmit={onSubmit}
      className="flex size-full flex-col justify-between ">
      <ModalLayout.Header>
        <h3>Add therapist</h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        <Form.InputText
          control={control}
          name="nickname"
          label="Nickname"
          placeholder="Enter your nickname"
        />
        <Form.InputText
          control={control}
          name="email"
          label="Email"
          placeholder="example@domain.com"
        />
        <Form.InputText
          control={control}
          name="name"
          label="Name"
          placeholder="Enter your name"
        />
        <Form.InputText
          control={control}
          name="surname"
          label="Surname"
          placeholder="Enter your surname"
        />
        <Form.InputText
          control={control}
          name="phone"
          label="Phone"
          placeholder="Enter your phone"
        />

        {languages && languages.length > 0 && (
          <Form.SelectAutocomplete
            control={control}
            name="locale"
            label="Invitation language"
            options={languages.map((language) => language.name)}
            placeholder="Language in which the invitation should be sent"
          />
        )}

        <Form.CheckboxGroup
          control={control}
          name="createZoomAccount"
          options={['Create Zoom Account']}
          label=""
        />
      </ModalLayout.Body>
      <ModalLayout.Footer>
        {hasErrors && <Warning color="red" />}
        <ButtonSubmit loadingSubmit={isSubmitting} />
      </ModalLayout.Footer>
    </Form>
  );
};
