import React, {useEffect, useState} from 'react';

import parse from 'html-react-parser';

import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import LocaleSelector from 'src/components/shared/LocaleSelector';
import {getPill} from 'src/state/action_creators/pillsCreator';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {Loading} from 'src/components/shared/Loading';
import {useAppDispatch} from 'src/hooks/hooks';
import {Pill} from 'src/types/Pill';

interface Props extends ModalSimpleInterface {
  pillId: number;
}

export const PillPreviewModal = ({show, setShow, pillId}: Props) => {
  const dispatch = useAppDispatch();

  const [locale, setLocale] = useState('en');
  const [loading, setLoading] = useState(true);
  const [pillPreview, setPillPreview] = useState<Pill | null>(null);

  const handleChangeLocale = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setLocale(target.value);
  };

  useEffect(() => {
    if (show) {
      setLoading(true);
      dispatch(getPill(pillId, locale)).then((pill: Pill | null) => {
        setLoading(false);
        if (pill) {
          setPillPreview(pill);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, pillId, locale]);

  const closeModal = () => setShow!(false);

  return (
    <ModalLayout
      show={show}
      closeModal={closeModal}
      className="previewPillModal">
      {pillPreview && !loading ? (
        <>
          <ModalLayout.Body
            isLargeBody={true}
            style={{
              backgroundImage: `url(${pillPreview.backgroundUrl})`,
              backgroundPosition: 'bottom',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}>
            <div className="min-h-full p-5 text-center">
              <div>
                <h2 className="font-sofia text-3.5 font-medium leading-6 text-blue-pillHeader">
                  {pillPreview.logoUrl && (
                    <>
                      <img
                        src={pillPreview.logoUrl}
                        alt="Logo pill"
                        className="inline-block w-7.5"
                      />{' '}
                      |{' '}
                    </>
                  )}
                  {pillPreview.header || 'Missing header'}
                </h2>
              </div>
              <h1 className="my-5 font-recoleta text-7 font-semibold leading-6 text-blue-pillTitle">
                {pillPreview.title || 'Missing title'}
              </h1>
              <div className="pill__description  text-left font-sofia text-4 font-light leading-6 text-blue-pillDescription">
                {parse(pillPreview.description) || 'Missing description'}
              </div>
            </div>
          </ModalLayout.Body>
          <ModalLayout.Footer>
            <div className="locale-selector-footer w-full text-center">
              <LocaleSelector
                locale={locale}
                handleChangeLocale={handleChangeLocale}
                showDefaultItem={false}
              />
            </div>
          </ModalLayout.Footer>
        </>
      ) : (
        <Loading />
      )}
    </ModalLayout>
  );
};
