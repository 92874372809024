import React, {ComponentPropsWithoutRef} from 'react';

import {
  BADGE_TYPE,
  badgeClassesByType,
} from 'src/helpers/set_classes/badgeClasses';

interface Props extends ComponentPropsWithoutRef<'span'> {
  badgeType: BADGE_TYPE;
}

export const Badge = ({badgeType, className, children}: Props) => {
  const typeClasses = badgeType ? badgeClassesByType(badgeType) : '';
  return (
    <span className={`${typeClasses} ${className || ''}`}>{children}</span>
  );
};
