import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {SharedActionType} from '@ifeelonline/chat-core';

import {PageHeader} from 'src/components/layout/PageHeader';
import {Loading} from 'src/components/shared/Loading';
import {PaginationComponent} from 'src/components/shared/PaginationComponent';
import {CustomTable} from 'src/components/tables/CustomTable';
import {NoResults} from 'src/components/tables/NoResults';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';
import {getSupervisors} from 'src/state/action_creators/supervisorsCreator';
import {Supervisor} from 'src/types/Supervisor';

const initValues = {
  page: 1,
  orderBy: 'id',
  orderDesc: true,
};

export const SupervisorsTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {status, supervisors, total} = useSelector(
    (state: RootState) => state.supervisors,
  );

  const [page, setPage] = useState(initValues.page);

  const columns = ['id', 'therapistId', 'ref', 'fullName'];

  const handleRowClick = (therapistId: number) => {
    navigate(`/therapist/${therapistId}`);
  };

  const dispatchGetSupervisors = () => {
    dispatch(getSupervisors(page));
  };

  useEffect(() => {
    dispatchGetSupervisors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return status === SharedActionType.LOADING ? (
    <Loading />
  ) : (
    <div>
      <PageHeader pageTitle="Supervisors" />
      {supervisors && (
        <>
          {total > 0 ? (
            <CustomTable
              headChildren={
                <tr>
                  {columns.map((c, index) => (
                    <th key={index}>{c.replace('_', ' ')}</th>
                  ))}
                </tr>
              }
              bodyChildren={supervisors.map(
                (supervisor: Supervisor, index: number) => (
                  <tr
                    className="cursor-pointer"
                    key={index}
                    onClick={() => handleRowClick(supervisor.therapistId)}>
                    {columns.map((c, index) => (
                      <td key={index}>{supervisor[c as keyof Supervisor]}</td>
                    ))}
                  </tr>
                ),
              )}
            />
          ) : (
            <NoResults />
          )}
        </>
      )}

      <PaginationComponent total={total} page={page} setPage={setPage} />
    </div>
  );
};
