import {EmailAuthorizationsPage} from 'src/pages/email_authorizations/EmailAuthorizationsPage';
import {OperationsDashboardPage} from 'src/pages/operation_dashboard/OperationsDashboardPage';
import {AcceptInvitationPage} from 'src/pages/accept_invitation/AcceptInvitationPage';
import {IncomingPatientsPage} from 'src/pages/incoming_patients/IncomingPatientsPage';
import {RecentActivityPage} from 'src/pages/recent_activity/RecentActivityPage';
import {UsersAndRolesPage} from 'src/pages/users_and_roles/UsersAndRolesPage';
import {VideoSessionsPage} from 'src/pages/video_sessions/VideoSessionsPage';
import {TherapistShowPage} from 'src/pages/therapists/TherapistShowPage';
import {SupportSettingsPage} from 'src/pages/chat/SupportSettingsPage';
import {SupervisorsPage} from 'src/pages/supervisors/SupervisorsPage';
import ToolsB2bReportsPage from 'src/pages/tools/ToolsB2bReportsPage';
import {TherapistsPage} from 'src/pages/therapists/TherapistsPage';
import {PatientShowPage} from 'src/pages/patient/PatientShowPage';
import {DashboardPage} from 'src/pages/dashboard/DashboardPage';
import {PartnersPage} from 'src/pages/partners/PartnersPage';
import {PatientsPage} from 'src/pages/patients/PatientsPage';
import WebhooksPage from 'src/pages/webhooks/WebhooksPage';
import {ProfilePage} from 'src/pages/profile/ProfilePage';
import {ControllerNames} from 'src/types/ControllerNames';
import {Custom404} from 'src/pages/custom404/Custom404';
import {EventsPage} from 'src/pages/events/EventsPage';
import {SearchPage} from 'src/pages/search/SearchPage';
import {LoginPage} from 'src/pages/login/LoginPage';
import {PillsPage} from 'src/pages/pills/PillsPage';
import {PlansPage} from 'src/pages/plans/PlansPage';
import {RolesPage} from 'src/pages/roles/RolesPage';
import {HomePage} from 'src/pages/home/HomePage';
import {Page} from 'src/types/Page';

import {RoutesPath} from './RoutesPath';

const privateRoutes: {
  component: ({controllerName}: Page) => JSX.Element;
  controllerName: ControllerNames;
  path: string;
  shouldShareControllerName: boolean;
}[] = [
  {
    path: RoutesPath.HOME,
    controllerName: ControllerNames.HOME,
    component: HomePage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.DASHBOARD,
    controllerName: ControllerNames.DASHBOARDS,
    component: DashboardPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.EMAIL_AUTHORIZATIONS,
    controllerName: ControllerNames.EMAILS_AUTHORIZATIONS,
    component: EmailAuthorizationsPage,
    shouldShareControllerName: true,
  },
  {
    path: RoutesPath.INCOMING_PATIENTS,
    controllerName: ControllerNames.INCOMING_PATIENTS,
    component: IncomingPatientsPage,
    shouldShareControllerName: true,
  },
  {
    path: RoutesPath.EVENTS,
    controllerName: ControllerNames.EVENTS,
    component: EventsPage,
    shouldShareControllerName: true,
  },
  {
    path: RoutesPath.OPERATION_DASHBOARD,
    controllerName: ControllerNames.OPERATION_DASHBOARD,
    component: OperationsDashboardPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.PARTNERS,
    controllerName: ControllerNames.PARTNERS,
    component: PartnersPage,
    shouldShareControllerName: true,
  },
  {
    path: RoutesPath.PATIENT,
    controllerName: ControllerNames.USERS,
    component: PatientShowPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.PATIENTS,
    controllerName: ControllerNames.USERS,
    component: PatientsPage,
    shouldShareControllerName: true,
  },
  {
    path: RoutesPath.PILLS,
    controllerName: ControllerNames.PILLS,
    component: PillsPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.PLANS,
    controllerName: ControllerNames.PLANS,
    component: PlansPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.PROFILE,
    controllerName: ControllerNames.USERS,
    component: ProfilePage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.RECENT_ACTIVITY,
    controllerName: ControllerNames.EVENTS,
    component: RecentActivityPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.SEARCH,
    controllerName: ControllerNames.USERS,
    component: SearchPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.SUPERVISORS,
    controllerName: ControllerNames.SUPERVISORS,
    component: SupervisorsPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.THERAPIST,
    controllerName: ControllerNames.THERAPISTS,
    component: TherapistShowPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.THERAPISTS,
    controllerName: ControllerNames.THERAPISTS,
    component: TherapistsPage,
    shouldShareControllerName: true,
  },
  {
    path: RoutesPath.TOOLS,
    controllerName: ControllerNames.TOOLS,
    component: ToolsB2bReportsPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.USERS_AND_ROLES,
    controllerName: ControllerNames.USER_ROLES,
    component: UsersAndRolesPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.SUPPORT_SETTINGS,
    controllerName: ControllerNames.SUPPORT,
    component: SupportSettingsPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.ROLES,
    controllerName: ControllerNames.ROLES,
    component: RolesPage,
    shouldShareControllerName: false,
  },
  {
    path: RoutesPath.VIDEO_SESSIONS,
    controllerName: ControllerNames.VIDEO_SESSIONS,
    component: VideoSessionsPage,
    shouldShareControllerName: true,
  },
  {
    path: RoutesPath.WEBHOOKS,
    controllerName: ControllerNames.WEBHOOKS,
    component: WebhooksPage,
    shouldShareControllerName: false,
  },
  {
    path: '*',
    controllerName: ControllerNames.SESSIONS,
    component: Custom404,
    shouldShareControllerName: false,
  },
];

const publicRoutes: {
  component: () => JSX.Element;
  path: string;
}[] = [
  {
    path: RoutesPath.LOGIN,
    component: LoginPage,
  },
  {
    path: RoutesPath.ACCEPT_INVITATION,
    component: AcceptInvitationPage,
  },
];

export const RoutesConfig = {
  private: privateRoutes,
  public: publicRoutes,
};
