import React, {useEffect} from 'react';

import {useController} from 'react-hook-form';

import {FormControl, FormControlLabel, Theme} from '@mui/material';
import {SxProps} from '@mui/system';

import {COLOR_ERROR} from 'src/styles/colors/colors';

import {ErrorText} from './ErrorText';
import {ALL_OPTION, BaseControlledComponentProps} from './type';
import {CustomCheckbox} from './StylesFormControlled';

interface CheckboxGroupProps
  extends Omit<BaseControlledComponentProps, 'placeholder'> {
  direction?: 'row' | 'column';
  hasCheckAllOption?: boolean;
  hasValueLabel?: boolean;
  options: string[];
  sx?: SxProps<Theme>;
}

export const CheckboxGroup = ({
  control,
  name,
  options,
  label,
  direction = 'column',
  hasValueLabel = true,
  hasCheckAllOption,
  sx,
}: CheckboxGroupProps) => {
  const {field, fieldState} = useController({
    control,
    name,
  });
  const [value, setValue] = React.useState(field.value || []);
  const isInvalid = fieldState.invalid;

  useEffect(() => {
    if (field.value === value) return;
    setValue(field.value || []);
  }, [field.value]);

  const customSx = sx
    ? sx
    : {
        [`&, `]: {
          marginLeft: '11px',
          color: isInvalid
            ? COLOR_ERROR
            : 'rgb(209 213 219 / var(--tw-border-opacity))',
          borderWidth: '1px',
        },
      };

  const rowsSx = {
    '&': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    label: {
      marginBottom: '0',
      margin: '0',
    },
  };

  return (
    <FormControl
      sx={direction === 'row' ? rowsSx : {}} //I need to do this here because the SX prop whines about the type if i try to do it in the rowsSx
      className={`flex max-w-full overflow-hidden pl-2 ${
        direction === 'row' ? '!flex-row' : 'flex-col items-start'
      }`}>
      <label className="font-regular mb-1 block">{label}</label>
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          control={
            <CustomCheckbox
              value={option}
              onChange={(e) => {
                if (
                  hasCheckAllOption &&
                  option.toLowerCase() === ALL_OPTION.toLowerCase()
                ) {
                  if (e.target.checked) {
                    setValue(options);
                    field.onChange(options);
                  } else {
                    setValue([]);
                    field.onChange([]);
                  }
                } else {
                  const isChecked = e.target.checked;
                  const updatedValue = isChecked
                    ? [...value, option]
                    : value.filter((item: string) => item !== option);
                  setValue(updatedValue);
                  field.onChange(updatedValue);
                }
              }}
              key={index}
              checked={value.includes(option)}
              sx={customSx}
            />
          }
          label={hasValueLabel ? option : ''}
        />
      ))}
      {fieldState.error?.message && (
        <ErrorText error={fieldState.error?.message} size="small" />
      )}
    </FormControl>
  );
};
