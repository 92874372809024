import {SharedActionType} from '@ifeelonline/chat-core';

import {VideoSession} from 'src/types/VideoSession';

import {VideoSessionsActionType} from '../action_types/VideoSessionsActionType';
import {VideoSessionAction} from '../actions/videoSessions';

export interface PartnerListItem {
  id: number;
  name: string;
}
interface VSState {
  error: null | string;
  partnersListSelector: null | PartnerListItem[];
  status: SharedActionType;
  total: number;
  totalCount: null | number;
  videoSessions: VideoSession[];
}

const initialState = {
  videoSessions: [],
  partnersListSelector: null,
  status: SharedActionType.IDLE,
  totalCount: null,
  error: null,
  total: 0,
};

export const videoSessionReducer = (
  state: VSState = initialState,
  action: VideoSessionAction,
) => {
  switch (action.type) {
    case VideoSessionsActionType.VIDEO_SESSIONS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case VideoSessionsActionType.SET_SELECTORS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case VideoSessionsActionType.SET_SELECTORS:
      return {
        ...state,
        partnersListSelector: action.payload.partners,
      };

    case VideoSessionsActionType.SET_VIDEO_SESSIONS:
      return {
        ...state,
        videoSessions: action.payload.videoSessions,
        total: action.payload.totalCount,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
