import {
  IncomingPatient,
  AppointmentStatus,
  IncomingPatientStorage,
} from 'src/types/IncomingPatient';

export function mapApiResponseIncomingPatients(
  response: IncomingPatientStorage[],
): IncomingPatient[] {
  return response.map((incomingPatient) => ({
    id: incomingPatient.id,
    appointment: incomingPatient.appointment ?? null,
    appointmentStatus: mapApiResponseIncomingPatientsStatus(
      incomingPatient.appointment_status,
    ),
    email: incomingPatient.email,
    name: incomingPatient.name,
    phone: incomingPatient.phone,
    eligibleId: incomingPatient.eligible_id,
    isHighRisk: incomingPatient.high_risk,
    psychologistGuide: incomingPatient.psychologist_guide
      ? {
          id: incomingPatient.psychologist_guide.id,
          nickname: incomingPatient.psychologist_guide.nickname,
        }
      : null,
    conversationUrl: incomingPatient.conversation_url,
    unassignedCalls: false,
  }));
}

function mapApiResponseIncomingPatientsStatus(
  status: 0 | 1,
): AppointmentStatus {
  switch (status) {
    case 0:
      return AppointmentStatus.ToCall;
    case 1:
      return AppointmentStatus.Scheduled;
    default:
      throw new Error('Invalid appointment status');
  }
}
