import React, {useEffect} from 'react';

import {Form} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';
import {useSelector} from 'react-redux';

import Button from 'src/components/buttons/Button';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {RootState} from 'src/state';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {PartnerSelector} from 'src/types/Partner';

interface PopOverFiltersInterface {
  filterSelected: FixMeLater;
  handleToggleFilter: FixMeLater;
  initValues: FixMeLater;
  partnerCheck: boolean;
  partnerValues: FixMeLater;
  setFilterSelected: FixMeLater;
  setOrderBy: FixMeLater;
  setOrderDesc: FixMeLater;
  setPage: FixMeLater;
  setPartnerCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setPartnerValues: React.Dispatch<React.SetStateAction<FixMeLater>>;
  setStartTimeCheck: FixMeLater;
  setStartTimeEnd: FixMeLater;
  setStartTimeStart: FixMeLater;
  setValidatedCheck: FixMeLater;
  setValidatedValue: FixMeLater;
  show: boolean;
  startTimeCheck: FixMeLater;
  startTimeEnd: FixMeLater;
  startTimeStart: FixMeLater;
  validatedCheck: FixMeLater;
  validatedValue: FixMeLater;
}

export const PopOverFilters = ({
  show,
  initValues,
  setPage,
  setOrderBy,
  setOrderDesc,
  handleToggleFilter,
  filterSelected,
  setFilterSelected,
  startTimeCheck,
  setStartTimeCheck,
  startTimeStart,
  setStartTimeStart,
  startTimeEnd,
  setStartTimeEnd,
  validatedCheck,
  setValidatedCheck,
  validatedValue,
  setValidatedValue,
  partnerCheck,
  setPartnerCheck,
  partnerValues,
  setPartnerValues,
}: PopOverFiltersInterface) => {
  const {partnersListSelector} = useSelector(
    (state: RootState) => state.videoSessions,
  );
  const handleCheckChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    let newPartner = partnerValues;
    if (!target.checked) {
      newPartner = partnerValues.filter(
        (item: FixMeLater) => item !== parseInt(target.name),
      );
    } else if (partnerValues.indexOf(target.name) < 0) {
      newPartner = [...partnerValues, parseInt(target.name)];
    }
    setPartnerValues(newPartner);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!startTimeCheck && !validatedCheck && !partnerCheck) {
      toast.error('You must select some filter');
    } else if (startTimeEnd.getTime() < startTimeStart.getTime()) {
      toast.error(
        'Review the selected dates. The initial cannot be later than the final',
      );
    } else {
      setPage(1);
      setOrderBy('id');
      setOrderDesc('desc');
      const filtersObj = {
        startTime: {
          checked: startTimeCheck,
          values: {
            start: startTimeStart,
            end: startTimeEnd,
          },
          field: 'start_time',
          badge: 'Start time',
        },
        validated: {
          checked: validatedCheck,
          values: validatedValue,
          field: 'validated',
          badge: 'Validated',
        },
        partner: {
          checked: partnerCheck,
          values: partnerValues,
          field: 'partner',
          badge: 'Partner',
        },
      };
      setFilterSelected(filtersObj);
      handleToggleFilter('filter');
    }
  };

  const clearForm = (e: React.MouseEvent) => {
    e.preventDefault();
    setPage(initValues.page);
    setOrderBy(initValues.orderBy);
    setOrderDesc(initValues.orderDesc);
    setStartTimeCheck(false);
    setStartTimeStart(initValues.startTime.values.start);
    setStartTimeEnd(initValues.startTime.values.end);
    setValidatedCheck(false);
    setValidatedValue(initValues.validated.value);
    setPartnerCheck(false);
    setPartnerValues([]);
    setFilterSelected(null);
    handleToggleFilter('filter');
  };

  useEffect(() => {
    if (filterSelected) {
      if (filterSelected.startTime && filterSelected.startTime.checked) {
        setStartTimeCheck(true);
        setStartTimeStart(filterSelected.startTime.values.start);
        setStartTimeEnd(filterSelected.startTime.values.end);
      }
      if (filterSelected.validated && filterSelected.validated.checked) {
        setValidatedCheck(true);
      }
      if (filterSelected.partner && filterSelected.partner.checked) {
        setPartnerCheck(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={show ? 'popover-custom on' : 'popover-custom'}>
      <div className="content">
        <form onSubmit={handleSubmit} className="popover-filter-patients">
          <div className="popover__header">
            <Button
              buttonType={ButtonType.WHITE}
              size="small"
              onClick={clearForm}
              type="submit">
              Clear
            </Button>
            <h4>Filters</h4>
            <Button buttonType={ButtonType.PRIMARY} size="small" type="submit">
              Done
            </Button>
          </div>
          <div className="popover__body">
            <div>
              <li>
                <Form.Check
                  checked={startTimeCheck}
                  onChange={() => setStartTimeCheck(!startTimeCheck)}
                  id="popover__filter_created_at"
                  label="Start time"
                  type="checkbox"
                />
              </li>
              {startTimeCheck && (
                <div className="popover__filter_field">
                  <span>is between</span>
                  <div className="two_datepicker flex">
                    <DatePicker
                      name="startTimeStart"
                      className="border border-solid border-black"
                      selected={startTimeStart}
                      onChange={(date) => setStartTimeStart(date)}
                      maxDate={new Date()}
                    />
                    <DatePicker
                      name="startTimeEnd"
                      className="border border-solid border-black"
                      selected={startTimeEnd}
                      onChange={(date) => setStartTimeEnd(date)}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
              )}
            </div>

            <div>
              <li>
                <Form.Check
                  checked={validatedCheck}
                  onChange={() => setValidatedCheck(!validatedCheck)}
                  id="popover__filter_validated"
                  label="Validated"
                  type="checkbox"
                />
              </li>
              {validatedCheck && (
                <div className="popover__filter_field">
                  <Form.Check
                    checked={validatedValue}
                    onChange={() => setValidatedValue(true)}
                    inline
                    label="Yes"
                    name="validated"
                    type="radio"
                    id="inline-radio-1"
                  />
                  <Form.Check
                    checked={!validatedValue}
                    onChange={() => setValidatedValue(false)}
                    inline
                    label="Pending"
                    name="validated"
                    type="radio"
                    id="inline-radio-2"
                  />
                </div>
              )}
            </div>
            <div>
              <li>
                <Form.Check
                  checked={partnerCheck}
                  onChange={() => setPartnerCheck(!partnerCheck)}
                  id="popover__filter_partner"
                  label="Partner"
                  type="checkbox"
                />
              </li>
              {partnerCheck && (
                <div className="popover__filter_field">
                  <span>belongs to</span>
                  <div className="">
                    <ul>
                      {partnersListSelector &&
                        partnersListSelector.map(
                          (partner: PartnerSelector, i: number) => (
                            <li key={i}>
                              <Form.Check
                                checked={
                                  partnerValues &&
                                  partnerValues.indexOf(partner.id) > -1
                                }
                                onChange={handleCheckChange}
                                id={`popover__filter_partner_${partner.name}`}
                                label={partner.name}
                                name={partner.id?.toString()}
                                type="checkbox"
                              />
                            </li>
                          ),
                        )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
