import React from 'react';

import {PaginationComponent} from 'src/components/shared/PaginationComponent';
import {CustomTable} from 'src/components/tables/CustomTable';
import {NoResults} from 'src/components/tables/NoResults';
import {WebHook} from 'src/types/WebHook';

export default function WebhooksTable({
  webhooks,
  total,
  page,
  setPage,
}: {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  webhooks: WebHook[] | null;
}) {
  return (
    <div>
      {webhooks ? (
        <CustomTable
          headChildren={
            <tr>
              <th>Response code</th>
              <th>Response error</th>
              <th>Created at</th>
              <th>Data</th>
              <th>Partner</th>
              <th>Status</th>
              <th>Type of event</th>
              <th>User id</th>
            </tr>
          }
          bodyChildren={webhooks.map((w, index) => (
            <tr key={index} className="group">
              <td>{w.response_code}</td>
              <td>{w.response_error}</td>
              <td>{w.created_at}</td>
              <td>{JSON.stringify(w.data)}</td>
              <td>{w.partner_id}</td>
              <td>{w.status}</td>
              <td>{w.type_of_event}</td>
              <td>{w.user_id}</td>
            </tr>
          ))}
        />
      ) : (
        <NoResults />
      )}

      <PaginationComponent total={total} page={page} setPage={setPage} />
    </div>
  );
}
