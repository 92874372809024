import React from 'react';

import {EyeClosed, Pencil} from '@phosphor-icons/react';

import {capitalizeFirstLetter} from 'src/helpers/generalFunctions';
import ButtonSimple from 'src/components/buttons/ButtonSimple';
import {Pill, PillActions} from 'src/types/Pill';

interface GenericPillCellProps extends React.ComponentProps<'div'> {
  handlePreviewPill: (pill: Pill) => void;
  pill: Pill;
}

const GenericPillCell = ({
  children,
  pill,
  handlePreviewPill,
}: GenericPillCellProps) => {
  return (
    <div onClick={() => handlePreviewPill(pill)} className="cursor-pointer">
      {children}
    </div>
  );
};

export function renderTableCell(
  column: {key: keyof PillActions; name: string},
  pill: Pill,
  handleEdit: (pill: Pill) => void,
  handlePreviewPill: (pill: Pill) => void,
) {
  switch (column.key) {
    case 'actions':
      return (
        <>
          <ButtonSimple onClick={() => handleEdit(pill)}>
            <Pencil size={16} />
          </ButtonSimple>

          <ButtonSimple
            onClick={() => handlePreviewPill(pill)}
            className="ml-3">
            <EyeClosed size={16} />
          </ButtonSimple>
        </>
      );
    case 'active':
      return (
        <GenericPillCell pill={pill} handlePreviewPill={handlePreviewPill}>
          {pill[column.key] ? 'Active' : 'Inactive'}
        </GenericPillCell>
      );
    case 'typeOfPill':
      return (
        <GenericPillCell pill={pill} handlePreviewPill={handlePreviewPill}>
          {capitalizeFirstLetter(pill[column.key])}
        </GenericPillCell>
      );
    default:
      return (
        <GenericPillCell pill={pill} handlePreviewPill={handlePreviewPill}>
          {pill[column.key]}
        </GenericPillCell>
      );
  }
}
