import React from 'react';

import {useSelector} from 'react-redux';

import {PencilLine} from '@phosphor-icons/react';

import {getTherapistDetailsSection} from 'src/helpers/data_generation/therapist';
import {LabelValueDisplay} from 'src/components/LabelValueDisplay';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import Button from 'src/components/buttons/Button';
import {Therapist} from 'src/types/Therapist';
import {RootState} from 'src/state';

export const DetailsComponent = ({
  therapist,
  setModalEditDetails,
}: {
  setModalEditDetails: React.Dispatch<React.SetStateAction<boolean>>;
  therapist: Therapist;
}) => {
  const {user} = useSelector((state: RootState) => state.auth);
  const therapistDetailsItems = getTherapistDetailsSection(
    therapist,
    ['finance', 'op_manager', 'admin', 'dashboard_admin'],
    user,
  );

  return (
    <>
      <div>
        <div className="section_header">
          <h3>Details</h3>
          <Button
            buttonType={ButtonType.ACTIONS}
            size="small"
            onClick={() => setModalEditDetails(true)}>
            <PencilLine size="14" />
          </Button>
        </div>
        {therapistDetailsItems &&
          therapistDetailsItems.map((item) => {
            return (
              <LabelValueDisplay
                key={item.label}
                value={item.value || ''}
                label={item.label}
              />
            );
          })}
      </div>
    </>
  );
};
