import React from 'react';

import {ButtonType} from 'src/helpers/set_classes/buttonClasses';

import Button from '../buttons/Button';

export interface PaginationComponentProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  total: number;
}

export const PaginationComponent = ({
  total,
  page,
  setPage,
}: PaginationComponentProps) => {
  const totalPages = Math.ceil(total / 20);
  return (
    <div className="flex items-center justify-between" data-testid="pagination">
      <p>
        <b>{total}</b> results
      </p>
      <div className="flex justify-end">
        {total > 0 && (
          <p>
            <b>
              Page {page} of {totalPages}
            </b>
          </p>
        )}
        {totalPages > 1 && (
          <>
            <Button
              buttonType={ButtonType.WHITE}
              size="small"
              disabled={page === 1}
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                }
              }}>
              Previous
            </Button>
            <Button
              buttonType={ButtonType.WHITE}
              size="small"
              disabled={page >= total / 20}
              onClick={() => {
                if (page < total / 20) {
                  setPage(page + 1);
                }
              }}>
              Next
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
