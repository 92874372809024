import React from 'react';

import {Warning} from '@phosphor-icons/react';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {Form} from 'src/components/form/Form';

import {useCSVWithPreviewForm} from '../../hooks/useCSVWithPreviewForm';

import {Step1} from './Step1';
import {Step2} from './Step2';

export interface FormValues {
  csv: File | null;
  partner_name?: string;
}

interface Props {
  canSelectPartner: boolean;
  closeModal: () => void;
  dispatchPreview: any;
  dispatchSave: any;
  formatPreviewRes: any;
  isEdit?: boolean;
  isModalOpen: boolean;
  setStepForm: React.Dispatch<React.SetStateAction<number>>;
  stepForm: number;
  title: string;
}
export const CSVWithPreviewForm = ({
  title,
  closeModal,
  isModalOpen,
  dispatchPreview,
  dispatchSave,
  formatPreviewRes,
  canSelectPartner,
  isEdit,
  setStepForm,
  stepForm,
}: Props) => {
  const {
    previewInfo,
    setEmailListPost,
    setUploadedCsv,
    isSubmitting,
    hasErrors,
    control,
    onSubmit,
    valuesHaveChanged,
  } = useCSVWithPreviewForm({
    isModalOpen,
    closeModal,
    dispatchPreview,
    dispatchSave,
    formatPreviewRes,
    setStepForm,
    stepForm,
    canSelectPartner,
  });

  return (
    <Form
      onSubmit={onSubmit}
      className="flex size-full flex-col justify-between">
      <ModalLayout.Header>
        <h3 className="flex items-center justify-between">
          {title}{' '}
          {canSelectPartner &&
            stepForm === 2 &&
            previewInfo &&
            previewInfo.partner && (
              <b style={{marginLeft: '4px'}}>
                - Partner: {previewInfo.partner}
              </b>
            )}
        </h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        {stepForm === 1 ? (
          <Step1
            setUploadedCsv={setUploadedCsv}
            canSelectPartner={canSelectPartner}
            control={control}
          />
        ) : (
          <Step2
            previewInfo={previewInfo}
            setEmailListPost={setEmailListPost}
            isEdit={isEdit}
          />
        )}
      </ModalLayout.Body>
      <ModalLayout.Footer>
        {hasErrors && <Warning color="red" />}
        {stepForm === 1 ? (
          <ButtonSubmit
            loadingSubmit={isSubmitting}
            text="Preview"
            disabled={valuesHaveChanged()}
          />
        ) : (
          <button
            className="btn btn-white btn-small"
            onClick={(e) => {
              e.preventDefault();
              setStepForm(stepForm - 1);
            }}>
            Back
          </button>
        )}
      </ModalLayout.Footer>
    </Form>
  );
};
