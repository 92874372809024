import React from 'react';

import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {
  arrayToCommaList,
  capitalizeFirstLetter,
} from 'src/helpers/generalFunctions';
import {PaginationComponent} from 'src/components/shared/PaginationComponent';
import UserSvg from 'src/assets/images/operations_dashboard/icon_user.svg';
import {OperationDashboardTherapist} from 'src/types/OperationsDashboard';
import {TableType} from 'src/helpers/set_classes/tableClasses';
import {CustomTable} from 'src/components/tables/CustomTable';
import {RootState} from 'src/state';

export const OperationsDashboardMain = ({
  therapists,
  page,
  setPage,
}: {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  therapists: OperationDashboardTherapist[];
}) => {
  const navigate = useNavigate();
  const {totalTherapist} = useSelector(
    (state: RootState) => state.operations_dashboard,
  );

  const columns = [
    'full_name',
    'free_spots',
    'speciality',
    'age',
    'gender',
  ] as const;
  type Column = (typeof columns)[number];

  const handleOrderBy = () => {
    // TO-DO
  };
  const handleRowClick = (e: React.MouseEvent, id: number) => {
    if (e.metaKey || e.ctrlKey) {
      const win = window.open(`/therapist/${id}`, '_blank');
      win?.focus();
    } else {
      navigate(`/therapist/${id}`);
    }
  };

  function genericTd(
    index: number,
    therapist: OperationDashboardTherapist,
    children: React.ReactNode,
  ) {
    return (
      <td
        key={index}
        className="cursor-pointer"
        onClick={(e) => handleRowClick(e, therapist.id)}>
        {children}
      </td>
    );
  }

  const getColumnName = (column: Column) => {
    if (column === 'full_name') return '';
    if (column === 'free_spots') return 'Patients free';
    return capitalizeFirstLetter(column);
  };

  return (
    <>
      <CustomTable
        tableType={TableType.DASHBOARD}
        headChildren={
          <tr>
            {columns.map((column: Column, index: number) => (
              <th
                key={index}
                className={`${
                  [
                    'full_name',
                    'patients_free',
                    'orientation',
                    'age',
                    'gender',
                  ].includes(column) || 'cursor-pointer'
                }`}
                onClick={() => handleOrderBy()}>
                {getColumnName(column)}
              </th>
            ))}
            <th key={columns.length}></th>
          </tr>
        }
        bodyChildren={therapists.map(
          (
            operationDashboardTherapist: OperationDashboardTherapist,
            index: number,
          ) => (
            <tr key={index}>
              {columns.map((column: Column, index: number) => {
                switch (column) {
                  case 'full_name':
                    return (
                      <td
                        key={index}
                        className="cursor-pointer"
                        onClick={(e) =>
                          handleRowClick(e, operationDashboardTherapist.id)
                        }>
                        <span className="font-semibold">
                          {operationDashboardTherapist.fullName
                            ? capitalizeFirstLetter(
                                operationDashboardTherapist.fullName,
                              )
                            : ''}
                        </span>
                        <br />
                        <span className="font-semibold text-blue-light">
                          {operationDashboardTherapist.license || ''}
                        </span>
                      </td>
                    );
                  case 'speciality':
                    return (
                      <td
                        key={index}
                        className="cursor-pointer"
                        onClick={(e) =>
                          handleRowClick(e, operationDashboardTherapist.id)
                        }>
                        {operationDashboardTherapist.speciality
                          ? arrayToCommaList(
                              operationDashboardTherapist.speciality,
                              'black',
                            )
                          : '_'}
                      </td>
                    );
                  case 'age':
                    return genericTd(
                      index,
                      operationDashboardTherapist,
                      operationDashboardTherapist.age,
                    );
                  case 'gender':
                    return genericTd(
                      index,
                      operationDashboardTherapist,
                      operationDashboardTherapist.gender,
                    );
                  case 'free_spots':
                    return genericTd(
                      index,
                      operationDashboardTherapist,
                      operationDashboardTherapist.freeSpots,
                    );
                }
              })}
              <td key={columns.length} className="cursor-pointer">
                <UserSvg />
              </td>
            </tr>
          ),
        )}
      />
      {totalTherapist && (
        <PaginationComponent
          total={totalTherapist}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  );
};
