import React, {createContext, useContext, useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {GYMPASS_CODES} from 'src/constants/Partners';
import {hasAnActivePlan} from 'src/helpers/subscriptions';
import {RootState} from 'src/state';

type SubscriptionContextType = {
  isModalCancelSubscriptionOpen: boolean;
  isModalCreateSubscriptionOpen: boolean;
  isModalEditSubscriptionNotes: boolean;
  isModalExtendSubscriptionOpen: boolean;
  isModalPauseReactivateSubscriptionOpen: boolean;
  isModalUndoCancelSubscriptionOpen: boolean;
  isModalUpdateSubscriptionOpen: boolean;
  isPartnerGympass: boolean | undefined;
  isPatientB2B: boolean | undefined;
  isPlanActive: boolean | undefined;
  isPlanPaused: boolean | undefined;
  isReactivateSubscription: boolean;
  isSetToCancel: boolean | undefined;
  setIsModalCancelSubscriptionOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsModalCreateSubscriptionOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsModalExtendSubscriptionOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsModalPauseReactivateSubscriptionOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsModalUndoCancelSubscriptionOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsModalUpdateSubscriptionNotes: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsModalUpdateSubscriptionOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsReactivateSubscription: React.Dispatch<React.SetStateAction<boolean>>;
};
const SubscriptionContext = createContext<SubscriptionContextType | undefined>(
  undefined,
);

function SubscriptionProvider({children}: {children: React.ReactNode}) {
  const {patient} = useSelector((state: RootState) => state.patient);

  const [isPatientB2B, setIsPatientB2B] = useState<boolean | undefined>(
    undefined,
  );
  const [isPlanActive, setIsPlanActive] = useState<boolean | undefined>(
    undefined,
  );
  const [isPlanPaused, setIsPlanPaused] = useState<boolean | undefined>(
    undefined,
  );
  const [isPartnerGympass, setIsPartnerGympass] = useState<boolean | undefined>(
    undefined,
  );

  const [isReactivateSubscription, setIsReactivateSubscription] =
    useState(false);

  const [isSetToCancel, setIsSetToCancel] = useState<boolean | undefined>(
    undefined,
  );

  const [isModalUpdateSubscriptionOpen, setIsModalUpdateSubscriptionOpen] =
    useState(false);
  const [isModalExtendSubscriptionOpen, setIsModalExtendSubscriptionOpen] =
    useState(false);
  const [isModalCancelSubscriptionOpen, setIsModalCancelSubscriptionOpen] =
    useState(false);
  const [
    isModalPauseReactivateSubscriptionOpen,
    setIsModalPauseReactivateSubscriptionOpen,
  ] = useState(false);
  const [isModalCreateSubscriptionOpen, setIsModalCreateSubscriptionOpen] =
    useState(false);
  const [isModalEditSubscriptionNotes, setIsModalUpdateSubscriptionNotes] =
    useState(false);
  const [
    isModalUndoCancelSubscriptionOpen,
    setIsModalUndoCancelSubscriptionOpen,
  ] = useState(false);

  useEffect(() => {
    if (patient) {
      setIsPatientB2B(patient.partner ? true : false);
      setIsSetToCancel(patient.atPeriodEnd ? true : false);
      setIsPlanActive(
        patient.activeUntil ? hasAnActivePlan(patient.activeUntil) : false,
      );
      setIsPlanPaused(
        patient.currentSubscription?.status === 'paused' ? true : false,
      );
      setIsPartnerGympass(
        patient && patient.partner
          ? GYMPASS_CODES.includes(patient.partner)
          : false,
      );
    }
  }, [patient]);

  return (
    <SubscriptionContext.Provider
      value={{
        isModalUpdateSubscriptionOpen,
        setIsModalUpdateSubscriptionOpen,
        isModalExtendSubscriptionOpen,
        setIsModalExtendSubscriptionOpen,
        isModalCancelSubscriptionOpen,
        setIsModalCancelSubscriptionOpen,
        isModalPauseReactivateSubscriptionOpen,
        setIsModalPauseReactivateSubscriptionOpen,
        isModalCreateSubscriptionOpen,
        setIsModalCreateSubscriptionOpen,
        isModalEditSubscriptionNotes,
        setIsModalUpdateSubscriptionNotes,
        isModalUndoCancelSubscriptionOpen,
        setIsModalUndoCancelSubscriptionOpen,
        isPatientB2B,
        isPlanActive,
        isPlanPaused,
        isPartnerGympass,
        isSetToCancel,
        isReactivateSubscription,
        setIsReactivateSubscription,
      }}>
      {children}
    </SubscriptionContext.Provider>
  );
}

function useSubscription() {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error(
      'useSubscription must be used within a SubscriptionProvider',
    );
  }
  return context;
}

export {SubscriptionProvider, useSubscription};
