import React from 'react';

import NoDataPNG from 'src/assets/images/operations_dashboard/no_data.png';

export const OperationsDashboardNoData = () => {
  return (
    <div className="flex h-70 w-full flex-col items-center justify-center rounded-2.5 border border-table-border bg-table-hoverBg">
      <img src={NoDataPNG} />
      <p className="mt-4 text-4 text-blue-dark">
        Use the selectors to filter the available data
      </p>
    </div>
  );
};
