import React from 'react';

import Button from 'src/components/buttons/Button';
import {Form} from 'src/components/form/Form';
import {ErrorText} from 'src/components/forms/ErrorText';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';

import {useLoginForm} from '../hooks/useLoginForm';

export const LoginForm = () => {
  const {control, onSubmit, isLoginError, isSubmitting} = useLoginForm();

  return (
    <div className={`login__box-white ${isLoginError ? 'shake' : ''}`}>
      <h3 className="auth__title">Login to your account</h3>
      <Form
        onSubmit={onSubmit}
        className="mt-6 flex flex-col items-stretch gap-3">
        <Form.InputText
          name="email"
          label="Email"
          control={control}
          placeholder="email@domain.com"
        />
        <Form.InputPassword
          name="password"
          label="Password"
          control={control}
          placeholder="Enter your password"
        />

        {isLoginError && <ErrorText error="Incorrect email or password." />}

        <Button
          buttonType={ButtonType.PRIMARY}
          size="medium"
          type="submit"
          disabled={isSubmitting}>
          Login
        </Button>
      </Form>
    </div>
  );
};
