import {PsychologistGuideForSelector} from 'src/types/PsychologistGuide';

import {IncomingPatientsActionType} from '../action_types/IncomingPatientsActionType';
import {IncomingPatientsAction} from '../actions/incomingPatients';

interface IncomingPatientsState {
  psychologistGuideList: PsychologistGuideForSelector[] | null;
}

const initialState: IncomingPatientsState = {
  psychologistGuideList: null,
};

export const incomingPatientsReducer = (
  state: IncomingPatientsState = initialState,
  action: IncomingPatientsAction,
): IncomingPatientsState => {
  switch (action.type) {
    case IncomingPatientsActionType.SET_PSYCHOLOGIST_GUIDE_LIST:
      return {
        ...state,
        psychologistGuideList: action.payload,
      };
    default:
      return state;
  }
};
