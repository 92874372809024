import React, {useEffect} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {getAllAttributesTherapist} from 'src/state/action_creators/therapistsCreator';
import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

export const AllAttributesModal = ({show, setShow}: ModalSimpleInterface) => {
  const dispatch = useAppDispatch();
  const {allAttributes, therapist, status} = useSelector(
    (state: RootState) => state.therapist,
  );

  useEffect(() => {
    if (show && therapist) {
      dispatch(getAllAttributesTherapist(therapist.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const closeModal = () => setShow!(false);

  return status !== SharedActionType.LOADING ? (
    <ModalLayout show={show} closeModal={closeModal}>
      <ModalLayout.Header>
        <h3>User attributes</h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        {allAttributes && (
          <ul className="!p-0">
            {Object.keys(allAttributes).map((key, i) => (
              <li key={i} className="mb-2">
                <span className="text-3 text-blue-disabled">{key}</span>
                <pre className="overflow-y-scroll whitespace-pre-line">
                  {JSON.stringify(allAttributes[key])}
                </pre>
              </li>
            ))}
          </ul>
        )}
      </ModalLayout.Body>
    </ModalLayout>
  ) : (
    <></>
  );
};
