import {SharedActionType} from '@ifeelonline/chat-core';

import {Therapist} from 'src/types/Therapist';

import {TherapistsActionType} from '../action_types/TherapistsActionType';
import {TherapistsAction} from '../actions/therapists';
import {FixMeLater} from '../interfaces/FixMeLater';

interface TherapistState {
  allAttributes: FixMeLater;
  cities: string[] | null;
  email: string | null;
  error: null | string;
  status: SharedActionType;
  therapist: Therapist | null;
}

const initialState = {
  allAttributes: null,
  cities: null,
  email: null,
  error: null,
  therapist: null,
  status: SharedActionType.IDLE,
};

export const therapistReducer = (
  state: TherapistState = initialState,
  action: TherapistsAction,
) => {
  switch (action.type) {
    case TherapistsActionType.THERAPIST_LOADING:
      return {
        ...state,
        therapist: state.therapist,
        status: SharedActionType.LOADING,
      };

    case TherapistsActionType.SET_CITIES:
      return {
        ...state,
        cities: action.payload,
      };

    case TherapistsActionType.SET_THERAPIST:
      return {
        ...state,
        therapist: action.payload,
        status: SharedActionType.COMPLETED,
      };

    case TherapistsActionType.SET_ALL_ATTRIBUTES_THERAPIST:
      return {
        ...state,
        allAttributes: action.payload,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
