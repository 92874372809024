import {SharedActionType} from '@ifeelonline/chat-core';

import {WebHook} from 'src/types/WebHook';

import {WebHooksActionType} from '../action_types/WebHooksActionType';
import {WebHookAction} from '../actions/webHooks';

interface WebHookState {
  error: string | null;
  status: SharedActionType;
  total: number;
  webhooks: WebHook[] | null;
}

const initialState = {
  webhooks: null,
  total: 0,
  status: SharedActionType.IDLE,
  error: null,
};

export const webhooksReducer = (
  state: WebHookState = initialState,
  action: WebHookAction,
) => {
  switch (action.type) {
    case WebHooksActionType.WEB_HOOKS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case WebHooksActionType.SET_WEB_HOOKS:
      return {
        ...state,
        webhooks: action.payload.webhooks,
        total: action.payload.total,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
