import React, {ReactNode} from 'react';

import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import Button from 'src/components/buttons/Button';

type GenericColumnSelectorProps = React.ComponentPropsWithoutRef<'div'> & {
  children: ReactNode;
  isOpen: boolean;
};

export const GenericColumnSelector = ({
  isOpen,
  children,
}: GenericColumnSelectorProps) => {
  return (
    <div
      className={isOpen ? 'popover-custom on' : 'popover-custom'}
      data-testid="column-selector">
      <div className="content">{children}</div>
    </div>
  );
};

const GenericColumnSelectorHeader = ({
  handleToggleColumnPopOver,
  resetVisibleColumns,
}: {
  handleToggleColumnPopOver: () => void;
  resetVisibleColumns: () => void;
}) => {
  const resetAndClose = () => {
    resetVisibleColumns();
    handleToggleColumnPopOver();
  };
  return (
    <div className="flex items-center justify-between bg-grey-soft p-2 shadow-modalHeader">
      <Button
        data-testid="column-selector-reset-button"
        buttonType={ButtonType.WHITE}
        size="small"
        onClick={() => resetAndClose()}
        type="button">
        Reset
      </Button>
      <h4>Columns</h4>
      <Button
        buttonType={ButtonType.WHITE}
        size="small"
        onClick={() => handleToggleColumnPopOver()}
        type="button">
        Close
      </Button>
    </div>
  );
};

const GenericColumnSelectorBody = ({children}: React.ComponentProps<'div'>) => (
  <div className="max-h-popoverBody overflow-hidden overflow-y-scroll">
    {children}
  </div>
);

GenericColumnSelector.Header = GenericColumnSelectorHeader;
GenericColumnSelector.Body = GenericColumnSelectorBody;
