import React, {useEffect, useState} from 'react';

import {Form} from 'react-bootstrap';
import toast from 'react-hot-toast';

import {
  getTherapist,
  updateFieldsTherapistAccount,
} from 'src/state/action_creators/therapistsCreator';
import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {useAppDispatch} from 'src/hooks/hooks';

const initInfo = {
  chat: false,
  video: false,
  couple: false,
  under_age: false,
};

interface TPTMInterface extends ModalSimpleInterface {
  therapistAccount: FixMeLater;
  therapistId: number;
}

export const TherapistPlanTypeModal = ({
  show,
  setShow,
  therapistId,
  therapistAccount,
}: TPTMInterface) => {
  const dispatch = useAppDispatch();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formValues, setFormValues] = useState<FixMeLater>(initInfo);
  const {chat, video, couple, under_age} = formValues;

  useEffect(() => {
    if (therapistAccount) {
      setFormValues({
        chat: therapistAccount.chat,
        video: therapistAccount.video,
        couple: therapistAccount.couple,
        under_age: therapistAccount.under_age,
      });
    }
  }, [show, therapistAccount, setFormValues]);

  const handleCheckboxChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [target.name]: target.checked,
    });
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    setLoadingSubmit(true);
    const plansChecked = Object.keys(formValues).filter(
      (key: FixMeLater) => formValues[key],
    );

    dispatch(
      updateFieldsTherapistAccount(
        therapistId,
        {mode: plansChecked},
        therapistAccount.id,
      ),
    ).then((res) => {
      if (res) {
        setLoadingSubmit(false);
        setShow!(false);
        toast.success('Plans type modified successfully');
        dispatch(getTherapist(therapistId));
      }
    });
  };

  const valuesHaveChanged = () => {
    const chatSameValue = therapistAccount.chat
      ? formValues.chat === therapistAccount.chat
      : formValues.chat === false || formValues.chat === undefined;
    const videoSameValue = therapistAccount.video
      ? formValues.video === therapistAccount.video
      : formValues.video === false || formValues.video === undefined;
    const coupleSameValue = therapistAccount.couple
      ? formValues.couple === therapistAccount.couple
      : formValues.couple === false || formValues.couple === undefined;
    const underAgeValue = therapistAccount.under_age
      ? formValues.under_age === therapistAccount.under_age
      : formValues.under_age === false || formValues.under_age === undefined;

    return chatSameValue && videoSameValue && coupleSameValue && underAgeValue;
  };

  const closeModal = () => setShow!(false);

  return (
    <ModalLayout
      show={show}
      closeModal={closeModal}
      className="modal-height-auto">
      <form className="container" onSubmit={handleSubmitForm}>
        <ModalLayout.Header>
          <h3>Plan type therapist accepts</h3>
        </ModalLayout.Header>
        <ModalLayout.Body>
          <Form.Check
            checked={chat}
            id="table__user-chat-active"
            label="Chat"
            type="checkbox"
            name="chat"
            onChange={handleCheckboxChange}
          />
          <Form.Check
            checked={video}
            id="table__user-video-active"
            label="Video"
            type="checkbox"
            name="video"
            onChange={handleCheckboxChange}
          />
          <Form.Check
            checked={couple}
            id="table__user-couple-active"
            label="Couple"
            type="checkbox"
            name="couple"
            onChange={handleCheckboxChange}
          />
          <Form.Check
            checked={under_age}
            id="table__user-under_age-active"
            label="Under age"
            type="checkbox"
            name="under_age"
            onChange={handleCheckboxChange}
          />
        </ModalLayout.Body>
        <ModalLayout.Footer>
          <ButtonSubmit
            loadingSubmit={loadingSubmit}
            disabled={valuesHaveChanged()}
          />
        </ModalLayout.Footer>
      </form>
    </ModalLayout>
  );
};
