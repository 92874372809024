import React, {ComponentPropsWithoutRef} from 'react';

import {useSelector} from 'react-redux';

import {RootState} from 'src/state';

import {Sidebar} from './Sidebar';
import {Navbar} from './Navbar';

export const Layout = ({
  className,
  children,
  ...rest
}: ComponentPropsWithoutRef<'div'>) => {
  const {sidebarHidden} = useSelector((state: RootState) => state.ui);

  return (
    <div
      className={`flex h-screen flex-row ${className ? className : ''}`}
      {...rest}>
      <Sidebar />
      <div className="relative flex flex-1 flex-row overflow-x-auto">
        <Navbar sidebarHidden={sidebarHidden} />
        <div className="m-auto min-h-full w-full px-10 pb-12 pt-19">
          {children}
        </div>
      </div>
    </div>
  );
};
