import {SubmitHandler, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {yupResolver} from '@hookform/resolvers/yup';

import {RootState} from 'src/state';
import {useAppDispatch} from 'src/hooks/hooks';
import {updateUserRoles} from 'src/state/action_creators/usersAndRolesCreator';

import {editUserRoleFormSchema} from '../components/forms/usersAndRolesSchemas';
import {EditDashboardUserFormValues} from '../types';

interface Params {
  closeModal: () => void;
}

export const useEditDashboardUserForm = ({closeModal}: Params) => {
  const dispatch = useAppDispatch();

  const {rolesListSelector, userRoleActive} = useSelector(
    (state: RootState) => state.uar,
  );

  const {
    control,
    formState: {isSubmitting, errors},
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {roles: userRoleActive.roles || []},
    resolver: yupResolver(editUserRoleFormSchema),
  });

  const handleSubmitForm: SubmitHandler<EditDashboardUserFormValues> = (
    values,
  ) => {
    const selectedRolesIds = rolesListSelector
      ?.filter((role) => values.roles.includes(role.name))
      .map((role) => role.id);

    dispatch(
      updateUserRoles(userRoleActive.id, {
        role_ids: selectedRolesIds,
      }),
    ).then((res) => {
      if (res) {
        closeModal();
        toast.success("User's roles modified successfully");
      }
    });
  };

  const valuesHaveChanged = () => {
    const currentValues = watch();

    const rolesSameValue =
      userRoleActive.roles.sort().toString() ===
      currentValues.roles?.sort().toString();

    return rolesSameValue;
  };

  return {
    control,
    hasErrors: Object.keys(errors).length > 0,
    isSubmitting,
    onSubmit: handleSubmit(handleSubmitForm),
    options: rolesListSelector?.map(({name}) => name) || [],
    valuesHaveChanged,
  };
};
