import React, {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {SharedActionType} from '@ifeelonline/chat-core';

import {
  ArrowBendRightDown,
  ArrowBendRightUp,
  ArrowSquareOut,
  Columns,
  EnvelopeOpen,
  Flashlight,
  Funnel,
  Plus,
  Repeat,
} from '@phosphor-icons/react';
import Swal from 'sweetalert2';

import {
  exportTherapists,
  getTherapists,
  reinviteTherapist,
} from 'src/state/action_creators/therapistsCreator';
import {arrayToCommaList, parseUnderscore} from 'src/helpers/generalFunctions';
import {PaginationComponent} from 'src/components/shared/PaginationComponent';
import {useTherapists} from 'src/providers/TherapistsTableProvider';
import {TdWithTooltip} from 'src/components/tables/TdWithTooltip';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {ExportModal} from 'src/components/shared/ExportModal';
import {CustomTable} from 'src/components/tables/CustomTable';
import {PageHeader} from 'src/components/layout/PageHeader';
import {ControllerNames} from 'src/types/ControllerNames';
import {Loading} from 'src/components/shared/Loading';
import Button from 'src/components/buttons/Button';
import {TherapistList} from 'src/types/Therapist';
import {useAppDispatch} from 'src/hooks/hooks';
import {formatDate} from 'src/helpers/dates';
import {RBAC} from 'src/helpers/Rbac';
import {RootState} from 'src/state';

import {PopOverColumns} from './components/therapist/PopOverColumns';
import {PopOverFilters} from './components/therapist/PopOverFilters';
import {TherapistNewForm} from './components/forms/TherapistNewForm';

export const TherapistsTable = ({
  controllerName,
  setOverflowHidden,
}: {
  controllerName: string;
  setOverflowHidden: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {status, therapists, total} = useSelector(
    (state: RootState) => state.therapists,
  ) as {status: SharedActionType; therapists: TherapistList[]; total: number};
  const {
    page,
    setPage,
    orderBy,
    setOrderBy,
    orderDesc,
    setOrderDesc,
    filterSelected,
    toggleShowPopOver,
    setToggleShowPopOver,
    toggleShowColumPopOver,
    setToggleShowColumPopOver,
    languagesCheck,
    languagesValues,
    planTypeCheck,
    planTypeValues,
    countryCheck,
    countryValue,
    fieldsFiltered,
    setFieldsFiltered,
    columnsVisible,
    modalExportOpen,
    setModalExportOpen,
    allFilterParams,
    setAllFilterParams,
    modalNewOpen,
    setModalNewOpen,
  } = useTherapists();

  const handleOrderBy = (newOrderBy: string) => {
    if (!['languages', 'plan_type', 'country'].includes(newOrderBy)) {
      setOrderBy(newOrderBy);
      setOrderDesc(!orderDesc);
    }
  };

  const handleRowClick = (e: React.MouseEvent, id: number) => {
    if (e.metaKey || e.ctrlKey) {
      const win = window.open(`/therapist/${id}`, '_blank');
      win?.focus();
    } else {
      navigate(`/therapist/${id}`);
    }
  };

  const handleAddTherapist = () => setModalNewOpen(true);
  const dispatchGetTherapists = () => {
    const newFilterParams = {
      page,
      orderBy,
      orderDir: orderDesc ? 'desc' : 'asc',
      languages: languagesCheck ? languagesValues : null,
      plan_type: planTypeCheck ? planTypeValues : null,
      country: countryCheck ? countryValue : null,
    };
    setAllFilterParams(newFilterParams);
    dispatch(getTherapists(newFilterParams));
  };

  const handleToggleFilter = (popup: string) => {
    if (popup === 'filter') {
      setToggleShowPopOver(!toggleShowPopOver);
      setOverflowHidden(toggleShowPopOver ? '' : 'overflowHidden');
    } else {
      setToggleShowColumPopOver(!toggleShowColumPopOver);
      setOverflowHidden(toggleShowColumPopOver ? '' : 'overflowHidden');
    }
  };

  const handleResendInvitation = (
    e: React.MouseEvent,
    therapist: TherapistList,
  ) => {
    e.preventDefault();

    Swal.fire({
      icon: 'info',
      title: 'Resend invitation',
      text: `Are you sure you want to resend an invitation to ${therapist.email}?`,
      showConfirmButton: true,
    }).then((res) => {
      if (res.isConfirmed)
        dispatch(reinviteTherapist(therapist.id!)).then((res) => {
          if (res) {
            toast.success('The invitation has been successfully resent');
            dispatchGetTherapists();
          }
        });
    });
  };

  useEffect(() => {
    dispatchGetTherapists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, orderDesc, filterSelected]);

  useEffect(() => {
    if (filterSelected !== null) {
      const filtersFiltered: any = Object.keys(filterSelected).filter(
        (key) => filterSelected[key].checked,
      );
      const fieldFiltersSelected: any = [];
      filtersFiltered.forEach((f: string) => {
        fieldFiltersSelected.push(filterSelected[f].field);
      });
      setFieldsFiltered(fieldFiltersSelected);
    }
  }, [filterSelected]);

  const renderColumnName = (columnName: string) => {
    switch (columnName) {
      case 'invitation_sent_at':
        return parseUnderscore('invitation_sent');
      case 'invitation_accepted_at':
        return parseUnderscore('invitation_accepted');
      default:
        return parseUnderscore(columnName);
    }
  };

  return status === SharedActionType.LOADING ? (
    <Loading />
  ) : (
    <div>
      <PageHeader pageTitle="Therapists">
        <div className="flex">
          <RBAC controllerName={ControllerNames.THERAPISTS} actionName="create">
            <Button
              buttonType={ButtonType.SAVE}
              size="small"
              onClick={handleAddTherapist}>
              <Plus size={18} className="mr-1" /> Add therapist
            </Button>
          </RBAC>
          <>
            <div className="popover__container">
              <Button
                buttonType={ButtonType.WHITE}
                size="small"
                onClick={() => handleToggleFilter('column')}>
                <Columns size={18} className="mr-1" /> Columns
              </Button>
              <PopOverColumns />
              {toggleShowColumPopOver && (
                <div
                  className="overlay-popover"
                  onClick={() => handleToggleFilter('column')}></div>
              )}
            </div>

            <div className="popover__container">
              <Button
                buttonType={ButtonType.WHITE}
                size="small"
                className={`${filterSelected ? 'active' : ''}`}
                onClick={() => handleToggleFilter('filter')}>
                <Funnel size={18} className="mr-1" /> Filter
                {filterSelected && (
                  <p>
                    |{' '}
                    <span>
                      {
                        Object.keys(filterSelected).filter(
                          (key) => filterSelected[key].checked,
                        ).length
                      }
                    </span>
                  </p>
                )}
              </Button>
              <PopOverFilters handleToggleFilter={handleToggleFilter} />
              {toggleShowPopOver && (
                <div
                  className="overlay-popover"
                  onClick={() => handleToggleFilter('filter')}></div>
              )}
            </div>
            <RBAC controllerName={controllerName} actionName="export">
              {filterSelected && therapists.length < 10000 && (
                <Button
                  buttonType={ButtonType.WHITE}
                  size="small"
                  onClick={() => setModalExportOpen(true)}>
                  <ArrowSquareOut size={18} className="mr-1" /> Export
                </Button>
              )}
            </RBAC>
          </>
        </div>
      </PageHeader>
      {therapists && (
        <>
          {total > 0 ? (
            <CustomTable
              headChildren={
                <tr>
                  {columnsVisible.map((column: string, index: number) => (
                    <th
                      key={index}
                      className={`${
                        ['languages', 'plan_type', 'country'].includes(
                          column,
                        ) || 'cursor-pointer'
                      } ${
                        filterSelected && fieldsFiltered.includes(column)
                          ? 'text-active'
                          : ''
                      }`}
                      onClick={() => handleOrderBy(column)}>
                      {filterSelected && fieldsFiltered.includes(column) && (
                        <Funnel size={18} />
                      )}
                      {renderColumnName(column)}
                      {orderBy === column &&
                        (orderDesc ? (
                          <ArrowBendRightDown size={10} />
                        ) : (
                          <ArrowBendRightUp size={10} />
                        ))}
                    </th>
                  ))}
                </tr>
              }
              bodyChildren={therapists.map(
                (therapist: TherapistList, index: number) => (
                  <tr key={index}>
                    {columnsVisible.map((column: string, index: number) => {
                      switch (column) {
                        case 'email':
                          return (
                            <TdWithTooltip
                              key={index}
                              content={therapist.email}
                              onClick={(e: React.MouseEvent) =>
                                handleRowClick(e, therapist.id)
                              }>
                              {therapist.tester && (
                                <span className="badge-text info static text-2.5">
                                  Tester
                                </span>
                              )}
                              {therapist.invitationSentAt &&
                                !therapist.invitationSentAt && (
                                  <span className="badge-text warning static text-2.5">
                                    Pending
                                  </span>
                                )}
                            </TdWithTooltip>
                          );
                        case 'invitation_sent_at':
                          return (
                            <td
                              key={index}
                              className="cursor-pointer"
                              onClick={(e) => handleRowClick(e, therapist.id)}>
                              {therapist.invitationSentAt
                                ? formatDate(therapist.invitationSentAt)
                                : ''}
                            </td>
                          );
                        case 'invitation_accepted_at':
                          return (
                            <td key={index}>
                              {therapist.invitationAcceptedAt ? (
                                formatDate(therapist.invitationAcceptedAt)
                              ) : (
                                <Button
                                  buttonType={ButtonType.ACTIONS}
                                  size="small"
                                  id="resend-invitation"
                                  onClick={(e) =>
                                    handleResendInvitation(e, therapist)
                                  }>
                                  <Repeat size={10} weight="duotone" />
                                  <EnvelopeOpen />
                                </Button>
                              )}
                            </td>
                          );
                        case 'plan_type':
                          return (
                            <td
                              key={index}
                              className="cursor-pointer"
                              onClick={(e) => handleRowClick(e, therapist.id!)}>
                              {therapist.planType
                                ? arrayToCommaList(therapist.planType)
                                : ''}
                            </td>
                          );
                        case 'languages':
                          return (
                            <td
                              key={index}
                              className="cursor-pointer"
                              onClick={(e) => handleRowClick(e, therapist.id!)}>
                              {therapist.languages
                                ? arrayToCommaList(therapist.languages)
                                : ''}
                            </td>
                          );
                        case 'nickname':
                          return (
                            <td
                              key={index}
                              className="cursor-pointer"
                              onClick={(e) => handleRowClick(e, therapist.id)}>
                              {therapist.nickname}
                            </td>
                          );
                      }
                    })}
                  </tr>
                ),
              )}
            />
          ) : (
            <div className="table__no-results">
              <Flashlight size={48} />
              <h3>No results found</h3>
              <p>There aren&apos;t any results for that query.</p>
            </div>
          )}
        </>
      )}

      <PaginationComponent total={total} page={page} setPage={setPage} />

      <ModalLayout
        show={modalNewOpen}
        closeModal={() => setModalNewOpen(false)}>
        <TherapistNewForm closeModal={() => setModalNewOpen(false)} />
      </ModalLayout>

      {therapists && filterSelected && therapists.length < 10000 && (
        <ExportModal
          show={modalExportOpen}
          setModalOpen={setModalExportOpen}
          allFilterParams={allFilterParams}
          columnsVisible={columnsVisible}
          title="Export therapists"
          exportReport={exportTherapists}
        />
      )}
    </div>
  );
};
