import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';
import {toQueryString} from 'src/helpers/generalFunctions';
import {Patient, PatientStorage} from 'src/types/Patient';
import {PartnerSelector} from 'src/types/Partner';
import {TherapistSelector, TherapistSelectorStorage} from 'src/types/Therapist';
import {mapApiResponsePatient} from 'src/helpers/api_response/patient';
import {mapApiResponseTherapistSelector} from 'src/helpers/api_response/therapist';

import {FixMeLater} from '../interfaces/FixMeLater';
import {
  PatientsAction,
  SetPatientsPayload,
  SetUserOptionsPayload,
} from '../actions/patients';
import {PatientsActionType} from '../action_types/PatientsActionType';
import {GraphicsActionType} from '../action_types/GraphicsActionType';

type DispatchResult = PatientsAction | any;

export const getPatients = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: PatientsActionType.PATIENTS_LOADING});
    const resp = await fetchWithToken(`admin/users?${toQueryString(params)}`);
    const body = await resp?.json();
    if (body && body.success) {
      const {
        patients: unmappedPatients,
        total_count: total,
      }: {patients: PatientStorage[]; total_count: number} = body;
      const patients: Patient[] = unmappedPatients.map(
        (patient: PatientStorage) => mapApiResponsePatient(patient),
      );
      dispatch(
        setPatients({
          patients,
          total: total || 0,
        }),
      );
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getPatientsOptions = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    /** TO-DO review dispatch loading ?? */
    const resp = await fetchWithToken(`admin/users/selectors`);
    const body = await resp?.json();
    if (body && body.success) {
      const {
        therapists: unmappedTherapists,
        partners: partnersSelector,
      }: {partners: PartnerSelector[]; therapists: TherapistSelectorStorage[]} =
        body;
      const therapistsSelector: TherapistSelector[] = unmappedTherapists.map(
        (therapist: TherapistSelectorStorage) =>
          mapApiResponseTherapistSelector(therapist),
      );
      const payload: SetUserOptionsPayload = {
        partnersSelector,
        therapistsSelector,
      };
      dispatch({
        type: PatientsActionType.SET_USERS_OPTIONS,
        payload,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const searchPatients = (paramsSearch: string) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: PatientsActionType.PATIENTS_LOADING});

    const resp = await fetchWithToken(
      `admin/users/search?search=${paramsSearch}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {patients, total_count} = body;
      dispatch(
        setPatients({
          patients: patients,
          total: total_count,
        }),
      );
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const exportPatients = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(`admin/users/export`, params, 'POST');
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getGraphicPatientsByTimeZone = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({
      type: GraphicsActionType.GET_GRAPHIC_PATIENTS_BY_TIMEZONE_LOADING,
    });
    const resp = await fetchWithToken(`admin/users/graphics_time_zone`);
    const body = await resp?.json();
    if (body && body.success) {
      const {data} = body;
      dispatch(setGraphicTimeZone({graphics: data}));
    } else {
      dispatch(catchApiError(body));
    }
  };
};

const setPatients = (payload: SetPatientsPayload) => ({
  type: PatientsActionType.SET_PATIENTS,
  payload,
});

const setGraphicTimeZone = (graphics: FixMeLater) => ({
  type: GraphicsActionType.GET_GRAPHIC_PATIENTS_BY_TIMEZONE,
  payload: graphics,
});
