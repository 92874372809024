import {passwordRegExp} from './regularExp';

export const validatePassword = (
  password: string,
  setError: (error: string | null) => void,
) => {
  if (!passwordRegExp.test(password)) {
    setError(
      'Password length should be 8-70 characters and include: 1 uppercase, 1 lowercase, 1 digit and 1 special character (!@#$%^&*).',
    );
    return false;
  } else {
    setError(null);
    return true;
  }
};

export const validatePasswords = (
  password: string,
  confirmPassword: string,
  setError: (error: string | null) => void,
  oldPassword?: string,
) => {
  if (
    password === '' ||
    confirmPassword === '' ||
    (oldPassword && oldPassword === '')
  ) {
    setError('You must complete all the fields');
    return false;
  } else if (password === confirmPassword) {
    return validatePassword(confirmPassword, setError);
  } else {
    setError("Passwords doesn't match");
    return false;
  }
};
