import {SharedActionType} from '@ifeelonline/chat-core';

import {Role} from 'src/types/Role';

import {RolesActionType} from '../action_types/RolesActionType';
import {RolesAction} from '../actions/roles';
import {FixMeLater} from '../interfaces/FixMeLater';

interface RolesState {
  actionsList: null | FixMeLater;
  controllables: null | FixMeLater;
  error: string | null;
  roleActive: Role | null;
  roles: Role[] | null;
  status: SharedActionType;
  total: number;
}

const initialState = {
  roleActive: null,
  roles: null,
  controllables: null,
  actionsList: null,
  total: 0,
  status: SharedActionType.IDLE,
  error: null,
};

export const rolesReducer = (
  state: RolesState = initialState,
  action: RolesAction,
) => {
  switch (action.type) {
    case RolesActionType.SET_ROLES_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case RolesActionType.SET_ROLES:
      return {
        ...state,
        roles: action.payload.roles,
        total: action.payload.total,
        status: SharedActionType.COMPLETED,
      };

    case RolesActionType.SET_ROLES_OPTIONS:
      return {
        ...state,
        controllables: action.payload.controllables,
        actionsList: action.payload.actions,
      };

    case RolesActionType.SET_ROLE_ACTIVE:
      return {
        ...state,
        roleActive: action.payload,
      };

    default:
      return state;
  }
};
