import React from 'react';

import {Warning} from '@phosphor-icons/react';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {Form} from 'src/components/form/Form';
import {Therapist} from 'src/types/Therapist';

import {useTherapistEditAccountAttrsForLocaleForm} from '../../hooks/useTherapistEditAccountAttrsForLocaleForm';

interface Props {
  closeModal: () => void;
  localeForAttr: string;
  therapist: Therapist;
}

export const TherapistEditAccountAttrsForLocaleForm = ({
  therapist,
  localeForAttr,
  closeModal,
}: Props) => {
  const {
    control,
    onSubmit,
    hasErrors,
    isSubmitting,
    isDirty,
    localeName,
    valuesHaveChanged,
  } = useTherapistEditAccountAttrsForLocaleForm({
    therapist,
    closeModal,
    localeForAttr,
  });

  return (
    <Form
      onSubmit={onSubmit}
      className="flex size-full flex-col justify-between">
      <ModalLayout.Header>
        <h3>
          {therapist.account
            ? `Edit account for ${localeName}`
            : `Create account for ${localeName}`}
        </h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        <Form.InputTextArea
          control={control}
          name="bio"
          label="Bio"
          placeholder="Bio"
        />
        <Form.InputTextArea
          control={control}
          name="titles"
          label="Titles"
          placeholder="Titles"
        />
        <Form.InputTextArea
          control={control}
          name="experience"
          label="Experience"
          placeholder="Experience"
        />
      </ModalLayout.Body>
      <ModalLayout.Footer>
        {hasErrors && <Warning color="red" />}
        <ButtonSubmit
          disabled={!isDirty || valuesHaveChanged()}
          loadingSubmit={isSubmitting}
        />
      </ModalLayout.Footer>
    </Form>
  );
};
