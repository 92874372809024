import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {SharedActionType} from '@ifeelonline/chat-core';

import {
  ArrowBendRightDown,
  ArrowBendRightUp,
  PencilLine,
  Plus,
  Trash,
} from '@phosphor-icons/react';

import {
  deleteRole,
  getRoles,
  getRolesOptions,
} from 'src/state/action_creators/rolesCreator';
import {PaginationComponent} from 'src/components/shared/PaginationComponent';
import {RolesActionType} from 'src/state/action_types/RolesActionType';
import {TdWithTooltip} from 'src/components/tables/TdWithTooltip';
import {DESTRUCTIVE_ACTION_CONFIRM} from 'src/constants/messages';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {CustomTable} from 'src/components/tables/CustomTable';
import {parseUnderscore} from 'src/helpers/generalFunctions';
import {PageHeader} from 'src/components/layout/PageHeader';
import {NoResults} from 'src/components/tables/NoResults';
import {Loading} from 'src/components/shared/Loading';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';
import {formatDate} from 'src/helpers/dates';
import {Role} from 'src/types/Role';
import {RootState} from 'src/state';

import {EditRoleModal} from './modals/EditRoleModal';
import {NewRoleModal} from './modals/NewRoleModal';

const initValues = {
  page: 1,
  orderBy: 'id',
  orderDesc: false,
};

export const RolesTable = () => {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(initValues.page);
  const [orderBy, setOrderBy] = useState(initValues.orderBy);
  const [orderDesc, setOrderDesc] = useState(initValues.orderDesc);
  const [modalEditRoles, setModalEditRoles] = useState(false);
  const [modalNewRole, setModalNewRole] = useState(false);

  const {status, total, roles, controllables, actionsList} = useSelector(
    (state: RootState) => state.roles,
  );

  const handleOrderBy = (newOrderBy: string) => {
    setOrderBy(newOrderBy);
    setOrderDesc(!orderDesc);
  };

  const handleEditRole = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    role: Role,
  ) => {
    event.preventDefault();
    dispatch({
      type: RolesActionType.SET_ROLE_ACTIVE,
      payload: role,
    });
    setModalEditRoles(true);
  };

  const handleDeleteRole = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    role: Role,
  ) => {
    event.preventDefault();
    const confirmBox = window.confirm(DESTRUCTIVE_ACTION_CONFIRM);
    if (confirmBox === true) {
      dispatch(deleteRole(role.id)).then(() => {
        toast.success(`Role ${role.name} deleted successfully`);
        dispatchRoles();
      });
    }
  };

  const columnsVisible = ['id', 'name', 'created_at', 'actions'];

  const dispatchRoles = () => {
    dispatch(
      getRoles({
        page,
        orderBy,
        orderDir: orderDesc ? 'desc' : 'asc',
      }),
    );
  };

  useEffect(() => {
    if (controllables === null || actionsList === null)
      dispatch(getRolesOptions());
    dispatchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, orderDesc]);

  return status === SharedActionType.LOADING ? (
    <Loading />
  ) : (
    <div>
      <PageHeader pageTitle="Roles">
        <div className="flex">
          <Button
            buttonType={ButtonType.SAVE}
            size="small"
            onClick={() => setModalNewRole(true)}>
            <Plus size={18} className="mr-1" /> Add role
          </Button>
        </div>
      </PageHeader>
      {roles &&
        (total > 0 ? (
          <CustomTable
            headChildren={
              <tr>
                {columnsVisible.map((column, index) => (
                  <th
                    key={index}
                    className={column !== 'actions' ? 'cursor-pointer' : ''}
                    onClick={() => {
                      if (column !== 'actions') handleOrderBy(column);
                    }}>
                    {parseUnderscore(column)}
                    {orderBy === column &&
                      (orderDesc ? (
                        <ArrowBendRightDown
                          size={10}
                          className="inline-block"
                        />
                      ) : (
                        <ArrowBendRightUp size={10} className="inline-block" />
                      ))}
                  </th>
                ))}
              </tr>
            }
            bodyChildren={roles.map((role: Role, index: number) => (
              <tr key={index} className="group">
                {columnsVisible.map((column, index) => {
                  switch (column) {
                    case 'created_at':
                      return <td key={index}>{formatDate(role.createdAt)}</td>;
                    case 'actions':
                      return (
                        <td
                          key={index}
                          className="actions flex justify-start !pl-0">
                          <Button
                            buttonType={ButtonType.ACTIONS}
                            size="small"
                            className="!px-1"
                            onClick={(e) => handleEditRole(e, role)}>
                            <PencilLine size="14" />
                          </Button>
                          <Button
                            buttonType={ButtonType.WARNING}
                            size="small"
                            className="!px-1"
                            onClick={(e) => handleDeleteRole(e, role)}>
                            <Trash size="14" />
                          </Button>
                        </td>
                      );
                    case 'id':
                      return <td key={index}>{role.id}</td>;
                    case 'name':
                      return <TdWithTooltip key={index} content={role.name} />;
                  }
                })}
              </tr>
            ))}
          />
        ) : (
          <NoResults />
        ))}

      <PaginationComponent total={total} page={page} setPage={setPage} />
      <EditRoleModal
        show={modalEditRoles}
        setShow={setModalEditRoles}
        page={page}
        orderBy={orderBy}
        orderDesc={orderDesc}
      />
      <NewRoleModal
        show={modalNewRole}
        setShow={setModalNewRole}
        page={page}
        orderBy={orderBy}
        orderDir={orderDesc ? 'desc' : 'asc'}
      />
    </div>
  );
};
