import {ChartOptionsValue} from 'src/types/Graphics';

export interface GraphicOptionType {
  text: string;
  value: string;
}

export const GRAPHIC_SELECT_OPTIONS: GraphicOptionType[] = [
  {
    value: 'day',
    text: 'Day',
  },
  {
    value: 'last7days',
    text: 'Last 7 days',
  },
  {
    value: 'last14days',
    text: 'Last 14 days',
  },
];

export const CHART_GRAPHIC_OPTIONS: {
  name: string;
  value: ChartOptionsValue;
}[] = [
  {name: 'Registrations', value: ChartOptionsValue.REGISTERS},
  {name: 'Logins', value: ChartOptionsValue.LOGINS},
  {name: 'Unregistered', value: ChartOptionsValue.UNREGISTERED},
  {name: 'Deleted', value: ChartOptionsValue.DELETED},
  {name: 'Intakes', value: ChartOptionsValue.INTAKES},
  {name: 'Intakes waiting time', value: ChartOptionsValue.INTAKES_WAITING_TIME},
  {
    name: 'Patients per therapist',
    value: ChartOptionsValue.PATIENTS_PER_THERAPIST,
  },
];
