import {SubmitHandler, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {LanguageAvailable} from '@ifeelonline/chat-core';

import {yupResolver} from '@hookform/resolvers/yup';

import {Therapist} from 'src/types/Therapist';
import {RootState} from 'src/state';
import {useAppDispatch} from 'src/hooks/hooks';
import {
  accountTherapist,
  getTherapist,
} from 'src/state/action_creators/therapistsCreator';

import {therapistEditAccountAttrsForLocaleSchema} from '../schemas/therapistSchema';

interface UseTherapistEditAccountAttrsForLocaleFormParams {
  closeModal: () => void;
  localeForAttr: string;
  therapist: Therapist;
}

interface FormValues {
  bio: string;
  experience: string;
  titles: string;
}

export const useTherapistEditAccountAttrsForLocaleForm = ({
  closeModal,
  localeForAttr,
  therapist,
}: UseTherapistEditAccountAttrsForLocaleFormParams) => {
  const dispatch = useAppDispatch();
  const {languages} = useSelector((state: RootState) => state.settings);

  const localeName = languages?.filter(
    ({locale}: LanguageAvailable) => locale === localeForAttr,
  )[0].name;

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting, isDirty},
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      bio: therapist.account?.bio || '',
      experience: therapist.account?.experience || '',
      titles: therapist.account?.titles || '',
    },
    resolver: yupResolver(therapistEditAccountAttrsForLocaleSchema),
    mode: 'onBlur',
  });

  const valuesHaveChanged = () => {
    const currentValues = watch();

    const bioSameValue = therapist.account?.bio
      ? currentValues.bio === therapist.account?.bio
      : currentValues.bio === '';
    const experienceSameValue = therapist.account?.experience
      ? currentValues.experience === therapist.account?.experience
      : currentValues.experience === '';
    const titlesSameValue = therapist.account?.titles
      ? currentValues.titles === therapist.account?.titles
      : currentValues.titles === '';

    return bioSameValue && experienceSameValue && titlesSameValue;
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (therapist.account) {
      dispatch(
        accountTherapist(
          therapist.id!,
          {
            ...data,
            locale: localeForAttr,
          },
          therapist.account?.id,
        ),
      ).then(() => {
        dispatch(getTherapist(therapist.id!));
        closeModal();
        toast.success('The account data has been successfully updated');
      });
    }
  };

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    hasErrors: Object.keys(errors).length > 0,
    isSubmitting,
    localeName,
    isDirty,
    valuesHaveChanged,
  };
};
