import {SharedActionType} from '@ifeelonline/chat-core';

import {UserRole, UsersAndRolesStorage} from 'src/types/UsersAndRoles';

import {UsersAndRolesActionType} from '../action_types/UsersAndRolesActionType';
import {UsersAndRolesAction} from '../actions/usersAndRoles';
import {FixMeLater} from '../interfaces/FixMeLater';

interface UsersAndRolesState {
  error: null | string;
  rolesListSelector: UserRole[] | null;
  status: SharedActionType;
  total: number;
  userRoleActive: FixMeLater;
  usersAndRoles: UsersAndRolesStorage[] | null;
}

const initialState = {
  userRoleActive: null,
  usersAndRoles: null,
  rolesListSelector: null,
  total: 0,
  status: SharedActionType.IDLE,
  error: null,
};

export const usersAndRolesReducer = (
  state: UsersAndRolesState = initialState,
  action: UsersAndRolesAction,
) => {
  switch (action.type) {
    case UsersAndRolesActionType.USERS_AND_ROLES_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case UsersAndRolesActionType.SET_USERS_AND_ROLES:
      return {
        ...state,
        usersAndRoles: action.payload.usersAndRoles,
        total: action.payload.total,
        status: SharedActionType.COMPLETED,
      };
    case UsersAndRolesActionType.SET_ROLES_LIST_SELECTOR:
      return {
        ...state,
        rolesListSelector: action.payload,
        status: SharedActionType.COMPLETED,
      };

    case UsersAndRolesActionType.SET_USER_ROLE_UPDATED:
      return {
        ...state,
        usersAndRoles: state.usersAndRoles
          ? state.usersAndRoles.map((userAndRole: UsersAndRolesStorage) =>
              userAndRole.id === action.payload.id
                ? action.payload
                : userAndRole,
            )
          : action.payload,
        status: SharedActionType.COMPLETED,
      };

    case UsersAndRolesActionType.SET_USER_ROLE_ACTIVE:
      return {
        ...state,
        userRoleActive: action.payload,
      };

    default:
      return state;
  }
};
