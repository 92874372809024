export type PlanTypeStorage = 'chat' | 'video';
export type SourceStorage = 'ios' | 'android' | 'web';
export type LocaleStorage = 'es' | 'en' | 'pt' | 'fr' | 'he';

export enum Source {
  ANDROID = 'android',
  IOS = 'ios',
  WEB = 'web',
}

export enum Locale {
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  HE = 'he',
  PT = 'pt',
}

export enum PlanType {
  CHAT = 'chat',
  VIDEO = 'video',
}

export interface UserStorage {
  account?: any;
  created_at: string;
  email: string;
  full_name?: string | null;
  id: number;
  locale: LocaleStorage;
  name?: string | null;
  nickname: string | null;
  phone?: string | null;
  surname?: string | null;
  therapist?: any;
}

export interface User {
  createdAt: string;
  email: string;
  fullName: string | null;
  id: number;
  locale: Locale;
  name: string | null;
  nickname: string | null;
  phone?: string | null;
  surname?: string | null;
}
