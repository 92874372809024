import React from 'react';

import toast from 'react-hot-toast';

import {
  accountTherapist,
  getTherapist,
} from 'src/state/action_creators/therapistsCreator';
import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {useAppDispatch} from 'src/hooks/hooks';
import {Therapist} from 'src/types/Therapist';

import {TableSpecialities} from './specialities_modal/components/TableSpecialities';
import {useSpecialities} from './specialities_modal/hooks/useSpecialities';

interface Props extends ModalSimpleInterface {
  setShow: (a: boolean) => void;
  show: boolean;
  therapist: Therapist;
}
export interface TherapistSpecialitiesEditModalProps {
  specialitiesTable: {level: number; originalKey: string; text: string}[];
  specialitiesTableOther: {level: number; originalKey: string; text: string}[];
}

export const TherapistSpecialitiesEditModal = ({
  show,
  setShow,
  therapist,
}: Props) => {
  const dispatch = useAppDispatch();
  const {
    specialitiesTable,
    specialitiesTableOther,
    handleCheckboxChange,
    handleCheckboxChangeOther,
    handleDeleteSpeciality,
    setSpecialitiesTableOther,
  } = useSpecialities(therapist);

  const handleSubmitForm = (event: React.FormEvent) => {
    event.preventDefault();

    const updatedSpecialities: {
      [key: string]: number;
    } = specialitiesTable.reduce((result: any, item) => {
      result[item.originalKey] = item.level;
      return result;
    }, {});

    specialitiesTableOther.forEach((element) => {
      updatedSpecialities[element.originalKey] = element.level;
    });

    dispatch(
      accountTherapist(
        therapist.id!,
        {
          specialities: updatedSpecialities,
        },
        therapist.account ? therapist.account.id : null,
      ),
    ).then(() => {
      dispatch(getTherapist(therapist.id!));
      setShow!(false);
      toast.success('The specialities has been successfully updated');
    });
  };

  const closeModal = () => setShow!(false);

  return (
    <ModalLayout
      show={show}
      closeModal={closeModal}
      className="max-h-8/10 !w-175 max-w-none">
      <form className="container" onSubmit={handleSubmitForm}>
        <ModalLayout.Header>
          <h3>Edit specialities</h3>
        </ModalLayout.Header>
        <ModalLayout.Body>
          <TableSpecialities
            title="Mains"
            specialities={specialitiesTable}
            handleCheckboxChange={handleCheckboxChange}
            isOthersSpecialities={false}
            handleDeleteSpeciality={handleDeleteSpeciality}
          />

          <TableSpecialities
            title="Others"
            specialities={specialitiesTableOther}
            handleCheckboxChange={handleCheckboxChangeOther}
            handleDeleteSpeciality={handleDeleteSpeciality}
            isOthersSpecialities={true}
            setSpecialitiesTableOther={setSpecialitiesTableOther}
          />
        </ModalLayout.Body>
        <ModalLayout.Footer>
          <ButtonSubmit loadingSubmit={false} />
        </ModalLayout.Footer>
      </form>
    </ModalLayout>
  );
};
