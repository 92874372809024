import {
  PlanType,
  PlanTypeStorage,
  Source,
  SourceStorage,
  User,
  UserStorage,
} from './User';

export type Subscription = {
  created_at: string;
  current_period_end: string;
  id: number;
  last_extension_details: string;
  note: string;
  paused_at: string;
  paused_days_remaining: string;
  plan_name: string;
  status: string;
  updated_at: string;
};

export interface PatientStorage extends UserStorage {
  active?: boolean;
  active_emails: boolean;
  active_notifications: boolean;
  active_until: string | null;
  age: Age | null;
  agent?: string;
  at_period_end?: boolean;
  confirmed_at?: string;
  confirmed_by?: string;
  conversation_with_support?: string | null;
  conversation_with_therapist?: string | null;
  current_plan?: string | null;
  current_subscription: Subscription | null;
  deleted: boolean;
  deleted_at: string | null;
  disorders?: string | DisorderStorage | null;
  email_notifications: EmailNotificationsStorage;
  enabled_audio: boolean;
  exact_age: number | null;
  gender: string | null;
  ghost?: boolean;
  had_a_subscription?: boolean;
  has_a_valid_subscription?: boolean;
  metric: string | null;
  nationality?: string | null;
  note: string | null;
  partner: string | null;
  past_subscriptions: Subscription[] | null;
  plan?: string | null;
  plan_name: string | null;
  plan_paused?: boolean;
  plan_type: PlanTypeStorage | null;
  plan_video: boolean;
  role: string;
  roles: string[];
  source: SourceStorage;
  state: boolean;
  status: number;
  status_text: string;
  stripe_customer: string | null;
  tester: boolean;
  therapist?: string | null;
  therapist_id?: number | null;
  therapists_history_initials?: (string | null)[] | null;
  trial_on: boolean;
  use_v3_web?: boolean;
  video?: boolean;
}

export interface Patient extends User {
  active?: boolean;
  activeEmails: boolean;
  activeNotifications: boolean;
  activeUntil: string | null;
  age: Age | null;
  agent?: string;
  atPeriodEnd?: boolean;
  confirmedAt: string | null;
  confirmedBy: string | null;
  conversationWithSupport?: string | null;
  conversationWithTherapist?: string | null;
  currentPlan: string | null;
  currentSubscription: Subscription | null;
  deleted: boolean;
  deletedAt: string | null;
  disorders: string | string[] | null;
  emailNotifications: EmailNotifications;
  enabledAudio: boolean;
  exactAge: number | null;
  gender: string | null;
  ghost?: boolean;
  metric: string | null;
  note: string | null;
  partner: string | null;
  pastSubscriptions: Subscription[] | null;
  planName: string | null;
  planPaused?: boolean;
  planType: PlanType | null;
  planVideo: boolean;
  role: string;
  roles: string[];
  source: Source;
  state: boolean;
  status: number | null;
  statusText: string;
  stripeCustomer: string | null;
  tester: boolean;
  therapist: string | null;
  therapistId: number | null;
  therapistsHistoryInitials: (string | null)[] | null;
  trialOn: boolean;
  useV3Web?: boolean;
}

export enum PatientStatus {
  SIGN_UP = 1,
  SURVEY = 2,
  SUPPORT_WAITING = 3,
  SUPPORT_MATCHED = 4,
  PAID = 5,
  UNDER_AGE = 6,
}

export interface DisorderStorage {
  body: string;
  created_at: string;
  id: number;
  onboard_message_id: number;
  slug: string;
  updated_at: string | null;
}
export interface Disorder {
  body: string;
  createdAt: string;
  id: number;
  onboardMessageId: number;
  slug: string;
  updatedAt: string | null;
}

export type Age =
  | 'age-18'
  | 'age-24'
  | 'age-34'
  | 'age-44'
  | 'age-54'
  | 'age-64'
  | 'age-65';

export interface EmailNotificationsStorage {
  messages_from_our_professional: boolean;
  onboarding_and_therapy: boolean;
  self_care_reminders: boolean;
  subscription_status: boolean;
}
export interface EmailNotifications {
  messagesFromOurProfessional: boolean;
  onboardingAndTherapy: boolean;
  selfCareReminders: boolean;
  subscriptionStatus: boolean;
}
