import React, {useState} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import Select, {SelectChangeEvent} from '@mui/material/Select';
import {Autocomplete, TextField} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import {
  getCountriesForContinent,
  getOperationsDashboardData,
  OperationsDashboardDataParams,
} from 'src/state/action_creators/operationsDashboardCreator';
import {OperationsDashboardActionType} from 'src/state/action_types/OperationsDashboardActionType';
import {mapApiResponseSpots} from 'src/helpers/api_response/operationsDashboard';
import {Spots, SpotsStorage} from 'src/types/OperationsDashboard';
import {OperationsDashboardTabEnum} from 'src/constants/TabLists';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {Loading} from 'src/components/shared/Loading';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

interface InitValuesProps {
  continent: string | undefined;
  country: string | undefined;
  language: string | undefined;
}

const initValues: InitValuesProps = {
  continent: undefined,
  country: undefined,
  language: undefined,
};

interface Props {
  loading: boolean;
  setAllFilterParams: React.Dispatch<
    React.SetStateAction<OperationsDashboardDataParams | null>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSpots: React.Dispatch<React.SetStateAction<Spots | null>>;
  tabVal: OperationsDashboardTabEnum;
}

export const OperationsDashboardSpotsHeader = ({
  loading,
  setLoading,
  setSpots,
  setAllFilterParams,
  tabVal,
}: Props) => {
  const {status, totalSpot, continents, countriesForContinent, languages} =
    useSelector((state: RootState) => state.operations_dashboard);
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState(initValues);
  const {continent, language} = formValues;

  const setFormValuesFunction = (name: string, value: FixMeLater) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSelectContinent = (value: string) => {
    setFormValuesFunction('continent', value);
    if (value === '') {
      dispatch({
        type: OperationsDashboardActionType.SET_COUNTRIES_FOR_CONTINENT,
        payload: null,
      });
    } else {
      dispatch(getCountriesForContinent(tabVal, value));
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      getOperationsDashboardData({
        type: tabVal,
        ...formValues,
      }),
    ).then((res: SpotsStorage) => {
      setLoading(false);
      setSpots(mapApiResponseSpots(res));
    });
    setAllFilterParams({
      type: tabVal,
      ...formValues,
    });
  };

  if (status === SharedActionType.COMPLETED) {
    return (
      <div
        className="header-selector mb-7.5 flex items-center justify-between"
        style={{alignItems: 'flex-start'}}>
        <h2 className="w-full">
          Total spots
          <span className="pl-2 text-6 font-bold">{totalSpot}</span>
        </h2>

        {continents && (
          <FormControl size="small" fullWidth={true}>
            <InputLabel id="continent-selected">Continent</InputLabel>
            <Select
              labelId="continent-select"
              id="continent-select"
              label="Continent"
              defaultValue=""
              onChange={(event: SelectChangeEvent) => {
                handleSelectContinent(event.target.value.toLowerCase());
              }}>
              <MenuItem value="">None</MenuItem>
              {continents.map((c: string, i: number) => (
                <MenuItem value={c} key={i}>
                  {c}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {countriesForContinent && (
          <Autocomplete
            id="country-selected"
            fullWidth={true}
            options={countriesForContinent}
            size="small"
            onChange={(_, newValue: FixMeLater) => {
              setFormValuesFunction(
                'country',
                newValue ? newValue.toLowerCase() : newValue,
              );
            }}
            renderInput={(params) => <TextField {...params} label="Country" />}
          />
        )}
        {languages && (
          <Autocomplete
            id="lang-selected"
            fullWidth={true}
            options={languages}
            size="small"
            style={{marginRight: '10px'}}
            onChange={(_, newValue: FixMeLater) => {
              setFormValuesFunction('language', newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Language" />}
          />
        )}

        <Button
          buttonType={ButtonType.DASHBOARDS}
          size="small"
          disabled={!continent && !language}
          loading={loading}
          onClick={handleSubmit}>
          Save
        </Button>
      </div>
    );
  }
  return <Loading />;
};
