import React, {useEffect} from 'react';

import {Layout} from 'src/components/layout/Layout';
import {useAppDispatch} from 'src/hooks/hooks';
import {getChatStatus} from 'src/state/action_creators/supportSettingsCreator';
import {PageHeader} from 'src/components/layout/PageHeader';

import {SupportChatStatusSwitch} from './components/SupportChatStatusSwitch';

export const SupportSettingsPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getChatStatus());
  }, []);

  return (
    <Layout>
      <PageHeader pageTitle="Support chat settings" />
      <p className="mt-2 text-blue-light">
        From this view, you can manage the status of the support chat.
      </p>
      <SupportChatStatusSwitch />
    </Layout>
  );
};
