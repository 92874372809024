import React from 'react';

import {
  CalendarBlank,
  ChartLine,
  CircleWavyCheck,
  Cube,
  Envelope,
  Gear,
  Handshake,
  HouseLine,
  Key,
  LockKey,
  Pill,
  Receipt,
  TreeStructure,
  Users,
  VideoCamera,
  CalendarHeart,
} from '@phosphor-icons/react';

import {ControllerNames} from 'src/types/ControllerNames';
import {RoutesPath} from 'src/config/RoutesPath';
import {MenuLiList} from 'src/types/Menus';

export const SIZE_ICONS_NAVBAR = 20;
export const WEIGHT_ICONS_NAVBAR = 'duotone';

export const MENU_LI_LIST: MenuLiList[] = [
  {
    controllerName: null,
    name: 'Home',
    url: RoutesPath.HOME,
    svg: <HouseLine size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.USERS,
    name: 'Users',
    url: RoutesPath.PATIENTS,
    svg: <Users size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.THERAPISTS,
    name: 'Therapists',
    url: RoutesPath.THERAPISTS,
    svg: <Users size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.SUPERVISORS,
    name: 'Supervisors',
    url: RoutesPath.SUPERVISORS,
    svg: (
      <CircleWavyCheck size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />
    ),
  },
  {
    controllerName: ControllerNames.VIDEO_SESSIONS,
    name: 'Video Sessions',
    url: RoutesPath.VIDEO_SESSIONS,
    svg: <VideoCamera size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.EVENTS,
    name: 'Events',
    url: RoutesPath.EVENTS,
    svg: (
      <CalendarBlank size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />
    ),
  },
  {
    controllerName: ControllerNames.PLANS,
    name: 'Plans',
    url: RoutesPath.PLANS,
    svg: <Cube size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.PARTNERS,
    name: 'Partners',
    url: RoutesPath.PARTNERS,
    svg: <Handshake size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.WEBHOOKS,
    name: 'Webhooks',
    url: RoutesPath.WEBHOOKS,
    svg: (
      <TreeStructure size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />
    ),
  },
  {
    controllerName: ControllerNames.EMAILS_AUTHORIZATIONS,
    name: 'Email authorizations',
    url: RoutesPath.EMAIL_AUTHORIZATIONS,
    svg: <Envelope size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.TOOLS,
    name: 'B2B Reports',
    url: RoutesPath.TOOLS,
    svg: <Receipt size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.PILLS,
    name: 'Pills',
    url: RoutesPath.PILLS,
    svg: <Pill size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.USER_ROLES,
    name: 'Users and roles',
    url: RoutesPath.USERS_AND_ROLES,
    svg: <Key size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.ROLES,
    name: 'Roles',
    url: RoutesPath.ROLES,
    svg: <LockKey size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.SUPPORT,
    name: 'Support Chat',
    url: RoutesPath.SUPPORT_SETTINGS,
    svg: <Gear size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.DASHBOARDS,
    name: 'Dashboard',
    url: RoutesPath.DASHBOARD,
    svg: <ChartLine size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.OPERATION_DASHBOARD,
    name: 'Operation dashboard',
    url: RoutesPath.OPERATION_DASHBOARD,
    svg: <ChartLine size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />,
  },
  {
    controllerName: ControllerNames.INCOMING_PATIENTS,
    name: 'Incoming patients',
    url: RoutesPath.INCOMING_PATIENTS,
    svg: (
      <CalendarHeart size={SIZE_ICONS_NAVBAR} weight={WEIGHT_ICONS_NAVBAR} />
    ),
  },
];
