import React, {useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {Form} from 'react-bootstrap';
import toast from 'react-hot-toast';

import Swal from 'sweetalert2';

import {
  getPatient,
  sendConfirmationEmail,
  sendEmailResetPassword,
  skipUserConfirmation,
  unsubscribeUser,
  updatePatient,
} from 'src/state/action_creators/patientCreator';
import {DESTRUCTIVE_ACTION_CONFIRM} from 'src/constants/messages';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {ControllerNames} from 'src/types/ControllerNames';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';
import {Patient} from 'src/types/Patient';
import {RBAC} from 'src/helpers/Rbac';

const initPatient = {
  active_notifications: false,
  tester: false,
  trial_on: false,
  active: false,
  use_v3_web: false,
  active_email_notifications: {
    onboarding_and_therapy: true,
    subscription_status: false,
    self_care_reminders: false,
    messages_from_our_professional: false,
  },
};
interface SurveyInterface {
  name: string;
  value: FixMeLater;
}

interface Props {
  id: number;
  patient: Patient;
  setModalExportOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModalSurveyOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSurveySelected: React.Dispatch<React.SetStateAction<SurveyInterface>>;
  surveySelected: SurveyInterface;
}

export const PatientOptionsForUser = ({
  patient,
  id,
  surveySelected,
  setModalExportOpen,
  setModalSurveyOpen,
  setSurveySelected,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState(initPatient);

  const {
    active_email_notifications,
    active_notifications,
    tester,
    trial_on,
    use_v3_web,
    active,
  } = formValues;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = event.target;
    setFormValues({
      ...formValues,
      active_email_notifications: {
        ...formValues.active_email_notifications,
        [name]: checked,
      },
      [name]: checked,
    });
  };

  const handleSendEmailResetPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(sendEmailResetPassword(id)).then(() =>
      toast.success('Email sent successfully'),
    );
  };

  const handleSelectSurvey = ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>) => {
    const {options, selectedIndex, value} = target;
    setSurveySelected({
      value: value,
      name: options[selectedIndex].innerHTML,
    });
  };

  const handleUnsubscribeUser = () => {
    dispatch(unsubscribeUser(id)).then(() => {
      toast.success('Account canceled successfully');
      setTimeout(() => {
        navigate(`/patients`);
      }, 4500);
    });
  };

  const handleEditPatient = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    dispatch(updatePatient(id, formValues));
    toast.success('Saved Successfully');
  };

  useEffect(() => {
    if (patient) {
      setFormValues({
        active_email_notifications: {
          onboarding_and_therapy:
            patient.emailNotifications.onboardingAndTherapy,
          subscription_status: patient.emailNotifications.subscriptionStatus,
          self_care_reminders: patient.emailNotifications.selfCareReminders,
          messages_from_our_professional:
            patient.emailNotifications.messagesFromOurProfessional,
        },
        active_notifications: patient.activeNotifications,
        tester: patient.tester,
        trial_on: patient.trialOn,
        active: patient.active || false,
        use_v3_web: patient.useV3Web || false,
      });
    }
  }, [patient]);

  const valuesHaveChanged = () => {
    const activeSameValue = patient?.active
      ? active === patient?.active
      : active === false;
    const testerSameValue = tester === patient.tester;
    const trialOnSameValue = trial_on === patient.trialOn;
    const v3ActiveSameValue = use_v3_web === patient.useV3Web;
    const subscriptionStatusSameValue =
      active_email_notifications.subscription_status ===
      patient.emailNotifications.subscriptionStatus;
    const selfCareRemindersSameValue =
      active_email_notifications.self_care_reminders ===
      patient.emailNotifications.selfCareReminders;
    const messagesFromOurProfessionalSameValue =
      active_email_notifications.messages_from_our_professional ===
      patient.emailNotifications.messagesFromOurProfessional;
    const activeNotificationsSameValue =
      active_notifications === patient.activeNotifications;

    return (
      activeSameValue &&
      testerSameValue &&
      trialOnSameValue &&
      v3ActiveSameValue &&
      subscriptionStatusSameValue &&
      selfCareRemindersSameValue &&
      messagesFromOurProfessionalSameValue &&
      activeNotificationsSameValue
    );
  };

  return (
    <Form>
      {!patient.deleted && (
        <div className="user__notifications user__section">
          <h3>Options for user</h3>
          <div>
            <Form.Check
              className="mr-5"
              checked={active}
              id="table__user-active"
              label="Active"
              type="checkbox"
              name="active"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              className="mr-5"
              checked={tester}
              id="table__user-tester"
              label="Tester"
              type="checkbox"
              name="tester"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              checked={trial_on}
              id="table__user-trial-active"
              label="Trial active"
              type="checkbox"
              name="trial_on"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              checked={use_v3_web}
              id="table__user-use_v3_web-active"
              label="V3 Webapp user"
              type="checkbox"
              name="use_v3_web"
              onChange={handleCheckboxChange}
            />
          </div>
          <h4>Notifications</h4>
          <div className="mb-3">
            <Form.Check
              className="mr-5"
              checked={active_email_notifications.subscription_status}
              id="table__user-subscription_status"
              label="Email: Subscription status"
              type="checkbox"
              name="subscription_status"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              className="mr-5"
              checked={active_email_notifications.self_care_reminders}
              id="table__user-self_care_reminders"
              label="Email: Well-being test reminder"
              type="checkbox"
              name="self_care_reminders"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              className="mr-5"
              checked={
                active_email_notifications.messages_from_our_professional
              }
              id="table__user-messages_from_our_professional"
              label="Email: Chat messages"
              type="checkbox"
              name="messages_from_our_professional"
              onChange={handleCheckboxChange}
            />
            <Form.Check
              checked={active_notifications}
              id="table__user-active-notifications"
              label="Push"
              type="checkbox"
              name="active_notifications"
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="flex justify-end">
            <Button buttonType={ButtonType.WHITE} size="small">
              Cancel
            </Button>
            <Button
              buttonType={ButtonType.WHITE}
              size="small"
              onClick={handleEditPatient}
              disabled={valuesHaveChanged()}>
              Save
            </Button>
          </div>
        </div>
      )}
      <div className="user__section">
        <h3>Surveys</h3>
        <div className="flex items-center">
          <select
            className="mr-3 h-6 border border-solid border-black px-1 py-2"
            value={surveySelected.value}
            onChange={handleSelectSurvey}>
            <option value="initial" data-name="initial">
              Initial
            </option>
            <option value="pay" data-name="after payment">
              After payment
            </option>
            <option value="cancel" data-name="after cancellation">
              Cancellation
            </option>
            <RBAC controllerName={ControllerNames.USERS} actionName="export">
              <option value="single_patient_export" data-name="automatization">
                Patient Report
              </option>
            </RBAC>
          </select>
          {surveySelected.value === 'single_patient_export' ? (
            <Button
              buttonType={ButtonType.ONE_OPTION}
              size="small"
              onClick={(e) => {
                e.preventDefault();
                setModalExportOpen(true);
              }}>
              Export
            </Button>
          ) : (
            <Button
              buttonType={ButtonType.ONE_OPTION}
              size="small"
              onClick={(e) => {
                e.preventDefault();
                setModalSurveyOpen(true);
              }}>
              See
            </Button>
          )}
        </div>
      </div>
      {!patient.deleted && (
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="user__section">
            <h3>Reset password</h3>
            <span>
              Send an email with the instructions to reset the user&apos;s
              password to the email
              <b>{patient.email}</b>
            </span>
            <Button
              buttonType={ButtonType.ONE_OPTION}
              size="small"
              onClick={handleSendEmailResetPassword}>
              Send email for reset password
            </Button>
          </div>
          {!patient.confirmedAt && (
            <>
              <RBAC
                controllerName={ControllerNames.CONFIRMATIONS}
                actionName="update">
                <div className="user__section">
                  <h3>Confirmation email</h3>
                  <span>
                    Send an email with the instructions to validate the account
                    to the email <b>{patient.email}</b>
                  </span>
                  <Button
                    buttonType={ButtonType.ONE_OPTION}
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(sendConfirmationEmail(id)).then(() =>
                        toast.success('Email sent successfully'),
                      );
                    }}>
                    Send confirmation email
                  </Button>
                </div>
              </RBAC>
              <RBAC
                controllerName={ControllerNames.CONFIRMATIONS}
                actionName="update">
                <div className="user__section">
                  <h3>Manually confirm email</h3>
                  <span>Manually confirm user&apos;s email</span>
                  <Button
                    buttonType={ButtonType.ONE_OPTION}
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      Swal.fire({
                        icon: 'info',
                        title: 'Confirm email',
                        text: 'This action can not be undone, are you sure you want to proceed?',
                        showCancelButton: true,
                        allowOutsideClick: true,
                        cancelButtonText: 'No',
                        confirmButtonText: 'Yes',
                        backdrop: true,
                      }).then(({isConfirmed}) => {
                        if (isConfirmed) {
                          dispatch(skipUserConfirmation(id)).then(() => {
                            toast.success('Email confirmed successfully');
                            dispatch(getPatient(patient.id));
                          });
                        }
                      });
                    }}>
                    Confirm email
                  </Button>
                </div>
              </RBAC>
            </>
          )}
          <div className="user__section">
            <h3>Cancel account</h3>
            <span>
              This action will cancel permanently the account with the email{' '}
              <b>{patient.email}</b>
            </span>
            <Button
              buttonType={ButtonType.ONE_OPTION}
              size="small"
              onClick={(e) => {
                e.preventDefault();
                Swal.fire({
                  icon: 'info',
                  title: 'Cancel account',
                  text: DESTRUCTIVE_ACTION_CONFIRM,
                  showCancelButton: true,
                  allowOutsideClick: true,
                  cancelButtonText: 'Back',
                  confirmButtonColor: '#ff8989',
                  confirmButtonText: 'Confirm',
                  backdrop: true,
                }).then(({isConfirmed}) => {
                  if (isConfirmed) {
                    handleUnsubscribeUser();
                  }
                });
              }}>
              Cancel account
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};
