import React, {useState} from 'react';

import {ArrowsClockwise} from '@phosphor-icons/react';

import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import Button from 'src/components/buttons/Button';
import {Patient} from 'src/types/Patient';

import {RefreshSubscriptionModal} from '../modals/RefreshSubscriptionModal';

import {PatientCurrentSubscriptionDetails} from './subscription_section/PatientCurrentSubscriptionDetails';
import {PatientSubscriptionHistoryItem} from './subscription_section/PatientSubscriptionHistoryItem';
import {PatientSubscriptionStatus} from './subscription_section/PatientSubscriptionStatus';

export const PatientSubscriptionSection = ({patient}: {patient: Patient}) => {
  const [isModalRefreshSubscriptionOpen, setIsModalRefreshSubscriptionOpen] =
    useState(false);

  return (
    <div className="user__plan user__section">
      <div className="mb-4 flex items-center justify-between border-b border-b-blue-lightLight">
        <h4 className="!text-base">Subscription</h4>
        <Button
          buttonType={ButtonType.WHITE}
          size="small"
          className="min-h-auto h-3 !p-1"
          onClick={() => {
            setIsModalRefreshSubscriptionOpen(true);
          }}>
          <ArrowsClockwise size={18} />
        </Button>
      </div>

      <div className="flex justify-between rounded-1.5 bg-table-hoverBg p-2 pr-6">
        <PatientSubscriptionStatus patient={patient} />
      </div>
      {patient.currentSubscription && (
        <PatientCurrentSubscriptionDetails patient={patient} />
      )}
      <div className="mt-10">
        <h4>Subscription&apos;s History</h4>

        {patient.pastSubscriptions && patient.pastSubscriptions.length > 0 ? (
          patient.pastSubscriptions.map((item) => (
            <div className="p-2" key={patient.id + item.created_at}>
              <PatientSubscriptionHistoryItem subscription={item} />
            </div>
          ))
        ) : (
          <p>No history available</p>
        )}
      </div>
      <RefreshSubscriptionModal
        show={isModalRefreshSubscriptionOpen}
        setShow={setIsModalRefreshSubscriptionOpen}
        patientId={patient.id}
      />
    </div>
  );
};
