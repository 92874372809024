import React from 'react';

import DatePicker from 'react-datepicker';

import {GenericFiltersToggle} from 'src/components/generic_table/filters/GenericFiltersToggle';
import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';

import {FormValuesEvent} from '../../EventsTable';

export const CreatedAtFilter = ({
  formValues,
  updateFormValue,
}: Pick<
  GenericTableProps<Event, FormValuesEvent>,
  'formValues' | 'updateFormValue'
>) => (
  <GenericFiltersToggle
    checked={formValues.createdAt.checked}
    onChange={() =>
      updateFormValue('createdAt', 'checked', !formValues.createdAt.checked)
    }
    label="Created at">
    {formValues.createdAt.checked && (
      <div className="popover__filter_field">
        <span>is between</span>
        <div className="two_datepicker flex">
          <DatePicker
            name="createdAtStart"
            className="border border-solid border-black"
            selected={formValues.createdAt.value.from}
            onChange={(date) =>
              updateFormValue('createdAt', 'value', {
                ...formValues.createdAt.value,
                from: date || new Date(),
              })
            }
            maxDate={new Date()}
          />
          <DatePicker
            name="createdAtEnd"
            className="border border-solid border-black"
            selected={formValues.createdAt.value.to}
            onChange={(date) =>
              updateFormValue('createdAt', 'value', {
                ...formValues.createdAt.value,
                to: date || new Date(),
              })
            }
            maxDate={new Date()}
          />
        </div>
      </div>
    )}
  </GenericFiltersToggle>
);
