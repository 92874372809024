import React, {createContext, useContext, useState} from 'react';

import {FixMeLater} from 'src/state/interfaces/FixMeLater';

interface TherapistsContextInterface {
  allFilterParams: FixMeLater;
  columnsVisible: FixMeLater;
  countryCheck: boolean;
  countryValue: FixMeLater;
  fieldsFiltered: FixMeLater;
  filterSelected: FixMeLater;
  initValues: FixMeLater;
  languagesCheck: boolean;
  languagesValues: FixMeLater;
  modalExportOpen: boolean;
  modalNewOpen: boolean;
  orderBy: string;
  orderDesc: boolean;
  page: number;
  planTypeCheck: boolean;
  planTypeValues: FixMeLater;
  setAllFilterParams: React.Dispatch<React.SetStateAction<FixMeLater>>;
  setColumnsVisible: React.Dispatch<React.SetStateAction<FixMeLater>>;
  setCountryCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setCountryValue: React.Dispatch<React.SetStateAction<FixMeLater>>;
  setFieldsFiltered: React.Dispatch<React.SetStateAction<FixMeLater>>;
  setFilterSelected: React.Dispatch<React.SetStateAction<FixMeLater>>;
  setLanguagesCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setLanguagesValues: React.Dispatch<React.SetStateAction<FixMeLater>>;
  setModalExportOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModalNewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setOrderDesc: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPlanTypeCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setPlanTypeValues: React.Dispatch<React.SetStateAction<FixMeLater>>;
  setToggleShowColumPopOver: React.Dispatch<React.SetStateAction<boolean>>;
  setToggleShowPopOver: React.Dispatch<React.SetStateAction<boolean>>;
  toggleShowColumPopOver: boolean;
  toggleShowPopOver: boolean;
}

export const TherapistsContext = createContext<
  TherapistsContextInterface | undefined
>(undefined);

const initValues = {
  page: 1,
  orderBy: 'id',
  orderDesc: true,
};

function TherapistsProvider({children}: {children: React.ReactNode}) {
  const [page, setPage] = useState<number>(initValues.page);
  const [orderBy, setOrderBy] = useState<string>(initValues.orderBy);
  const [orderDesc, setOrderDesc] = useState<boolean>(initValues.orderDesc);
  const [filterSelected, setFilterSelected] = useState<FixMeLater>(null);
  const [toggleShowPopOver, setToggleShowPopOver] = useState(false);
  const [toggleShowColumPopOver, setToggleShowColumPopOver] = useState(false);

  const [languagesCheck, setLanguagesCheck] = useState(false);
  const [languagesValues, setLanguagesValues] = useState<FixMeLater>([]);

  const [planTypeCheck, setPlanTypeCheck] = useState(false);
  const [planTypeValues, setPlanTypeValues] = useState<FixMeLater>([]);

  const [countryCheck, setCountryCheck] = useState(false);
  const [countryValue, setCountryValue] = useState<string>('');

  const [fieldsFiltered, setFieldsFiltered] = useState<FixMeLater>([]);
  const [columnsVisible, setColumnsVisible] = useState<FixMeLater>([
    'email',
    'nickname',
    'invitation_sent_at',
    'invitation_accepted_at',
    'languages',
  ]);

  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [allFilterParams, setAllFilterParams] = useState(null);

  const [modalNewOpen, setModalNewOpen] = useState(false);

  return (
    <TherapistsContext.Provider
      value={{
        page,
        setPage,
        orderBy,
        setOrderBy,
        orderDesc,
        setOrderDesc,
        filterSelected,
        setFilterSelected,
        toggleShowPopOver,
        setToggleShowPopOver,
        toggleShowColumPopOver,
        setToggleShowColumPopOver,
        languagesCheck,
        setLanguagesCheck,
        languagesValues,
        setLanguagesValues,
        planTypeCheck,
        setPlanTypeCheck,
        planTypeValues,
        setPlanTypeValues,
        countryCheck,
        setCountryCheck,
        countryValue,
        setCountryValue,
        fieldsFiltered,
        setFieldsFiltered,
        columnsVisible,
        setColumnsVisible,
        modalExportOpen,
        setModalExportOpen,
        allFilterParams,
        setAllFilterParams,
        modalNewOpen,
        setModalNewOpen,
        initValues,
      }}>
      {children}
    </TherapistsContext.Provider>
  );
}

function useTherapists() {
  const context = useContext(TherapistsContext);
  if (context === undefined) {
    throw new Error('useTherapists must be used within a TherapistsProvider');
  }
  return context;
}

export {TherapistsProvider, useTherapists};
