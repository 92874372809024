import React from 'react';

import {Warning} from '@phosphor-icons/react';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {Form} from 'src/components/form/Form';

import {useTherapistEditForm} from '../../hooks/useTherapistEditForm';

interface Props {
  closeModal: () => void;
}
export const TherapistEditForm = ({closeModal}: Props) => {
  const {
    control,
    onSubmit,
    isSubmitting,
    hasErrors,
    setFileAvatar,
    urlAvatarFile,
    valuesHaveChanged,
  } = useTherapistEditForm({
    closeModal,
  });
  return (
    <Form
      onSubmit={onSubmit}
      className="flex size-full flex-col justify-between ">
      <ModalLayout.Header>
        <h3>Edit therapist</h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        <div className="flex w-full flex-col items-start gap-5">
          <Form.InputText
            control={control}
            name="nickname"
            label="Nickname"
            placeholder="Enter your nickname"
          />
          <Form.InputText
            control={control}
            name="name"
            label="Name"
            placeholder="Enter your name"
          />
          <Form.InputText
            control={control}
            name="surname"
            label="Surname"
            placeholder="Enter your surname"
          />
          <Form.InputText
            control={control}
            name="email"
            label="Email"
            placeholder="example@domain.com"
          />
          <Form.InputText
            control={control}
            name="phone"
            label="Phone"
            placeholder="Enter your phone"
          />

          <Form.InputFile
            control={control}
            name="avatar"
            label="Avatar"
            shouldShowPreview={true}
            setFile={setFileAvatar}
            urlFile={urlAvatarFile}
          />

          <Form.CheckboxGroup
            control={control}
            name="hasZoomAccount"
            options={['Zoom Account']}
            label=""
          />
        </div>
      </ModalLayout.Body>
      <ModalLayout.Footer>
        {hasErrors && <Warning color="red" />}
        <ButtonSubmit
          loadingSubmit={isSubmitting}
          disabled={valuesHaveChanged()}
        />
      </ModalLayout.Footer>
    </Form>
  );
};
