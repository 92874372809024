import {PlanTypeStorage, User, UserStorage} from './User';

export type genderStorage =
  | 'female'
  | 'male'
  | 'trans'
  | 'other'
  | 'non_binary';

export type feeStorage = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';

export type Collaboration = 'europe' | 'latam';

interface Avatar {
  cover: {
    url: string;
  };
  url: string;
}

interface SuperviseeStorage {
  full_name: string;
  initials: string;
  therapist_id: number;
}

interface IsSupervisorStorage {
  average_supervisees_active_patients: number;
  id: number;
  supervisees: SuperviseeStorage[];
  supervisees_available_percent: string;
}

interface IsSupervisor {
  averageSuperviseesActivePatients: number | null;
  id: number | null;
  supervisees: SuperviseeStorage[] | null;
  superviseesAvailablePercent: string | null;
}

export interface OrientationStorage {
  cognitive_behavioral: {
    has: boolean;
    text: string;
  };
  humanist: {
    has: boolean;
    text: string;
  };
  psychoanalysis: {
    has: boolean;
    text: string;
  };
  systemic: {
    has: boolean;
    text: string;
  };
}

export interface Orientation {
  cognitiveBehavioral: {
    has: boolean;
    text: string;
  };
  humanist: {
    has: boolean;
    text: string;
  };
  psychoanalysis: {
    has: boolean;
    text: string;
  };
  systemic: {
    has: boolean;
    text: string;
  };
}

export interface Speciality {
  level: number;
  text: string;
}

export const SPECIALITY_LEVELS: Speciality[] = [
  {level: 2, text: 'Expert'},
  {level: 1, text: 'Can attend'},
  {level: 0, text: 'Doesn’t attend'},
];

export interface Specialities {
  [key: string]: Speciality;
}

interface AccountStorage {
  active_patients: number;
  age: number;
  avatar: Avatar | null;
  billing_role: string | null;
  bio: string;
  birthday: string;
  chat: boolean;
  city: string;
  collaboration: Collaboration | 0;
  confidentiality: boolean;
  country: string;
  couple: boolean;
  derive: boolean;
  disable: boolean;
  experience: string;
  experience_started_at?: string | null;
  extra_languages: string[];
  fee: string | null;
  free_slots: number;
  gender: string;
  id: number;
  image_tag?: string | null;
  incorporated_at: string | null;
  initials: string;
  languages: string[];
  license: string;
  max_patients: number;
  mode: string[];
  nationality: string | null;
  note: string;
  orientation: OrientationStorage;
  orientation_others?: string[];
  partner_confidentiality: boolean | null;
  patients_priority: boolean | null;
  schedule: string | null;
  school: string;
  specialities: Specialities;
  specialities_other: Record<string, number>;
  time_zone: string;
  titles: string;
  total_attended: number | null;
  trials_patients: number;
  under_age: boolean;
  video: boolean;
  years_of_experience: number | null;
}

interface TherapistBack {
  avatar: Avatar | null;
  avatar_url: string;
  created_at: string | null;
  description: string;
  email: string;
  full_name: string;
  gender: string;
  has_supervisor: {
    full_name: string | null;
    id: number | null;
    initials: number | null;
    therapist_id: number | null;
  } | null;
  id: number;
  ifeel_birthday: string;

  is_supervisor: IsSupervisorStorage;
  locale: string;
  match_messages: MatchMessages;
  name: string;
  nickname: string;
  patients_today: number | null;
  phone: string;
  surname: string;
  tester: boolean;
  total_partners: number;
  total_patients: number;
  videocall_enabled: boolean;
  zoom_account_id: string;
}

export interface TherapistListStorage {
  country: string | null;
  email: string;
  full_name: string;
  id: number;
  invitation_accepted_at: string | null;
  invitation_sent_at: string | null;
  languages?: string[];
  nickname: string;
  plan_type: PlanTypeStorage[] | null;
  tester: boolean;
}
export interface TherapistList {
  country: string | null;
  email: string;
  fullName: string;
  id: number;
  invitationAcceptedAt: string | null;
  invitationSentAt: string | null;
  languages?: string[];
  nickname: string;
  planType: PlanTypeStorage[] | null;
  tester: boolean;
}

export type TherapistStorage = UserStorage;

export interface Therapist extends User {
  account: TherapistAccount | null;
  avatar: Avatar | null;
  hasSupervisor: {
    fullName: string | null;
    initials: number | null;
    supervisorId: number | null;
    therapistId: number | null;
  } | null;
  invitationAcceptedAt: string | null;
  invitationSentAt: string | null;
  isSupervisor: IsSupervisor | null;
  languages?: string[];
  matchMessages?: MatchMessages;
  planType: string[] | null;
  supervisorId: number | null;
  tester: boolean;
  videocallEnabled: boolean;
  whoSupervises: WhoSupervisesStorage[] | null;
}

export interface TherapistAccountStorage {
  account: AccountStorage;
  therapist: TherapistBack;
}

export interface TherapistAccount {
  activePatients: number;
  age: number | null;
  avatar: Avatar | null;
  billingRole: string | null;
  bio: string | null;
  birthday: string | null;
  chat: boolean;
  city: string | null;
  collaboration: Collaboration | 0;
  confidentiality: boolean;
  country: string | null;
  couple: boolean;
  derive: boolean;
  disable: boolean;
  experience: string | null;
  experienceStartedAt?: string | null;
  extraLanguages?: string[];
  fee: string | null;
  freeSlots: number;
  gender: string;
  id: number;
  ifeelBirthday: string | null;
  imageTag?: string | null;
  incorporatedAt: string | null;
  initials: string;
  languages?: string[];
  license: string | null;
  maxPatients: number | null;
  mode: string[];
  nationality: string | null;
  note: string;
  orientation: Orientation | null;
  orientationOthers: string[] | null;
  partnerConfidentiality: boolean | null;
  patientsPriority: boolean | null;
  patientsToday: number | null;
  schedule: string | null;
  school: string | null;
  specialities: Specialities;
  specialitiesOthers: Specialities | null;
  supervisor: null;
  supervisorId: number | null;
  timeZone: string | null;
  titles: string | null;
  totalAttended: number | null;
  totalPartners: number;
  totalPatients: number;
  trialsPatients: number;
  underAge: boolean;
  video: boolean;
  yearsOfExperience: number | null;
}

export interface MatchMessages {
  en: string | null;
  es: string | null;
  fr: string | null;
  he: string | null;
  pt: string | null;
}

export type TherapistSelectorStorage = {
  full_name: string;
  id: number;
  name: string;
};

export type TherapistSelector = {
  fullName: string;
  id: number;
  name: string;
};

export interface WhoSupervisesStorage {
  fullName: string | null;
  initials: string;
  therapist_id: number;
}

type AccountSettingsSpecialitiesStorageKeys =
  | 'addictions'
  | 'anxiety'
  | 'behavioral_problems_and_violence'
  | 'couple'
  | 'depression'
  | 'disability'
  | 'eating_issues_alterations'
  | 'emotional_dependency'
  | 'emotional_instability'
  | 'family'
  | 'gender_violence'
  | 'grief'
  | 'lgtbiqa'
  | 'maternity'
  | 'personal_development'
  | 'postraumatic_stress'
  | 'schizophrenia'
  | 'seniors'
  | 'several_mental_disorders'
  | 'sexuality'
  | 'sleep'
  | 'social_habilities'
  | 'workstress';

type OrientationStorageKeys =
  | 'cognitive_behavioral'
  | 'humanist'
  | 'psychoanalysis'
  | 'systemic';

export interface TherapistAccountSettingsStorage {
  billing_roles: string[];
  can_access_billing_fee: boolean;
  collaborations: string[];
  countries: string[];
  fees: string[];
  gender: {female: 'female'; male: 'male'};
  languages: {extras: string[]; main: string[]};
  mode: {
    chat: 'Chat';
    couple: 'Couple';
    under_age: 'under_age';
    video: 'Video';
  };
  nationalities: string[];
  orientation: {
    [key in OrientationStorageKeys]: string;
  };
  specialities: {[key in AccountSettingsSpecialitiesStorageKeys]: string};
  supervisors: {id: number; initials: string | null; therapist_id: number}[];
  time_zone: [[string, string]];
}
