import {Dispatch} from 'redux';

import {Pill, PillArea, PillStorage, TypeOfPill} from 'src/types/Pill';
import {mapApiResponsePill} from 'src/helpers/api_response/pill';
import {fetchFormData, fetchWithToken} from 'src/helpers/fetch';
import {toQueryString} from 'src/helpers/generalFunctions';
import {catchApiError} from 'src/helpers/catchApiError';
import {FormValuesPill} from 'src/pages/pills/PillsTable';

import {
  PillsAction,
  SetPillsOptionsPayload,
  SetPillsPayload,
} from '../actions/pills';
import {PillsActionType} from '../action_types/PillsActionType';
import {FixMeLater} from '../interfaces/FixMeLater';

type DispatchResult = PillsAction | any;

export const getPills = ({
  page,
  orderBy,
  orderDesc,
  formValues,
}: {
  formValues?: FormValuesPill;
  orderBy: string;
  orderDesc: boolean;
  page: number;
}) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: PillsActionType.PILLS_LOADING});
    const params: {[key: string]: string | number} = {
      locale: 'en',
      page,
      orderBy,
      orderDir: orderDesc ? 'desc' : 'asc',
    };
    if (formValues?.title.checked && formValues.title.value)
      params['title'] = formValues.title.value;
    if (formValues?.area_id.checked && formValues.area_id.value)
      params['area_id'] = formValues.area_id.value;

    const resp = await fetchWithToken(`admin/pills?${toQueryString(params)}`);
    const body = await resp?.json();
    if (body && body.success) {
      const {pills: unmappedPills, total_count: total} = body;
      const pills = unmappedPills.map((pill: PillStorage) =>
        mapApiResponsePill(pill),
      );
      const payload: SetPillsPayload = {pills, total};
      dispatch({
        type: PillsActionType.SET_PILLS,
        payload,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getPill = (pillId: number, locale: string) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(`admin/pills/${pillId}?locale=${locale}`);
    const body = await resp?.json();
    if (body && body.success) {
      const pill: Pill = mapApiResponsePill(body.pill);
      return pill;
    } else {
      dispatch(catchApiError(body));
      return null;
    }
  };
};

export const getPillsOptions = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken('admin/pills/new?locale=en');
    const body = await resp?.json();
    if (body && body.success) {
      const {
        areas,
        type_of_pills: typeOfPills,
      }: {areas: PillArea[]; type_of_pills: TypeOfPill[]} = body;
      const payload: SetPillsOptionsPayload = {
        areas,
        typeOfPills,
      };
      dispatch({type: PillsActionType.SET_PILLS_OPTIONS, payload});
    } else {
      dispatch(catchApiError(body));
    }
  };
};
export const createPill = (data: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchFormData('admin/pills', data, 'POST');
    const body = await resp?.json();
    if (body && body.success) {
      const pill: Pill = mapApiResponsePill(body.pill);
      dispatch({type: PillsActionType.NEW_PILL, payload: pill});
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const updatePill = (data: FixMeLater, pillId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchFormData(`admin/pills/${pillId}`, data, 'PUT');
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
      return true;
    }
  };
};
