import React from 'react';

import {CopyToClipboardButton} from 'src/components/buttons/CopyToClipboardButton';
import {NavigateToButton} from 'src/components/buttons/NavigateToButton';
import {Patient} from 'src/types/Patient';

import {getAgeRange} from '../getAgeRange';
import {formatDate} from '../dates';

export const getPatientGeneralDetails = (patient: Patient) => {
  const ageRange = patient.age ? getAgeRange(patient.age) : ' ';

  const referralsInfo = () =>
    patient.therapistsHistoryInitials &&
    patient.therapistsHistoryInitials.length > 0
      ? patient.therapistsHistoryInitials.join(', ')
      : '';

  const confirmedAtInfo = () => {
    const info = [];
    if (patient.confirmedBy) info.push(patient.confirmedBy);
    if (patient.confirmedAt) info.push(formatDate(patient.confirmedAt));
    return info.join(' - ');
  };

  const agentInfo = () => (
    <>
      {patient.agent ? patient.agent + ' - ' : ''}
      {patient.conversationWithSupport && (
        <CopyToClipboardButton
          textButton="Url to conversation"
          textToCopy={patient.conversationWithSupport}
        />
      )}
    </>
  );

  const therapistInfo = () => (
    <>
      <NavigateToButton
        to={`/therapist/${patient.therapistId}`}
        text={patient.therapist || 'Therapist'}
      />
      <span> - </span>
      {patient.conversationWithTherapist && (
        <CopyToClipboardButton
          textButton="Url to conversation"
          textToCopy={patient.conversationWithTherapist}
        />
      )}
    </>
  );

  return [
    {label: 'ID', value: patient.id},
    {label: 'Gender', value: patient.gender || ''},
    {
      label: 'Created',
      value: formatDate(patient.createdAt),
    },

    {
      label: 'Partner',
      value: patient.partner || '',
    },
    {
      label: 'Confirmed at',
      value: confirmedAtInfo(),
    },
    {label: 'Locale', value: patient.locale},
    {
      label: 'Agent',
      value: agentInfo(),
    },
    {label: 'Disorder', value: patient.disorders},
    {
      label: 'Therapist',
      value: therapistInfo(),
    },
    {label: 'Metric', value: patient.metric},
    {
      label: 'Referrals',
      value: referralsInfo(),
    },
    {
      label: 'Age',
      value: `${patient.exactAge || ''} ${ageRange}`,
    },
    {
      label: 'Notes',
      value: patient.note,
    },
  ];
};

export const getPatientSubscriptionDetails = (patient: Patient) => {
  return [
    {
      label: 'Pause date: ',
      value: patient.currentSubscription?.paused_at
        ? formatDate(patient.currentSubscription.paused_at)
        : '',
    },
    {
      label: 'Plan start: ',
      value: patient.currentSubscription?.created_at
        ? formatDate(patient.currentSubscription.created_at)
        : '',
    },
    {
      label: 'Extension reason: ',
      value: patient.currentSubscription?.last_extension_details || '',
    },
    {
      label: 'Notes: ',
      value: patient.currentSubscription?.note || '',
    },
  ];
};
