/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import {GenericColumnSelectorToggle} from 'src/components/generic_table/columns/GenericColumnSelectorToggle';
import {GenericColumnSelector} from 'src/components/generic_table/columns/GenericColumnSelector';
import {Column} from 'src/components/tables/types/Column';
import {Event} from 'src/types/Event';

export const EventsColumnSelector = ({
  allColumns,
  handleToggle,
  visibleColumns,
  toggleColumnVisibility,
  resetVisibleColumns,
  isPopOverOpen,
}: {
  allColumns: Column<Event>[];
  handleToggle: () => void;
  isPopOverOpen: boolean;
  resetVisibleColumns: () => void;
  toggleColumnVisibility: (columnKey: Column<Event>) => void;
  visibleColumns: Column<Event>[];
}) => (
  <GenericColumnSelector isOpen={isPopOverOpen}>
    <GenericColumnSelector.Header
      handleToggleColumnPopOver={handleToggle}
      resetVisibleColumns={resetVisibleColumns}
    />
    <GenericColumnSelector.Body>
      {allColumns.map((column) => {
        return (
          <GenericColumnSelectorToggle
            key={column.key}
            checked={visibleColumns.some((vc) => vc.key === column.key)}
            onChange={() => toggleColumnVisibility(column)}
            label={column.name}
          />
        );
      })}
    </GenericColumnSelector.Body>
  </GenericColumnSelector>
);
