import React, {useEffect, useState} from 'react';

import {Form} from 'react-bootstrap';
import toast from 'react-hot-toast';

import {DatePicker} from '@mui/x-date-pickers';
import {TextField} from '@mui/material';
import dayjs from 'dayjs';

import {extendSubscription} from 'src/state/action_creators/subscriptionsCreator';
import {getPatient} from 'src/state/action_creators/patientCreator';
import {useSubscription} from 'src/utils/subscriptions-provider';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {hasAnActivePlan} from 'src/helpers/subscriptions';
import {Loading} from 'src/components/shared/Loading';
import {useAppDispatch} from 'src/hooks/hooks';
import {tomorrow} from 'src/helpers/dates';
import {Patient} from 'src/types/Patient';

interface FormProps {
  extension: string;
}

const initInfo: FormProps = {
  extension: '',
};

export const ExtendSubscriptionModal = ({patient}: {patient: Patient}) => {
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState<FormProps>(initInfo);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [dateSelected, setDateSelected] = useState<Date | null>(null);
  const {isModalExtendSubscriptionOpen, setIsModalExtendSubscriptionOpen} =
    useSubscription();

  const subscriptionEndDate =
    isModalExtendSubscriptionOpen &&
    patient.activeUntil &&
    hasAnActivePlan(patient.activeUntil)
      ? new Date(patient.activeUntil)
      : tomorrow;

  useEffect(() => {
    setDateSelected(subscriptionEndDate);
  }, [isModalExtendSubscriptionOpen]);

  const closeModal = () => setIsModalExtendSubscriptionOpen(false);

  const handleInputChange = ({
    target,
  }:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadingSubmit(true);
    const date1 = dateSelected ? dayjs(dateSelected) : null;
    const date2 = patient.activeUntil ? dayjs(patient.activeUntil) : null;
    let dayDifference = 0;
    if (date1 && date2) dayDifference = date1.diff(date2, 'day');

    const data = {
      id: patient.id,
      trial_date: dateSelected,
      extension_days: dayDifference > 0 ? dayDifference : 0,
      last_extension_details: formValues.extension,
    };

    dispatch(extendSubscription(data)).then((res) => {
      setTimeout(() => {
        if (res) {
          toast.success('Subscription extended successfully');
          closeModal();
          dispatch(getPatient(patient.id!));
        }
        setLoadingSubmit(false);
      }, 2000);
    });
  };

  return (
    <ModalLayout
      show={isModalExtendSubscriptionOpen}
      closeModal={closeModal}
      id="extendSubscriptionModal"
      className="modal-height-auto">
      {patient ? (
        <form className="allInputsBlock container" onSubmit={handleSubmitForm}>
          <ModalLayout.Header>
            <h3>Extend subscription</h3>
          </ModalLayout.Header>
          <ModalLayout.Body isExtendModal={true}>
            <Form.Group>
              <Form.Label>Current plan</Form.Label>
              <input
                type="text"
                disabled={true}
                name="current_plan"
                autoComplete="off"
                value={patient.planName || ''}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Trial end date</Form.Label>
              <DatePicker
                value={dateSelected}
                onChange={(date) => setDateSelected(date)}
                minDate={subscriptionEndDate}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    className={`custom-date-picker w-full `}
                  />
                )}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Extension reason</Form.Label>
              <textarea
                name="extension"
                autoComplete="off"
                value={formValues.extension}
                onChange={handleInputChange}
              />
            </Form.Group>
          </ModalLayout.Body>
          <ModalLayout.Footer>
            <ButtonSubmit
              loadingSubmit={loadingSubmit}
              text="Confirm"
              disabled={dateSelected === patient.activeUntil}
            />
          </ModalLayout.Footer>
        </form>
      ) : (
        <Loading />
      )}
    </ModalLayout>
  );
};
