import React from 'react';

import {PencilLine, Plus} from '@phosphor-icons/react';

import {getTherapistPatientsSummaryInfo} from 'src/helpers/data_generation/therapist';
import {LabelValueDisplay} from 'src/components/LabelValueDisplay';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import Button from 'src/components/buttons/Button';
import {Therapist} from 'src/types/Therapist';

export const PatientsDetailsComponent = ({
  therapist,
  setModalEditPatientsDetails,
}: {
  setModalEditPatientsDetails: React.Dispatch<React.SetStateAction<boolean>>;
  therapist: Therapist;
}) => {
  const therapistPatientsDetails = getTherapistPatientsSummaryInfo(therapist);

  return (
    <div>
      <div className="section_header mt-4">
        <h3>Patients</h3>
        <Button
          buttonType={ButtonType.ACTIONS}
          size="small"
          onClick={() => setModalEditPatientsDetails(true)}>
          {therapist.account ? <PencilLine size="14" /> : <Plus size="14" />}
        </Button>
      </div>

      {therapistPatientsDetails &&
        therapistPatientsDetails.map((item) => {
          return (
            <LabelValueDisplay
              key={item.label}
              value={item.value || ''}
              label={item.label}
            />
          );
        })}
    </div>
  );
};
