import React from 'react';

import {GenericFiltersToggle} from 'src/components/generic_table/filters/GenericFiltersToggle';
import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';
import {IncomingPatient} from 'src/types/IncomingPatient';

import {FormValuesIncomingPatients} from '../../IncomingPatientsPage';

export const UnassignedCallsFilter = ({
  formValues,
  updateFormValue,
}: {
  formValues: GenericTableProps<
    IncomingPatient,
    FormValuesIncomingPatients
  >['formValues'];
  updateFormValue: <
    K extends keyof GenericTableProps<
      IncomingPatient,
      FormValuesIncomingPatients
    >['formValues'],
  >(
    field: K,
    subField: keyof GenericTableProps<
      IncomingPatient,
      FormValuesIncomingPatients
    >['formValues'][K],
    value: any,
  ) => void;
}) => {
  return (
    <GenericFiltersToggle
      isEmptyFilter
      checked={formValues.unassignedCalls?.checked}
      onChange={() => {
        updateFormValue(
          'unassignedCalls',
          'checked',
          !formValues.unassignedCalls.checked,
        );
      }}
      label="Unassigned Calls"
    />
  );
};
