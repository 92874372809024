import React from 'react';

import {Play} from '@phosphor-icons/react';

import {useSubscription} from 'src/utils/subscriptions-provider';
import ButtonSimple from 'src/components/buttons/ButtonSimple';

export const PauseSubscriptionButton = () => {
  const {setIsModalPauseReactivateSubscriptionOpen} = useSubscription();

  return (
    <ButtonSimple
      onClick={() => setIsModalPauseReactivateSubscriptionOpen(true)}>
      <Play size={16} />
    </ButtonSimple>
  );
};
