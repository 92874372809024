import dayjs from 'dayjs';

export const tomorrow: Date = dayjs().add(1, 'days').toDate();
export type DateString = `${number}-${number}-${number}`;

export function isValidDateString(
  dateString: string,
): dateString is DateString {
  const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
  const matches = dateString.match(dateRegex);

  if (!matches) {
    return false;
  }

  const year = parseInt(matches[1]);
  const month = parseInt(matches[2]) - 1; // months are 0-indexed in JavaScript
  const day = parseInt(matches[3]);

  const date = new Date(year, month, day);

  return (
    date.getFullYear() === year &&
    date.getMonth() === month &&
    date.getDate() === day
  );
}

export const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const firstOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const lastOfMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const adjustTimezone = ({
  date,
  formatAs = 'YYYY-MM-DD',
}: {
  date: Date;
  formatAs?: string;
}) => dayjs(date).format(formatAs);

export const formatDate = (date: string) =>
  date ? dayjs(date).format('LLL') : '';

export const checkIfValueIsDate = (value: unknown) => {
  //This could be Any, but unknown will force anybody to check the type when they change this function
  return value instanceof Date ? true : false;
};

export const getDateTimeNextHour = () => {
  const now = new Date();
  now.setMinutes(now.getMinutes() + 60 - (now.getMinutes() % 60));
  return new Date(now.getTime() - now.getTimezoneOffset() * 60000)
    .toISOString()
    .replace(/T/, ' ')
    .replace(/\..+/, '')
    .slice(0, 16);
};
