import React, {ComponentPropsWithoutRef} from 'react';

export const Select = ({
  className,
  children,
  ...rest
}: ComponentPropsWithoutRef<'select'>) => {
  return (
    <select
      {...rest}
      className={
        className || 'rounded-1.5 border border-solid border-black p-2'
      }>
      {children}
    </select>
  );
};
