import React from 'react';

import {PillsTable} from 'src/pages/pills/PillsTable';
import {Layout} from 'src/components/layout/Layout';

export const PillsPage = () => {
  return (
    <Layout>
      <PillsTable />
    </Layout>
  );
};
