import {Control} from 'react-hook-form';

export interface BaseControlledComponentProps {
  control?: Control<any>; // 'any' is required for the component to be generic: string;
  label: string;
  labelError?: string;
  name: string;
  placeholder?: string;
}

export enum INPUT_FILE_STATES {
  LOADING = 'LOADING',
  NO_PREVIEW = 'NO_PREVIEW',
  PREVIEW_DEFAULT = 'PREVIEW_DEFAULT',
  PREVIEW_IMAGE = 'PREVIEW_IMAGE',
}

export const ALL_OPTION = 'All';

export enum FileTypeStatus {
  DELETED = 'deleted',
  ORIGINAL = 'original',
  SELECTED = 'selected',
}
export interface FileType {
  file: File | null;
  status: FileTypeStatus;
}

export const fileInit = {
  file: null,
  status: FileTypeStatus.ORIGINAL,
};
