import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';

import {authReducer} from 'src/state/reducers/authReducer';

import {emailAuthorizationsReducer} from './reducers/emailAuthorizationsReducer';
import {operationsDashboardReducer} from './reducers/operationsDashboardReducer';
import {incomingPatientsReducer} from './reducers/incomingPatientsReducer';
import {graphicsTimeZoneReducer} from './reducers/graphicsTimeZoneReducer';
import {supportSettingsReducer} from './reducers/supportSettingsReducer';
import {videoSessionReducer} from './reducers/videoSessionReducer';
import {supervisorsReducer} from './reducers/supervisorsReducer';
import {therapistsReducer} from './reducers/therapistsReducer';
import {usersAndRolesReducer} from './reducers/usersAndRoles';
import {dashboardReducer} from './reducers/dashboardReducer';
import {therapistReducer} from './reducers/therapistReducer';
import {partnersReducer} from './reducers/partnersReducer';
import {patientsReducer} from './reducers/patientsReducer';
import {webhooksReducer} from './reducers/webhooksReducer';
import {settingsReducer} from './reducers/settingsReducer';
import {patientReducer} from './reducers/patientReducer';
import {eventsReducer} from './reducers/eventsReducer';
import {searchReducer} from './reducers/searchReducer';
import {pillsReducer} from './reducers/pillsReducer';
import {plansReducer} from './reducers/plansReducer';
import {rolesReducer} from './reducers/rolesReducer';
import {toolsReducer} from './reducers/toolsReducer';
import {homeReducer} from './reducers/homeReducer';
import {myReducer} from './reducers/myReducer';
import {uiReducer} from './reducers/uiReducer';

export const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    email_authorizations: emailAuthorizationsReducer,
    events: eventsReducer,
    graphics: graphicsTimeZoneReducer,
    home: homeReducer,
    incoming_patients: incomingPatientsReducer,
    my: myReducer,
    operations_dashboard: operationsDashboardReducer,
    partners: partnersReducer,
    patient: patientReducer,
    patients: patientsReducer,
    pills: pillsReducer,
    plans: plansReducer,
    roles: rolesReducer,
    search: searchReducer,
    settings: settingsReducer,
    supervisors: supervisorsReducer,
    support: supportSettingsReducer,
    therapist: therapistReducer,
    therapists: therapistsReducer,
    tools: toolsReducer,
    ui: uiReducer,
    uar: usersAndRolesReducer,
    videoSessions: videoSessionReducer,
    webhooks: webhooksReducer,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
