import {useEffect, useState} from 'react';

import {SubmitHandler, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {yupResolver} from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import {
  accountTherapist,
  getAccountTherapist,
  getCitiesForCountry,
  getTherapist,
  therapistAccountSetting,
} from 'src/state/action_creators/therapistsCreator';
import {
  getOrientationTherapist,
  isRoleAllowed,
} from 'src/helpers/data_generation/therapist';
import {
  FileType,
  FileTypeStatus,
  fileInit,
} from 'src/components/form/controlled/type';
import {TherapistAccountSetting} from 'src/state/interfaces/Therapist';
import {toSnakeCase} from 'src/helpers/generalFunctions';
import {TherapistAccount} from 'src/types/Therapist';
import {useAppDispatch} from 'src/hooks/hooks';
import {Locale} from 'src/types/User';
import {RootState} from 'src/state';

import {therapistAddEditAccountSchema} from '../../schemas/therapistSchema';
import {TherapistAccountEditFormValues} from '../types';

interface Params {
  closeModal: () => void;
}

export const useEditTherapistAccountForm = ({closeModal}: Params) => {
  const initialValues = {
    disable: [],
    partnerConfidentiality: [],
    initials: '',
    birthday: '',
    incorporatedAt: '',
    experienceStartedAt: '',
    nationality: '',
    country: '',
    city: '',
    gender: '',
    collaboration: '',
    fee: '',
    billingRole: '',
    school: '',
    note: '',
    license: '',
    avatar: '',
    orientation: [],
    mainLanguage: [],
    extraLanguage: [],
    orientationOthers: [],
  };
  const dispatch = useAppDispatch();
  const {therapist} = useSelector((state: RootState) => state.therapist);
  const {user} = useSelector((state: RootState) => state.auth);

  const [accountSettings, setAccountSettings] =
    useState<TherapistAccountSetting | null>(null);
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [cities, setCities] = useState<string[]>([]);
  const [orientationOthers, setOrientationOthers] = useState<string[]>([]);
  const [fileAvatar, setFileAvatar] = useState<FileType>(fileInit);
  const [uploadFile, setUploadFile] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [originalValues, setOriginalValues] = useState({
    ...initialValues,
    disable: [''] || [],
    partnerConfidentiality: [''] || [],
    orientation: [''] || [],
    extraLanguage: [''] || [],
    mainLanguage: [''] || [],
  });
  const allowedRoles = [
    'finance',
    'op_manager',
    'admin',
    'dashboard_admin',
    'op_supervisor',
    'op_admin',
  ];

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: {isSubmitting, errors, dirtyFields},
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(therapistAddEditAccountSchema),
    mode: 'all',
  });

  const updateCountriesAndCitiesSelector = async (country: string) => {
    if (!isFirstLoad) {
      setValue('city', '');
    } else {
      setIsFirstLoad(false);
    }
    if (country) {
      setIsLoadingCities(true);
      const res = await dispatch(getCitiesForCountry(country));
      if (res) setCities(res);
    }
    setIsLoadingCities(false);
  };

  const getTherapistInfo = async () => {
    if (therapist) {
      setIsLoading(true);
      const result = await dispatch(therapistAccountSetting(therapist.id!));
      if (result) {
        setAccountSettings(result);
        if (therapist.account) {
          const account: TherapistAccount | false = await dispatch(
            getAccountTherapist(therapist.id, Locale.EN),
          );
          if (account) {
            if (therapist.account.avatar) {
              setUploadFile(therapist.account.avatar.url);
            }
            setOrientationOthers(account.orientationOthers || []);

            setOriginalValues({
              ...initialValues,
              disable: account.disable ? ['Disabled'] : [],
              initials: account.initials || '',
              birthday: account.birthday || '',
              avatar: account.imageTag || '',
              incorporatedAt: account.incorporatedAt || '',
              experienceStartedAt: account.experienceStartedAt || '',
              nationality: account.nationality || '',
              country: account.country || '',
              city: account.city || '',
              billingRole: account.billingRole || '',
              collaboration: account.collaboration || '',
              fee: account.fee || '',
              gender: account.gender || '',
              note: account.note || '',
              school: account.school || '',
              license: account.license || '',
              partnerConfidentiality: account.partnerConfidentiality
                ? ['Partner confidentiality']
                : [],
              orientation: getOrientationTherapist(therapist) || [],
              extraLanguage: account.extraLanguages || [],
              mainLanguage: account.languages || [],
            });

            reset({
              ...initialValues,
              disable: account.disable ? ['Disabled'] : [],
              initials: account.initials || '',
              birthday: account.birthday || '',
              avatar: account.imageTag || '',
              incorporatedAt: account.incorporatedAt || '',
              experienceStartedAt: account.experienceStartedAt || '',
              nationality: account.nationality || '',
              country: account.country || '',
              city: account.city || '',
              billingRole: account.billingRole || '',
              collaboration: account.collaboration || '',
              fee: account.fee || '',
              gender: account.gender || '',
              note: account.note || '',
              school: account.school || '',
              license: account.license || '',
              partnerConfidentiality: account.partnerConfidentiality
                ? ['Partner confidentiality']
                : [],
              orientation: getOrientationTherapist(therapist) || [],
              extraLanguage: account.extraLanguages || [],
              mainLanguage: account.languages || [],
            });
          }
        }
      }
      setIsLoading(false);
    }
  };

  const valuesHaveChanged = () => {
    const currentValues = watch();

    const disableSameValue =
      originalValues.disable.length > 0
        ? currentValues.disable[0] === 'Disabled'
        : currentValues.disable.length === 0;
    const initialsSameValue =
      currentValues.initials === originalValues.initials;
    const birthdaySameValue =
      dayjs(currentValues.birthday).format('YYYY-MM-DD') ===
      dayjs(originalValues.birthday).format('YYYY-MM-DD');
    const incorporatedAtSameValue =
      dayjs(currentValues.incorporatedAt).format('YYYY-MM-DD') ===
      dayjs(originalValues.incorporatedAt).format('YYYY-MM-DD');
    const experienceStartedAtSameValue =
      dayjs(currentValues.experienceStartedAt).format('YYYY-MM-DD') ===
      dayjs(originalValues.experienceStartedAt).format('YYYY-MM-DD');
    const nationalitySameValue =
      currentValues.nationality === originalValues.nationality;
    const countrySameValue = currentValues.country === originalValues.country;
    const citySameValue = currentValues.city === originalValues.city;
    const billingRoleSameValue =
      currentValues.billingRole === originalValues.billingRole;
    const collaborationSameValue =
      currentValues.collaboration === originalValues.collaboration;
    const feeSameValue = currentValues.fee === originalValues.fee;
    const genderSameValue = currentValues.gender === originalValues.gender;
    const noteSameValue = currentValues.note === originalValues.note;
    const schoolSameValue = currentValues.school === originalValues.school;
    const licenseSameValue = currentValues.license === originalValues.license;
    const partnerConfidentialitySameValue =
      originalValues.partnerConfidentiality.length > 0
        ? currentValues.partnerConfidentiality[0] === 'Partner confidentiality'
        : currentValues.partnerConfidentiality.length === 0;
    const extraLanguageSameValue =
      originalValues.extraLanguage.sort().toString() ===
      currentValues.extraLanguage?.sort().toString();
    const mainLanguageSameValue =
      originalValues.mainLanguage.sort().toString() ===
      currentValues.mainLanguage?.sort().toString();
    const orientationSameValue =
      originalValues.orientation.sort().toString() ===
      currentValues.orientation?.sort().toString();
    const avatarSameValue = !dirtyFields.avatar;

    return (
      disableSameValue &&
      initialsSameValue &&
      birthdaySameValue &&
      incorporatedAtSameValue &&
      extraLanguageSameValue &&
      mainLanguageSameValue &&
      experienceStartedAtSameValue &&
      nationalitySameValue &&
      countrySameValue &&
      schoolSameValue &&
      collaborationSameValue &&
      licenseSameValue &&
      genderSameValue &&
      orientationSameValue &&
      feeSameValue &&
      billingRoleSameValue &&
      noteSameValue &&
      partnerConfidentialitySameValue &&
      citySameValue &&
      avatarSameValue
    );
  };

  useEffect(() => {
    getTherapistInfo();
  }, []);

  const onSubmit: SubmitHandler<TherapistAccountEditFormValues> = async (
    data,
  ) => {
    const orientationArray = accountSettings?.orientation
      ? Object.keys(accountSettings.orientation).filter((key) =>
          data.orientation?.includes(
            accountSettings.orientation[
              key as keyof typeof accountSettings.orientation
            ],
          ),
        )
      : [];

    const bodyRequest = {
      initials: data.initials !== '' ? data.initials : null,

      birthday: data.birthday,
      incorporatedAt: data.incorporatedAt,
      experienceStartedAt: data.experienceStartedAt,
      disable:
        data.disable &&
        data.disable.length > 0 &&
        data.disable[0] === 'Disabled'
          ? true
          : false,
      partnerConfidentiality:
        data.partnerConfidentiality &&
        data.partnerConfidentiality.length > 0 &&
        data.partnerConfidentiality[0] === 'Partner confidentiality'
          ? true
          : false,
      nationality: data.nationality,
      country: data.country,
      city: data.city,
      collaboration: data.collaboration,
      fee: data.fee,
      billingRole: data.billingRole,
      gender: data.gender,
      note: data.note,
      school: data.school,
      license: data.license,
      orientation:
        orientationArray.length > 0
          ? orientationArray.map((item) => toSnakeCase(item))
          : [],
      orientationOthers: orientationOthers,
      languages: {main: data.mainLanguage, extras: data.extraLanguage},
    };

    if (therapist) {
      const res = await dispatch(
        accountTherapist(
          therapist.id!,
          bodyRequest,
          therapist.account ? therapist.account.id : null,
        ),
      );

      if (res) {
        dispatch(getTherapist(therapist.id!)).then(() => {
          if (
            fileAvatar.status !== FileTypeStatus.ORIGINAL &&
            therapist.account
          ) {
            const formData = new FormData();
            formData.append('avatar', fileAvatar.file || '');
            dispatch(
              accountTherapist(
                therapist.id!,
                formData,
                therapist.account.id,
                true,
              ),
            );
          }
        });

        await dispatch(getTherapist(therapist.id!));
      }
    }

    closeModal();
  };

  useEffect(() => {
    const country = watch('country');
    if (country && !isLoading) {
      updateCountriesAndCitiesSelector(country);
    }
  }, [watch('country'), isLoading]);

  return {
    control,
    hasErrors: Object.keys(errors).length > 0,
    isSubmitting,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    accountSettings,
    cities,
    isLoadingCities,
    setFileAvatar,
    setOrientationOthers,
    uploadFile,
    isLoading,
    orientationOthers,
    isRoleAllowed: isRoleAllowed(allowedRoles, user),
    valuesHaveChanged,
  };
};
