import * as Yup from 'yup';

import {blankSpacesRegExp, phoneRegExp} from 'src/helpers/regularExp';
import {validateTypeFile} from 'src/helpers/generalFunctions';
import {ALLOWED_FILE_FORMATS} from 'src/constants/Common';

const therapistAddNewSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Email is required'),
  name: Yup.string()
    .required('Name is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  nickname: Yup.string()
    .required('Nickname is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  phone: Yup.string()
    .required('Phone is required')
    .matches(phoneRegExp, 'Not a valid phone number'),
  surname: Yup.string()
    .required('Surname is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  locale: Yup.string().required('Locale is required'),
  createZoomAccount: Yup.array().min(0).of(Yup.string().required()).required(),
});

const therapistEditSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Email is required'),
  name: Yup.string()
    .required('Name is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  nickname: Yup.string()
    .required('Nickname is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  phone: Yup.string()
    .required('Phone is required')
    .matches(phoneRegExp, 'Not a valid phone number'),
  surname: Yup.string()
    .required('Surname is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  avatar: Yup.mixed()
    .notRequired()
    .test(
      'format',
      "File extension not permitted. Make sure it's one of the following: jpg, jpeg, png or gif.",
      (value) => validateTypeFile(value, ALLOWED_FILE_FORMATS),
    ),
  hasZoomAccount: Yup.array().min(0).of(Yup.string().required()).required(),
});

const therapistAddEditAccountSchema = Yup.object({
  initials: Yup.string().notRequired(),
  disable: Yup.array().min(0).of(Yup.string().required()).required(),
  partnerConfidentiality: Yup.array()
    .min(0)
    .of(Yup.string().notRequired())
    .notRequired(),
  birthday: Yup.string().notRequired(),
  incorporatedAt: Yup.string().notRequired(),
  experienceStartedAt: Yup.string().notRequired(),
  nationality: Yup.string().notRequired().nullable(),
  country: Yup.string().notRequired().nullable(),
  city: Yup.string().notRequired().nullable(),
  gender: Yup.string().notRequired().nullable(),
  collaboration: Yup.string().notRequired().nullable(),
  fee: Yup.string().notRequired().nullable(),
  billingRole: Yup.string().notRequired().nullable(),
  school: Yup.string().notRequired(),
  note: Yup.string().notRequired(),
  license: Yup.string().notRequired(),
  avatar: Yup.mixed()
    .notRequired()
    .test(
      'format',
      "File extension not permitted. Make sure it's one of the following: jpg, jpeg, png or gif.",
      (value) => validateTypeFile(value, ALLOWED_FILE_FORMATS),
    ),
  orientation: Yup.array().notRequired(),
  extraLanguage: Yup.array().notRequired().min(0),
  mainLanguage: Yup.array().notRequired(),
});

const therapistEditAccountAttrsForLocaleSchema = Yup.object({
  bio: Yup.string()
    .required('Bio is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  experience: Yup.string()
    .required('Experience is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  titles: Yup.string()
    .required('Titles are required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
});

export {
  therapistAddEditAccountSchema,
  therapistAddNewSchema,
  therapistEditAccountAttrsForLocaleSchema,
  therapistEditSchema,
};
