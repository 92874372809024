import {UiActionType} from '../action_types/UiActionType';
import {UiAction} from '../actions/ui';

interface UiState {
  sidebarHidden: boolean;
}

const initialState = {
  sidebarHidden: false,
};

export const uiReducer = (state: UiState = initialState, action: UiAction) => {
  switch (action.type) {
    case UiActionType.UI_HIDE_SIDEBAR:
      return {
        sidebarHidden: false,
      };

    case UiActionType.UI_SHOW_SIDEBAR:
      return {
        sidebarHidden: true,
      };

    default:
      return state;
  }
};
