import React, {ComponentProps, useState} from 'react';

import {Eye, EyeSlash} from '@phosphor-icons/react';

import {validatePassword} from 'src/helpers/validatePassword';

import {ErrorText} from './ErrorText';

interface Props extends ComponentProps<'input'> {
  checkPasswordValidation?: boolean;
  handleInputChange: ({target}: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
}
export const InputPassword = ({
  handleInputChange,
  label,
  name,
  value,
  checkPasswordValidation = true,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  return (
    <div className="mb-2.5">
      {label && <label className="mb-2.5 block">{label}</label>}
      <div
        className={`flex h-11 w-full rounded-1.5 border ${
          error
            ? 'invalid:border-secondary-700 focus:border-secondary-700 filled:border-secondary-700 bg-backgrounds-1 !border-brandSecondary-700'
            : ''
        }`}>
        <input
          className="w-full !border-transparent bg-transparent px-2.5 py-0 shadow-none"
          type={`${showPassword ? 'text' : 'password'}`}
          name={name}
          autoComplete="off"
          value={value}
          onChange={(event) => {
            if (checkPasswordValidation) {
              validatePassword(event.target.value, setError);
            }
            handleInputChange(event);
          }}
        />
        <div
          onClick={() => setShowPassword(!showPassword)}
          className="flex h-full cursor-pointer items-center px-2.5">
          {showPassword ? <Eye size={16} /> : <EyeSlash size={16} />}
        </div>
      </div>
      {error && <ErrorText error={error} />}
    </div>
  );
};
