import React, {useState} from 'react';

import {FixMeLater} from 'src/state/interfaces/FixMeLater';

export const useForm = (initialState = {}) => {
  const [values, setValues] = useState<FixMeLater>(initialState);

  const reset = (newFormState = initialState) => {
    setValues(newFormState);
  };

  const handleInputChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  return [values, handleInputChange, reset];
};
