import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';
import {toQueryString} from 'src/helpers/generalFunctions';

import {RolesActionType} from '../action_types/RolesActionType';
import {RolesAction, SetRolesPayload} from '../actions/roles';
import {FixMeLater} from '../interfaces/FixMeLater';

type DispatchResult = RolesAction | any;

interface GetRolesParams {
  orderBy: string;
  orderDir: string;
  page: number;
}

export const getRolesOptions = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: RolesActionType.SET_ROLES_LOADING});
    const resp = await fetchWithToken(`admin/roles/new`);
    const body = await resp?.json();
    if (body && body.success) {
      const {actions, controllables} = body;
      dispatch(
        setRolesOptions({
          actions,
          controllables,
        }),
      );
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getRoles = (params: GetRolesParams) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: RolesActionType.SET_ROLES_LOADING});
    const resp = await fetchWithToken(`admin/roles?${toQueryString(params)}`);
    const body = await resp?.json();
    if (body && body.success) {
      const {roles, total_count: total} = body;
      const payload: SetRolesPayload = {
        roles,
        total,
      };
      dispatch({
        type: RolesActionType.SET_ROLES,
        payload,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getRole = (roleId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: RolesActionType.SET_ROLE_LOADING});
    const resp = await fetchWithToken(`admin/roles/${roleId}`);
    const body = await resp?.json();
    if (body && body.success) {
      return body.role.permissions.roles_permissions;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const createRole = (fieldsToEdit: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: RolesActionType.SET_ROLES_LOADING});
    const resp = await fetchWithToken(`admin/roles`, fieldsToEdit, 'POST');
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const updateRolePermissions = (
  roleId: number,
  fieldsToEdit: FixMeLater,
) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: RolesActionType.SET_ROLES_LOADING});
    const resp = await fetchWithToken(
      `admin/roles/${roleId}`,
      fieldsToEdit,
      'PUT',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const deleteRole = (roleId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: RolesActionType.SET_ROLES_LOADING});
    const resp = await fetchWithToken(
      `admin/roles/${roleId}`,
      {
        id: roleId,
      },
      'DELETE',
    );

    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

const setRolesOptions = (rolesOptsObj: FixMeLater) => ({
  type: RolesActionType.SET_ROLES_OPTIONS,
  payload: rolesOptsObj,
});
