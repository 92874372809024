import React from 'react';

import {NameSelector} from '@ifeelonline/chat-core';

import {Autocomplete, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';

import {getMetricPartner} from 'src/state/action_creators/partnerCreator';
import {capitalizeFirstLetter} from 'src/helpers/generalFunctions';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {DASHBOARD_PERIOD_ENUM} from 'src/types/Dashboard';
import {TIMES_SELECTOR} from 'src/constants/Dashboard';
import {PartnerSelector} from 'src/types/Partner';
import {useAppDispatch} from 'src/hooks/hooks';

interface Props {
  handleSubmit: () => void;
  loading: boolean;
  metric: string;
  metricList: any;
  monthSelected: Date;
  partner: PartnerSelector;
  partnersListSelector: PartnerSelector[];
  period: DASHBOARD_PERIOD_ENUM;
  setFormValuesFunction: any;
  setMetricList: React.Dispatch<any>;
}
export const DashboardHeader = ({
  partnersListSelector,
  partner,
  metric,
  period,
  setFormValuesFunction,
  metricList,
  setMetricList,
  handleSubmit,
  loading,
  monthSelected,
}: Props) => {
  const dispatch = useAppDispatch();

  const getMetrics = (partner_id: number) => {
    dispatch(getMetricPartner(partner_id, 'dashboards')).then(
      (res: NameSelector[] | null) => {
        setMetricList(res && res.length > 0 ? res : []);
      },
    );
  };

  return (
    <div
      className="header-selector flex items-center justify-between"
      style={{alignItems: 'flex-start'}}>
      {partnersListSelector && partner !== null && (
        <Autocomplete
          id="partner-selector"
          fullWidth={true}
          options={partnersListSelector}
          style={{marginRight: '10px'}}
          value={partner}
          disableClearable
          isOptionEqualToValue={(
            option: PartnerSelector,
            newValue: PartnerSelector,
          ) => {
            return option.id === newValue.id;
          }}
          getOptionLabel={(option: FixMeLater) =>
            capitalizeFirstLetter(option.name)
          }
          onChange={(event, newValue: FixMeLater) => {
            setFormValuesFunction('partner', newValue ? newValue : '');
            if (newValue !== null && newValue !== '') {
              getMetrics(newValue.id);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Partner"
              error={!partner}
              helperText={!partner ? 'Partner is required' : ''}
              required
            />
          )}
        />
      )}
      <Autocomplete
        id="partner-metric"
        fullWidth={true}
        options={metricList}
        style={{width: '100%', marginRight: '10px'}}
        getOptionLabel={(option: FixMeLater) => option.name}
        inputValue={metric}
        disabled={metricList.length === 0}
        onInputChange={(_, newInputValue) => {
          setFormValuesFunction('metric', newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} size="small" label="Metric" />
        )}
      />
      <Autocomplete
        id="period-selected"
        fullWidth={true}
        options={TIMES_SELECTOR}
        defaultValue={TIMES_SELECTOR[0]}
        style={{marginRight: '10px'}}
        getOptionLabel={(option: FixMeLater) => option.name}
        onChange={(_, newValue: FixMeLater) => {
          setFormValuesFunction('period', newValue ? newValue.value : '');
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Period"
            error={!period}
            helperText={!period ? 'Period is required' : ''}
            required
          />
        )}
      />
      {period === DASHBOARD_PERIOD_ENUM.MONTH && (
        <DatePicker
          views={['year', 'month']}
          label="Year and Month"
          openTo="year"
          minDate={new Date('2016/01/01')}
          maxDate={new Date()}
          value={monthSelected}
          className="w-full"
          onChange={(dateObject: any) => {
            if (dateObject) {
              const date = new Date(dateObject);
              setFormValuesFunction('monthSelected', date);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Month"
              error={!monthSelected}
              helperText={!monthSelected ? 'Month is required' : ''}
              required
            />
          )}
        />
      )}

      <ButtonSubmit loadingSubmit={loading} onClick={handleSubmit} />
    </div>
  );
};
