import React from 'react';

import {useSelector} from 'react-redux';

import {Autocomplete, TextField} from '@mui/material';

import {GenericFiltersToggle} from 'src/components/generic_table/filters/GenericFiltersToggle';
import {PsychologistGuideForSelector} from 'src/types/PsychologistGuide';
import {capitalizeFirstLetter} from 'src/helpers/generalFunctions';
import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';
import {IncomingPatient} from 'src/types/IncomingPatient';
import {RootState} from 'src/state';

import {FormValuesIncomingPatients} from '../../IncomingPatientsPage';

export const PsychologistGuideFilter = ({
  formValues,
  updateFormValue,
}: {
  formValues: GenericTableProps<
    IncomingPatient,
    FormValuesIncomingPatients
  >['formValues'];
  updateFormValue: <
    K extends keyof GenericTableProps<
      IncomingPatient,
      FormValuesIncomingPatients
    >['formValues'],
  >(
    field: K,
    subField: keyof GenericTableProps<
      IncomingPatient,
      FormValuesIncomingPatients
    >['formValues'][K],
    value: any,
  ) => void;
}) => {
  const {psychologistGuideList} = useSelector(
    (state: RootState) => state.incoming_patients,
  );
  if (!psychologistGuideList) return null;
  return (
    <GenericFiltersToggle
      checked={formValues.psychologistGuide?.checked}
      onChange={() =>
        updateFormValue(
          'psychologistGuide',
          'checked',
          !formValues.psychologistGuide.checked,
        )
      }
      label="Therapist">
      {formValues.psychologistGuide?.checked && (
        <div className="popover__filter_field">
          <Autocomplete
            id="psychologist-guide-selector"
            options={psychologistGuideList}
            size="small"
            getOptionLabel={(option: PsychologistGuideForSelector) =>
              capitalizeFirstLetter(option.nickname)
            }
            value={
              psychologistGuideList?.find(
                (option: PsychologistGuideForSelector) =>
                  option.id === formValues.psychologistGuide.value,
              ) || null
            }
            onChange={(event, newValue) => {
              updateFormValue(
                'psychologistGuide',
                'value',
                newValue?.id || null,
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '& legend': {display: 'none'},
                  '& fieldset': {top: 0},
                }}
              />
            )}
          />
        </div>
      )}
    </GenericFiltersToggle>
  );
};
