import React from 'react';

import toast from 'react-hot-toast';

import IncomingPatientsRepository from 'src/services/IncomingPatientsRepository';
import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {IncomingPatient} from 'src/types/IncomingPatient';

import {IncomingPatientEditModalForm} from './IncomingPatientEditModalForm';

export interface IncomingPatientFormValues {
  appointmentDate?: string | null;
  psychologistGuideId: number | null;
}

interface Props extends ModalSimpleInterface {
  fetchIncomingPatients: (page: number) => Promise<void>;
  incomingPatient: IncomingPatient | null;
  page: number;
}

export const IncomingPatientEditModal = ({
  show,
  setShow,
  incomingPatient,
  fetchIncomingPatients,
  page,
}: Props) => {
  const closeModal = () => setShow!(false);

  const handleFormSubmit = async (data: IncomingPatientFormValues) => {
    if (!data.psychologistGuideId) return;
    const res = await IncomingPatientsRepository.update(
      incomingPatient!.id,
      Number(data.psychologistGuideId),
      data.appointmentDate ?? undefined,
    );

    closeModal();

    if (res) {
      fetchIncomingPatients(page);
      toast.success('Patient updated successfully');
    }
  };

  return (
    <ModalLayout show={show} closeModal={closeModal}>
      {incomingPatient && (
        <IncomingPatientEditModalForm
          incomingPatient={incomingPatient}
          onSubmit={handleFormSubmit}
        />
      )}
    </ModalLayout>
  );
};
