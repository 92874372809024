import React, {useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {CaretDown, CaretUp, MagnifyingGlass} from '@phosphor-icons/react';
import Popover from '@mui/material/Popover';
import {Tooltip} from '@mui/material';

import {getFilters} from 'src/state/action_creators/searchCreator';
import {hasNonNumericChars} from 'src/helpers/generalFunctions';
import {useAppDispatch} from 'src/hooks/hooks';
import {useQuery} from 'src/hooks/useQuery';
import {RootState} from 'src/state';

export interface Filter {
  filterId: string;
  name: string;
}

export interface FilterBack {
  filter_id: string;
  name: string;
}
interface searchBarProps {
  search: string;
  setSearch: (value: string) => void;
}

export const SearchBar = ({search, setSearch}: searchBarProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('Email');
  const [isValidInput, setIsValidInput] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [filters, setFilters] = useState<FilterBack[]>([]);
  const {status} = useSelector((state: RootState) => state.search);

  const query = useQuery();
  const searchQuery = query.get('text');
  const filterId = query.get('filter_id');

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleOptionSelect = (option: string) => () => {
    setSelectedOption(option);
    setSearch('');
    handleClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (selectedOption.toLowerCase() === 'id') {
      setIsValidInput(!hasNonNumericChars(e.target.value));
    } else {
      setIsValidInput(true);
    }
  };

  const handleSubmitForm = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<SVGSVGElement>,
  ) => {
    e.preventDefault();
    if (search.length > 0 && isValidInput) {
      const selectedFilter = filters.find(
        (filter) => filter.name === selectedOption,
      );
      if (selectedFilter) {
        const filterId = selectedFilter.filter_id;
        navigate(
          `/search?text=${encodeURIComponent(
            search,
          )}&filter_id=${encodeURIComponent(filterId)}`,
        );
      }
    }
  };

  useEffect(() => {
    dispatch(getFilters()).then((res) => {
      setFilters(res);
    });
  }, []);

  useEffect(() => {
    if (searchQuery && filterId) {
      const selectedFilter = filters.find(
        (filter) => filter.filter_id === filterId,
      );
      if (selectedFilter) {
        setSelectedOption(selectedFilter.name);
      }
      setSearch(searchQuery);
    }
  }, [filters]);

  return (
    <div className="flex-auto">
      {status === SharedActionType.LOADING ? (
        <></>
      ) : (
        <form
          onSubmit={handleSubmitForm}
          className="flex items-center justify-start">
          <MagnifyingGlass
            size={16}
            className="cursor-pointer"
            onClick={(e) => handleSubmitForm(e)}
          />
          <div>
            <div
              onClick={(e) => handleClick(e)}
              className="mx-2 cursor-pointer rounded bg-brandSecondary-300/100 px-2 py-1 hover:bg-brandSecondary-300/80">
              {anchorEl ? (
                <CaretUp size={10} color="black" />
              ) : (
                <CaretDown size={10} color="black" />
              )}{' '}
              {selectedOption}:
            </div>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              className="mt-2">
              {filters.map((filter: FilterBack) => (
                <div key={filter.filter_id}>
                  <p
                    onClick={handleOptionSelect(filter.name)}
                    className={`w-auto cursor-pointer rounded px-5 py-1 hover:bg-brandSecondary-200 ${
                      selectedOption === filter.name
                        ? 'bg-brandSecondary-200'
                        : ''
                    }`}>
                    {filter.name}
                  </p>
                </div>
              ))}
            </Popover>
          </div>
          <Tooltip
            title="When searching by ID, please enter only numerical values."
            open={!isValidInput}>
            <input
              type="search"
              placeholder="Search..."
              name="search"
              autoComplete="off"
              value={search}
              onChange={handleInputChange}
              className="flex-1 border-none pl-2.5 text-3.5 text-blue-light"
            />
          </Tooltip>
        </form>
      )}
    </div>
  );
};
