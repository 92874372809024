import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {
  exportIncomingPatients,
  getPsychologistGuideSelector,
} from 'src/state/action_creators/incomingPatientsCreator';
import IncomingPatientsRepository from 'src/services/IncomingPatientsRepository';
import GenericTable from 'src/components/generic_table/GenericTable';
import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';
import {ExportModal} from 'src/components/shared/ExportModal';
import {IncomingPatient} from 'src/types/IncomingPatient';
import {Loading} from 'src/components/shared/Loading';
import {Layout} from 'src/components/layout/Layout';
import {useAppDispatch} from 'src/hooks/hooks';
import {Page} from 'src/types/Page';
import {RootState} from 'src/state';

import {IncomingPatientsFiltersForm} from './components/IncomingPatientsFiltersForm';
import {IncomingPatientEditModal} from './modals/IncomingPatientEditModal';
import {buildRequestParams} from './utils/buildRequestParams';
import {renderTableCell} from './utils/renderTableCell';
import {columnsToShow} from './utils/columnsToShow';

export type AppointmentDateValue = {from: Date | null; to: Date | null} | false;

type FilterableKeys = keyof Pick<
  IncomingPatient,
  'appointment' | 'isHighRisk' | 'psychologistGuide' | 'unassignedCalls'
>;

type ValueType<K extends FilterableKeys> = K extends 'appointment'
  ? AppointmentDateValue
  : K extends 'isHighRisk'
    ? boolean | null
    : K extends 'psychologistGuide'
      ? number | null
      : K extends 'unassignedCalls'
        ? string | null
        : never;

export type FormValuesIncomingPatients = {
  [K in FilterableKeys]: {
    checked: boolean;
    value: ValueType<K>;
  };
};

export const IncomingPatientsPage = ({controllerName}: Page) => {
  const dispatch = useAppDispatch();
  const {psychologistGuideList} = useSelector(
    (state: RootState) => state.incoming_patients,
  );
  /* TO-DO watch form */
  const [overflowHidden, setOverflowHidden] = useState('');
  const [activeIncomingPatient, setActiveIncomingPatient] =
    useState<IncomingPatient | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [incomingPatients, setIncomingPatients] = useState<{
    items: IncomingPatient[];
    total: number;
  }>({
    items: [],
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [isPopOverOpen, setIsPopOverOpen] = useState<boolean>(false);

  const [filtersSelected, setFiltersSelected] = useState<
    GenericTableProps<
      IncomingPatient,
      FormValuesIncomingPatients
    >['filtersSelected']
  >([]);

  const initValues: GenericTableProps<
    IncomingPatient,
    FormValuesIncomingPatients
  >['formValues'] = {
    appointment: {
      checked: false,
      value: false,
    },
    isHighRisk: {
      checked: false,
      value: null,
    },
    psychologistGuide: {
      checked: false,
      value: null,
    },
    unassignedCalls: {
      checked: false,
      value: null,
    },
  };
  const [formValues, setFormValues] =
    useState<
      GenericTableProps<
        IncomingPatient,
        FormValuesIncomingPatients
      >['formValues']
    >(initValues);

  const fetchIncomingPatients = async (page: number) => {
    setIsLoading(true);

    try {
      const response = await IncomingPatientsRepository.get(
        buildRequestParams(page, formValues),
      );
      if (response) setIncomingPatients(response);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch incoming patients:', error);
    }
    setIsLoading(false);
  };

  const handleToggleFilter = () => setIsPopOverOpen(!isPopOverOpen);
  const handleExport = () => setModalExportOpen(true);

  useEffect(() => {
    if (!psychologistGuideList) {
      dispatch(getPsychologistGuideSelector());
    }
  }, []);

  useEffect(() => {
    fetchIncomingPatients(page);
  }, [page, filtersSelected]);

  return (
    <Layout className={overflowHidden}>
      <>
        {isLoading && <Loading />}
        <GenericTable>
          <GenericTable.Header
            filtersSelected={filtersSelected}
            handleToggleFilter={handleToggleFilter}
            isFilterPopOverOpen={isPopOverOpen}
            data={incomingPatients}
            controllerName={controllerName}
            handleExport={handleExport}
            pageTitle="Incoming patients">
            <IncomingPatientsFiltersForm
              handleToggleFilter={handleToggleFilter}
              setFiltersSelected={setFiltersSelected}
              setPage={setPage}
              isPopOverOpen={isPopOverOpen}
              formValues={formValues}
              setFormValues={setFormValues}
              initValues={initValues}
            />
          </GenericTable.Header>

          <GenericTable.Body
            isLoading={isLoading}
            data={incomingPatients.items}>
            <GenericTable.Body.Head
              columns={columnsToShow}
              filtersSelected={filtersSelected}
            />
            <GenericTable.Body.Main
              data={incomingPatients.items}
              columns={columnsToShow}
              renderCell={(column, incomingPatient) =>
                renderTableCell(
                  column,
                  incomingPatient,
                  setActiveIncomingPatient,
                )
              }
            />
          </GenericTable.Body>
          <GenericTable.Footer
            total={incomingPatients.total}
            page={page}
            setPage={setPage}
          />
        </GenericTable>

        <IncomingPatientEditModal
          show={activeIncomingPatient !== null}
          setShow={() => setActiveIncomingPatient(null)}
          incomingPatient={activeIncomingPatient}
          fetchIncomingPatients={fetchIncomingPatients}
          page={page}
        />
        {incomingPatients.items &&
          filtersSelected &&
          incomingPatients.items.length < 10000 && (
            <ExportModal
              show={modalExportOpen}
              setModalOpen={setModalExportOpen}
              allFilterParams={buildRequestParams(page, formValues)}
              title="Export incoming patients"
              exportReport={exportIncomingPatients}
            />
          )}
      </>
    </Layout>
  );
};
