import {UserStorage} from '@ifeelonline/chat-core';

import {PsychologistGuideForSelector} from './PsychologistGuide';
import {User} from './User';

export interface IncomingPatientStorage
  extends Pick<UserStorage, 'id' | 'email' | 'name' | 'phone'> {
  appointment?: string;
  appointment_status: 0 | 1;
  conversation_url: string;
  eligible_id: string;
  email: string;
  high_risk: boolean;
  psychologist_guide?: {
    id: number;
    nickname: string;
  };
}

export enum AppointmentStatus {
  ToCall = 0,
  Scheduled = 1,
}

export interface IncomingPatient
  extends Pick<User, 'id' | 'email' | 'name' | 'phone'> {
  appointment: string | null;
  appointmentStatus: AppointmentStatus;
  conversationUrl: string;
  eligibleId: string;
  isHighRisk: boolean;
  psychologistGuide: PsychologistGuideForSelector | null;
  unassignedCalls: boolean;
}

export interface IncomingPatientGetParams {
  appointment?: false | {from: string; to: string};
  high_risk?: boolean;
  page: number;
  psychologist_guide_id?: number;
}
