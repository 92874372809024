import React from 'react';

import {ArrowsClockwise, WarningCircle} from '@phosphor-icons/react';

import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import Button from 'src/components/buttons/Button';

export const NoConnection = () => {
  const refreshPage = () => window.location.reload();

  return (
    <div className="absolute left-0 top-0 flex size-full flex-col items-center justify-center text-blue-primary">
      <WarningCircle size={60} />
      <h5 className="my-5">No connection with backend...</h5>
      <Button
        buttonType={ButtonType.DEFAULT}
        size="small"
        onClick={refreshPage}>
        <ArrowsClockwise size={18} color="#fff" className="mr-1" />
        Click to reload!
      </Button>
    </div>
  );
};
