import React from 'react';

import {Loading} from '../shared/Loading';

const FormInputLoading = () => {
  return (
    <div className="mb-3 flex w-full justify-center rounded-1 border-2">
      <Loading isSmall={true} />
    </div>
  );
};
export default FormInputLoading;
