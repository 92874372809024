import React from 'react';

import BasicTooltip from 'src/components/shared/BasicTooltip';
import {LineChart} from 'src/components/graphics/LineChart';
import {GraphicBoxInterface} from 'src/types/Graphics';

export const ChartCard = ({
  periodSelected,
  total,
  graphic_data,
  data_tip,
  title,
  colors,
  height,
  showGrid,
  icon,
  gradient = false,
  annotation = false,
  offsetGradient,
  ...rest
}: GraphicBoxInterface) => {
  return (
    <div className="chart-card" {...rest}>
      <div className="chart-card-header">
        {icon && <>{icon}</>}
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <h3>{title}</h3>
          <span className="total">{total}</span>
        </div>
      </div>
      <div className="tooltip-chart">
        <BasicTooltip title={data_tip} />
      </div>
      {graphic_data ? (
        <LineChart
          data={graphic_data}
          colors={colors}
          height={height}
          showGrid={showGrid}
          period={periodSelected}
          gradient={gradient}
          annotation={annotation}
          offsetGradient={offsetGradient}
        />
      ) : (
        <div
          className="flex items-center justify-center"
          style={{height: height + 32}}>
          No data available
        </div>
      )}
    </div>
  );
};
