import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {ControllerNames} from 'src/types/ControllerNames';

const areAllActionsChecked = (
  rolesPermissionsSelected: FixMeLater,
  controllerName: FixMeLater,
  actionsListLength: number,
) => {
  return (
    rolesPermissionFilter(rolesPermissionsSelected, controllerName).length >
      0 &&
    rolesPermissionFilter(rolesPermissionsSelected, controllerName)[0].actions
      .length === actionsListLength
  );
};

const areSessionsAndGraphicsChecked = (
  rolesPermissionsSelected: FixMeLater,
) => {
  return (
    rolesPermissionFilter(rolesPermissionsSelected, ControllerNames.SESSIONS)
      .length > 0 &&
    rolesPermissionFilter(rolesPermissionsSelected, ControllerNames.GRAPHICS)
      .length > 0
  );
};

const rolesPermissionFilter = (
  rolesPermissions: FixMeLater,
  controllerName: string,
) => {
  return rolesPermissions.filter(function (rP: FixMeLater) {
    return Array.isArray(controllerName)
      ? controllerName.includes(rP.controller)
      : controllerName === rP.controller;
  });
};

type ControllerActions = {
  actions: string[];
  controller: string;
};

const roleHasActionPermission = (
  roleFiltered: ControllerActions[],
  actionName: string,
): boolean => {
  if (actionName === null) return true;

  for (const entry of roleFiltered) {
    if (entry.actions.includes(actionName)) {
      return true;
    }
  }

  return false;
};

export {
  areAllActionsChecked,
  areSessionsAndGraphicsChecked,
  roleHasActionPermission,
  rolesPermissionFilter,
};
