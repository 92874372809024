import React, {ComponentProps} from 'react';

import {Form} from 'react-bootstrap';

interface Props extends ComponentProps<'div'> {
  checked: boolean;
  label: string;
  onChange: () => void;
}

export const GenericColumnSelectorToggle = ({
  checked,
  onChange,
  label,
}: Props) => {
  return (
    <div data-testid="column-selector-toggle">
      <li className="p-2 shadow-modalHeader">
        <Form.Check
          checked={checked}
          onChange={onChange}
          label={label.charAt(0).toUpperCase() + label.slice(1)}
          type="checkbox"
        />
      </li>
    </div>
  );
};
