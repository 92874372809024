import React from 'react';

import {Warning} from '@phosphor-icons/react';

export const ErrorText = ({error}: {error: string}) => {
  return (
    <p className="mb-2.5 text-3.5 text-brandSecondary-700">
      <Warning size={16} /> {error}
    </p>
  );
};
