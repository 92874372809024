import React from 'react';

import {Warning} from '@phosphor-icons/react';

interface Props {
  error: string;
  hasIcon?: boolean;
  size?: 'small' | 'normal';
}

export const ErrorText = ({error, size = 'normal', hasIcon = false}: Props) => {
  if (!error) return null;

  return (
    <div
      className="mb-2.5 flex items-center justify-start text-brandSecondary-700"
      data-cy="input-error">
      {hasIcon && <Warning size={16} bg-green-900 />}
      <p className={`ml-4 mt-1 ${size === 'small' ? 'text-3' : 'text-3.5'}`}>
        {error}
      </p>
    </div>
  );
};
