import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {camelCaseToSnakeCase, SharedActionType} from '@ifeelonline/chat-core';

import dayjs from 'dayjs';

import {
  exportEvents,
  getEvents,
  getEventsType,
} from 'src/state/action_creators/eventsCreator';
import GenericTable from 'src/components/generic_table/GenericTable';
import {useGenericColumnsForm} from 'src/hooks/useGenericColumsForm';
import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';
import {ExportModal} from 'src/components/shared/ExportModal';
import {Order} from 'src/components/tables/types/Order';
import {useAppDispatch} from 'src/hooks/hooks';
import {Event} from 'src/types/Event';
import {RootState} from 'src/state';

import {EventsColumnSelector} from './components/EventsColumnSelector';
import {EventsFiltersForm} from './components/EventsFiltersForm';
import {buildRequestParams} from './utils/buildRequestParams';
import {renderTableCell} from './utils/renderTableCell';
import {columnsToShow} from './utils/columnToShow';

const initFilterValues = {
  createdAt: {
    checked: false,
    value: {from: dayjs().subtract(7, 'days').toDate(), to: new Date()},
  },
  typeOfEvent: {
    checked: false,
    value: null,
  },
  userId: {
    checked: false,
    value: null,
  },
};

type FilterableKeys = keyof Pick<Event, 'createdAt' | 'typeOfEvent' | 'userId'>;

type ValueType<K extends FilterableKeys> = K extends 'createdAt'
  ? {from: Date | null; to: Date | null}
  : K extends 'typeOfEvent'
    ? string | null
    : K extends 'userId'
      ? number | null
      : never;

export type FormValuesEvent = {
  [K in FilterableKeys]: {
    checked: boolean;
    value: ValueType<K>;
  };
};

export type SelectableColumnsKeys = keyof Event;

export const EventsTable = ({
  controllerName,
}: {
  controllerName: string;
  setOverflowHidden: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch = useAppDispatch();
  const {status, events, total, typeOfEventsList} = useSelector(
    (state: RootState) => state.events,
  );

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<Order<Event>>({
    by: 'id',
    direction: 'desc',
  });
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [isFilterPopOverOpen, setIsFilterPopOverOpen] =
    useState<boolean>(false);
  const [isColumnPopOverOpen, setIsColumnPopOverOpen] =
    useState<boolean>(false);

  const [filtersSelected, setFiltersSelected] = useState<
    GenericTableProps<Event, FormValuesEvent>['filtersSelected']
  >([]);

  const [formValues, setFormValues] =
    useState<GenericTableProps<Event, FormValuesEvent>['formValues']>(
      initFilterValues,
    );

  const {visibleColumns, toggleColumnVisibility, resetVisibleColumns} =
    useGenericColumnsForm<Event>(columnsToShow, columnsToShow, filtersSelected);

  const handleSetOrder = (newOrder: Order<Event>) => setOrder(newOrder);

  const fetchEvents = async (page: number, order: Order<Event>) => {
    dispatch(getEvents(buildRequestParams(page, order, formValues)));
  };

  const handleToggleFilter = () => setIsFilterPopOverOpen(!isFilterPopOverOpen);
  const handleToggleColumnPopOver = () =>
    setIsColumnPopOverOpen(!isColumnPopOverOpen);
  const handleExport = () => setModalExportOpen(true);

  useEffect(() => {
    if (!typeOfEventsList) {
      dispatch(getEventsType());
    }
  }, []);

  useEffect(() => {
    fetchEvents(page, order);
  }, [page, order, filtersSelected]);

  return (
    <div>
      <GenericTable>
        <GenericTable.Header
          filtersSelected={filtersSelected}
          handleToggleColumnPopOver={handleToggleColumnPopOver}
          handleToggleFilter={handleToggleFilter}
          isColumnPopOverOpen={isColumnPopOverOpen}
          isFilterPopOverOpen={isFilterPopOverOpen}
          data={{items: events, total: total}}
          controllerName={controllerName}
          handleExport={handleExport}
          pageTitle="Events">
          <EventsColumnSelector
            data-testid="events-column-selector"
            isPopOverOpen={isColumnPopOverOpen}
            handleToggle={handleToggleColumnPopOver}
            allColumns={columnsToShow}
            visibleColumns={visibleColumns}
            toggleColumnVisibility={toggleColumnVisibility}
            resetVisibleColumns={resetVisibleColumns}
          />
          <EventsFiltersForm
            initValues={initFilterValues}
            handleToggleFilter={handleToggleFilter}
            setFiltersSelected={setFiltersSelected}
            setPage={setPage}
            isPopOverOpen={isFilterPopOverOpen}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </GenericTable.Header>

        <GenericTable.Body
          isLoading={status === SharedActionType.LOADING}
          data={events}>
          <GenericTable.Body.Head
            columns={visibleColumns}
            filtersSelected={filtersSelected}
            handleSetOrder={handleSetOrder}
            order={order}
          />
          <GenericTable.Body.Main
            data={events}
            columns={visibleColumns}
            renderCell={(column, event) => renderTableCell(column, event)}
          />
        </GenericTable.Body>
        {status !== SharedActionType.LOADING && (
          <GenericTable.Footer total={total} page={page} setPage={setPage} />
        )}
      </GenericTable>

      {events && filtersSelected && events.length < 10000 && (
        <ExportModal
          show={modalExportOpen}
          setModalOpen={setModalExportOpen}
          allFilterParams={buildRequestParams(page, order, formValues)}
          columnsVisible={columnsToShow.map((column) =>
            camelCaseToSnakeCase(column.key),
          )}
          title="Export events"
          exportReport={exportEvents}
        />
      )}
    </div>
  );
};
