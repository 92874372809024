export const getStatusPatient = (status: number) => {
  switch (status) {
    case 1:
      return 'Signup';
    case 2:
      return 'Survey';
    case 3:
      return 'Support waiting';
    case 4:
      return 'Support matched';
    case 5:
      return 'Paid';
    case 6:
      return 'Under age';
    default:
      break;
  }
};
