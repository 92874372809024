import {useSelector} from 'react-redux';
import {SubmitHandler, useForm} from 'react-hook-form';
import toast from 'react-hot-toast';

import {yupResolver} from '@hookform/resolvers/yup';

import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';
import {
  createUserRole,
  getUsersAndRoles,
} from 'src/state/action_creators/usersAndRolesCreator';

import {newUserFormSchema} from '../components/forms/usersAndRolesSchemas';
import {
  NewDashboardUserFormValues,
  UseNewDashboardUserFormParams,
} from '../types';

const initialValues: NewDashboardUserFormValues = {
  email: '',
  name: '',
  nickname: '',
  roles: [],
  surname: '',
};

export const useNewDashboardUserForm = ({
  sorting,
  closeModal,
}: UseNewDashboardUserFormParams) => {
  const dispatch = useAppDispatch();

  const {rolesListSelector} = useSelector((state: RootState) => state.uar);

  const onSubmit: SubmitHandler<NewDashboardUserFormValues> = (values) => {
    const {roles: selectedRoles, ...valuesWithoutRoles} = values;

    const selectedRolesIds = rolesListSelector
      ?.filter(({name}) => selectedRoles.includes(name))
      .map(({id}) => id);

    dispatch(
      createUserRole({...valuesWithoutRoles, role_ids: selectedRolesIds}),
    )
      .then((isSuccess) => {
        if (isSuccess) {
          toast.success('New user with role has been successfully created');
          dispatch(getUsersAndRoles(sorting));
        }
      })
      .finally(() => closeModal());
  };

  const {
    control,
    handleSubmit,
    formState: {isSubmitting, errors},
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(newUserFormSchema),
    mode: 'onBlur',
  });

  const valuesHaveChanged = () => {
    const currentValues = watch();

    const emailSameValue = currentValues.email === '';
    const nameSameValue = currentValues.name === '';
    const nicknameSameValue = currentValues.nickname === '';
    const surnameSameValue = currentValues.surname === '';
    const rolesSameValue = currentValues.roles.length === 0;

    return (
      emailSameValue &&
      nameSameValue &&
      nicknameSameValue &&
      surnameSameValue &&
      rolesSameValue
    );
  };

  return {
    control,
    hasErrors: Object.keys(errors).length > 0,
    isSubmitting,
    onSubmit: handleSubmit(onSubmit),
    valuesHaveChanged,
  };
};
