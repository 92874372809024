import React, {useEffect, useState} from 'react';

import {Form} from 'react-bootstrap';
import toast from 'react-hot-toast';

import {
  getTherapist,
  updateFieldsTherapistAccount,
} from 'src/state/action_creators/therapistsCreator';
import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {TherapistAccount} from 'src/types/Therapist';
import {useAppDispatch} from 'src/hooks/hooks';

const initInfo = {
  derive: false,
  maxPatient: 0,
  totalAttended: 0,
  schedule: '',
};

interface Props extends ModalSimpleInterface {
  therapistAccount: TherapistAccount;
  therapistId: number;
}

export const TherapistPatientsDetailsModal = ({
  show,
  setShow,
  therapistId,
  therapistAccount,
}: Props) => {
  const dispatch = useAppDispatch();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formValues, setFormValues] = useState<FixMeLater>(initInfo);
  const {derive, maxPatients, schedule} = formValues;

  useEffect(() => {
    if (therapistAccount) {
      setFormValues({
        derive: therapistAccount.derive,
        maxPatients: therapistAccount.maxPatients,
        schedule: therapistAccount.schedule,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, therapistAccount]);

  const handleInputChange = ({
    target,
  }:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };
  const handleCheckboxChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [target.name]: target.checked,
    });
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    setLoadingSubmit(true);

    dispatch(
      updateFieldsTherapistAccount(
        therapistId,
        {
          max_patients: parseInt(maxPatients),
          derive: derive,
          schedule: schedule,
        },
        therapistAccount.id,
      ),
    ).then((res) => {
      if (res) {
        setLoadingSubmit(false);
        setShow!(false);
        toast.success('Total patients modified successfully');
        dispatch(getTherapist(therapistId));
      }
    });
  };

  const valuesHaveChanged = () => {
    const deriveSameValue = formValues.derive === therapistAccount.derive;
    const maxPatientsSameValue =
      formValues.maxPatients !== undefined &&
      therapistAccount.maxPatients !== undefined
        ? formValues.maxPatients.toString() ===
          therapistAccount.maxPatients?.toString()
        : formValues.maxPatients === undefined ||
          formValues.maxPatients === '0';
    const scheduleSameValue = therapistAccount.schedule
      ? formValues.schedule === therapistAccount.schedule
      : formValues.schedule === '' || formValues.schedule === undefined;

    return deriveSameValue && maxPatientsSameValue && scheduleSameValue;
  };

  const closeModal = () => setShow!(false);

  return (
    <ModalLayout
      show={show}
      closeModal={closeModal}
      className="modal-height-auto">
      <form className="container" onSubmit={handleSubmitForm}>
        <ModalLayout.Header>
          <h3>Patients details</h3>
        </ModalLayout.Header>
        <ModalLayout.Body>
          <Form.Check
            checked={derive}
            id="table__user-derive-active"
            label="Derivation"
            type="checkbox"
            name="derive"
            onChange={handleCheckboxChange}
          />
          <div className="form-group">
            <label>Total patients</label>
            <input
              type="number"
              name="maxPatients"
              autoComplete="off"
              value={maxPatients}
              onChange={handleInputChange}
              min="0"
            />
          </div>
          {/* @TODO-3464 CHECK ACCOUNTSETTING.total_attended && (*/}

          <div className="form-group">
            <label>Schedule</label>
            <textarea
              rows={5} // Define las filas aquí
              name="schedule"
              autoComplete="off"
              value={schedule}
              onChange={handleInputChange}
            />
          </div>
        </ModalLayout.Body>
        <ModalLayout.Footer>
          <ButtonSubmit
            loadingSubmit={loadingSubmit}
            disabled={valuesHaveChanged()}
          />
        </ModalLayout.Footer>
      </form>
    </ModalLayout>
  );
};
