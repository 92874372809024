// helpers/rollbarHelper.js
import Rollbar from 'rollbar';

import {IS_DEVELOPMENT} from 'src/constants/Routes';

export const configureRollbar = () => {
  const rollbarConfig = {
    accessToken: `${import.meta.env.VITE_APP_ROLLBAR_ACCESS_TOKEN}`,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: !IS_DEVELOPMENT,
    payload: {
      environment: `${import.meta.env.VITE_APP_ENV}`,
      client: {
        project: 'Admin Dashboard',
      },
    },
  };
  return new Rollbar(rollbarConfig);
};
