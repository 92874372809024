import {SharedActionType} from '@ifeelonline/chat-core';

import {SupportSettingsType} from '../action_types/SupportSettingsType';
import {SupportAction} from '../actions/supportSettings';

export interface ChatStateInfo {
  id: number;
  nickname: string;
  partner: string;
  state: boolean;
}
export interface SupportChatState {
  chatsState: ChatStateInfo[] | null;
  status: SharedActionType;
}

const initialState = {
  chatsState: null,
  status: SharedActionType.IDLE,
};

export const supportSettingsReducer = (
  state: SupportChatState = initialState,
  action: SupportAction,
) => {
  switch (action.type) {
    case SupportSettingsType.SET_CHATS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };
    case SupportSettingsType.SET_CHATS_STATUS:
      return {
        ...state,
        chatsState: action.payload,
        status: SharedActionType.COMPLETED,
      };

    case SupportSettingsType.UPDATE_CHAT_STATUS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case SupportSettingsType.UPDATE_CHAT_STATUS: {
      const newChatsState: SupportChatState['chatsState'] = state.chatsState
        ? state.chatsState.map((chatInfo) => {
            if (chatInfo.id === action.payload.id) {
              return {...chatInfo, state: action.payload.state};
            } else {
              return chatInfo;
            }
          })
        : null;
      return {
        ...state,
        chatsState: newChatsState,
        status: SharedActionType.COMPLETED,
      };
    }
    default:
      return state;
  }
};
