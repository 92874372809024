import React from 'react';

import {UseFormSetValue, useController} from 'react-hook-form';

import {Box, Chip, MenuItem, Select, SelectChangeEvent} from '@mui/material';

import {COLOR_BORDER, COLOR_ERROR} from 'src/styles/colors/colors';

import {CustomTooltip} from '../../shared/CustomTooltip';

import {BaseControlledComponentProps} from './type';

interface Props extends BaseControlledComponentProps {
  options: string[];
  placeholder?: string;
  setValue: UseFormSetValue<any>;
  tooltipText: string;
}
export const SelectChip = ({
  control,
  name,
  label,
  options,
  setValue,
  tooltipText,
  placeholder,
}: Props) => {
  const {field, fieldState} = useController({
    control,
    name,
  });
  const isInvalid = fieldState.invalid;
  const selectRef = React.useRef(null);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: {value},
    } = event;
    setValue(name, typeof value === 'string' ? value.split(',') : value);
  };
  return (
    <div className="mt-5 w-full">
      {options && (
        <div>
          <label className="mb-1 block">
            <div className="flex w-full items-center justify-start gap-1">
              {label}
              <CustomTooltip title={tooltipText} />
            </div>
          </label>

          <Select
            {...field}
            sx={{
              backgroundColor: 'white',
              minHeight: '40px',
              padding: '4px',
              border: '1px solid',
              borderColor: isInvalid ? COLOR_ERROR : COLOR_BORDER,
              '&.Mui-focused': {
                fieldset: {
                  borderRadius: '4px',
                  border: '1px solid',
                  borderColor: isInvalid
                    ? COLOR_ERROR + ' !important'
                    : COLOR_BORDER + ' !important',
                },
              },
            }}
            multiple
            ref={selectRef}
            fullWidth
            value={field.value}
            placeholder={placeholder}
            onChange={handleChange}
            onClose={(event) => {
              //This is dirty as hell, but it works, that's why i hate MUI so much (T_T)
              const selectElement = selectRef.current
                ? (selectRef.current as HTMLElement)
                : null;
              if (selectElement) {
                selectElement.classList.remove('Mui-focused');
              }
            }}
            renderValue={(selected: string[]) => (
              <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}>
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </div>
  );
};
