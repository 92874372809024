import React from 'react';

import {EditPencilSizeMediumEditPencil, PlusAddSmall} from '@ifeelonline/icons';

import {ArrowSquareUpRight, Copy} from '@phosphor-icons/react';

import {AppointmentStatus, IncomingPatient} from 'src/types/IncomingPatient';
import {copyToClipboard, openInNewTab} from 'src/helpers/generalFunctions';
import ButtonActionTable from 'src/components/buttons/ButtonActionTable';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {BADGE_TYPE} from 'src/helpers/set_classes/badgeClasses';
import Button from 'src/components/buttons/Button';
import {Badge} from 'src/components/badge/Badge';
import {formatDate} from 'src/helpers/dates';

export function renderTableCell(
  column: {key: keyof IncomingPatient; name: string},
  incomingPatient: IncomingPatient,
  setActiveIncomingPatient: React.Dispatch<
    React.SetStateAction<IncomingPatient | null>
  >,
) {
  switch (column.key) {
    case 'appointment':
      return incomingPatient.appointment ? (
        formatDate(incomingPatient.appointment)
      ) : (
        <Button
          buttonType={ButtonType.OUTLINE_PRIMARY}
          size="small"
          onClick={() => setActiveIncomingPatient(incomingPatient)}
          className="gap-1"
          data-testid="assign-appointment">
          <PlusAddSmall size={12} />
          Assign
        </Button>
      );

    case 'isHighRisk':
      return (
        <Badge
          badgeType={
            incomingPatient.isHighRisk
              ? BADGE_TYPE.HIGH_RISK
              : BADGE_TYPE.MEDIUM_LOW_RISK
          }>
          {incomingPatient.isHighRisk ? 'High' : 'Medium / Low'}
        </Badge>
      );

    case 'appointmentStatus':
      return incomingPatient.appointmentStatus === AppointmentStatus.ToCall
        ? 'To Call'
        : 'Scheduled';

    case 'psychologistGuide':
      return (
        <Button
          buttonType={
            incomingPatient.psychologistGuide
              ? ButtonType.WHITE
              : ButtonType.OUTLINE_PRIMARY
          }
          size="small"
          onClick={() => setActiveIncomingPatient(incomingPatient)}
          className="gap-1"
          data-testid="assign-psychologist-guide">
          {incomingPatient.psychologistGuide ? (
            <EditPencilSizeMediumEditPencil size={12} />
          ) : (
            <PlusAddSmall size={12} />
          )}

          {incomingPatient.psychologistGuide
            ? incomingPatient.psychologistGuide.nickname
            : 'Assign'}
        </Button>
      );

    case 'conversationUrl':
      return (
        <>
          <ButtonActionTable
            onClick={() => copyToClipboard(incomingPatient.conversationUrl)}
            data-testid="copy-url-to-clipboard">
            <Copy size={18} />
          </ButtonActionTable>
          <ButtonActionTable
            className="mx-1 transition-all hover:text-primary"
            onClick={() => openInNewTab(incomingPatient.conversationUrl)}
            data-testid="open-url-in-new-tab">
            <ArrowSquareUpRight size={18} />
          </ButtonActionTable>
        </>
      );

    default:
      return incomingPatient[column.key];
  }
}
