import React from 'react';

import { PageTitle } from './PageTitle';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  pageTitle?: string;
}

export const PageHeader = ({children, pageTitle}: Props) => {
  return (
    <div className="flex items-center justify-between">
      {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
      {children}
    </div>
  );
};
