import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';
import {toQueryString} from 'src/helpers/generalFunctions';
import {EventsPayload} from 'src/state/actions/events';
import {mapApiResponseEvent} from 'src/helpers/api_response/event';
import {EventStorage, Event} from 'src/types/Event';

import {EventsActionType} from '../action_types/EventsActionType';
import {EventsAction} from '../actions/events';
import {FixMeLater} from '../interfaces/FixMeLater';

type DispatchResult = EventsAction | any;

export const getEvents = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: EventsActionType.EVENTS_LOADING});
    const resp = await fetchWithToken(`admin/events?${toQueryString(params)}`);
    const body = await resp?.json();
    if (body && body.success) {
      const {events: unmappedEvents, total_count: total} = body;
      const events: Event[] = unmappedEvents.map((event: EventStorage) =>
        mapApiResponseEvent(event),
      );

      dispatch(
        setEvents({
          events,
          total,
        }),
      );
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getEventsType = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(`admin/events/selectors`);
    const body = await resp?.json();
    if (body && body.success) {
      dispatch({
        type: EventsActionType.SET_EVENTS_TYPE,
        payload: body.type_of_events,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getRecentActivity = (userId: number, page: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    if (page === 1) {
      dispatch({type: EventsActionType.EVENTS_LOADING});
    }
    const resp = await fetchWithToken(
      `admin/events?${toQueryString({user_id: userId, page: page})}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {events: unmappedEvents} = body;
      const events = unmappedEvents.map((event: EventStorage) =>
        mapApiResponseEvent(event),
      );
      if (page > 1) {
        if (events) {
          dispatch(loadMoreRecentActivity(events));
          return page;
        } else {
          return false;
        }
      } else {
        dispatch(setRecentActivity(events));
        return body.user_nickname;
      }
    } else {
      dispatch(catchApiError(body));
    }
  };
};

const setEvents = (eventsObj: EventsPayload) => ({
  type: EventsActionType.SET_EVENTS,
  payload: eventsObj,
});
const setRecentActivity = (eventsObj: EventsPayload) => ({
  type: EventsActionType.SET_RECENT_ACTIVITY,
  payload: eventsObj,
});
const loadMoreRecentActivity = (eventsObj: EventsPayload) => ({
  type: EventsActionType.LOAD_MORE_RECENT_ACTIVITY,
  payload: eventsObj,
});

export const exportEvents = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(`admin/events/export`, params, 'POST');
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};
