import {Event, EventStorage} from 'src/types/Event';

export function mapApiResponseEvent(jsonBack: EventStorage) {
  const event: Event = {
    id: jsonBack.id,
    userId: jsonBack.user_id,
    typeOfEvent: jsonBack.type_of_event,
    userEmail: jsonBack.properties?.email || null,
    partner: jsonBack.partner || null,
    createdAt: jsonBack.created_at,
  };
  return event;
}
