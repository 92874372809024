import React, {PropsWithChildren} from 'react';

import {useHasActionPermissions} from 'src/hooks/useHasActionPermissions';

interface Props {
  actionName?: string | null;
  controllerName: string;
}

export const RBAC = ({
  controllerName,
  actionName = null,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const hasActionPermission = useHasActionPermissions({
    controllerName,
    actionName,
  });

  return <>{hasActionPermission ? children : null}</>;
};
