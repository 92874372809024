import {useEffect} from 'react';

export const useImportScript = () => {
  useEffect(() => {
    if (
      import.meta.env.VITE_APP_ENV === 'staging' ||
      import.meta.env.VITE_APP_ENV === 'production'
    ) {
      const script = document.createElement('script');
      if (import.meta.env.VITE_APP_ENV === 'production') {
        script.src = '/newrelic_production.js';
      } else {
        script.src = '/newrelic_staging.js';
      }
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
};
