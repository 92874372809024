import React from 'react';

import toast from 'react-hot-toast';

import {PencilLine, Plus} from '@phosphor-icons/react';

import {
  SPECIALITY_LEVELS,
  Specialities,
  Speciality,
  Therapist,
} from 'src/types/Therapist';
import {LabelValueDisplay} from 'src/components/LabelValueDisplay';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import Button from 'src/components/buttons/Button';

export const TherapistsSpecialitiesComponent = ({
  therapist,
  setSpecialitiesEditModalOpen,
}: {
  setSpecialitiesEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  therapist: Therapist;
}) => {
  const getSpecialitiesByLevel = (
    specialities: Specialities,
    level: Speciality['level'],
  ) => {
    return Object.keys(specialities)
      .filter((key) => specialities[key].level === level)
      .map((key) => specialities[key].text)
      .join(', ');
  };

  const renderSpecialitiesSection = (
    levels: Speciality[],
    specialities: Specialities,
  ) =>
    levels.map(({level, text}) => {
      const value = getSpecialitiesByLevel(specialities, level);
      return value ? (
        <LabelValueDisplay key={level} label={text} value={value} />
      ) : null;
    });

  const isSpecialitiesEdit =
    (therapist?.account &&
      Object.values(therapist?.account?.specialities).some(
        (item) => item.level !== 0,
      )) ||
    (therapist?.account?.specialitiesOthers &&
      Object.values(therapist?.account?.specialitiesOthers).some(
        (item) => item.level !== 0,
      ));

  return (
    <div className="w-full">
      <div className="mt-12 flex w-full flex-row justify-between pr-2">
        <h3>Specialities</h3>
        <Button
          buttonType={ButtonType.ACTIONS}
          size="small"
          onClick={() => {
            if (therapist.account) {
              setSpecialitiesEditModalOpen(true);
            } else {
              toast('Please complete the therapist details first');
            }
          }}>
          {therapist.account ? <PencilLine size="14" /> : <Plus size="14" />}
        </Button>
      </div>
      {isSpecialitiesEdit && (
        <>
          <h5 className="mb-2 underline">Mains</h5>
          <div className="w-full">
            {renderSpecialitiesSection(
              SPECIALITY_LEVELS,
              therapist?.account?.specialities || {},
            )}
          </div>

          {therapist?.account?.specialitiesOthers && (
            <div>
              <h5 className="mb-2 underline">Others</h5>
              <div className="w-full">
                {renderSpecialitiesSection(
                  SPECIALITY_LEVELS,
                  therapist?.account?.specialitiesOthers,
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
