import {Pill, PillStorage} from 'src/types/Pill';

export const mapApiResponsePill = (jsonBack: PillStorage): Pill => ({
  id: jsonBack.id,
  description: jsonBack.description,
  title: jsonBack.title,
  header: jsonBack.header,
  backgroundUrl: jsonBack.background ? jsonBack.background.url : null,
  thumbnailUrl: jsonBack.thumbnail ? jsonBack.thumbnail.url : null,
  typeOfPill: jsonBack.type_of_pill,
  logoUrl: jsonBack.logo || null,
  active: jsonBack.active,
  time: jsonBack.time,
  area: jsonBack.area,
});
