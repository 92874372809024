import React from 'react';

import {Layout} from 'src/components/layout/Layout';
import {PlansTable} from 'src/pages/plans/PlansTable';

export const PlansPage = () => {
  return (
    <Layout>
      <PlansTable />
    </Layout>
  );
};
