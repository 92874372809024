import React, {useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {
  ArrowBendRightDown,
  ArrowBendRightUp,
  ArrowSquareOut,
  Columns,
  Flashlight,
  Funnel,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';

import {
  exportPatients,
  getPatients,
} from 'src/state/action_creators/patientsCreator';
import {BADGE_TYPE, checkBadgeType} from 'src/helpers/set_classes/badgeClasses';
import {PaginationComponent} from 'src/components/shared/PaginationComponent';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {ExportModal} from 'src/components/shared/ExportModal';
import {CustomTable} from 'src/components/tables/CustomTable';
import {parseUnderscore} from 'src/helpers/generalFunctions';
import {PageHeader} from 'src/components/layout/PageHeader';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {Loading} from 'src/components/shared/Loading';
import Button from 'src/components/buttons/Button';
import {Badge} from 'src/components/badge/Badge';
import {useAppDispatch} from 'src/hooks/hooks';
import {formatDate} from 'src/helpers/dates';
import {Patient} from 'src/types/Patient';
import {RBAC} from 'src/helpers/Rbac';
import {RootState} from 'src/state';

import {PopOverColumns} from './components/PopOverColumns';
import {PopOverFilters} from './components/PopOverFilters';

const initValues = {
  page: 1,
  orderBy: 'id',
  orderDesc: true,
  activeUntil: {
    checked: false,
    values: {
      start: dayjs().subtract(7, 'days').toDate(),
      end: new Date(),
    },
  },
  createdAt: {
    checked: false,
    values: {
      start: dayjs().subtract(7, 'days').toDate(),
      end: new Date(),
    },
  },
  partner: {
    checked: false,
    values: [],
  },
};

export const PatientsTable = ({
  controllerName,
  setOverflowHidden,
}: {
  controllerName: string;
  setOverflowHidden: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {status, patients, total} = useSelector(
    (state: RootState) => state.patients,
  );

  const [page, setPage] = useState(initValues.page);
  const [orderBy, setOrderBy] = useState(initValues.orderBy);
  const [orderDesc, setOrderDesc] = useState(initValues.orderDesc);
  const [filterSelected, setFilterSelected] = useState<FixMeLater>(null);
  const [toggleShowPopOver, setToggleShowPopOver] = useState<boolean>(false);
  const [toggleShowColumPopOver, setToggleShowColumPopOver] =
    useState<boolean>(false);

  const [createdAtCheck, setCreatedAtCheck] = useState(false);
  const [createdAtStart, setCreatedAtStart] = useState(
    initValues.createdAt.values.start,
  );
  const [createdAtEnd, setCreatedAtEnd] = useState(
    initValues.createdAt.values.end,
  );

  const [activeUntilCheck, setActiveUntilCheck] = useState(false);
  const [activeUntilStart, setActiveUntilStart] = useState(
    initValues.activeUntil.values.start,
  );
  const [activeUntilEnd, setActiveUntilEnd] = useState(
    initValues.activeUntil.values.end,
  );

  const [businessCheck, setBusinessCheck] = useState(false);
  const [businessValue, setBusinessValue] = useState(false);
  const [partnerCheck, setPartnerCheck] = useState(false);
  const [partnerValues, setPartnerValues] = useState([]);
  const [therapistCheck, setTherapistCheck] = useState(false);
  const [therapistValue, setTherapistValue] = useState(null);

  const [fieldsFiltered, setFieldsFiltered] = useState<FixMeLater>([]);
  const [columnsVisible, setColumnsVisible] = useState<string[]>([
    'email',
    'id',
    'nickname',
    'business',
    'partner',
    'status',
    'therapist',
    'active_until',
    'created_at',
  ]);

  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [allFilterParams, setAllFilterParams] = useState<FixMeLater>(null);

  const handleOrderBy = (newOrderBy: string) => {
    setOrderBy(newOrderBy);
    setOrderDesc(!orderDesc);
  };

  const handleRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: number,
  ) => {
    if (e.metaKey || e.ctrlKey) {
      const win = window.open(`/patient/${id}`, '_blank');
      win?.focus();
    } else {
      navigate(`/patient/${id}`);
    }
  };

  const dispatchGetPatients = () => {
    const newFilterParams = {
      page,
      orderBy,
      orderDir: orderDesc ? 'desc' : 'asc',
      created_at: createdAtCheck
        ? {
            from: createdAtStart.toISOString(),
            to: createdAtEnd.toISOString(),
          }
        : null,
      active_until: activeUntilCheck
        ? {
            from: activeUntilStart.toISOString(),
            to: activeUntilEnd.toISOString(),
          }
        : null,
      business: businessCheck ? businessValue : null,
      partner: partnerCheck ? partnerValues : null,
      therapist_id: therapistCheck ? therapistValue : null,
    };
    setAllFilterParams(newFilterParams);
    dispatch(getPatients(newFilterParams));
  };

  const handleToggleFilter = (popup: string) => {
    if (popup === 'filter') {
      setToggleShowPopOver(!toggleShowPopOver);
      setOverflowHidden(toggleShowPopOver ? '' : 'overflowHidden');
    } else {
      setToggleShowColumPopOver(!toggleShowColumPopOver);
      setOverflowHidden(toggleShowColumPopOver ? '' : 'overflowHidden');
    }
  };

  const handleExport = () => {
    setModalExportOpen(true);
  };

  useEffect(() => {
    dispatchGetPatients();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, orderDesc, filterSelected]);

  useEffect(() => {
    if (filterSelected !== null) {
      const filtersFiltered: FixMeLater = Object.keys(filterSelected).filter(
        (key) => filterSelected[key].checked,
      );
      const fieldFiltersSelected: FixMeLater = [];
      filtersFiltered.forEach((f: FixMeLater) => {
        fieldFiltersSelected.push(filterSelected[f].field);
      });
      setFieldsFiltered(fieldFiltersSelected);
    }
  }, [filterSelected]);

  return status === SharedActionType.LOADING ? (
    <Loading />
  ) : (
    <div>
      <PageHeader pageTitle="Users">
        <div className="flex">
          <div className="popover__container">
            <Button
              buttonType={ButtonType.WHITE}
              size="small"
              onClick={() => handleToggleFilter('column')}>
              <Columns size={18} className="mr-1" /> Columns
            </Button>
            <PopOverColumns
              show={toggleShowColumPopOver}
              columnsVisible={columnsVisible}
              setColumnsVisible={setColumnsVisible}
              filterSelected={filterSelected}
            />
            {toggleShowColumPopOver && (
              <div
                className="overlay-popover"
                onClick={() => handleToggleFilter('column')}></div>
            )}
          </div>
          <div className="popover__container">
            <Button
              buttonType={ButtonType.WHITE}
              size="small"
              className={`${filterSelected ? 'active' : ''}`}
              onClick={() => handleToggleFilter('filter')}>
              <Funnel size={18} className="mr-1" /> Filter
              {filterSelected && (
                <p>
                  |{' '}
                  <span>
                    {
                      Object.keys(filterSelected).filter(
                        (key) => filterSelected[key].checked,
                      ).length
                    }
                  </span>
                </p>
              )}
            </Button>
            <PopOverFilters
              show={toggleShowPopOver}
              initValues={initValues}
              setPage={setPage}
              setOrderBy={setOrderBy}
              setOrderDesc={setOrderDesc}
              handleToggleFilter={handleToggleFilter}
              filterSelected={filterSelected}
              setFilterSelected={setFilterSelected}
              activeUntilCheck={activeUntilCheck}
              activeUntilStart={activeUntilStart}
              activeUntilEnd={activeUntilEnd}
              setActiveUntilCheck={setActiveUntilCheck}
              setActiveUntilStart={setActiveUntilStart}
              setActiveUntilEnd={setActiveUntilEnd}
              createdAtCheck={createdAtCheck}
              createdAtStart={createdAtStart}
              createdAtEnd={createdAtEnd}
              setCreatedAtCheck={setCreatedAtCheck}
              setCreatedAtStart={setCreatedAtStart}
              setCreatedAtEnd={setCreatedAtEnd}
              businessCheck={businessCheck}
              setBusinessCheck={setBusinessCheck}
              businessValue={businessValue}
              setBusinessValue={setBusinessValue}
              partnerCheck={partnerCheck}
              setPartnerCheck={setPartnerCheck}
              partnerValues={partnerValues}
              setPartnerValues={setPartnerValues}
              therapistCheck={therapistCheck}
              setTherapistCheck={setTherapistCheck}
              therapistValue={therapistValue}
              setTherapistValue={setTherapistValue}
            />
            {toggleShowPopOver && (
              <div
                className="overlay-popover"
                onClick={() => handleToggleFilter('filter')}></div>
            )}
          </div>
          <RBAC controllerName={controllerName} actionName="export">
            {patients && filterSelected && patients.length < 10000 && (
              <Button
                buttonType={ButtonType.WHITE}
                size="small"
                onClick={handleExport}>
                <ArrowSquareOut size={18} className="mr-1" /> Export
              </Button>
            )}
          </RBAC>
        </div>
      </PageHeader>
      {patients && (
        <>
          {total > 0 ? (
            <CustomTable
              headChildren={
                <tr>
                  {columnsVisible.map((column: string, index: number) =>
                    ['status', 'business', 'plan_name'].includes(column) ? (
                      <th
                        key={index}
                        className={`${
                          filterSelected && fieldsFiltered.includes(column)
                            ? 'text-active'
                            : ''
                        } `}>
                        {filterSelected && fieldsFiltered.includes(column) && (
                          <Funnel size={18} />
                        )}
                        {parseUnderscore(column)}
                      </th>
                    ) : (
                      <th
                        key={index}
                        className={`cursor-pointer ${column} ${
                          filterSelected && fieldsFiltered.includes(column)
                            ? 'text-active'
                            : ''
                        }`}
                        onClick={() => handleOrderBy(column)}>
                        {filterSelected && fieldsFiltered.includes(column) && (
                          <Funnel size={18} />
                        )}
                        {parseUnderscore(column)}
                        {orderBy === column &&
                          (orderDesc ? (
                            <ArrowBendRightDown size={10} />
                          ) : (
                            <ArrowBendRightUp size={10} />
                          ))}
                      </th>
                    ),
                  )}
                </tr>
              }
              bodyChildren={patients.map((patient: Patient, index: number) => (
                <tr
                  key={index}
                  onClick={(e) => handleRowClick(e, patient.id!)}
                  className={`cursor-pointer ${
                    patient.deleted ? 'deleted' : ''
                  }`}>
                  {columnsVisible.map((column: string, index: number) => {
                    switch (column) {
                      case 'email':
                        return (
                          <td key={index} className="badge-relative">
                            <div className="flex justify-start">
                              <p>{patient.email}</p>
                              {patient.tester && (
                                <span className="badge-text info static text-2.5">
                                  Tester
                                </span>
                              )}
                            </div>
                          </td>
                        );
                      case 'status':
                        return (
                          <td key={index}>
                            {patient.status && (
                              <Badge badgeType={checkBadgeType(patient.status)}>
                                {patient.deleted
                                  ? 'Deleted'
                                  : patient.statusText}
                              </Badge>
                            )}
                          </td>
                        );
                      case 'business':
                        return (
                          <td key={index}>
                            <Badge
                              badgeType={
                                patient.partner
                                  ? BADGE_TYPE.B2B
                                  : BADGE_TYPE.B2C
                              }>
                              {patient.partner ? 'B2B' : 'B2C'}
                            </Badge>
                          </td>
                        );
                      case 'created_at':
                        return (
                          <td key={index}>
                            {patient.createdAt
                              ? formatDate(patient.createdAt)
                              : ''}
                          </td>
                        );
                      case 'active_until':
                        return (
                          <td key={index}>
                            {patient.activeUntil
                              ? formatDate(patient.activeUntil)
                              : ''}
                          </td>
                        );
                      case 'disorder':
                        return <td key={index}>{patient.disorders || ''}</td>;
                      case 'plan_name':
                        return <td key={index}>{patient.planName || ''}</td>;
                      default:
                        return (
                          <td key={index}>
                            {
                              patient[column as keyof Patient] as
                                | string
                                | number
                                | boolean
                            }
                          </td>
                        );
                    }
                  })}
                </tr>
              ))}
            />
          ) : (
            <div className="table__no-results">
              <Flashlight size={48} />
              <h3>No results found</h3>
              <p>There aren&apos;t any results for that query.</p>
            </div>
          )}
        </>
      )}

      <PaginationComponent total={total} page={page} setPage={setPage} />

      {patients && filterSelected && patients.length < 10000 && (
        <ExportModal
          show={modalExportOpen}
          setModalOpen={setModalExportOpen}
          allFilterParams={allFilterParams}
          columnsVisible={columnsVisible}
          title="Export patients"
          exportReport={exportPatients}
        />
      )}
    </div>
  );
};
