import {Column} from 'src/components/tables/types/Column';
import {PillActions} from 'src/types/Pill';

export const columnsToShow: Column<PillActions>[] = [
  {
    key: 'id',
    name: 'id',
    sortable: true,
  },
  {
    key: 'title',
    name: 'title',
    sortable: false,
  },
  {
    key: 'area',
    name: 'area',
    sortable: false,
  },
  {
    key: 'typeOfPill',
    name: 'Type Of Pill',
    sortable: true,
  },
  {
    key: 'active',
    name: 'active',
    sortable: true,
  },
  {
    key: 'actions',
    name: 'actions',
    sortable: false,
  },
];
