import React, {ComponentProps} from 'react';

import 'src/styles/components/shared/buttons/buttons.scss';

export default function ButtonActionTable({
  children,
  onClick,
  ...props
}: ComponentProps<'button'>) {
  return (
    <button
      className="mx-1 rounded-1 border border-solid border-transparent p-1 transition-all  hover:bg-white hover:shadow-md"
      onClick={onClick}
      {...props}>
      {children}
    </button>
  );
}
