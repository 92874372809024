import React from 'react';

import ReactApexChart from 'react-apexcharts';

import {ApexOptions} from 'apexcharts';
import dayjs from 'dayjs';

import {FixMeLater} from 'src/state/interfaces/FixMeLater';

export const LineChartHome = ({
  data,
  periodSelected,
}: {
  data: FixMeLater;
  periodSelected: string;
}) => {
  const series = [
    {
      name: '',
      data: data.map((d: FixMeLater) => d.y),
    },
  ];
  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ['#5C62C5', '#a3acb9'],
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 1,
      strokeColors: 'transparent',
      hover: {
        size: 3,
      },
    },
    stroke: {
      show: true,
      curve: 'straight',
      colors: undefined,
      width: 2,
      dashArray: 0,
    },

    xaxis: {
      type: 'datetime',
      categories: data.map((d: FixMeLater) => d.x),
      labels: {
        show: false,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,
        formatter: function (value: string) {
          return dayjs(value).format(
            periodSelected === 'day' ? 'MMMM DD HH:mm' : 'LL',
          );
        },
        style: {
          colors: [],
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-label',
        },
        offsetX: 0,
        offsetY: 0,
        format: 'HH:mm',
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },

    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={200}
      />
    </div>
  );
};
