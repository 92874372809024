import React, {useEffect} from 'react';

import {Form} from 'react-bootstrap';
import toast from 'react-hot-toast';
import {useSelector} from 'react-redux';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Button from 'src/components/buttons/Button';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {useAppDispatch} from 'src/hooks/hooks';
import {useTherapists} from 'src/providers/TherapistsTableProvider';
import {RootState} from 'src/state';
import {getCountries} from 'src/state/action_creators/therapistsCreator';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';

import 'react-datepicker/dist/react-datepicker.css';

export const PopOverFilters = ({
  handleToggleFilter,
}: {
  handleToggleFilter: FixMeLater;
}) => {
  const dispatch = useAppDispatch();
  const {
    toggleShowPopOver,
    initValues,
    setPage,
    setOrderBy,
    setOrderDesc,
    filterSelected,
    setFilterSelected,
    languagesCheck,
    setLanguagesCheck,
    languagesValues,
    setLanguagesValues,
    planTypeCheck,
    setPlanTypeCheck,
    planTypeValues,
    setPlanTypeValues,
    countryCheck,
    setCountryCheck,
    countryValue,
    setCountryValue,
  } = useTherapists();
  const {countryListSelector} = useSelector(
    (state: RootState) => state.therapists,
  );
  const {languages} = useSelector((state: RootState) => state.settings);
  const planTypesList = [
    {
      value: 'video',
      name: 'Video',
    },
    {
      value: 'chat',
      name: 'Chat',
    },
    {
      value: 'couple',
      name: 'Couple',
    },
    {
      value: 'under_age',
      name: 'Under age',
    },
  ];

  const handleCheckChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputValue: FixMeLater,
    setFunction: FixMeLater,
  ) => {
    let newLanguages = inputValue;
    if (!e.target.checked) {
      newLanguages = inputValue.filter(
        (item: FixMeLater) => item !== e.target.name,
      );
    } else if (inputValue.indexOf(e.target.name) < 0) {
      newLanguages = [...inputValue, e.target.name];
    }
    setFunction(newLanguages);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!languagesCheck && !planTypeCheck && !countryCheck) {
      toast.error('You must select some filter');
    } else {
      setPage(1);
      setOrderBy('id');
      setOrderDesc(true);
      const filtersObj = {
        languages: {
          checked: languagesCheck,
          values: languagesValues,
          field: 'languages',
          badge: 'Languages',
        },
        planType: {
          checked: planTypeCheck,
          values: planTypeValues,
          field: 'plan_type',
          badge: 'Plan Type',
        },
        country: {
          checked: countryCheck,
          values: countryValue,
          field: 'country',
          badge: 'Country',
        },
      };
      setFilterSelected(filtersObj);
      handleToggleFilter('filter');
    }
  };

  const clearForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setPage(initValues.page);
    setOrderBy(initValues.orderBy);
    setOrderDesc(initValues.orderDesc);
    setLanguagesCheck(false);
    setLanguagesValues([]);
    setPlanTypeCheck(false);
    setPlanTypeValues([]);
    setCountryCheck(false);
    setCountryValue(null);
    setFilterSelected(null);
    handleToggleFilter('filter');
  };

  useEffect(() => {
    if (filterSelected) {
      if (filterSelected.languages && filterSelected.languages.checked) {
        setLanguagesCheck(true);
      }
      if (filterSelected.playType && filterSelected.planType.checked) {
        setPlanTypeCheck(true);
      }
      if (filterSelected.country && filterSelected.country.checked) {
        setCountryCheck(true);
      }
    }
    if (countryListSelector === null) {
      dispatch(getCountries());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={toggleShowPopOver ? 'popover-custom on' : 'popover-custom'}>
      <div className="content">
        <form onSubmit={handleSubmit} className="popover-filter-patients">
          <div className="popover__header">
            <Button
              buttonType={ButtonType.WHITE}
              size="small"
              onClick={clearForm}
              type="submit">
              Clear
            </Button>
            <h4>Filters</h4>
            <Button buttonType={ButtonType.PRIMARY} size="small" type="submit">
              Done
            </Button>
          </div>
          <div className="popover__body">
            <div>
              <li>
                <Form.Check
                  checked={languagesCheck}
                  onChange={() => setLanguagesCheck(!languagesCheck)}
                  id="popover__filter_languages"
                  label="Languages"
                  type="checkbox"
                />
              </li>
              {languagesCheck && (
                <div className="popover__filter_field">
                  <div className="">
                    <ul>
                      {languages &&
                        languages.map((l, index) => (
                          <li key={index}>
                            <Form.Check
                              checked={
                                languagesValues &&
                                languagesValues.indexOf(l.name) > -1
                              }
                              onChange={(e) =>
                                handleCheckChange(
                                  e,
                                  languagesValues,
                                  setLanguagesValues,
                                )
                              }
                              id={`popover__filter_languages_${l.name}`}
                              label={l.name}
                              name={l.name}
                              type="checkbox"
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div>
              <li>
                <Form.Check
                  checked={planTypeCheck}
                  onChange={() => setPlanTypeCheck(!planTypeCheck)}
                  id="popover__filter_plan_type"
                  label="Plan type"
                  type="checkbox"
                />
              </li>
              {planTypeCheck && (
                <div className="popover__filter_field">
                  <div className="">
                    <ul>
                      {planTypesList.map((p, index) => (
                        <li key={index}>
                          <Form.Check
                            checked={
                              planTypeValues &&
                              planTypeValues.indexOf(p.value) > -1
                            }
                            onChange={(e) =>
                              handleCheckChange(
                                e,
                                planTypeValues,
                                setPlanTypeValues,
                              )
                            }
                            id={`popover__filter_languages_${p.name}`}
                            label={p.name}
                            name={p.value}
                            type="checkbox"
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div>
              <li>
                <Form.Check
                  checked={countryCheck}
                  onChange={() => setCountryCheck(!countryCheck)}
                  id="popover__filter_country"
                  label="Country"
                  type="checkbox"
                />
              </li>
              {countryCheck && (
                <div className="popover__filter_field">
                  <div className="">
                    <ul>
                      {countryListSelector && (
                        <Autocomplete
                          id="country-selector"
                          options={countryListSelector}
                          size="small"
                          getOptionLabel={(option) => option}
                          value={countryValue}
                          onChange={(event, newValue) => {
                            setCountryValue(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                '& legend': {display: 'none'},
                                '& fieldset': {top: 0},
                              }}
                            />
                          )}
                        />
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
