import React, {useEffect} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {getAllAttributesPatient} from 'src/state/action_creators/patientCreator';
import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

export const AllAttributesModal = ({show, setShow}: ModalSimpleInterface) => {
  const dispatch = useAppDispatch();
  const {allAttributes, patient, status} = useSelector(
    (state: RootState) => state.patient,
  );

  useEffect(() => {
    if (show && patient) {
      dispatch(getAllAttributesPatient(patient.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const closeModal = () => setShow!(false);

  return status !== SharedActionType.LOADING ? (
    <ModalLayout show={show} closeModal={closeModal}>
      <ModalLayout.Header>
        <h3>User attributes</h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        {allAttributes && (
          <ul className="list-style-circle">
            {Object.keys(allAttributes).map((key, i) => (
              <li key={i}>
                {key}:{' '}
                <pre className="overflow-auto">
                  {JSON.stringify(allAttributes[key])}
                </pre>
              </li>
            ))}
          </ul>
        )}
      </ModalLayout.Body>
    </ModalLayout>
  ) : (
    <></>
  );
};
