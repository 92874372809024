import {Dispatch} from 'react';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';
import {DASHBOARD_PERIOD_ENUM} from 'src/types/Dashboard';
import {ChartOptionsValue} from 'src/types/Graphics';
import {
  mapApiResponseDashboard,
  mapApiResponseDashboardGraphic,
} from 'src/helpers/api_response/dashboard';
import {toQueryString} from 'src/helpers/generalFunctions';
import {DateString} from 'src/helpers/dates';

import {DashboardAction, SetSelectorsPayload} from '../actions/dashboards';
import {DashboardActionType} from '../action_types/DashboardActionType';

type DispatchResult = DashboardAction | any;

export const getSelectors = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: DashboardActionType.SET_SELECTORS_LOADING});
    const resp = await fetchWithToken(`admin/dashboards/selectors`);
    const body = await resp?.json();
    if (body && body.success) {
      const {partners} = body;
      const payload: SetSelectorsPayload = {
        partners,
      };
      dispatch({
        type: DashboardActionType.SET_SELECTORS,
        payload,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getDashboardData = ({
  period,
  partnerId,
  metric,
  monthSelected,
}: {
  metric: string | null;
  monthSelected: DateString;
  partnerId: number;
  period: DASHBOARD_PERIOD_ENUM;
}) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/dashboards?${buildDashboardParamsQuery(
        period,
        partnerId,
        metric,
        monthSelected,
        null,
      )}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {data} = body;
      return mapApiResponseDashboard(data);
    } else {
      dispatch(catchApiError(body));
    }
  };
};

function buildDashboardParamsQuery(
  period: DASHBOARD_PERIOD_ENUM,
  partnerId: number,
  metric: string | null,
  monthSelected: DateString,
  registerOption: ChartOptionsValue | null,
) {
  return toQueryString({
    select_period: period,
    partner_id: partnerId,
    metric,
    start_time: period === DASHBOARD_PERIOD_ENUM.MONTH ? monthSelected : null,
    graphic_type: registerOption,
  });
}

export const getDashboardRegistersData = ({
  period,
  partnerId,
  metric,
  registerOption,
  monthSelected,
}: {
  metric: string;
  monthSelected: DateString;
  partnerId: number;
  period: DASHBOARD_PERIOD_ENUM;
  registerOption: ChartOptionsValue;
}) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/dashboards/graphics?${buildDashboardParamsQuery(
        period,
        partnerId,
        metric,
        monthSelected,
        registerOption,
      )}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {data} = body;
      return mapApiResponseDashboardGraphic(data);
    } else {
      dispatch(catchApiError(body));
    }
  };
};
export type DashboardDataParams = {
  metric: string | null;
  monthSelected: Date;
  partnerId: number;
  period: DASHBOARD_PERIOD_ENUM;
};

export const exportDashboardData = (params: DashboardDataParams) => {
  const {metric, monthSelected, partnerId, period} = params;
  return async (dispatch: Dispatch<DispatchResult>) => {
    const paramsForBack: ParamsForBack = {
      metric,
      partner_id: partnerId,
      select_period: period,
    };
    if (params.period === DASHBOARD_PERIOD_ENUM.MONTH)
      paramsForBack['start_time'] = monthSelected;

    const resp = await fetchWithToken(
      `admin/dashboards/export`,
      paramsForBack,
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

interface ParamsForBack {
  metric: string | null;
  partner_id: number;
  select_period: DASHBOARD_PERIOD_ENUM;
  start_time?: Date;
}
