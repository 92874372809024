import React from 'react';

import {Spinner} from '@phosphor-icons/react';

import {buttonClasses, ButtonType} from 'src/helpers/set_classes/buttonClasses';

import 'src/styles/components/shared/buttons/buttons.scss';

interface Props extends React.ComponentProps<'button'> {
  buttonType: ButtonType;
  children?: React.ReactNode;
  loading?: boolean;
  size: 'small' | 'medium' | 'big';
}

export default function Button({
  className,
  children,
  buttonType,
  loading,
  size = 'small',
  disabled = false,
  ...rest
}: Props) {
  const typeClasses = buttonClasses(buttonType);

  return (
    <button
      className={`btn rounded-1 transition-all ${typeClasses} btn-${size} ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } ${className || ''}`}
      disabled={disabled}
      {...rest}>
      {loading && (
        <Spinner weight="duotone" size={20} className="mr-1">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="4s"
            repeatCount="indefinite"></animate>
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="5s"
            from="0 0 0"
            to="360 0 0"
            repeatCount="indefinite"></animateTransform>
        </Spinner>
      )}
      {children}
    </button>
  );
}
