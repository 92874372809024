import React from 'react';

import {useController} from 'react-hook-form';

import {BaseControlledComponentProps} from './type';
import RichTextEditor from './RichTextEditor';
import 'react-quill/dist/quill.snow.css';
import {ErrorText} from './ErrorText';

export const RichText = ({
  control,
  name,
  label,
  labelError,
  placeholder,
}: BaseControlledComponentProps) => {
  const {field, fieldState} = useController({
    control,
    name,
  });

  const isInvalid = fieldState.invalid;

  const handleChange = (value: string) => {
    const processedValue =
      value.replace(/<(.|\n)*?>/g, '').trim().length === 0 ? '' : value;
    field.onChange({
      target: {
        name: field.name,
        value: processedValue,
      },
    });
  };

  return (
    <div className="w-full">
      <label className="mb-1 block">
        {isInvalid && labelError ? labelError : label}
      </label>
      <div
        className={`relative  w-full rounded-1.5  border ${
          isInvalid
            ? 'border-brandSecondary-700'
            : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
        }`}>
        <div className="bg rounded-1 border border-richText">
          <RichTextEditor
            value={field.value}
            onChange={handleChange}
            placeholder={placeholder}
          />
        </div>
      </div>
      {fieldState.error?.message && (
        <ErrorText error={fieldState.error?.message} size="small" />
      )}
    </div>
  );
};
