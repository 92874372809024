export enum TableType {
  DASHBOARD = 'Dashboard',
  DEFAULT = 'Default',
  ROLES = 'Roles',
}

export function tableClasses(tableType: TableType) {
  switch (tableType) {
    case TableType.DASHBOARD:
      return 'table-dashboard';
    case TableType.ROLES:
      return 'table-roles';
    case TableType.DEFAULT:
      return 'table-custom';

    default:
      break;
  }
}
