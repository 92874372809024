import {LanguageAvailable} from '@ifeelonline/chat-core';

import {SettingsAction} from '../actions/settings';
import {SettingsActionType} from '../action_types/SettingsActionType';

interface SettingsState {
  languages: null | LanguageAvailable[];
}

const initialState = {
  languages: null,
};

export const settingsReducer = (
  state: SettingsState = initialState,
  action: SettingsAction,
): SettingsState => {
  switch (action.type) {
    case SettingsActionType.SETTINGS_LANGUAGES:
      return {
        languages: action.payload,
      };

    default:
      return state;
  }
};
