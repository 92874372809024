export enum ControllerNames {
  CONFIRMATIONS = 'confirmations_controller',
  DASHBOARDS = 'dashboards_controller',
  EMAILS_AUTHORIZATIONS = 'email_authorizations_controller',
  EVENTS = 'events_controller',
  GRAPHICS = 'graphics_controller',
  HOME = 'home_controller',
  INCOMING_PATIENTS = 'incoming_patients_controller',
  OPERATION_DASHBOARD = 'operation_dashboards_controller',
  PARTNERS = 'partners_controller',
  PILLS = 'pills_controller',
  PLANS = 'plans_controller',
  ROLES = 'roles_controller',
  SESSIONS = 'sessions_controller',
  SUBSCRIPTIONS = 'subscriptions_controller',
  SUPERVISORS = 'supervisors_controller',
  SUPPORT = 'support_controller',
  THERAPISTS = 'therapists_controller',
  TOOLS = 'tools_controller',
  USERS = 'users_controller',
  USER_ROLES = 'user_roles_controller',
  VIDEO_SESSIONS = 'video_sessions_controller',
  WEBHOOKS = 'webhooks_controller',
}
