import React from 'react';

import {useSubscription} from 'src/utils/subscriptions-provider';

import {RenderActionsForActivePlan} from './actions/RenderActionsForActivePlan';
import {PauseSubscriptionButton} from './actions/PauseSubscriptionButton';
import {CreateSubscriptionButton} from './actions/CreateSubscriptionButton';

export const RenderActionsForSubscription = () => {
  const {isPlanActive, isPlanPaused} = useSubscription();
  if (isPlanActive) {
    return <RenderActionsForActivePlan />;
  }
  if (isPlanPaused) {
    return <PauseSubscriptionButton />;
  }
  return <CreateSubscriptionButton />;
};
