import React from 'react';

import ReactDOM from 'react-dom/client';

import {DashboardApp as App} from './DashboardApp';
import 'src/styles/style.scss';
import 'src/styles/index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
