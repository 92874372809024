import React from 'react';

import {getPatientSubscriptionDetails} from 'src/helpers/data_generation/patient';
import {Patient} from 'src/types/Patient';

import {PatientSubscriptionItem} from './PatientSubscriptionItem';

export const PatientCurrentSubscriptionDetails = ({
  patient,
}: {
  patient: Patient;
}) => {
  const patientSubscriptionDetails = getPatientSubscriptionDetails(patient);
  return (
    <div className="mt-5 grid grid-cols-2 gap-2">
      {patientSubscriptionDetails.map((item, index) => (
        <PatientSubscriptionItem
          key={patient.id + index}
          label={item.label}
          value={item.value}
        />
      ))}
    </div>
  );
};
