import React from 'react';

import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {SharedActionType} from '@ifeelonline/chat-core';

import Swal from 'sweetalert2';
import {FormControlLabel, Switch} from '@mui/material';

import {Loading} from 'src/components/shared/Loading';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';
import {changeStatusChat} from 'src/state/action_creators/supportSettingsCreator';
import {ChatStateInfo} from 'src/state/reducers/supportSettingsReducer';

export const SupportChatStatusSwitch = () => {
  const dispatch = useAppDispatch();
  const {chatsState, status} = useSelector((state: RootState) => state.support);

  const handleChange = (
    {target}: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const title_status = target.checked
      ? 'You are going to turn on the chat'
      : 'You are going to turn off the chat';
    Swal.fire({
      icon: 'info',
      title: title_status,
      text: 'Do you want to continue?',
      showCancelButton: true,
      allowOutsideClick: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#ff8989',
      confirmButtonText: 'Yes',
      backdrop: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const state = target.checked ? false : true;

        dispatch(changeStatusChat(id, state)).then(() =>
          toast.success(
            state
              ? 'Support changed the status to online'
              : 'Support changed the status to offline',
          ),
        );
      }
    });
  };

  return status === SharedActionType.LOADING ? (
    <Loading />
  ) : (
    <div className="mt-7 flex justify-start">
      {chatsState &&
        chatsState.map((chatStateInfo: ChatStateInfo) => (
          <div
            key={chatStateInfo.id}
            className="mr-12 flex flex-col items-baseline">
            <h3 className="text-blue-dark">{chatStateInfo.nickname}</h3>
            <FormControlLabel
              control={
                <Switch
                  checked={chatStateInfo.state}
                  onChange={(e) => handleChange(e, chatStateInfo.id)}
                />
              }
              className="text-blue-light"
              label={chatStateInfo.state ? 'On' : 'Off'}
            />
          </div>
        ))}
    </div>
  );
};
