import React from 'react';

import {Control} from 'react-hook-form';

import {Theme} from '@mui/material';
import {SxProps} from '@mui/system';

import {CheckboxGroup} from '../../../components/form/controlled';
import {RolesTableStrings} from '../types';

interface CheckableTableProps {
  checkableRows: string[];
  checkableValues: string[];
  control: Control<any>;
  indexName: string;
  label: string;
  selectAllOption: boolean;
}

export const CheckableTable = ({
  control,
  label,
  indexName,
  checkableValues,
  checkableRows,
  selectAllOption,
}: CheckableTableProps) => {
  if (selectAllOption) {
    checkableValues = [RolesTableStrings.ALL, ...checkableValues];
  }

  const sxConfig: SxProps<Theme> = {
    '&': {
      display: 'flex',
      margin: '0px',
    },
  };

  return (
    <>
      <label className="mb-1 block">{label}</label>
      <div id="checkableTable">
        <div
          id="NewRolesTableBody"
          className="rounded border-2 shadow-checkableTable">
          <div className="border-b-3 grid h-10 grid-flow-col content-around justify-items-start gap-2 border-solid border-table-border px-3 text-xs font-medium uppercase hover:bg-brandPrimary-100">
            <div className="flex w-24 text-start">{indexName}</div>
            <div className="around flex w-full flex-row content-around justify-around">
              {checkableValues &&
                checkableValues.map(
                  (action: string, index: number, {length}) => (
                    <div
                      key={action}
                      className={`w-10 min-w-10 text-center ${
                        length === index + 1 ? 'pl-1.5' : ''
                      }`}>
                      {action}
                    </div>
                  ),
                )}
            </div>
          </div>
          <div className="h-96 overflow-x-hidden overflow-y-scroll">
            {checkableRows &&
              checkableRows.map((controller: string, index: number) => (
                <div
                  className={`grid h-9 grid-flow-col content-around justify-items-start gap-4 border-y border-solid border-table-border pl-6 hover:bg-brandPrimary-100 ${
                    index % 2 === 0 ? 'bg-brandPrimary-200' : ''
                  }`}
                  key={controller}>
                  <div className="w-24 translate-y-1/4  text-start text-sm font-medium">
                    {controller.replace(
                      RolesTableStrings.CONTROLLER_SUFFIX,
                      '',
                    )}
                  </div>
                  <CheckboxGroup
                    sx={sxConfig}
                    hasCheckAllOption={true}
                    hasValueLabel={false}
                    direction="row"
                    options={checkableValues}
                    control={control}
                    label=""
                    name={controller}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
