import * as Yup from 'yup';

const csvSchemaWithPartner = Yup.object({
  partner_name: Yup.string().trim().required('You must select a partner'),
  csv: Yup.mixed().required('You must upload a file'),
});

const csvSchema = Yup.object({
  csv: Yup.mixed().required('You must upload a file'),
});

export {csvSchema, csvSchemaWithPartner};
