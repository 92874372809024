import React, {useEffect, useState} from 'react';

import {useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {PaginationComponent} from 'src/components/shared/PaginationComponent';
import {searchUsers} from 'src/state/action_creators/searchCreator';
import {CustomTable} from 'src/components/tables/CustomTable';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {NoResults} from 'src/components/tables/NoResults';
import {Loading} from 'src/components/shared/Loading';
import {Layout} from 'src/components/layout/Layout';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const SearchPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const {status, results, total} = useSelector(
    (state: RootState) => state.search,
  );

  const query = useQuery();
  const search = query.get('text');
  const filter_id = query.get('filter_id');

  const handleRowClick = (id: number, roles: FixMeLater) => {
    if (roles.includes('therapist')) {
      navigate(`/therapist/${id}`);
    } else if (roles.includes('patient')) {
      navigate(`/patient/${id}`);
    }
  };

  useEffect(() => {
    dispatch(searchUsers(search!, filter_id!, page));
  }, [dispatch, search, page]);

  return (
    <Layout>
      {status === SharedActionType.LOADING ? (
        <Loading />
      ) : (
        <>
          {results && (
            <>
              {results.length > 0 ? (
                <>
                  <div className="table_header flex justify-start">
                    <h3 className="text-5">
                      Search results <b>{total}</b>
                    </h3>
                  </div>
                  <CustomTable
                    headChildren={
                      <tr>
                        <th>Email</th>
                        <th>Nickname</th>
                        <th>ID</th>
                        <th>Stripe customer</th>
                        <th>Roles</th>
                      </tr>
                    }
                    bodyChildren={results.map(
                      (p: FixMeLater, index: number) => (
                        <tr
                          className="cursor-pointer"
                          key={index}
                          onClick={() => handleRowClick(p.id, p.roles)}>
                          <td className="badge-relative">
                            <div className="flex justify-start">
                              <p>{p.email}</p>
                              {p.tester && (
                                <span className="badge-text info static text-2.5">
                                  Tester
                                </span>
                              )}
                            </div>
                          </td>
                          <td>{p.nickname}</td>
                          <td>{p.id}</td>
                          <td>{p.stripe_customer}</td>
                          <td>
                            {p.roles.map(
                              (r: FixMeLater, i: number, arr: FixMeLater) => (
                                <span key={i}>
                                  {r}
                                  {arr.length - 1 === i ? '' : ', '}
                                </span>
                              ),
                            )}
                          </td>
                        </tr>
                      ),
                    )}
                  />
                  <PaginationComponent
                    total={total}
                    page={page}
                    setPage={setPage}
                  />
                </>
              ) : (
                <NoResults
                  title={
                    <>
                      No search results found for <strong>{search}</strong>.
                    </>
                  }
                  subtitle="Please try again with a different search query"
                />
              )}
            </>
          )}
        </>
      )}
    </Layout>
  );
};
