import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';
import {Supervisor, SupervisorStorage} from 'src/types/Supervisor';
import {mapApiResponseSupervisor} from 'src/helpers/api_response/supervisor';

import {SupervisorsActionType} from '../action_types/SupervisorsActionType';
import {TherapistsActionType} from '../action_types/TherapistsActionType';
import {SupervisorAction} from '../actions/supervisors';
import {FixMeLater} from '../interfaces/FixMeLater';

type DispatchResult = SupervisorAction | any;

export const getSupervisors = (page: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: SupervisorsActionType.SUPERVISORS_LOADING});

    const resp = await fetchWithToken(`admin/supervisors?page=${page}`);
    const body = await resp?.json();
    if (body && body.success) {
      const {
        supervisors: unmappedSupervisors,
        total_count: total,
      }: {supervisors: SupervisorStorage[] | null; total_count: number} = body;

      const supervisors: Supervisor[] | null = unmappedSupervisors
        ? unmappedSupervisors.map((supervisor: SupervisorStorage) =>
            mapApiResponseSupervisor(supervisor),
          )
        : null;
      dispatch(
        setSupervisors({
          supervisors,
          total,
        }),
      );
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const listOfAllSupervisors = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(`admin/supervisors`);
    const body = await resp?.json();
    if (body && body.success) {
      const {supervisors} = body;
      dispatch(setSupervisorListSelector(supervisors));
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const updateSupervisor = (
  therapistId: number,
  supervisorId: string | null,
) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/therapists/${therapistId}/update_supervisor`,
      {supervisor_id: supervisorId},
      'PUT',
    );
    const body = await resp?.json();
    if (body && body.success) {
      dispatch({type: TherapistsActionType.THERAPIST_LOADING});
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const createSupervisor = (therapistId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/supervisors`,
      {therapist_id: therapistId},
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      dispatch({type: TherapistsActionType.THERAPIST_LOADING});
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const deleteSupervisor = (id: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/supervisors/${id}`,
      {
        id: id,
      },
      'DELETE',
    );
    const body = await resp?.json();
    if (body && body.success) {
      dispatch({type: TherapistsActionType.THERAPIST_LOADING});
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

const setSupervisors = (supervisorsObj: FixMeLater) => ({
  type: SupervisorsActionType.SET_SUPERVISORS,
  payload: supervisorsObj,
});

const setSupervisorListSelector = (supervisorsArray: FixMeLater) => ({
  type: SupervisorsActionType.SET_SUPERVISOR_LIST_SELECTOR,
  payload: supervisorsArray,
});
