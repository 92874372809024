import {GenericResponseInterface} from 'src/state/interfaces/GenericResponseInterface';

import {PreviewInfo, Preview} from '../types';

export function formatPreviewNew(res: GenericResponseInterface): PreviewInfo {
  const previewArray: Preview[] = [];

  if (res.emails_with_error) {
    previewArray.push({
      emails: res.emails_with_error,
      success: false,
      title: 'Emails with error',
    });
  }
  if (res.emails_duplicated) {
    previewArray.push({
      emails: res.emails_duplicated,
      success: false,
      title: 'Emails duplicated',
    });
  }
  if (res.emails) {
    previewArray.push({
      emails: res.emails,
      success: true,
      title: 'Emails that will be authorized',
    });
  }

  return {
    total: res.total_emails,
    total_success: res.emails.length,
    preview: previewArray,
    partner: res.partner.name,
  };
}

export function formatPreviewUnsubscribe(
  res: GenericResponseInterface,
): PreviewInfo {
  const previewArray: Preview[] = [];

  if (res.emails_not_found) {
    previewArray.push({
      emails: res.emails_not_found,
      success: false,
      title: 'Users not authorized',
    });
  }
  if (res.unregistered) {
    previewArray.push({
      emails: res.unregistered,
      success: true,
      title: 'Users unregistered',
    });
  }
  if (res.partner_without_therapy) {
    previewArray.push({
      emails: res.partner_without_therapy,
      success: true,
      title: 'Users without therapy',
    });
  }
  if (res.partner_with_therapy) {
    previewArray.push({
      emails: res.partner_with_therapy,
      success: true,
      title: 'Users with therapy',
    });
  }

  return {
    total: res.total_emails,
    preview: previewArray,
  };
}

export function formatPreviewEdit(res: GenericResponseInterface): PreviewInfo {
  const previewArray: Preview[] = [];

  if (res.emails_not_found) {
    previewArray.push({
      emails: res.emails_not_found,
      success: false,
      title: 'Users not authorized',
    });
  }
  if (res.emails_with_error) {
    previewArray.push({
      emails: res.emails_with_error,
      success: false,
      title: 'Emails with error',
    });
  }
  if (res.emails) {
    previewArray.push({
      emails: res.emails,
      success: true,
      title: 'Emails that will be updated',
    });
  }

  return {
    total: res.total_emails,
    preview: previewArray,
  };
}
