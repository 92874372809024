import React from 'react';

import toast from 'react-hot-toast';

import {FixMeLater} from 'src/state/interfaces/FixMeLater';

const arrayToCommaList = (
  list: string[] | number[],
  cssClass?: string,
  index = 0,
) =>
  list.map((item, i) => (
    <span key={i} className={cssClass}>
      {i > index && ', '}
      {typeof item === 'string' ? capitalizeFirstLetter(item) : item}
    </span>
  ));

const arrayToLowerCase = (arr: any[]) =>
  arr.map((element) => {
    return element.toLowerCase();
  });

const capitalizeFirstLetter = (labelName: string) => {
  return labelName.charAt(0).toUpperCase() + labelName.slice(1);
};

const parseUnderscore = (
  eventString: string,
  reuseCapitalizeFirstLetter = false,
) => {
  const eventName = eventString.replaceAll('_', ' ');
  if (reuseCapitalizeFirstLetter) {
    return capitalizeFirstLetter(eventName);
  }
  return eventName;
};

const camelCaseToWords = (camelCaseString: string) => {
  const wordsWithSpaces = camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2');
  return wordsWithSpaces.charAt(0).toUpperCase() + wordsWithSpaces.slice(1);
};

const toQueryBoolean = (data: any) => {
  if (typeof data === 'object') {
    let query = '';
    const pending = Object.keys(data).map((key) => ({key, value: data[key]}));

    let current = null;
    while ((current = pending.shift())) {
      const {key, value} = current;
      if (Array.isArray(value)) {
        for (const val of value) {
          pending.push({key: `${key}[]`, value: val});
        }
      } else if (typeof value === 'object') {
        for (const objectKey in value) {
          pending.push({
            key: `${key}[${objectKey}]`,
            value: value[objectKey],
          });
        }
      } else if (value !== '') {
        query += `&${key}=${encodeURIComponent(value)}`;
      }
    }
    return query;
  } else {
    return data;
  }
};

const toQueryString = (data: FixMeLater, addUndefinedParams = true) => {
  if (typeof data === 'object') {
    let query = '';
    const pending = Object.keys(data).map((key) => ({key, value: data[key]}));

    let current = null;
    while ((current = pending.shift())) {
      const {key, value} = current;
      if (Array.isArray(value)) {
        for (const val of value) {
          pending.push({key: `${key}[]`, value: val});
        }
      } else if (typeof value === 'object') {
        for (const objectKey in value) {
          pending.push({
            key: `${key}[${objectKey}]`,
            value: value[objectKey],
          });
        }
      } else if (addUndefinedParams || typeof value !== 'undefined') {
        query +=
          (query || !addUndefinedParams ? '&' : '') +
          `${key}=${encodeURIComponent(value)}`;
      }
    }
    return query;
  } else {
    return data;
  }
};
const toSnakeCase = (string: string) =>
  string.replace(/([A-Z])/g, '_$1').toLowerCase();

const validateTypeFile = (value: string, supportedFormats: string[]) => {
  if (!value) return true;
  const fileExtension = value.split('.').pop()?.toLowerCase();

  return !!fileExtension && !supportedFormats.includes('.' + fileExtension);
};

function formatFloatButInt(
  value: number,
  decimals: number,
  returnNumber: boolean,
): string | number {
  const formatted = Number.isInteger(value)
    ? value.toString()
    : value.toFixed(decimals);
  return returnNumber ? Number(formatted) : formatted;
}

function hasNonNumericChars(value: string) {
  return /[^0-9]/.test(value);
}

function copyToClipboard(value: string) {
  navigator.clipboard.writeText(value);
  toast.success('Copy to clipboard');
}

function openInNewTab(url: string) {
  window.open(url, '_blank', 'noopener,noreferrer');
}

export {
  arrayToCommaList,
  arrayToLowerCase,
  camelCaseToWords,
  capitalizeFirstLetter,
  copyToClipboard,
  formatFloatButInt,
  openInNewTab,
  parseUnderscore,
  toQueryBoolean,
  toQueryString,
  toSnakeCase,
  hasNonNumericChars,
  validateTypeFile,
};
