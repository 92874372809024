import React, {useState} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {
  exportOperationsDashboardData,
  OperationsDashboardDataParams,
} from 'src/state/action_creators/operationsDashboardCreator';
import {
  OPERATIONS_DASHBOARD_TABS,
  OperationsDashboardTabEnum,
} from 'src/constants/TabLists';
import {
  OperationDashboardTherapist,
  Spots,
} from 'src/types/OperationsDashboard';
import ExportSvg from 'src/assets/images/dashboard/icon_export.svg?react';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {ExportModal} from 'src/components/shared/ExportModal';
import {PageHeader} from 'src/components/layout/PageHeader';
import {Layout} from 'src/components/layout/Layout';
import Button from 'src/components/buttons/Button';
import TabComp from 'src/components/TabComp';
import {RootState} from 'src/state';

import {OperationsDashboardSpotsHeader} from './spots/OperationsDashboardSpotsHeader';
import {OperationsDashboardHeader} from './therapists_tab/OperationsDashboardHeader';
import {OperationsDashboardSpotsMain} from './spots/OperationsDashboardSpotsMain';
import {OperationsDashboardMain} from './therapists_tab/OperationsDashboardMain';
import {OperationsDashboardNoData} from './OperationsDashboardNoData';

import 'src/styles/components/operationDashboard.scss';

export const OperationsDashboardPage = () => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [tabVal, setTabVal] = useState<string>(OPERATIONS_DASHBOARD_TABS[0]);
  const [loading, setLoading] = useState(false);
  const [dataTherapists, setDataTherapists] = useState<
    OperationDashboardTherapist[] | null
  >(null);
  const [spots, setSpots] = useState<Spots | null>(null);
  const [page, setPage] = useState<number>(1);
  const {status} = useSelector(
    (state: RootState) => state.operations_dashboard,
  );
  const [allFilterParams, setAllFilterParams] =
    useState<OperationsDashboardDataParams | null>(null);

  function checkShowExportModal() {
    return (
      (tabVal === OPERATIONS_DASHBOARD_TABS[0] && dataTherapists) ||
      (tabVal === OPERATIONS_DASHBOARD_TABS[1] && spots)
    );
  }

  function renderHeaderForTab(tabVal: OperationsDashboardTabEnum) {
    switch (tabVal) {
      case OPERATIONS_DASHBOARD_TABS[0]:
        return (
          <OperationsDashboardHeader
            loading={loading}
            setLoading={setLoading}
            setData={setDataTherapists}
            setAllFilterParams={setAllFilterParams}
            tabVal={tabVal as OperationsDashboardTabEnum}
            page={page}
            setPage={setPage}
          />
        );
      case OPERATIONS_DASHBOARD_TABS[1]:
        return (
          <OperationsDashboardSpotsHeader
            loading={loading}
            setLoading={setLoading}
            setSpots={setSpots}
            setAllFilterParams={setAllFilterParams}
            tabVal={tabVal as OperationsDashboardTabEnum}
          />
        );

      default:
        break;
    }
  }
  function renderMainForTab(tabVal: OperationsDashboardTabEnum) {
    switch (tabVal) {
      case OPERATIONS_DASHBOARD_TABS[0]:
        return dataTherapists ? (
          <OperationsDashboardMain
            therapists={dataTherapists}
            page={page}
            setPage={setPage}
          />
        ) : (
          <OperationsDashboardNoData />
        );
      case OPERATIONS_DASHBOARD_TABS[1]:
        return spots ? (
          <OperationsDashboardSpotsMain spots={spots as any} />
        ) : (
          <OperationsDashboardNoData />
        );

      default:
        break;
    }
  }

  return (
    <Layout>
      <div className="operation-dashboard-page">
        <PageHeader pageTitle="Operation dashboard">
          {checkShowExportModal() && (
            <Button
              buttonType={ButtonType.SIMPLE}
              size="small"
              onClick={() => setShowExportModal(true)}>
              <ExportSvg className="mr-1" />
              Export csv
            </Button>
          )}
        </PageHeader>
        <TabComp setValue={setTabVal} tabList={OPERATIONS_DASHBOARD_TABS} />
        {renderHeaderForTab(tabVal as OperationsDashboardTabEnum)}
        {status === SharedActionType.COMPLETED &&
          renderMainForTab(tabVal as OperationsDashboardTabEnum)}
        {checkShowExportModal() && (
          <ExportModal
            show={showExportModal}
            setModalOpen={setShowExportModal}
            allFilterParams={allFilterParams}
            title="Export operation dashboard data"
            exportReport={exportOperationsDashboardData}
          />
        )}
      </div>
    </Layout>
  );
};
