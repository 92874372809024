import {Dispatch} from 'react';

import Swal from 'sweetalert2';

import {AuthActionType} from 'src/state/action_types/AuthActionType';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {AuthAction} from 'src/state/actions/auth';

export const catchApiError = (body: FixMeLater) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    if (body?.auth_token_expired) {
      Swal.fire({
        icon: 'warning',
        title: 'Session expired',
        text: 'Your session expired because another user entered with your credentials',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      }).then(() => {
        localStorage.clear();
        dispatch({type: AuthActionType.AUTH_LOGOUT});
      });
    } else if (body?.access_denied) {
      Swal.fire({
        icon: 'warning',
        title: 'Access denied',
        text: 'Sorry, you have no permissions to access this view. Please contact the administrator.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
      }).then(() => {
        localStorage.clear();
        dispatch({type: AuthActionType.AUTH_LOGOUT});
      });
    } else {
      Swal.fire({
        title:
          body?.errors?.length > 0 && typeof body.errors[0] === 'string'
            ? body.errors[0]
            : 'Error',
        text:
          body?.errors?.length > 0
            ? body.errors[0]
            : 'There was an error, please contact the administrator',
        icon: 'error',
      });
    }
  };
};
