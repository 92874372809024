import React from 'react';

import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import {CaretDown} from '@phosphor-icons/react';

import {
  MatchMessages as MatchMessagesInterface,
  Therapist,
} from 'src/types/Therapist';

export const MatchMessages = ({therapist}: {therapist: Therapist}) => {
  return (
    <div className="user__section">
      <h3>Match messages</h3>
      {therapist && therapist.matchMessages && (
        <>
          {Object.keys(therapist.matchMessages).map((key, i) => (
            <Accordion key={i}>
              <AccordionSummary expandIcon={<CaretDown size={18} />} key={i}>
                <Typography>{key}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {
                    therapist.matchMessages![
                      key as keyof MatchMessagesInterface
                    ]
                  }
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
    </div>
  );
};
