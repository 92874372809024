import React from 'react';

import toast from 'react-hot-toast';

import {Plus} from '@phosphor-icons/react';

import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {useSubscription} from 'src/utils/subscriptions-provider';
import Button from 'src/components/buttons/Button';

export const CreateSubscriptionButton = () => {
  const {setIsModalCreateSubscriptionOpen, isPatientB2B, isPartnerGympass} =
    useSubscription();

  return (
    <Button
      buttonType={ButtonType.SAVE}
      size="small"
      disabled={!isPatientB2B || isPartnerGympass}
      onClick={() => {
        !isPatientB2B
          ? toast.error('This action is not yet available for this patient')
          : setIsModalCreateSubscriptionOpen(true);
      }}>
      <div className="flex gap-3">
        <Plus size={16} /> Create subscription
      </div>
    </Button>
  );
};
