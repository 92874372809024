import {
  OperationDashboardTherapist,
  OperationDashboardTherapistStorage,
  Spots,
  SpotsStorage,
} from 'src/types/OperationsDashboard';

export function mapApiResponseSpots(jsonBack: SpotsStorage) {
  const spots: Spots = {
    busy: {
      total: jsonBack.busy_spots?.total || 0,
      b2c: jsonBack.busy_spots?.b2b || 0,
      b2b: jsonBack.busy_spots?.b2c || 0,
    },
    free: jsonBack.free_spots || 0,
    subscriptions: {
      b2c: jsonBack.subscriptions?.b2c || null,
      b2b: jsonBack.subscriptions?.b2b || null,
    },
    result: jsonBack.result,
    totalSpots: jsonBack.total_spots || 0,
    totalTherapist: jsonBack.total_therapist || 0,
  };
  return spots;
}
export function mapApiResponseOperationDashboardTherapist(
  jsonBack: OperationDashboardTherapistStorage,
) {
  const therapist: OperationDashboardTherapist = {
    id: jsonBack.id,
    nickname: jsonBack.nickname,
    fullName: jsonBack.full_name,
    freeSpots: jsonBack.free_spots,
    license: jsonBack.license,
    age: jsonBack.age,
    country: jsonBack.country,
    speciality: jsonBack.speciality,
    gender: jsonBack.gender,
  };
  return therapist;
}
