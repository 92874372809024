import React from 'react';

import {Tooltip} from 'react-bootstrap';

import {FixMeLater} from 'src/state/interfaces/FixMeLater';

export const renderTooltip = (textTooltip: string, props?: FixMeLater) => (
  <Tooltip id="button-tooltip" {...props}>
    {textTooltip}
  </Tooltip>
);
