import React, {useState} from 'react';

import toast from 'react-hot-toast';

import {
  pauseSubscription,
  reactivateSubscription,
} from 'src/state/action_creators/subscriptionsCreator';
import {getPatient} from 'src/state/action_creators/patientCreator';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {useSubscription} from 'src/utils/subscriptions-provider';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';
import {Patient} from 'src/types/Patient';

export const PauseReactivateSubscriptionModal = ({
  patient,
}: {
  patient: Patient;
}) => {
  const dispatch = useAppDispatch();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const {
    isModalPauseReactivateSubscriptionOpen,
    setIsModalPauseReactivateSubscriptionOpen,
    setIsReactivateSubscription,
    isPlanActive,
  } = useSubscription();

  const closeModal = () => setIsModalPauseReactivateSubscriptionOpen(false);

  const handleSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    setLoadingSubmit(true);
    if (isPlanActive) {
      dispatch(pauseSubscription({id: patient.id})).then((res) =>
        successPauseReactive(res, 'paused'),
      );
    } else {
      dispatch(reactivateSubscription({id: patient.id})).then((res) =>
        successPauseReactive(res, 'reactivated'),
      );
      setIsReactivateSubscription(true);
    }
  };

  const successPauseReactive = (res: FixMeLater, word: string) => {
    if (res.success) {
      toast.success(`Subscription ${word} successfully`);
      closeModal();
      dispatch(getPatient(patient.id!));
    }
    setLoadingSubmit(false);
  };

  return (
    <ModalLayout
      show={isModalPauseReactivateSubscriptionOpen}
      closeModal={closeModal}
      className="modal-height-auto">
      <form className="allInputsBlock container" onSubmit={handleSubmitForm}>
        <ModalLayout.Header>
          <h3>{isPlanActive ? 'Pause' : 'Reactivate'} subscription</h3>
        </ModalLayout.Header>
        <ModalLayout.Body>
          <p>
            Are you sure that you want to{' '}
            {isPlanActive ? 'pause' : 'reactivate'} the subscription of the
            patient?
          </p>
        </ModalLayout.Body>
        <ModalLayout.Footer>
          <Button
            buttonType={ButtonType.WHITE}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}>
            Cancel
          </Button>
          <ButtonSubmit loadingSubmit={loadingSubmit} text="Confirm" />
        </ModalLayout.Footer>
      </form>
    </ModalLayout>
  );
};
