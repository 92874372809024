import {
  areAllActionsChecked,
  rolesPermissionFilter,
} from 'src/helpers/rolesFunctions';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';

export const handleRoleCheckboxChange = (
  controllerName: FixMeLater,
  action: FixMeLater,
  rolesPermissionsSelected: FixMeLater,
  setRolesPermissionsSelected: FixMeLater,
  actionsList: FixMeLater,
  btnDisabled: FixMeLater,
  setBtnDisabled: FixMeLater,
) => {
  let newRolesPermissionsSelected;
  if (btnDisabled) setBtnDisabled(false);
  if (action === 'all') {
    newRolesPermissionsSelected = rolesPermissionsSelected.filter(
      (r: FixMeLater) => r.controller !== controllerName,
    );
    if (
      areAllActionsChecked(
        rolesPermissionsSelected,
        controllerName,
        actionsList.length,
      )
    ) {
      newRolesPermissionsSelected = [...newRolesPermissionsSelected];
    } else {
      newRolesPermissionsSelected = [
        ...newRolesPermissionsSelected,
        {controller: controllerName, actions: actionsList},
      ];
    }
  } else {
    if (
      rolesPermissionFilter(rolesPermissionsSelected, controllerName).length > 0
    ) {
      if (
        rolesPermissionFilter(rolesPermissionsSelected, controllerName)[0]
          .actions.length === 1 &&
        rolesPermissionFilter(rolesPermissionsSelected, controllerName)[0]
          .actions[0] === action
      ) {
        newRolesPermissionsSelected = rolesPermissionsSelected.filter(
          (r: FixMeLater) => r.controller !== controllerName,
        );
      } else {
        const newActions = rolesPermissionFilter(
          rolesPermissionsSelected,
          controllerName,
        )[0].actions.includes(action)
          ? rolesPermissionFilter(
              rolesPermissionsSelected,
              controllerName,
            )[0].actions.filter((a: FixMeLater) => a !== action)
          : [
              ...rolesPermissionFilter(
                rolesPermissionsSelected,
                controllerName,
              )[0].actions,
              action,
            ];

        newRolesPermissionsSelected = [
          {
            controller: rolesPermissionFilter(
              rolesPermissionsSelected,
              controllerName,
            )[0].controller,
            actions: newActions,
          },
          ...rolesPermissionsSelected.filter(
            (r: FixMeLater) => r.controller !== controllerName,
          ),
        ];
      }
    } else {
      newRolesPermissionsSelected = [
        ...rolesPermissionsSelected,
        {controller: controllerName, actions: [action]},
      ];
    }
  }
  setRolesPermissionsSelected(newRolesPermissionsSelected);
};
