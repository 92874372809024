import React from 'react';

import {useController} from 'react-hook-form';
import DatePickerMui from 'react-datepicker';

import {ErrorText} from './ErrorText';
import {BaseControlledComponentProps} from './type';

interface Props extends BaseControlledComponentProps {
  maxDate?: Date;
  minDate?: Date;
}

export const DatePicker = ({
  control,
  name,
  label,
  labelError,
  minDate,
  maxDate,
}: Props) => {
  const {
    field: {onChange, value},
    fieldState,
  } = useController({
    control,
    name,
  });

  const isInvalid = fieldState.invalid;

  return (
    <div className="mb-3 mt-5 w-full">
      <label className="mb-1 block">
        {isInvalid && labelError ? labelError : label}
      </label>
      <div
        className={`relative w-full rounded-1.5 border ${
          isInvalid
            ? 'border-brandSecondary-700'
            : 'border-gray-300 hover:border-gray-400 focus:border-gray-400'
        }`}>
        <DatePickerMui
          onChange={onChange}
          selected={value ? new Date(value) : null}
          minDate={minDate}
          maxDate={maxDate}
          className="h-12 w-full !border-transparent"
          placeholderText="Select a date"
        />
      </div>

      {fieldState.error?.message && (
        <ErrorText error={fieldState.error?.message} size="small" />
      )}
    </div>
  );
};
