import React from 'react';

import {
  GenericTableProps,
  useGenericFiltersForm,
} from 'src/hooks/useGenericFiltersForm';
import {GenericFiltersForm} from 'src/components/generic_table/filters/GenericFiltersForm';
import {Pill} from 'src/types/Pill';

import {TitleFilter} from '../popover_filters/TitleFilter';
import {AreaFilter} from '../popover_filters/AreaFilter';
import {FormValuesPill} from '../../PillsTable';

export const PillsFiltersForm = ({
  handleToggleFilter,
  setFiltersSelected,
  setPage,
  isPopOverOpen,
  formValues,
  setFormValues,
  initValues,
}: Pick<
  GenericTableProps<Pill, FormValuesPill>,
  | 'handleToggleFilter'
  | 'setFiltersSelected'
  | 'setPage'
  | 'isPopOverOpen'
  | 'formValues'
  | 'setFormValues'
  | 'initValues'
>) => {
  const {handleSubmit, clearForm, updateFormValue} = useGenericFiltersForm({
    formValues,
    setFormValues,
    setPage,
    setFiltersSelected,
    handleToggleFilter,
    initValues,
  });

  return (
    <GenericFiltersForm isOpen={isPopOverOpen}>
      <form onSubmit={handleSubmit} className="popover-filter-patients">
        <GenericFiltersForm.Header clearForm={clearForm} />
        <GenericFiltersForm.Body>
          <TitleFilter
            formValues={formValues}
            updateFormValue={updateFormValue}
          />
          <AreaFilter
            formValues={formValues}
            updateFormValue={updateFormValue}
          />
        </GenericFiltersForm.Body>
      </form>
    </GenericFiltersForm>
  );
};
