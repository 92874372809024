import {SubmitHandler, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {yupResolver} from '@hookform/resolvers/yup';

import {createTherapist} from 'src/state/action_creators/therapistsCreator';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {therapistAddNewSchema} from '../schemas/therapistSchema';

interface Params {
  closeModal: () => void;
}

interface FormValues {
  createZoomAccount: string[];
  email: string;
  locale: string;
  name: string;
  nickname: string;
  phone: string;
  surname: string;
}
const initialValues: FormValues = {
  nickname: '',
  email: '',
  name: '',
  surname: '',
  phone: '',
  createZoomAccount: [],
  locale: '',
};

export const useTherapistNewForm = ({closeModal}: Params) => {
  const dispatch = useAppDispatch();
  const {languages} = useSelector((state: RootState) => state.settings) || [];

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const isCreateZoomAccount = data.createZoomAccount.length > 0;
    unregister('createZoomAccount');

    data.locale =
      languages?.find((lang) => lang.name === data.locale)?.locale || 'en';

    const res = await dispatch(createTherapist(data, isCreateZoomAccount));
    if (res) {
      toast.success('The new therapist has been successfully created');
      closeModal();
    }
  };

  const {
    control,
    handleSubmit,
    formState: {isSubmitting, errors},
    unregister,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(therapistAddNewSchema),
    mode: 'onBlur',
  });

  return {
    control,
    hasErrors: Object.keys(errors).length > 0,
    isSubmitting,
    onSubmit: handleSubmit(onSubmit),
    languages,
  };
};
