import {Dispatch} from 'redux';

import {mapApiResponsePatient} from 'src/helpers/api_response/patient';
import {mapApiResponsePlan} from 'src/helpers/api_response/plan';
import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';
import {Patient, PatientStorage} from 'src/types/Patient';
import {Plan, PlanStorage} from 'src/types/Plan';

import {PatientActionType} from '../action_types/PatientActionType';
import {PatientAction, SetPatientPlansOptionsPayload} from '../actions/patient';
import {FixMeLater} from '../interfaces/FixMeLater';

type DispatchResult = PatientAction | any;

export const getPatientPlansOptions = (patientId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(`admin/users/selectors?id=${patientId}`);
    const body = await resp?.json();
    if (body && body.success) {
      const {
        plans: unmappedPlans,
        coupons,
      }: {coupons: string[]; plans: PlanStorage[]} = body;
      const plans: Plan[] = unmappedPlans.map((plan: PlanStorage) =>
        mapApiResponsePlan(plan),
      );
      const payload: SetPatientPlansOptionsPayload = {plans, coupons};
      dispatch({
        type: PatientActionType.SET_PATIENT_PLANS_OPTIONS,
        payload,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getPatient = (patientId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: PatientActionType.PATIENT_LOADING});

    const resp = await fetchWithToken(`admin/users/${patientId}?patient=true`);
    const body = await resp?.json();
    if (body && body.success) {
      const {patient: unmappedPatient}: {patient: PatientStorage} = body;
      const patient: Patient = mapApiResponsePatient(unmappedPatient);

      dispatch(setPatient(patient));
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getAllAttributesPatient = (patientId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: PatientActionType.PATIENT_LOADING});

    const resp = await fetchWithToken(
      `admin/users/${patientId}?show_all_attributes=true`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {user} = body;
      dispatch(setAllAttributes(user));
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const exportPatient = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/users/${
        params.id
      }/single_patient_export?email=${encodeURIComponent(params.email)}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getSurveyAnswersPatient = (
  patientId: number,
  surveyObj: FixMeLater,
) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/users/surveys?id=${patientId}&survey=${surveyObj.value}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const updatePatient = (patientId: number, fieldsToEdit: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: PatientActionType.PATIENT_LOADING});
    const resp = await fetchWithToken(
      `admin/users/${patientId}`,
      fieldsToEdit,
      'PUT',
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {patient: unmappedPatient}: {patient: PatientStorage} = body;
      const patient: Patient = mapApiResponsePatient(unmappedPatient);
      dispatch(setPatient(patient));
      return body;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const sendEmailResetPassword = (patientId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/users/send_reset_password_instructions`,
      {
        id: patientId,
      },
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const sendConfirmationEmail = (patientId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/confirmations/send_confirmation_instructions?id=${patientId}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const unsubscribeUser = (patientId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: PatientActionType.PATIENT_LOADING});
    const resp = await fetchWithToken(
      `admin/users/${patientId}`,
      {
        id: patientId,
      },
      'DELETE',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const skipUserConfirmation =
  (patientId: number) => async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/confirmations/skip_confirmation?id=${patientId}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };

export const refreshSubscription = (patientId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/subscriptions/refresh`,
      {
        id: patientId,
      },
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      dispatch(getPatient(parseInt(patientId.toString())));
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

const setPatient = (payload: Patient) => ({
  type: PatientActionType.SET_PATIENT,
  payload,
});

const setAllAttributes = (payload: any) => ({
  type: PatientActionType.SET_ALL_ATTRIBUTES,
  payload,
});
