import React from 'react';

import {Menu} from '@headlessui/react';

export const LiDropdown = ({item}: any) => {
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <Menu.Item>
      {({active}) => (
        <a
          href="#"
          className={classNames(
            active ? 'bg-gray-100 ' : 'text-brandPrimary-500',
            'block px-4 py-2 text-3.5 text-brandPrimary-700 hover:text-brandPrimary-500',
          )}
          onClick={item.onClick}>
          {item.name}
        </a>
      )}
    </Menu.Item>
  );
};
