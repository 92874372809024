import React from 'react';

import {Warning} from '@phosphor-icons/react';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {Form} from 'src/components/form/Form';

import {useEditDashboardUserForm} from '../../hooks/useEditDashboardUserForm';

interface Props {
  closeModal: () => void;
}

export const EditDashboardUserRolesForm = ({closeModal}: Props) => {
  const {
    control,
    onSubmit,
    hasErrors,
    isSubmitting,
    options,
    valuesHaveChanged,
  } = useEditDashboardUserForm({closeModal});

  return (
    <Form
      onSubmit={onSubmit}
      className="flex size-full flex-col justify-between">
      <ModalLayout.Header>
        <h3>Edit User</h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        <Form.CheckboxGroup
          control={control}
          name="roles"
          label="Roles"
          options={options}
        />
      </ModalLayout.Body>
      <ModalLayout.Footer>
        {hasErrors && <Warning color="red" />}
        <ButtonSubmit
          loadingSubmit={isSubmitting}
          disabled={valuesHaveChanged()}
        />
      </ModalLayout.Footer>
    </Form>
  );
};
