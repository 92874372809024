import {SharedActionType} from '@ifeelonline/chat-core';

import {OperationsDashboardActionType} from '../action_types/OperationsDashboardActionType';
import {OperationsDashboardAction} from '../actions/operationsDashboards';

interface OperationsDashboardState {
  continents: string[] | null;
  countries: string[] | null;
  countriesForContinent: string[] | null;
  languages: string[] | null;
  specialities: string[] | null;
  status: SharedActionType;
  totalSpot: number | null;
  totalTherapist: number | null;
}

const initialState = {
  status: SharedActionType.IDLE,
  totalTherapist: null,
  totalSpot: null,
  continents: null,
  countries: null,
  countriesForContinent: null,
  languages: null,
  specialities: null,
};

export const operationsDashboardReducer = (
  state: OperationsDashboardState = initialState,
  action: OperationsDashboardAction,
) => {
  switch (action.type) {
    case OperationsDashboardActionType.SET_SELECTORS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case OperationsDashboardActionType.SET_SELECTORS:
      return {
        ...state,
        status: SharedActionType.COMPLETED,
        totalTherapist: action.payload.totalTherapist,
        totalSpot: action.payload.totalSpot,
        continents: action.payload.continents,
        countries: action.payload.countries,
        languages: action.payload.languages,
        specialities: action.payload.specialities,
      };
    case OperationsDashboardActionType.SET_COUNTRIES_FOR_CONTINENT:
      return {
        ...state,
        countriesForContinent: action.payload,
      };

    case OperationsDashboardActionType.SET_TOTAL_THERAPISTS:
      return {
        ...state,
        totalTherapist: action.payload,
      };
    case OperationsDashboardActionType.SET_TOTAL_SPOTS:
      return {
        ...state,
        status: SharedActionType.COMPLETED,
        totalSpot: action.payload,
      };

    default:
      return state;
  }
};
