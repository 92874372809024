import React from 'react';

import {Layout} from 'src/components/layout/Layout';
import {SupervisorsTable} from 'src/pages/supervisors/SupervisorsTable';

export const SupervisorsPage = () => {
  return (
    <Layout>
      <SupervisorsTable />
    </Layout>
  );
};
