import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import toast from 'react-hot-toast';

import {PencilLine, Trash, User} from '@phosphor-icons/react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {Switch} from '@mui/material';

import {
  createSupervisor,
  deleteSupervisor,
  listOfAllSupervisors,
  updateSupervisor,
} from 'src/state/action_creators/supervisorsCreator';
import {
  getTherapist,
  updateFieldsTherapistAccount,
} from 'src/state/action_creators/therapistsCreator';
import {LabelValueDisplay} from 'src/components/LabelValueDisplay';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';
import {Therapist} from 'src/types/Therapist';
import {RootState} from 'src/state';

type Params = {
  id: string;
};
/* TO-DO delete any by typing correctly supervisors reducer */
export const SupervisorComponent = ({
  therapist,
  setModalWhoSupervises,
}: {
  setModalWhoSupervises: React.Dispatch<React.SetStateAction<boolean>>;
  therapist: Therapist;
}) => {
  const {id} = useParams<Params>();
  const dispatch = useAppDispatch();
  const {supervisorListSelector} = useSelector(
    (state: RootState) => state.supervisors,
  );
  const [editingSupervisor, setEditingSupervisor] = useState(false);
  const [supervisor, setSupervisor] = useState('');

  useEffect(() => {
    if (supervisorListSelector === null) {
      dispatch(listOfAllSupervisors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSwitchSupervisor = (
    e: React.ChangeEvent<HTMLInputElement>,
    supervisorId: number | null,
  ) => {
    if (e.target.checked) {
      handleAddToSupervisors();
    } else {
      if (supervisorId) handleRemoveFromSupervisors(supervisorId!);
    }
  };

  const handleSwitchConfidentiality = (
    e: React.ChangeEvent<HTMLInputElement>,
    accountConfidentiality: boolean,
  ) => {
    e.preventDefault();
    if (therapist && therapist.account) {
      dispatch(
        updateFieldsTherapistAccount(
          therapist.id,
          {confidentiality: accountConfidentiality},
          therapist.account.id,
        ),
      ).then((res) => {
        if (res) {
          toast.success('Confidentiality modified successfully');
          dispatch(getTherapist(parseInt(id!)));
        }
      });
    }
  };

  const handleAddToSupervisors = () => {
    dispatch(createSupervisor(parseInt(id!))).then((res) => {
      if (res) {
        toast.success('Clinical advisor created successfully');
        dispatch(getTherapist(parseInt(id!)));
      }
    });
  };

  const handleRemoveFromSupervisors = (supervisorId: number) => {
    dispatch(deleteSupervisor(supervisorId)).then((res) => {
      if (res) {
        toast.success('Clinical advisor deleted successfully');
        dispatch(getTherapist(parseInt(id!)));
      }
    });
  };

  const handleSelectSupervisor = (deleteSupervisor = false) => {
    if (therapist && therapist.account) {
      const supervisor_id =
        deleteSupervisor || supervisor === '' ? null : supervisor;
      dispatch(updateSupervisor(parseInt(id!), supervisor_id)).then((res) => {
        if (res) {
          setEditingSupervisor(false);
          toast.success(
            supervisor_id === null
              ? 'Clinical advisor unmatched successfully'
              : 'Clinical advisor matched successfully',
          );
          dispatch(getTherapist(parseInt(id!)));
        }
      });
    }
  };

  return (
    <>
      <div className="user__plan user__section supervisor__section">
        <div className="grid grid-cols-2">
          <div>
            <h3>Clinical advisors</h3>
            {supervisorListSelector && (
              <div className="my-2 flex items-center justify-between">
                {editingSupervisor ? (
                  <>
                    <Autocomplete
                      id="supervisor-selector"
                      options={supervisorListSelector.filter(
                        (item: any) => item.therapist_id !== parseInt(id!),
                      )}
                      size="small"
                      getOptionLabel={(option: any) => option.full_name}
                      style={{width: 250}}
                      onChange={(_, newValue) =>
                        setSupervisor(newValue !== null ? newValue.id : null)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select clinical advisor"
                        />
                      )}
                    />
                    <div className="flex">
                      <Button
                        buttonType={ButtonType.WHITE}
                        size="small"
                        onClick={() => setEditingSupervisor(false)}>
                        Cancel
                      </Button>
                      <Button
                        buttonType={ButtonType.SAVE}
                        size="small"
                        onClick={() => handleSelectSupervisor(false)}
                        disabled={
                          supervisor === null ||
                          supervisor === '' ||
                          Number(supervisor) ===
                            therapist.hasSupervisor?.supervisorId
                        }>
                        Save
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    {therapist.hasSupervisor?.supervisorId ? (
                      <>
                        <p>
                          <span>Clinical advisor - </span>
                          <b>
                            {therapist.hasSupervisor.initials}{' '}
                            {therapist.hasSupervisor.fullName}
                          </b>
                        </p>
                      </>
                    ) : (
                      <span>Has no clinical advisor</span>
                    )}
                    <div className="flex flex-row">
                      <Button
                        buttonType={ButtonType.ACTIONS}
                        size="small"
                        onClick={() => setEditingSupervisor(true)}>
                        <PencilLine size="14" />
                      </Button>

                      {therapist.hasSupervisor?.supervisorId && (
                        <Button
                          buttonType={ButtonType.ACTIONS}
                          size="small"
                          onClick={() => handleSelectSupervisor(true)}>
                          <Trash size="14" />
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="my-2 flex items-center justify-between">
              <span>Confidentiality</span>
              <div>
                <Switch
                  size="small"
                  checked={therapist.account?.confidentiality}
                  value={therapist.account?.confidentiality ? 'Yes' : 'No'}
                  onChange={(e) =>
                    handleSwitchConfidentiality(
                      e,
                      !therapist.account?.confidentiality,
                    )
                  }
                />
                <span>{therapist.account?.confidentiality ? 'Yes' : 'No'}</span>
              </div>
            </div>
            <div className="my-2 flex items-center justify-between">
              <span>Is clinical advisor?</span>
              <div>
                <Switch
                  size="small"
                  checked={therapist.supervisorId ? true : false}
                  onChange={(e) =>
                    handleSwitchSupervisor(e, therapist.supervisorId!)
                  }
                  value={therapist.supervisorId ? 'Yes' : 'No'}
                />
                <span>{therapist.supervisorId ? 'Yes' : 'No'}</span>
              </div>
            </div>
            <div className="my-2 flex items-center justify-between">
              <span>Therapists</span>
              <div className="popover__container">
                <Button
                  buttonType={ButtonType.ACTIONS}
                  size="small"
                  onClick={() => setModalWhoSupervises(true)}>
                  <b>
                    {therapist.whoSupervises
                      ? therapist.whoSupervises.length
                      : 0}
                  </b>{' '}
                  <User className="ml-1" />
                </Button>
              </div>
            </div>

            {therapist.isSupervisor?.id && (
              <div className="w-full via-badge-deleted">
                <div className="my-2 flex items-center justify-between">
                  <span className="w-1/3 font-bold">Therapist available</span>
                  <LabelValueDisplay
                    value={
                      therapist.isSupervisor?.superviseesAvailablePercent?.toString() ||
                      '0%'
                    }
                    label=""
                  />
                </div>
                <div className="my-2 flex items-center justify-between ">
                  <span className="w-full font-bold">
                    Average active patients per therapist&apos;
                  </span>
                  <LabelValueDisplay
                    value={
                      therapist.isSupervisor?.averageSuperviseesActivePatients?.toString() ||
                      ''
                    }
                    label=""
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
