import React, {useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import toast from 'react-hot-toast';

import {SharedActionType} from '@ifeelonline/chat-core';

import {Warning} from '@phosphor-icons/react';

import {
  getTherapist,
  sendEmailLinkApps,
  sendEmailResetPass,
} from 'src/state/action_creators/therapistsCreator';
import {AccountFieldsWithLocale} from 'src/pages/therapists/components/therapistShow/AccountFieldsWithLocale';
import {AccountAttributesModal} from 'src/pages/therapists/modals/actionsModals/AccountAttributesModal';
import {TherapistPatientsDetailsModal} from 'src/pages/therapists/modals/TherapistPatientsDetailsModal';
import {PatientsDetailsComponent} from 'src/pages/therapists/components/therapistShow/PatientsDetails';
import {AllAttributesModal} from 'src/pages/therapists/modals/actionsModals/AllAttributesModal';
import {ViewAsPatientModal} from 'src/pages/therapists/modals/actionsModals/ViewAsPatientModal';
import {SupervisorComponent} from 'src/pages/therapists/components/therapistShow/Supervisor';
import {MatchMessages} from 'src/pages/therapists/components/therapistShow/MatchMessages';
import {TherapistPlanTypeModal} from 'src/pages/therapists/modals/TherapistPlanTypeModal';
import {PlanTypeComponent} from 'src/pages/therapists/components/therapistShow/PlanType';
import {DetailsComponent} from 'src/pages/therapists/components/therapistShow/Details';
import {WhoSupervisesModal} from 'src/pages/therapists/modals/WhoSupervisesModal';
import {UserComponent} from 'src/pages/therapists/components/therapistShow/User';
import MenuBasic from 'src/components/shared/dropdown/MenuBasic';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {ControllerNames} from 'src/types/ControllerNames';
import {Loading} from 'src/components/shared/Loading';
import {Layout} from 'src/components/layout/Layout';
import Button from 'src/components/buttons/Button';
import {MenuBasicOption} from 'src/types/Menus';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {TherapistEditAccountAttrsForLocaleForm} from './components/forms/TherapistEditAccountAttrsForLocaleForm';
import {TherapistsSpecialitiesComponent} from './components/therapistShow/PatientsSpecialities';
import {TherapistSpecialitiesEditModal} from './modals/TherapistSpecialitiesEditModal';
import {EditTherapistAccountForm} from './components/forms/EditTherapistAccountForm';
import {TherapistEditForm} from './components/forms/TherapistEditForm';

type Params = {
  id: string;
};

export const TherapistShowPage = () => {
  const {id} = useParams<Params>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {status, therapist} = useSelector(
    (state: RootState) => state.therapist,
  );

  const [isAllAttributesModalOpen, setIsAllAttributesModalOpen] =
    useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditSpecialitiesModalOpen, setIsEditSpecialitiesModalOpen] =
    useState(false);
  const [isAsPatientModalOpen, setIsAsPatientModalOpen] = useState(false);
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] =
    useState(false);
  const [
    isEditAccountAttrsForLocaleModalOpen,
    setIsEditAccountAttrsForLocaleModalOpen,
  ] = useState(false);
  const [isEditPlanTypeModalOpen, setIsEditPlanTypeModalOpen] = useState(false);
  const [isEditPatientsDetailsModalOpen, setIsEditPatientsDetailsModalOpen] =
    useState(false);
  const [isWhoSupervisesModalOpen, setIsWhoSupervisesModalOpen] =
    useState(false);
  const [localeForAttr, setLocaleForAttr] = useState('es');

  useEffect(() => {
    if (!therapist || therapist.id !== parseInt(id!)) {
      dispatch(getTherapist(parseInt(id!)));
    }
  }, [id]);

  function getMenuOptions() {
    const therapistDropdownList: MenuBasicOption[] = [
      {
        name: 'User attributes',
        onClick: () => setIsAllAttributesModalOpen(true),
      },
      {
        name: 'View as patient',
        onClick: () => setIsAsPatientModalOpen(true),
      },
      {
        name: 'Recent activity',
        controllerName: ControllerNames.EVENTS,
        onClick: () => navigate(`/recent_activity/${therapist?.id}`),
      },
    ];
    if (therapist && therapist.account) {
      therapistDropdownList.unshift({
        name: 'User account attributes',
        onClick: () => setIsAccountModalOpen(true),
      });
    }
    return therapistDropdownList;
  }

  const handleSendLinkApps = () => {
    dispatch(sendEmailLinkApps(parseInt(id!))).then((res) => {
      if (res.success) {
        toast.success('Email sent successfully');
      }
    });
  };

  const handleResetPass = () => {
    dispatch(sendEmailResetPass(parseInt(id!))).then((res) => {
      if (res.success) {
        toast.success('Email sent successfully');
      }
    });
  };

  const hasAccount = therapist && therapist.account;

  return (
    <Layout>
      {status === SharedActionType.LOADING ? (
        <Loading />
      ) : (
        <div>
          {therapist && (
            <div>
              <div className="user__name flex items-center justify-between">
                <div className="flex">
                  {therapist.avatar?.url && (
                    <img src={therapist.avatar.url} alt="Avatar" />
                  )}
                  <div className="user__name-info">
                    <h1>{therapist.email}</h1>
                    <p>{therapist.nickname}</p>
                  </div>
                </div>
                <MenuBasic
                  items={getMenuOptions() as any}
                  buttonText="Actions"
                />
              </div>

              <div className="user__plan user__section">
                {!hasAccount && (
                  <div className="complete-info">
                    <p>
                      <Warning size={18} /> The profile is incomplete{' '}
                    </p>
                    <Button
                      buttonType={ButtonType.WARNING}
                      size="small"
                      onClick={() => setIsCreateAccountModalOpen(true)}>
                      Complete information
                    </Button>
                  </div>
                )}
                <div className="grid grid-cols-2 gap-4">
                  <div className="">
                    <UserComponent
                      therapist={therapist}
                      setModalEditUser={setIsEditModalOpen}
                    />
                    {hasAccount && (
                      <TherapistsSpecialitiesComponent
                        therapist={therapist}
                        setSpecialitiesEditModalOpen={
                          setIsEditSpecialitiesModalOpen
                        }
                      />
                    )}
                  </div>
                  <DetailsComponent
                    therapist={therapist}
                    setModalEditDetails={setIsCreateAccountModalOpen}
                  />
                </div>
                {therapist.account && (
                  <div className="grid grid-cols-2 gap-4">
                    <PatientsDetailsComponent
                      therapist={therapist}
                      setModalEditPatientsDetails={
                        setIsEditPatientsDetailsModalOpen
                      }
                    />
                    <PlanTypeComponent
                      therapist={therapist}
                      setModalEditPlanType={setIsEditPlanTypeModalOpen}
                    />
                  </div>
                )}
              </div>
              {therapist.account && (
                <>
                  <SupervisorComponent
                    therapist={therapist}
                    setModalWhoSupervises={setIsWhoSupervisesModalOpen}
                  />
                  <AccountFieldsWithLocale
                    therapist={therapist}
                    setModalEditAccountAttrsForLocale={
                      setIsEditAccountAttrsForLocaleModalOpen
                    }
                    localeForAttr={localeForAttr}
                    setLocaleForAttr={setLocaleForAttr}
                  />
                </>
              )}

              <MatchMessages therapist={therapist} />

              <div className="user__section">
                <h3>Send link to app store</h3>
                <span>
                  Send an email with the link to download the therapist app to{' '}
                  <b>{therapist.email}</b>
                </span>
                <Button
                  buttonType={ButtonType.ONE_OPTION}
                  size="small"
                  onClick={handleSendLinkApps}>
                  Send mail with link to app store
                </Button>
              </div>
              <div className="user__section">
                <h3>Reset password</h3>
                <span>
                  Send an email with the instructions to reset the user&apos;s
                  password to the email
                  <b>{therapist.email}</b>
                </span>
                <Button
                  buttonType={ButtonType.ONE_OPTION}
                  size="small"
                  onClick={handleResetPass}>
                  Send email for reset password
                </Button>
              </div>
            </div>
          )}
        </div>
      )}

      {therapist && (
        <>
          <ViewAsPatientModal
            show={isAsPatientModalOpen}
            setShow={setIsAsPatientModalOpen}
            therapist={therapist}
          />
          <AllAttributesModal
            show={isAllAttributesModalOpen}
            setShow={setIsAllAttributesModalOpen}
          />

          <ModalLayout
            show={isEditModalOpen}
            closeModal={() => setIsEditModalOpen(false)}>
            <TherapistEditForm closeModal={() => setIsEditModalOpen(false)} />
          </ModalLayout>

          <ModalLayout
            show={isCreateAccountModalOpen}
            closeModal={() => setIsCreateAccountModalOpen(false)}>
            <EditTherapistAccountForm
              closeModal={() => setIsCreateAccountModalOpen(false)}
            />
          </ModalLayout>
          {therapist.account && (
            <>
              <AccountAttributesModal
                show={isAccountModalOpen}
                setShow={setIsAccountModalOpen}
              />

              <TherapistPlanTypeModal
                show={isEditPlanTypeModalOpen}
                setShow={setIsEditPlanTypeModalOpen}
                therapistId={therapist.id}
                therapistAccount={therapist.account}
              />

              <TherapistPatientsDetailsModal
                show={isEditPatientsDetailsModalOpen}
                setShow={setIsEditPatientsDetailsModalOpen}
                therapistId={therapist.id}
                therapistAccount={therapist.account}
              />

              {/* Attributes for each language modal: */}
              <ModalLayout
                show={isEditAccountAttrsForLocaleModalOpen}
                closeModal={() =>
                  setIsEditAccountAttrsForLocaleModalOpen(false)
                }>
                <TherapistEditAccountAttrsForLocaleForm
                  therapist={therapist}
                  localeForAttr={localeForAttr}
                  closeModal={() =>
                    setIsEditAccountAttrsForLocaleModalOpen(false)
                  }
                />
              </ModalLayout>

              <TherapistSpecialitiesEditModal
                show={isEditSpecialitiesModalOpen}
                setShow={setIsEditSpecialitiesModalOpen}
                therapist={therapist}
              />
            </>
          )}
          {therapist.whoSupervises && therapist.whoSupervises.length > 0 && (
            <WhoSupervisesModal
              show={isWhoSupervisesModalOpen}
              setShow={setIsWhoSupervisesModalOpen}
              therapist={therapist}
              therapistSupervisedList={therapist.whoSupervises}
            />
          )}
        </>
      )}
    </Layout>
  );
};
