import {
  Locale,
  PlanType,
  PlanTypeStorage,
  Source,
  SourceStorage,
  User,
  UserStorage,
} from 'src/types/User';
import {
  DisorderStorage,
  EmailNotifications,
  EmailNotificationsStorage,
} from 'src/types/Patient';
import {TherapistStorage} from 'src/types/Therapist';

export function mapApiResponseUser(jsonBack: UserStorage | TherapistStorage) {
  const user: User = {
    createdAt: jsonBack.created_at || jsonBack.therapist?.created_at || '',
    fullName: jsonBack.full_name || jsonBack.therapist?.full_name || null,
    id: jsonBack.id || jsonBack.therapist?.id || null,
    email: jsonBack.email || jsonBack.therapist?.email || null,
    locale: mapApiResponseLocale(jsonBack.locale),
    name: jsonBack.name || jsonBack.therapist?.name || null,
    surname: jsonBack.surname || jsonBack.therapist?.surname || null,
    nickname: jsonBack.nickname || jsonBack.therapist?.nickname || null,
    phone: jsonBack.phone || jsonBack.therapist?.phone || null,
  };
  return user;
}

export function mapApiResponseEmailNotification(
  jsonBack: EmailNotificationsStorage,
) {
  const notifications: EmailNotifications = {
    onboardingAndTherapy: jsonBack?.onboarding_and_therapy,
    subscriptionStatus: jsonBack?.subscription_status,
    selfCareReminders: jsonBack?.self_care_reminders,
    messagesFromOurProfessional: jsonBack?.messages_from_our_professional,
  };
  return notifications;
}
export function mapApiResponseDisorder(
  jsonBack: string | string[] | DisorderStorage,
) {
  if (Array.isArray(jsonBack)) {
    return jsonBack
      .map((disorder) => {
        return disorder;
      })
      .toString();
  } else if (typeof jsonBack === 'object') {
    return jsonBack.body;
  } else {
    return jsonBack;
  }
}

export function mapApiResponseSource(source: SourceStorage) {
  switch (source) {
    case 'android':
      return Source.ANDROID;
    case 'ios':
      return Source.IOS;
    default:
      return Source.WEB;
  }
}

export function mapApiResponsePlanType(planType: PlanTypeStorage) {
  switch (planType) {
    case 'chat':
      return PlanType.CHAT;
    default:
      return PlanType.VIDEO;
  }
}

export function mapApiResponseLocale(locale: string) {
  switch (locale) {
    case 'fr':
      return Locale.FR;
    case 'he':
      return Locale.HE;
    case 'pt':
      return Locale.PT;
    case 'es':
      return Locale.ES;
    default:
      return Locale.EN;
  }
}
