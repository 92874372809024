import React from 'react';

interface Props {
  label?: string;
  value?: string;
}

export const PatientSubscriptionItem = ({label, value}: Props) => {
  return (
    <div className="flex items-start justify-start gap-2">
      {label && <p className="max-w-36">{label}</p>}
      {value && <span className="whitespace-pre-wrap text-left">{value}</span>}
    </div>
  );
};
