import {
  Speciality,
  Therapist,
  TherapistAccount,
  TherapistAccountStorage,
  TherapistList,
  TherapistListStorage,
  TherapistSelector,
  TherapistSelectorStorage,
  TherapistStorage,
} from 'src/types/Therapist';
import {User, UserStorage} from 'src/types/User';
import {
  SupervisorStorage,
  TherapistAccountSetting,
  TherapistAccountSettingStorage,
} from 'src/state/interfaces/Therapist';

import {mapApiResponseUser} from './user';

export function mapApiResponseTherapist(
  jsonBack: TherapistStorage | UserStorage | any,
) {
  const user: User = mapApiResponseUser(jsonBack);

  const therapist: Therapist = {
    ...user,

    account:
      jsonBack.account && jsonBack.account
        ? mapApiResponseTherapistAccount(jsonBack)
        : null,
    avatar:
      jsonBack.therapist && jsonBack.therapist.avatar
        ? {
            url: jsonBack.therapist.avatar.url || null,
            cover: {
              url: jsonBack.therapist.avatar.cover?.url || null,
            },
          }
        : null,
    hasSupervisor:
      jsonBack.therapist && jsonBack.therapist.has_supervisor
        ? {
            fullName: jsonBack.therapist.has_supervisor.full_name,
            initials: jsonBack.therapist.has_supervisor.initials,
            supervisorId: jsonBack.therapist.has_supervisor.id,
            therapistId: jsonBack.therapist.has_supervisor.therapist_id,
          }
        : null,
    invitationAcceptedAt:
      (jsonBack.therapist && jsonBack.therapist.invitation_accepted_at) || null,
    invitationSentAt:
      (jsonBack.therapist && jsonBack.therapist.invitation_sent_at) || null,
    languages: jsonBack.therapist && jsonBack.therapist.languages,
    matchMessages:
      (jsonBack.therapist && jsonBack.therapist.match_messages) || null,
    planType:
      jsonBack.therapist && jsonBack.therapist.plan_type
        ? [jsonBack.therapist.plan_type]
        : null,
    tester: jsonBack.therapist && jsonBack.therapist.tester,
    isSupervisor:
      jsonBack.therapist && jsonBack.therapist.is_supervisor
        ? {
            averageSuperviseesActivePatients:
              jsonBack.therapist?.is_supervisor
                ?.average_supervisees_active_patients,
            id: jsonBack.therapist.is_supervisor?.id,
            supervisees: jsonBack.therapist?.is_supervisor?.supervisees,
            superviseesAvailablePercent:
              jsonBack.therapist?.is_supervisor?.supervisees_available_percent,
          }
        : null,
    supervisorId:
      (jsonBack.therapist && jsonBack.therapist.is_supervisor?.id) || null,
    videocallEnabled:
      jsonBack.therapist && jsonBack.therapist.videocall_enabled,
    whoSupervises: jsonBack.therapist?.is_supervisor?.supervisees || null,
  };
  return therapist;
}

export function mapApiResponseTherapistAccount(
  jsonBack: TherapistAccountStorage,
) {
  const account: TherapistAccount = {
    id: jsonBack.account.id,
    bio: jsonBack.account.bio,
    school: jsonBack.account.school,
    license: jsonBack.account.license,
    avatar: jsonBack.account.avatar ? jsonBack.account.avatar : null,
    activePatients: jsonBack.account.active_patients,
    age: jsonBack.account.age,
    birthday: jsonBack.account.birthday,
    chat: jsonBack.account.chat,
    city: jsonBack.account.city,
    collaboration: jsonBack.account.collaboration,
    confidentiality: jsonBack.account.confidentiality,
    country: jsonBack.account.country,
    couple: jsonBack.account.couple,
    derive: jsonBack.account.derive,
    disable: jsonBack.account.disable,
    extraLanguages: jsonBack.account.extra_languages,
    experience: jsonBack.account.experience,
    freeSlots: jsonBack.account.free_slots,
    gender: jsonBack.account.gender,
    ifeelBirthday: jsonBack.therapist.ifeel_birthday,
    initials: jsonBack.account.initials,
    languages: jsonBack.account.languages,
    maxPatients: jsonBack.account.max_patients,
    mode: jsonBack.account.mode,
    note: jsonBack.account.note,
    orientation:
      jsonBack.account && jsonBack.account.orientation
        ? {
            cognitiveBehavioral:
              jsonBack.account.orientation.cognitive_behavioral,
            humanist: jsonBack.account.orientation.humanist,
            psychoanalysis: jsonBack.account.orientation.psychoanalysis,
            systemic: jsonBack.account.orientation.systemic,
          }
        : null,
    orientationOthers: jsonBack.account.orientation_others || [],
    specialities: jsonBack.account.specialities,
    specialitiesOthers: convertSpecialitiesOthers(
      jsonBack.account.specialities_other,
    ),
    supervisorId: jsonBack.therapist?.is_supervisor?.id || null,
    timeZone: jsonBack.account.time_zone,
    titles: jsonBack.account.titles,
    totalPartners: jsonBack.therapist.total_partners,
    totalPatients: jsonBack.therapist.total_patients,
    trialsPatients: jsonBack.account.trials_patients,
    underAge: jsonBack.account.under_age,
    video: jsonBack.account.video,
    incorporatedAt: jsonBack.account.incorporated_at || null,
    nationality: jsonBack.account.nationality || null,
    fee: jsonBack.account.fee || null,
    billingRole: jsonBack.account.billing_role,
    partnerConfidentiality: jsonBack.account.partner_confidentiality,
    totalAttended: jsonBack.account.total_attended,
    patientsToday: jsonBack.therapist.patients_today,
    patientsPriority: jsonBack.account.patients_priority,
    yearsOfExperience: jsonBack.account.years_of_experience ?? 0,
    experienceStartedAt: jsonBack.account.experience_started_at || null,
    schedule: jsonBack.account.schedule,
    supervisor: null,
    imageTag: jsonBack.account.image_tag || null,
  };
  return account;
}

function convertSpecialitiesOthers(
  specialitiesOthers: Record<string, number> | null,
) {
  if (!specialitiesOthers) return null;
  return Object.entries(specialitiesOthers).reduce((obj, [key, level]) => {
    const text = key
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    const formattedKey = key;

    obj[formattedKey] = {text, level};

    return obj;
  }, {} as Record<string, Speciality>);
}

export function mapApiResponseTherapistSelector(
  jsonBack: TherapistSelectorStorage,
) {
  const therapistSelector: TherapistSelector = {
    id: jsonBack.id,
    name: jsonBack.name,
    fullName: jsonBack.full_name,
  };
  return therapistSelector;
}

export function mapTherapistAccountSetting(
  storageData: TherapistAccountSettingStorage,
): TherapistAccountSetting {
  const mappedData: TherapistAccountSetting = {
    billingRoles: storageData.billing_roles,
    canAccessBillingFee: storageData.can_access_billing_fee,
    collaborations: storageData.collaborations,
    countries: storageData.countries,
    fees: storageData.fees,
    gender: {
      female: storageData.gender.female,
      male: storageData.gender.male,
    },
    languages: {
      extras: storageData.languages.extras,
      main: storageData.languages.main,
    },
    mode: {
      chat: storageData.mode.chat,
      couple: storageData.mode.couple,
      underAge: storageData.mode.under_age,
      video: storageData.mode.video,
    },
    nationalities: storageData.nationalities,
    orientation: {
      cognitiveBehavioral: storageData.orientation.cognitive_behavioral,
      humanist: storageData.orientation.humanist,
      psychoanalysis: storageData.orientation.psychoanalysis,
      systemic: storageData.orientation.systemic,
    },
    specialities: {
      addictions: storageData.specialities.addictions,
      anxiety: storageData.specialities.anxiety,
      behavioralProblemsAndViolence:
        storageData.specialities.behavioral_problems_and_violence,
      couple: storageData.specialities.couple,
      depression: storageData.specialities.depression,
      disability: storageData.specialities.disability,
      eatingIssuesAlterations:
        storageData.specialities.eating_issues_alterations,
      emotionalDependency: storageData.specialities.emotional_dependency,
      emotionalInstability: storageData.specialities.emotional_instability,
      family: storageData.specialities.family,
      genderViolence: storageData.specialities.gender_violence,
      grief: storageData.specialities.grief,
      lgtbiqa: storageData.specialities.lgtbiqa,
      maternity: storageData.specialities.maternity,
      personalDevelopment: storageData.specialities.personal_development,
      postraumaticStress: storageData.specialities.postraumatic_stress,
      schizophrenia: storageData.specialities.schizophrenia,
      seniors: storageData.specialities.seniors,
      severalMentalDisorders: storageData.specialities.several_mental_disorders,
      sexuality: storageData.specialities.sexuality,
      sleep: storageData.specialities.sleep,
      socialHabilities: storageData.specialities.social_habilities,
      workstress: storageData.specialities.workstress,
    },
    supervisors: storageData.supervisors.map(
      (supervisor: SupervisorStorage) => ({
        id: supervisor.id,
        initials: supervisor.initials,
        therapistId: supervisor.therapist_id,
      }),
    ),
    timeZone: storageData.time_zone,
  };

  return mappedData;
}

export function mapApiResponseTherapists(
  storage: TherapistListStorage,
): TherapistList {
  return {
    country: storage.country,
    email: storage.email,
    fullName: storage.full_name,
    id: storage.id,
    invitationAcceptedAt: storage.invitation_accepted_at,
    invitationSentAt: storage.invitation_sent_at,
    languages: storage.languages,
    nickname: storage.nickname,
    planType: storage.plan_type,
    tester: storage.tester,
  };
}
