import * as Yup from 'yup';

const createRoleSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(
      /^\S+$/,
      'This field cannot contain blank spaces, replace them with "_"',
    ),
});

export {createRoleSchema};
