import {ENVIRONMENTS} from './Environments';

export enum ROUTES {
  HOME = '/',
}
export const EXTERNAL_ROUTES = {
  APP_IFEELONLINE: getIfeelonlineRoute(),
  AMAZON_S3_IMAGES: 'https://s3.eu-west-2.amazonaws.com/ifeel-media/images',
};

export const IS_DEVELOPMENT: boolean =
  import.meta.env.VITE_APP_ENV &&
  ['local', 'development'].includes(import.meta.env.VITE_APP_ENV)
    ? true
    : false;

function getIfeelonlineRoute() {
  switch (import.meta.env.VITE_APP_ENV) {
    case ENVIRONMENTS.LOCAL:
    case ENVIRONMENTS.STAGING:
      return 'https://staging.ifeelonline.com/';
    case ENVIRONMENTS.TEST:
      return 'https://test.ifeelonline.com/';
    case ENVIRONMENTS.DEVELOP:
      return 'https://preprod.ifeelonline.com/';
    case ENVIRONMENTS.PRODUCTION:
      return 'https://app.ifeelonline.com/';
    default:
      return 'https://app.ifeelonline.com/';
  }
}
export function partnerLandingUrl(partnerName: string) {
  return `${EXTERNAL_ROUTES.APP_IFEELONLINE}${partnerName}`;
}
