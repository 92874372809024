import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {SharedActionType} from '@ifeelonline/chat-core';

import {
  ArrowBendRightDown,
  ArrowBendRightUp,
  EnvelopeOpen,
  PencilLine,
  Plus,
  Repeat,
} from '@phosphor-icons/react';
import Swal from 'sweetalert2';

import {
  getRolesSelector,
  getUsersAndRoles,
  reinviteUserRoles,
} from 'src/state/action_creators/usersAndRolesCreator';
import {UsersAndRolesActionType} from 'src/state/action_types/UsersAndRolesActionType';
import {arrayToCommaList, parseUnderscore} from 'src/helpers/generalFunctions';
import {PaginationComponent} from 'src/components/shared/PaginationComponent';
import {TdWithTooltip} from 'src/components/tables/TdWithTooltip';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {CustomTable} from 'src/components/tables/CustomTable';
import {UsersAndRolesStorage} from 'src/types/UsersAndRoles';
import {PageHeader} from 'src/components/layout/PageHeader';
import {NoResults} from 'src/components/tables/NoResults';
import {Loading} from 'src/components/shared/Loading';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';
import {formatDate} from 'src/helpers/dates';
import {RootState} from 'src/state';

import {EditDashboardUserRolesForm, NewDashboardUserForm} from './forms';

const initValues = {
  page: 1,
  orderBy: 'id',
  orderDesc: false,
};

export const UsersAndRolesTable = () => {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(initValues.page);
  const [orderBy, setOrderBy] = useState(initValues.orderBy);
  const [isOrderDesc, setIsOrderDesc] = useState(initValues.orderDesc);
  const [isEditRolesModalOpen, setIsEditRolesModalOpen] = useState(false);
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);

  const {usersAndRoles, rolesListSelector, status, total} = useSelector(
    (state: RootState) => state.uar,
  );

  useEffect(() => {
    if (rolesListSelector === null) {
      dispatch(getRolesSelector());
    }
  }, []);

  const handleOrderBy = (newOrderBy: string) => {
    setOrderBy(newOrderBy);
    setIsOrderDesc(!isOrderDesc);
  };

  const handleEditUserRole = (
    e: React.MouseEvent,
    user: UsersAndRolesStorage,
  ) => {
    e.preventDefault();
    dispatch({
      type: UsersAndRolesActionType.SET_USER_ROLE_ACTIVE,
      payload: user,
    });
    setIsEditRolesModalOpen(true);
  };

  const handleResendInvitation = (
    e: React.MouseEvent,
    user: UsersAndRolesStorage,
  ) => {
    e.preventDefault();
    Swal.fire({
      icon: 'info',
      title: 'Resend invitation',
      text: `Are you sure you want to resend an invitation to ${user.email}?`,
      showConfirmButton: true,
    }).then((res) => {
      if (res.isConfirmed)
        dispatch(reinviteUserRoles(user.id)).then((res) => {
          if (res) {
            toast.success('The invitation has been successfully resent');
            dispatch(
              getUsersAndRoles({
                page,
                orderBy,
                orderDir: isOrderDesc ? 'desc' : 'asc',
              }),
            );
          }
        });
    });
  };

  const columnsVisible = [
    'id',
    'email',
    'invitation_sent_at',
    'invitation_accepted_at',
    'roles',
    'actions',
  ];

  useEffect(() => {
    dispatch(
      getUsersAndRoles({
        page,
        orderBy,
        orderDir: isOrderDesc ? 'desc' : 'asc',
      }),
    );
  }, [page, orderBy, isOrderDesc]);

  return status === SharedActionType.LOADING || usersAndRoles === null ? (
    <Loading />
  ) : (
    <div>
      <PageHeader pageTitle="Users and roles">
        <div className="flex">
          <Button
            buttonType={ButtonType.SAVE}
            size="small"
            onClick={() => setIsNewUserModalOpen(true)}>
            <Plus size={18} className="mr-1.5" /> Add user
          </Button>
        </div>
      </PageHeader>
      {usersAndRoles && (
        <>
          {total > 0 ? (
            <CustomTable
              headChildren={
                <tr>
                  {columnsVisible.map((column, index) => (
                    <th
                      key={index}
                      className={column === 'roles' ? '' : 'cursor-pointer'}
                      onClick={() => {
                        if (column !== 'roles') handleOrderBy(column);
                      }}>
                      <div className="flex justify-start">
                        {parseUnderscore(column)}
                        {orderBy === column &&
                          (isOrderDesc ? (
                            <ArrowBendRightDown size={10} />
                          ) : (
                            <ArrowBendRightUp size={10} />
                          ))}
                      </div>
                    </th>
                  ))}
                </tr>
              }
              bodyChildren={usersAndRoles.map(
                (usersAndRoles: UsersAndRolesStorage, index: number) => (
                  <tr key={index} className="group">
                    {columnsVisible.map((column, index) => {
                      switch (column) {
                        case 'id':
                          return (
                            <td
                              key={index}
                              className="badge-relative pointer"
                              onClick={(e) =>
                                handleEditUserRole(e, usersAndRoles)
                              }>
                              <div className="flex">
                                <p>{usersAndRoles.id}</p>
                              </div>
                            </td>
                          );
                        case 'email':
                          return (
                            <TdWithTooltip
                              key={index}
                              content={usersAndRoles.email}
                              onClick={(e: React.MouseEvent) =>
                                handleEditUserRole(e, usersAndRoles)
                              }
                            />
                          );

                        case 'roles':
                          return (
                            <td
                              key={index}
                              className="badge-relative"
                              onClick={(e) =>
                                handleEditUserRole(e, usersAndRoles)
                              }>
                              <div className="flex justify-start">
                                <p>
                                  {usersAndRoles.roles
                                    ? arrayToCommaList(usersAndRoles.roles)
                                    : ''}
                                </p>
                              </div>
                            </td>
                          );
                        case 'invitation_sent_at':
                          return (
                            <td
                              key={index}
                              onClick={(e) =>
                                handleEditUserRole(e, usersAndRoles)
                              }>
                              {usersAndRoles.invitation_sent_at
                                ? formatDate(usersAndRoles.invitation_sent_at)
                                : ''}
                            </td>
                          );
                        case 'invitation_accepted_at':
                          return (
                            <td key={index}>
                              {usersAndRoles.invitation_accepted_at ? (
                                formatDate(usersAndRoles.invitation_accepted_at)
                              ) : (
                                <Button
                                  buttonType={ButtonType.ACTIONS}
                                  size="small"
                                  id="resend-invitation"
                                  onClick={(e) =>
                                    handleResendInvitation(e, usersAndRoles)
                                  }>
                                  <Repeat size={10} weight="duotone" />
                                  <EnvelopeOpen />
                                </Button>
                              )}
                            </td>
                          );
                        case 'actions':
                          return (
                            <td key={index}>
                              <Button
                                buttonType={ButtonType.ACTIONS}
                                size="small"
                                onClick={(e) =>
                                  handleEditUserRole(e, usersAndRoles)
                                }>
                                <PencilLine size="14" weight="duotone" />
                              </Button>
                            </td>
                          );
                        default:
                          return (
                            <td key={index}>
                              {
                                usersAndRoles[
                                  column as keyof UsersAndRolesStorage
                                ]
                              }
                            </td>
                          );
                      }
                    })}
                  </tr>
                ),
              )}
            />
          ) : (
            <NoResults />
          )}
        </>
      )}

      <PaginationComponent total={total} page={page} setPage={setPage} />

      <ModalLayout
        show={isEditRolesModalOpen}
        closeModal={() => setIsEditRolesModalOpen(false)}>
        <EditDashboardUserRolesForm
          closeModal={() => setIsEditRolesModalOpen(false)}
        />
      </ModalLayout>

      <ModalLayout
        show={isNewUserModalOpen}
        closeModal={() => setIsNewUserModalOpen(false)}>
        <NewDashboardUserForm
          sorting={{page, orderBy, orderDir: isOrderDesc ? 'desc' : 'asc'}}
          closeModal={() => setIsNewUserModalOpen(false)}
        />
      </ModalLayout>
    </div>
  );
};
