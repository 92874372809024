import {useState} from 'react';

import {useForm, SubmitHandler} from 'react-hook-form';
import toast from 'react-hot-toast';

import {yupResolver} from '@hookform/resolvers/yup';

import {
  createRole,
  getRoles,
} from '../../../state/action_creators/rolesCreator';
import {areSessionsAndGraphicsChecked} from '../../../helpers/rolesFunctions';
import {createRoleSchema} from '../schemas/RoleSchema';
import {useAppDispatch} from '../../../hooks/hooks';
import {RolesTableStrings} from '../types';

//This will allow to roleName to be a string and the rest of the object a string array despite not knowing their names
export type RoleFormValues = {
  [key: string]: string | string[];
  name: string;
};

interface UseRolesFormProps {
  closeModal: () => void;
  orderBy: string;
  orderDir: string;
  page: number;
}

export const useRolesForm = ({
  closeModal,
  page,
  orderDir,
  orderBy,
}: UseRolesFormProps) => {
  const dispatch = useAppDispatch();

  const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);

  const {
    control,
    handleSubmit,
    formState: {isSubmitting},
  } = useForm({
    resolver: yupResolver(createRoleSchema),
    mode: 'all',
  });

  const mapNewRolePayload = (values: RoleFormValues) => {
    const rolesPermissions = Object.keys(values)
      .map((controller) => {
        if (
          controller.includes(RolesTableStrings.CONTROLLER_SUFFIX) &&
          values[controller]
        ) {
          return {
            controller,
            actions: values[controller],
          };
        }
      })
      .filter((controller) => controller);

    return {
      name: values.name,
      permissions: {
        roles_permissions: rolesPermissions,
      },
    };
  };

  const onSubmit: SubmitHandler<RoleFormValues> = (values: RoleFormValues) => {
    const payload = mapNewRolePayload(values);
    if (areSessionsAndGraphicsChecked(payload.permissions.roles_permissions)) {
      setIsLoadingOnSubmit(true);
      dispatch(createRole(payload)).then((response) => {
        if (response) {
          dispatch(
            getRoles({
              page: page,
              orderBy: orderBy,
              orderDir: orderDir,
            }),
          ).then(() => {
            setIsLoadingOnSubmit(false);
            closeModal();
            toast.success(`Role ${values.name} created successfully`);
          });
        }
      });
    } else {
      toast.error(
        'Access to sessions and graphics controller are required at least',
      );
      return false;
    }
  };

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    isSubmitting,
    isLoadingOnSubmit,
  };
};
