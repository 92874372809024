import React from 'react';

import ReactQuill from 'react-quill';

import ButtonSimple from 'src/components/buttons/ButtonSimple';

const CustomToolbar = () => (
  <div id="toolbar">
    <select
      className="ql-header"
      defaultValue=""
      onChange={(e) => e.persist()}>
      <option value="1"></option>
      <option value="2"></option>
      <option></option>
    </select>
    <ButtonSimple className="ql-bold" />
    <ButtonSimple className="ql-italic" />
    <select className="ql-color">
      <option value="#b5bcd6"></option>
      <option value="#082c6c"></option>
      <option value="#3e4b79"></option>
      <option value="#809add"></option>
      <option></option>
    </select>
  </div>
);

interface EditorProps {
  onChange: (content: string) => void;
  placeholder?: string;
  value: string;
}

const RichTextEditor = ({value, onChange, placeholder}: EditorProps) => {
  const modules = {
    toolbar: {
      container: '#toolbar',
    },
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
  ];

  return (
    <div>
      <CustomToolbar />
      <ReactQuill
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
      />
    </div>
  );
};

export default RichTextEditor;
