import {SharedActionType} from '@ifeelonline/chat-core';

import {EmailAuthorization} from 'src/types/EmailAuthorization';
import {PartnerSelector} from 'src/types/Partner';

import {EmailAuthorizationAction} from '../actions/emailsAuthorizations';
import {EmailAuthorizationsActionType} from '../action_types/EmailAuthorizationsType';

interface EmailAuthorizationState {
  emailAuthorizations: EmailAuthorization[] | null;
  error: string | null;
  partnersListSelector: PartnerSelector[] | null;
  status: SharedActionType;
  total: number;
}

const initialState = {
  emailAuthorizations: null,
  partnersListSelector: null,
  total: 0,
  status: SharedActionType.IDLE,
  error: null,
};

export const emailAuthorizationsReducer = (
  state: EmailAuthorizationState = initialState,
  action: EmailAuthorizationAction,
) => {
  switch (action.type) {
    case EmailAuthorizationsActionType.EMAIL_AUTHORIZATIONS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case EmailAuthorizationsActionType.SET_EMAIL_AUTHORIZATIONS_OPTIONS:
      return {
        ...state,
        partnersListSelector: action.payload,
      };

    case EmailAuthorizationsActionType.SET_EMAIL_AUTHORIZATIONS:
      return {
        ...state,
        emailAuthorizations: action.payload.emailAuthorizations,
        total: action.payload.total,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
