import React, {useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';

import {SharedActionType} from '@ifeelonline/chat-core';

import {
  exportPatient,
  getPatient,
  getPatientPlansOptions,
} from 'src/state/action_creators/patientCreator';
import {PauseReactivateSubscriptionModal} from 'src/pages/patient/modals/PauseReactivateSubscriptionModal';
import {PatientDetailsSection} from 'src/pages/patient/components/PatientDetailsSection';
import {PatientOptionsForUser} from 'src/pages/patient/components/PatientOptionsForUser';
import {CancelSubscriptionModal} from 'src/pages/patient/modals/CancelSubscriptionModal';
import {CreateSubscriptionModal} from 'src/pages/patient/modals/CreateSubscriptionModal';
import {ExtendSubscriptionModal} from 'src/pages/patient/modals/ExtendSubscriptionModal';
import {UpdateSubscriptionModal} from 'src/pages/patient/modals/UpdateSubscriptionModal';
import {PatientProgressSteps} from 'src/pages/patients/components/PatientProgressSteps';
import {AllAttributesModal} from 'src/pages/patients/modals/AllAttributesModal';
import {SurveyAnswersModal} from 'src/pages/patients/modals/SurveyAnswersModal';
import {PatientEditModal} from 'src/pages/patients/modals/PatientEditModal';
import MenuBasic from 'src/components/shared/dropdown/MenuBasic';
import {ExportModal} from 'src/components/shared/ExportModal';
import {ControllerNames} from 'src/types/ControllerNames';
import {Loading} from 'src/components/shared/Loading';
import {Layout} from 'src/components/layout/Layout';
import {MenuBasicOption} from 'src/types/Menus';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {PatientSubscriptionSection} from './components/PatientSubscriptionSection';
import {UpdateSubscriptionNotesModal} from './modals/UpdateSubscriptionNotesModal';
import {UndoCancelSubscriptionModal} from './modals/UndoCancelSubscriptionModal';

type Params = {
  id: string;
};

export const PatientShowPage = () => {
  const {id} = useParams<Params>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {status, patient} = useSelector((state: RootState) => state.patient);
  const [surveySelected, setSurveySelected] = useState({
    value: 'initial',
    name: 'initial',
  });

  const [modalOpen, setModalOpen] = useState(false);

  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalSurveyOpen, setModalSurveyOpen] = useState(false);

  function getMenuOptions() {
    if (patient) {
      const patientDropdownList: MenuBasicOption[] = [
        {
          name: 'User attributes',
          onClick: () => setModalOpen(true),
        },
        {
          name: 'Recent activity',
          controllerName: ControllerNames.EVENTS,
          onClick: () => navigate(`/recent_activity/${patient.id}`),
        },
      ];
      if (!patient.deleted) {
        patientDropdownList.unshift({
          name: 'Edit user',
          onClick: () => setModalEditOpen(true),
        });
      }
      return patientDropdownList;
    }
  }

  useEffect(() => {
    dispatch(getPatient(parseInt(id!)));
    dispatch(getPatientPlansOptions(parseInt(id!)));
  }, [dispatch, id]);

  return (
    <Layout>
      <>
        {status === SharedActionType.LOADING ? (
          <Loading />
        ) : (
          <div>
            {patient && (
              <div>
                <div className="user__name flex items-center justify-between">
                  <div className="flex items-center justify-center">
                    <div
                      className={`big-badge ${
                        patient.partner ? 'b2b' : 'b2c'
                      }`}>
                      {patient.partner ? 'B2B' : 'B2C'}
                    </div>
                    <div className="user__name-info">
                      <h1>{patient.email}</h1>
                      <p>{patient.nickname}</p>
                    </div>
                  </div>
                  <MenuBasic
                    items={getMenuOptions() as any}
                    buttonText="Actions"
                  />
                </div>

                <div className="user__plan user__section">
                  <h3>User status</h3>

                  <PatientProgressSteps
                    patientStatus={patient.status}
                    deleted={patient.deleted}
                  />
                </div>
                <PatientDetailsSection patient={patient} />

                <PatientSubscriptionSection patient={patient} />

                <PatientOptionsForUser
                  patient={patient}
                  id={parseInt(id!)}
                  surveySelected={surveySelected}
                  setModalExportOpen={setModalExportOpen}
                  setModalSurveyOpen={setModalSurveyOpen}
                  setSurveySelected={setSurveySelected}
                />
              </div>
            )}
          </div>
        )}
        {patient && patient.currentPlan && (
          <>
            <UpdateSubscriptionModal />
            <ExtendSubscriptionModal patient={patient} />
            <PauseReactivateSubscriptionModal patient={patient} />
            <CancelSubscriptionModal />
          </>
        )}
        {patient && (
          <>
            <AllAttributesModal show={modalOpen} setShow={setModalOpen} />
            <SurveyAnswersModal
              show={modalSurveyOpen}
              setShow={setModalSurveyOpen}
              patientId={patient.id}
              surveySelected={surveySelected}
            />
            <PatientEditModal show={modalEditOpen} setShow={setModalEditOpen} />
            <CreateSubscriptionModal />
            <ExportModal
              show={modalExportOpen}
              setModalOpen={setModalExportOpen}
              title="Export patient"
              exportReport={exportPatient}
              id={patient.id}
              type="patientReport"
            />
            <UpdateSubscriptionNotesModal patient={patient} />
            <UndoCancelSubscriptionModal patient={patient} />
          </>
        )}
      </>
    </Layout>
  );
};
