import React, {useState} from 'react';

import {useController} from 'react-hook-form';

import {ErrorText} from './ErrorText';
import {BaseControlledComponentProps} from './type';
import {CustomTextField} from './StylesFormControlled';

export function InputNumber({
  control,
  name,
  label,
  labelError,
  placeholder,
}: BaseControlledComponentProps) {
  const {field, fieldState} = useController({
    control,
    name,
  });

  const isInvalid = fieldState.invalid;
  const [isClicked, setIsClicked] = useState(false);

  return (
    <div className="mb-3 min-h-12 w-full">
      <label className="mb-1 block">
        {isInvalid && labelError ? labelError : label}
      </label>
      <CustomTextField
        error={isInvalid}
        size="small"
        style={{width: '100%'}}
        type="number"
        inputProps={{
          autoComplete: 'off',
          readOnly: isClicked ? false : true, //Hack to prevent autofill when a password input is used
        }}
        placeholder={placeholder}
        {...field}
        onBlur={() => {
          if (isClicked) setIsClicked(false);
        }}
        onClick={() => {
          if (!isClicked) setIsClicked(true);
        }}
      />
      {fieldState.error?.message && (
        <ErrorText error={fieldState.error?.message} size="small" />
      )}
    </div>
  );
}
