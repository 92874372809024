import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';

import {HomeActionType} from '../action_types/HomeActionType';
import {HomeAction} from '../actions/home';
import {FixMeLater} from '../interfaces/FixMeLater';

type DispatchResult = HomeAction | any;

export const getHomeData = (period: string | null) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: HomeActionType.HOME_DATA_LOADING});

    const resp = await fetchWithToken(
      `admin/graphics/graphics?select_period=${period}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {data} = body;
      if (period === 'last14days') {
        dispatch(setHomeData14Days({data}));
      } else if (period === 'day') {
        dispatch(setHomeDataDay({data}));
      } else {
        dispatch(setHomeData7Days({data}));
      }
      return data;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

const setHomeDataDay = (data: FixMeLater) => ({
  type: HomeActionType.SET_HOME_DATA_DAY,
  payload: data,
});

const setHomeData7Days = (data: FixMeLater) => ({
  type: HomeActionType.SET_HOME_DATA_7_DAYS,
  payload: data,
});
const setHomeData14Days = (data: FixMeLater) => ({
  type: HomeActionType.SET_HOME_DATA_14_DAYS,
  payload: data,
});
