import {Dispatch} from 'redux';

import {
  mapApiResponseTherapist,
  mapApiResponseTherapistAccount,
  mapApiResponseTherapists,
  mapTherapistAccountSetting,
} from 'src/helpers/api_response/therapist';
import {catchApiError} from 'src/helpers/catchApiError';
import {fetchFormData, fetchWithToken} from 'src/helpers/fetch';
import {toQueryString} from 'src/helpers/generalFunctions';
import {
  Therapist,
  TherapistAccount,
  TherapistList,
  TherapistListStorage,
  TherapistStorage,
} from 'src/types/Therapist';

import {PatientActionType} from '../action_types/PatientActionType';
import {TherapistsActionType} from '../action_types/TherapistsActionType';
import {SetTherapistsPayload, TherapistsAction} from '../actions/therapists';
import {FixMeLater} from '../interfaces/FixMeLater';

type DispatchResult = TherapistsAction | any;

export const getTherapists = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: TherapistsActionType.THERAPISTS_LOADING});

    const resp = await fetchWithToken(
      `admin/therapists?${toQueryString(params)}`,
    );
    const body = await resp?.json();

    if (body && body.success) {
      const {
        therapists,
        total_count,
      }: {therapists: TherapistListStorage[]; total_count: number} = body;
      const therapistsMapped: TherapistList[] = therapists.map((therapist) =>
        mapApiResponseTherapists(therapist),
      );
      dispatch(
        setTherapists({
          therapists: therapistsMapped,
          total: total_count,
        }),
      );
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getTherapist = (therapistId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: TherapistsActionType.THERAPIST_LOADING});

    const resp = await fetchWithToken(`admin/therapists/${therapistId}`);
    const body = await resp?.json();

    if (body) {
      const therapist: Therapist = mapApiResponseTherapist(body);
      dispatch(setTherapist(therapist));
      return therapist;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getCountries = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(`admin/therapists/countries`);
    const body = await resp?.json();
    if (body && body.success) {
      const {countries}: {countries: string[]} = body;
      dispatch({
        type: TherapistsActionType.SET_COUNTRIES,
        payload: countries,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};
export const getCitiesForCountry = (country: string) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/accounts/cities?country=${country}`,
    );
    const body = await resp?.json();
    if (body.cities) {
      const {cities}: {cities: string[]} = body;
      dispatch({
        type: TherapistsActionType.SET_CITIES,
        payload: cities,
      });
      return cities;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const updateTherapist = (
  therapistId: number,
  fieldsToEdit: FixMeLater,
) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchFormData(
      `admin/therapists/${therapistId}`,
      fieldsToEdit,
      'PUT',
    );
    const body = await resp?.json();

    if (body) {
      return body;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const accountTherapist = (
  therapistId: number,
  fieldsToEdit: FixMeLater,
  accountId: number | null,
  file?: boolean,
) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = file
      ? await fetchFormData(
          accountId
            ? `admin/therapists/${therapistId}/accounts/${accountId}`
            : `admin/therapists/${therapistId}/accounts`,
          fieldsToEdit,
          accountId ? 'PUT' : 'POST',
        )
      : await fetchWithToken(
          accountId
            ? `admin/therapists/${therapistId}/accounts/${accountId}`
            : `admin/therapists/${therapistId}/accounts`,
          fieldsToEdit,
          accountId ? 'PUT' : 'POST',
        );

    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const getAccountTherapist = (therapistId: number, locale: string) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/therapists/${therapistId}?locale=${locale}`,
    );

    const body = await resp?.json();
    if (body) {
      const account: TherapistAccount = mapApiResponseTherapistAccount(body);
      return account;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const getAllAttributesTherapist = (therapistId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: TherapistsActionType.THERAPIST_LOADING});

    const resp = await fetchWithToken(
      `admin/therapists/${therapistId}?show_all_attributes=true`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const therapist = body.data;
      dispatch(setAllAttributesTherapist(therapist));
    } else {
      dispatch(catchApiError(body));
    }
  };
};
export const therapistAccountSetting = (therapistId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/therapists/${therapistId}/accounts/account_settings`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {account} = body;
      return mapTherapistAccountSetting(account);
    } else {
      dispatch(catchApiError(body));
      return null;
    }
  };
};

export const createTherapist = (
  data: FixMeLater,
  createZoomAccount: boolean,
) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      'admin/therapists',
      {
        user: data,
        create_zoom_account: createZoomAccount,
      },
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {therapist: unmappedTherapist}: {therapist: TherapistStorage} =
        body;
      const therapist: Therapist = mapApiResponseTherapist(unmappedTherapist);
      dispatch(therapistAddNew(therapist));
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const exportTherapists = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/therapists/export`,
      params,
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const updateFieldsTherapistAccount = (
  therapistId: number,
  fieldsToEdit: FixMeLater,
  accountId: number,
) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/therapists/${therapistId}/accounts/${accountId}`,
      fieldsToEdit,
      'PUT',
    );

    const body = await resp?.json();
    if (body) {
      return body;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const reinviteTherapist = (therapistId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: PatientActionType.PATIENT_LOADING});
    const resp = await fetchWithToken(
      `admin/therapists/reinvite?id=${therapistId}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const sendEmailLinkApps = (id: number) => {
  return async (dispatch: Dispatch<any>) => {
    const resp = await fetchWithToken(
      `admin/therapists/apps_link_email`,
      {id},
      'POST',
    );
    const body = await resp?.json();
    if (!body?.success) {
      dispatch(catchApiError(body));
    }
    return body;
  };
};

export const sendEmailResetPass = (id: number) => {
  return async (dispatch: Dispatch<any>) => {
    const resp = await fetchWithToken(
      `admin/users/send_reset_password_instructions`,
      {id},
      'POST',
    );
    const body = await resp?.json();
    if (!body?.success) {
      dispatch(catchApiError(body));
    }
    return body;
  };
};

export const viewAsPatient = (id: number, locale: string) => {
  return async (dispatch: Dispatch<any>) => {
    const resp = await fetchWithToken(
      `admin/therapists/view_as_patient`,
      {id, locale},
      'POST',
    );
    const body = await resp?.json();
    if (!body.success) {
      dispatch(catchApiError(body));
    }
    return body;
  };
};

const setTherapists = (payload: SetTherapistsPayload) => ({
  type: TherapistsActionType.SET_THERAPISTS,
  payload,
});

const setTherapist = (therapist: Therapist) => ({
  type: TherapistsActionType.SET_THERAPIST,
  payload: therapist,
});

const setAllAttributesTherapist = (attributes: any) => ({
  type: TherapistsActionType.SET_ALL_ATTRIBUTES_THERAPIST,
  payload: attributes,
});

const therapistAddNew = (therapist: Therapist) => ({
  type: TherapistsActionType.THERAPIST_ADD_NEW,
  payload: therapist,
});
