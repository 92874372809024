import {OverSLAValue} from 'src/constants/Dashboard';
import {OperationsDashboardTabEnum} from 'src/constants/TabLists';
import {ChartOptionsValue, Series} from 'src/types/Graphics';

export function GetOffsetPerOverSLA(graphic: Series[]) {
  const maxValue = Math.max(...graphic.map((g) => g.y));
  return OverSLAValue / maxValue;
}

export function GetTypeParam(type: string) {
  return type === OperationsDashboardTabEnum.THERAPISTS
    ? 'therapists'
    : 'spots';
}

export function RegistrationTooltip(optionSelected: ChartOptionsValue) {
  switch (optionSelected) {
    case ChartOptionsValue.REGISTERS:
      return 'Nº of accounts registered';
    case ChartOptionsValue.UNREGISTERED:
      return 'Nº of authorized users that have not created an account';
    case ChartOptionsValue.LOGINS:
      return 'Nº of people that have log into the platform (online users)';
    case ChartOptionsValue.DELETED:
      return 'Accounts deleted';
    case ChartOptionsValue.INTAKES:
      return 'Nº of people that have been registered in the platform';
    case ChartOptionsValue.PATIENTS_PER_THERAPIST:
      return 'Nº of patients per therapist';
    case ChartOptionsValue.INTAKES_WAITING_TIME:
      return 'Average time of waiting for the intake to be registered';
  }
}
