import React from 'react';

import {Warning} from '@phosphor-icons/react';

import {Layout} from 'src/components/layout/Layout';

export const Custom404 = () => {
  return (
    <Layout>
      <div className="mt-10 flex justify-center">
        <Warning size={100} className="fill-slate-500" weight="fill" />
      </div>
      <h1 className="text-center">Couldn&apos;t load page</h1>
    </Layout>
  );
};
