import React from 'react';

import {useSelector} from 'react-redux';

import {Autocomplete, TextField} from '@mui/material';

import {GenericFiltersToggle} from 'src/components/generic_table/filters/GenericFiltersToggle';
import {capitalizeFirstLetter} from 'src/helpers/generalFunctions';
import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';
import {Event, TypeOfEventForSelector} from 'src/types/Event';
import {RootState} from 'src/state';

import {FormValuesEvent} from '../../EventsTable';

export const TypeOfEventFilter = ({
  formValues,
  updateFormValue,
}: Pick<
  GenericTableProps<Event, FormValuesEvent>,
  'formValues' | 'updateFormValue'
>) => {
  const {typeOfEventsList} = useSelector((state: RootState) => state.events);
  if (!typeOfEventsList) return null;
  return (
    <GenericFiltersToggle
      checked={formValues.typeOfEvent?.checked}
      onChange={() =>
        updateFormValue(
          'typeOfEvent',
          'checked',
          !formValues.typeOfEvent.checked,
        )
      }
      label="Type of event">
      {formValues.typeOfEvent?.checked && (
        <div className="popover__filter_field">
          <Autocomplete
            id="type-of-event-selector"
            options={typeOfEventsList}
            size="small"
            getOptionLabel={(option: TypeOfEventForSelector) =>
              capitalizeFirstLetter(option.name)
            }
            value={
              typeOfEventsList?.find(
                (option: TypeOfEventForSelector) =>
                  option.value === formValues.typeOfEvent.value,
              ) || null
            }
            onChange={(event, newValue) => {
              updateFormValue('typeOfEvent', 'value', newValue?.value || null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '& legend': {display: 'none'},
                  '& fieldset': {top: 0},
                }}
              />
            )}
          />
        </div>
      )}
    </GenericFiltersToggle>
  );
};
