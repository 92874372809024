import {Supervisor, SupervisorStorage} from 'src/types/Supervisor';

export function mapApiResponseSupervisor(jsonBack: SupervisorStorage) {
  const supervisor: Supervisor = {
    id: jsonBack.id,
    fullName: jsonBack.full_name,
    initials: jsonBack.initials,
    therapistId: jsonBack.therapist_id,
  };
  return supervisor;
}
