import React from 'react';

import {OverlayTrigger} from 'react-bootstrap';

import {Info} from '@phosphor-icons/react';

import {renderTooltip} from 'src/components/shared/TooltipRender';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';

import {LineChartHome} from './LineChartHome';

interface GraphicBoxInterface {
  data_tip: string;
  graphic_data: FixMeLater;
  periodSelected: string;
  title: string;
  total: number;
}

export const GraphicBox = ({
  periodSelected,
  total,
  graphic_data,
  data_tip,
  title,
}: GraphicBoxInterface) => {
  return (
    <div className="graphic-box">
      <h3>
        {title}{' '}
        <OverlayTrigger
          placement="bottom"
          delay={{show: 100, hide: 100}}
          overlay={renderTooltip(data_tip)}>
          <Info />
        </OverlayTrigger>
      </h3>

      <span>{total}</span>
      <LineChartHome data={graphic_data} periodSelected={periodSelected} />
    </div>
  );
};
