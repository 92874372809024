import React from 'react';

import {UserCircle} from '@phosphor-icons/react';

import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import {Therapist, WhoSupervisesStorage} from 'src/types/Therapist';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';

interface Props extends ModalSimpleInterface {
  therapist: Therapist;
  therapistSupervisedList: FixMeLater;
}

export const WhoSupervisesModal = ({
  show,
  setShow,
  therapist,
  therapistSupervisedList,
}: Props) => {
  const closeModal = () => setShow!(false);

  return (
    <ModalLayout
      show={show}
      closeModal={closeModal}
      className="supervises-modal">
      <ModalLayout.Header>
        <h3>Therapist advised by {therapist.nickname}</h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        <ul className="">
          {therapistSupervisedList.map(
            (therapist: WhoSupervisesStorage, index: number) => (
              <li key={index}>
                <UserCircle size={18} /> {therapist.initials}{' '}
                {therapist.fullName}
              </li>
            ),
          )}
        </ul>
      </ModalLayout.Body>
    </ModalLayout>
  );
};
