import React from 'react';

import {copyToClipboard} from 'src/helpers/generalFunctions';

import {ActionSpan} from './ActionSpan';

interface Props {
  textButton: string;
  textToCopy: string;
}

export const CopyToClipboardButton = ({textToCopy, textButton}: Props) => {
  return (
    <ActionSpan onClick={() => copyToClipboard(textToCopy)}>
      {textButton}
    </ActionSpan>
  );
};
