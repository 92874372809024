import React from 'react';

import {Disclosure, Transition} from '@headlessui/react';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  buttonChildren: React.ReactNode;
  buttonIcon?: React.ReactNode;
  collapseChildren: React.ReactNode;
}

export default function CollapseComp({
  buttonChildren,
  buttonIcon,
  collapseChildren,
  className,
}: Props) {
  return (
    <div className="w-full">
      <div className={`mx-auto w-full ${className || ''}`}>
        <Disclosure as="div">
          {({open}) => (
            <>
              <Disclosure.Button
                className="flex w-full justify-between"
                as="div">
                {buttonChildren}
                {buttonIcon && (
                  <div className={`${open ? '' : 'rotate'}`}>{buttonIcon}</div>
                )}
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition ease duration-150 transform"
                enterFrom="opacity-0 -translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease duration-150 transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-1">
                <Disclosure.Panel className="overflow-hidden">
                  {collapseChildren}
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
