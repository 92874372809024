import {SharedActionType} from '@ifeelonline/chat-core';

import {TherapistList} from 'src/types/Therapist';

import {TherapistsActionType} from '../action_types/TherapistsActionType';
import {TherapistsAction} from '../actions/therapists';
import {FixMeLater} from '../interfaces/FixMeLater';

interface TherapistsState {
  countryListSelector: FixMeLater;
  error: null | string;
  status: SharedActionType;
  therapistListSelector: FixMeLater;
  therapists: TherapistList[];
  total: number;
}

const initialState = {
  therapists: [],
  countryListSelector: null,
  total: 0,
  status: SharedActionType.IDLE,
  therapistListSelector: null,
  error: null,
};

export const therapistsReducer = (
  state: TherapistsState = initialState,
  action: TherapistsAction,
) => {
  switch (action.type) {
    case TherapistsActionType.THERAPISTS_LOADING:
      return {
        ...state,
        therapists: [],
        status: SharedActionType.LOADING,
      };

    case TherapistsActionType.SET_COUNTRIES:
      return {
        ...state,
        countryListSelector: action.payload,
      };

    case TherapistsActionType.SET_THERAPISTS:
      return {
        ...state,
        therapists: action.payload.therapists,
        total: action.payload.total,
        status: SharedActionType.COMPLETED,
      };

    case TherapistsActionType.THERAPIST_ADD_NEW:
      return {
        ...state,
        therapists: state.therapists
          ? [action.payload, ...state.therapists]
          : [action.payload],
        total: state.total ? state.total + 1 : 1,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
