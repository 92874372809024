import {Fragment, useState} from 'react';
import * as React from 'react';

import {useSelector} from 'react-redux';

import {Menu, Transition} from '@headlessui/react';
import {CaretDown} from '@phosphor-icons/react';

import {RBAC} from 'src/helpers/Rbac';
import {RootState} from 'src/state';

import {LiDropdown} from './LiDropdown';

interface DropdownMenuInterface extends React.ComponentProps<'button'> {
  dropdownList: MenuListInterface[];
  header?: string;
  svg?: React.ReactElement | null;
}
export interface MenuListInterface {
  actionName?: string | null;
  controllerName?: string | null;
  name: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

export const DropdownMenu = ({
  header,
  className,
  dropdownList,
  svg,
}: DropdownMenuInterface) => {
  const {user} = useSelector((state: RootState) => state.auth);
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className={className} onClick={handleShow}>
          {header}{' '}
          {svg ? (
            svg
          ) : (
            <CaretDown
              size={12}
              className={`ml-1 ${show ? 'rotate-180' : ''}`}
            />
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-1.5 bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="py-1">
            {!header && (
              <div className="border-b p-4">
                {user && (
                  <h3 className="text-4 font-medium">
                    {user.name ? user.name : user.email.split('@')[0]}
                  </h3>
                )}
              </div>
            )}
            {dropdownList.map((item: MenuListInterface, index: number) =>
              item.controllerName ? (
                <RBAC
                  key={index}
                  controllerName={item.controllerName}
                  actionName={item.actionName}>
                  <LiDropdown item={item} />
                </RBAC>
              ) : (
                <LiDropdown key={index} item={item} />
              ),
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
