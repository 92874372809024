import {useState} from 'react';

import toast from 'react-hot-toast';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {yupResolver} from '@hookform/resolvers/yup';

import {
  getTherapist,
  updateTherapist,
} from 'src/state/action_creators/therapistsCreator';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';
import {createFormData} from 'src/helpers/form';
import {
  FileType,
  FileTypeStatus,
  fileInit,
} from 'src/components/form/controlled/type';

import {therapistEditSchema} from '../schemas/therapistSchema';

interface Params {
  closeModal: () => void;
}
interface FormValues {
  avatar: string | null;
  email: string;
  hasZoomAccount: string[];
  name: string;
  nickname: string;
  phone: string;
  surname: string;
}

export const useTherapistEditForm = ({closeModal}: Params) => {
  const dispatch = useAppDispatch();
  const {therapist} = useSelector((state: RootState) => state.therapist);

  const initialValues: FormValues = {
    avatar: therapist?.avatar?.url || null,
    name: therapist?.name || '',
    nickname: therapist?.nickname || '',
    email: therapist?.email || '',
    phone: therapist?.phone || '',
    surname: therapist?.surname || '',
    hasZoomAccount: therapist?.videocallEnabled ? ['Zoom Account'] : [],
  };

  const [fileAvatar, setFileAvatar] = useState<FileType>(fileInit);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (therapist) {
      const formData = createFormData(data);

      if (fileAvatar.status === FileTypeStatus.ORIGINAL)
        formData.delete('avatar');
      else formData.set('avatar', fileAvatar.file || '');

      const isCreateZoomAccount = data.hasZoomAccount.length > 0;

      if (isCreateZoomAccount !== therapist?.videocallEnabled) {
        formData.set(
          'create_zoom_account',
          isCreateZoomAccount ? 'true' : 'false',
        );
      }

      const res = await dispatch(updateTherapist(therapist.id, formData));
      if (res) {
        toast.success('The account data has been successfully updated');
        dispatch(getTherapist(therapist.id));
        closeModal();
      }
    }
  };
  const {
    control,
    handleSubmit,
    formState: {isSubmitting, errors, dirtyFields},
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(therapistEditSchema),
    mode: 'onBlur',
  });

  const valuesHaveChanged = () => {
    const currentValues = watch();

    const nameSameValue = therapist?.name
      ? currentValues.name === therapist?.name
      : currentValues.name === '';
    const nicknameSameValue = therapist?.nickname
      ? currentValues.nickname === therapist?.nickname
      : currentValues.nickname === '';
    const emailSameValue = therapist?.email
      ? currentValues.email === therapist?.email
      : currentValues.email === '';
    const phoneSameValue = therapist?.phone
      ? currentValues.phone === therapist?.phone
      : currentValues.phone === '';
    const surnameSameValue = therapist?.surname
      ? currentValues.surname === therapist?.surname
      : currentValues.surname === '';
    const hasZoomAccountSameValue = therapist?.videocallEnabled
      ? currentValues.hasZoomAccount[0] === 'Zoom Account'
      : currentValues.hasZoomAccount.length === 0;
    const avatarSameValue = !dirtyFields.avatar;

    return (
      avatarSameValue &&
      emailSameValue &&
      nicknameSameValue &&
      nameSameValue &&
      phoneSameValue &&
      surnameSameValue &&
      hasZoomAccountSameValue
    );
  };

  return {
    control,
    hasErrors: Object.keys(errors).length > 0,
    isSubmitting,
    setFileAvatar,
    urlAvatarFile: therapist?.avatar?.url,
    onSubmit: handleSubmit(onSubmit),
    valuesHaveChanged,
  };
};
