import React from 'react';

import {useController} from 'react-hook-form';

import {Autocomplete} from '@mui/material';

import {ErrorText} from './ErrorText';
import {BaseControlledComponentProps} from './type';
import {CustomTextField} from './StylesFormControlled';

interface Props extends BaseControlledComponentProps {
  options: string[];
  setField?: (field: string) => void;
}

export const SelectAutocomplete = ({
  control,
  name,
  label,
  labelError,
  options,
  placeholder,
  setField,
}: Props) => {
  const {field, fieldState} = useController({control, name});

  const isInvalid = fieldState.invalid;

  return (
    <div className="mb-3 min-h-12 w-full">
      <label className="mb-1 block">
        {isInvalid && labelError ? labelError : label}
      </label>
      <Autocomplete
        disablePortal
        options={options}
        sx={{width: '100%'}}
        value={field.value ? field.value : null}
        onChange={(_, newValue) => {
          field.onChange(newValue ? newValue : '');
          if (setField) setField(newValue ? newValue : '');
        }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            error={isInvalid}
            inputProps={{
              ...params.inputProps,
            }}
            autoComplete="off"
            size="small"
            style={{width: '100%'}}
            placeholder={placeholder}
          />
        )}
      />

      {fieldState.error?.message && (
        <ErrorText error={fieldState.error?.message} size="small" />
      )}
    </div>
  );
};
