import React, {useState} from 'react';

import {Form} from 'react-bootstrap';
import toast from 'react-hot-toast';

import {updateSubscriptionNotes} from 'src/state/action_creators/subscriptionsCreator';
import {getPatient} from 'src/state/action_creators/patientCreator';
import {useSubscription} from 'src/utils/subscriptions-provider';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {Loading} from 'src/components/shared/Loading';
import {useAppDispatch} from 'src/hooks/hooks';
import {Patient} from 'src/types/Patient';

interface FormValues {
  note: string;
}

export const UpdateSubscriptionNotesModal = ({patient}: {patient: Patient}) => {
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState<FormValues>({
    note: patient.currentSubscription?.note || '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {isModalEditSubscriptionNotes, setIsModalUpdateSubscriptionNotes} =
    useSubscription();

  const closeModal = () => setIsModalUpdateSubscriptionNotes(false);

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    const updateNoteData = {
      id: patient.id,
      sub_id: patient.currentSubscription?.id,
      note: formValues.note,
    };
    dispatch(updateSubscriptionNotes(updateNoteData))
      .then((isSuccess) => {
        if (isSuccess) {
          toast.success('Notes updated successfully');
          dispatch(getPatient(patient.id!));
          closeModal();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const isSubmitDisabled = () =>
    !formValues.note || patient.currentSubscription?.note === formValues.note;

  return (
    <ModalLayout
      show={isModalEditSubscriptionNotes}
      closeModal={closeModal}
      id="extendSubscriptionModal"
      className="modal-height-auto">
      {patient ? (
        <form className="allInputsBlock container" onSubmit={handleSubmitForm}>
          <ModalLayout.Header>
            <h3>Update subscription notes</h3>
          </ModalLayout.Header>
          <ModalLayout.Body isExtendModal={true}>
            <Form.Group>
              <Form.Label>Notes</Form.Label>
              <textarea
                name="note"
                autoComplete="off"
                value={formValues.note}
                onChange={({target}) => {
                  setFormValues({
                    ...formValues,
                    [target.name]: target.value,
                  });
                }}
              />
            </Form.Group>
          </ModalLayout.Body>
          <ModalLayout.Footer>
            <ButtonSubmit
              loadingSubmit={isSubmitting}
              text="Confirm"
              disabled={isSubmitDisabled()}
            />
          </ModalLayout.Footer>
        </form>
      ) : (
        <Loading />
      )}
    </ModalLayout>
  );
};
