import {useState, useCallback, useEffect} from 'react';

import {Column} from 'src/components/tables/types/Column';

export function useGenericColumnsForm<T>(
  allColumns: Column<T>[],
  initialValues: Column<T>[],
  appliedFilters: (keyof T)[],
) {
  const [visibleColumns, setVisibleColumns] =
    useState<Column<T>[]>(initialValues);

  const toggleColumnVisibility = useCallback((columnKey: Column<T>) => {
    setVisibleColumns((prev) =>
      prev.includes(columnKey)
        ? prev.filter((key) => key !== columnKey)
        : [...prev, columnKey],
    );
  }, []);

  const resetVisibleColumns = useCallback(() => {
    setVisibleColumns(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (appliedFilters.length !== 0) {
      const filteredColumns = allColumns.filter((column) =>
        appliedFilters.includes(column.key),
      );
      setVisibleColumns((prev) => {
        return [...new Set([...prev, ...filteredColumns])];
      });
    }
  }, [allColumns, initialValues, appliedFilters]);

  return {
    visibleColumns,
    allColumns,
    toggleColumnVisibility,
    resetVisibleColumns,
  };
}
