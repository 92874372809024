import {Column} from 'src/components/tables/types/Column';
import {Event} from 'src/types/Event';

export const columnsToShow: Column<Event>[] = [
  {
    key: 'typeOfEvent',
    name: 'Type of Event',
    sortable: true,
  },
  {
    key: 'createdAt',
    name: 'created at',
    sortable: true,
  },
  {
    key: 'userId',
    name: 'user id',
    sortable: true,
  },
  {
    key: 'userEmail',
    name: 'user email',
    sortable: false,
  },
  {
    key: 'partner',
    name: 'partner',
    sortable: false,
  },
];
