import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {
  DashboardDataParams,
  exportDashboardData,
  getDashboardData,
  getDashboardRegistersData,
  getSelectors,
} from 'src/state/action_creators/dashboardCreator';
import {
  DASHBOARD_PERIOD_ENUM,
  Dashboard,
  DashboardGraphic,
} from 'src/types/Dashboard';
import {CHART_GRAPHIC_OPTIONS} from 'src/constants/GraphicHomeSelectOptions';
import {DateString, adjustTimezone, firstOfMonth} from 'src/helpers/dates';
import ExportSvg from 'src/assets/images/dashboard/icon_export.svg?react';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {ExportModal} from 'src/components/shared/ExportModal';
import {PageHeader} from 'src/components/layout/PageHeader';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {TIMES_SELECTOR} from 'src/constants/Dashboard';
import {ChartOptionsValue} from 'src/types/Graphics';
import {Layout} from 'src/components/layout/Layout';
import Button from 'src/components/buttons/Button';
import {PartnerSelector} from 'src/types/Partner';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {DashboardHeader} from './DashboardHeader';
import {DashboardMain} from './DashboardMain';

interface Props {
  metric: string;
  monthSelected: Date;
  partner: PartnerSelector | null;
  period: DASHBOARD_PERIOD_ENUM;
}

const initValues: Props = {
  partner: null,
  metric: '',
  period: TIMES_SELECTOR[0].value,
  monthSelected: firstOfMonth(),
};

export const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const {partnersListSelector} = useSelector(
    (state: RootState) => state.dashboard,
  );

  const [formValues, setFormValues] = useState(initValues);
  const {partner, metric, period, monthSelected} = formValues;
  const [dropDownOption, setDropDownOption] = useState<ChartOptionsValue>(
    CHART_GRAPHIC_OPTIONS[0].value,
  );

  const [metricList, setMetricList] = useState<FixMeLater>([]);
  const [graphics, setGraphics] = useState<Dashboard | null>(null);
  const [loading, setLoading] = useState(true);
  const [dropDownGraphic, setDropDownGraphic] =
    useState<DashboardGraphic | null>(null);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [allFilterParams, setAllFilterParams] =
    useState<DashboardDataParams | null>(null);

  const setFormValuesFunction = (name: string, value: FixMeLater) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    setLoadingOptions(true);
    if (partner && period) {
      fetchDashboard(partner.id, period, metric, monthSelected);
    }
  };

  useEffect(() => {
    if (partner && period) {
      fetchOptions(
        partner.id,
        period,
        metric,
        adjustTimezone({date: monthSelected}) as DateString,
      );
    }
  }, [dropDownOption]);

  useEffect(() => {
    if (!partnersListSelector) {
      dispatch(getSelectors());
    } else {
      setFormValuesFunction('partner', partnersListSelector[0]);
      fetchDashboard(partnersListSelector[0].id, period, metric, monthSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnersListSelector]);

  function fetchDashboard(
    partnerId: number,
    period: DASHBOARD_PERIOD_ENUM,
    metric: string,
    monthSelected: Date,
  ) {
    const monthSelectedString = adjustTimezone({
      date: monthSelected,
    }) as DateString;
    dispatch(
      getDashboardData({
        metric,
        monthSelected: monthSelectedString,
        period,
        partnerId,
      }),
    ).then((res: Dashboard | undefined) => {
      if (res) {
        setGraphics(res);
        setLoading(false);
      }
    });
    fetchOptions(partnerId, period, metric, monthSelectedString);
    setAllFilterParams({
      partnerId,
      period,
      metric,
      monthSelected,
    });
  }

  function fetchOptions(
    partnerId: number,
    period: DASHBOARD_PERIOD_ENUM,
    metric: string,
    monthSelected: DateString,
  ) {
    dispatch(
      getDashboardRegistersData({
        period,
        partnerId,
        metric,
        registerOption: dropDownOption,
        monthSelected,
      }),
    ).then((res: DashboardGraphic | undefined) => {
      if (res) {
        setDropDownGraphic(res);
        setLoadingOptions(false);
      }
    });
  }

  return (
    <Layout>
      <div className="dashboard-page">
        <PageHeader pageTitle="Dashboard">
          <Button
            buttonType={ButtonType.SIMPLE}
            size="medium"
            onClick={() => setShowExportModal(true)}>
            <ExportSvg className="mr-1" />
            Export csv
          </Button>
        </PageHeader>
        {partnersListSelector && metricList && partner !== null && (
          <DashboardHeader
            partnersListSelector={partnersListSelector}
            partner={partner}
            metric={metric}
            period={period}
            setFormValuesFunction={setFormValuesFunction}
            metricList={metricList}
            setMetricList={setMetricList}
            handleSubmit={handleSubmit}
            loading={loading}
            monthSelected={monthSelected}
          />
        )}
        <hr />
        {dropDownGraphic && (
          <DashboardMain
            period={period}
            graphics={graphics}
            loading={loading}
            dropDownGraphic={dropDownGraphic}
            dropDownOption={dropDownOption}
            setDropDownOption={setDropDownOption}
            loadingOptions={loadingOptions}
            setLoadingOptions={setLoadingOptions}
          />
        )}
      </div>
      {partner && period && (
        <ExportModal
          show={showExportModal}
          setModalOpen={setShowExportModal}
          allFilterParams={{
            ...allFilterParams,
            monthSelected: adjustTimezone({date: monthSelected}),
          }}
          title="Export dashboard data"
          exportReport={exportDashboardData}
        />
      )}
    </Layout>
  );
};
