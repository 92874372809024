import React from 'react';

import {NavLink} from 'react-router-dom';

export const LiNavbar = ({
  name,
  url,
  children,
  isSidebarHidden,
}: {
  children: React.ReactNode;
  isSidebarHidden: boolean;
  name: string;
  url: string;
}) => {
  return (
    <li>
      <NavLink
        to={url}
        end
        className={({isActive}) =>
          `gap-2.5 items-center p-1.25 transition-colors rounded-1 text-blue-primary my-1 flex bg-transparent p-1 duration-300 hover:bg-grey-softDarker ${
            isActive ? 'text-primary font-bold' : ' focus:text-blue-500'
          }`
        }>
        <div className="icon">{children}</div>
        {!isSidebarHidden && <span className="">{name}</span>}
      </NavLink>
    </li>
  );
};
