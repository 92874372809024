import * as Yup from 'yup';

import {blankSpacesRegExp} from 'src/helpers/regularExp';
import {ALLOWED_FILE_FORMATS} from 'src/constants/Common';
import {validateTypeFile} from 'src/helpers/generalFunctions';

export const pillNewSchema = Yup.object({
  areaId: Yup.string().required('You must select an area'),

  typeOfPill: Yup.string().required('You must select a type of pill'),

  header: Yup.string()
    .required('Header is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  title: Yup.string()
    .required('Title is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  logo: Yup.mixed()
    .required('You must upload a file')
    .test(
      'format',
      "File extension not permitted. Make sure it's one of the following: jpg, jpeg, png or gif.",
      (value) => validateTypeFile(value, ALLOWED_FILE_FORMATS),
    ),
  background: Yup.mixed()
    .required('You must upload a file')
    .test(
      'format',
      "File extension not permitted. Make sure it's one of the following: jpg, jpeg, png or gif.",
      (value) => validateTypeFile(value, ALLOWED_FILE_FORMATS),
    ),
  thumbnail: Yup.mixed()
    .required('You must upload a file')
    .test(
      'format',
      "File extension not permitted. Make sure it's one of the following: jpg, jpeg, png or gif.",
      (value) => validateTypeFile(value, ALLOWED_FILE_FORMATS),
    ),
  description: Yup.string().required('Description is required'),
  activePill: Yup.array().min(0).of(Yup.string().required()).required(),
});

export const pillEditSchema = Yup.object({
  areaId: Yup.string().required('You must select an area'),
  typeOfPill: Yup.string().required('You must select a type of pill'),
  locale: Yup.string().required('You must select a locale'),
  header: Yup.string()
    .required('Header is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  title: Yup.string()
    .required('Title is required')
    .matches(blankSpacesRegExp, 'This field cannot contain only blankspaces'),
  description: Yup.string().required('Description is required'),
  activePill: Yup.array().min(0).of(Yup.string().required()).required(),
  logo: Yup.mixed()
    .required('You must upload a file')
    .test(
      'format',
      "File extension not permitted. Make sure it's one of the following: jpg, jpeg, png or gif.",
      (value) => validateTypeFile(value, ALLOWED_FILE_FORMATS),
    ),
  background: Yup.mixed()
    .required('You must upload a file')
    .test(
      'format',
      "File extension not permitted. Make sure it's one of the following: jpg, jpeg, png or gif.",
      (value) => validateTypeFile(value, ALLOWED_FILE_FORMATS),
    ),
  thumbnail: Yup.mixed()
    .required('You must upload a file')
    .test(
      'format',
      "File extension not permitted. Make sure it's one of the following: jpg, jpeg, png or gif.",
      (value) => validateTypeFile(value, ALLOWED_FILE_FORMATS),
    ),
});
