import React from 'react';

import {ModalLayout} from 'src/components/layout/ModalLayout';

export const PreloadingModal = ({show}: {show: boolean}) => {
  return (
    <ModalLayout show={show} className="modalPreloading">
      <div className="preloading__container flex items-center justify-center">
        <div className="push-pop loader">
          <div></div>
          <div></div>
        </div>
        <div className="push-out loader">
          <div></div>
          <div></div>
        </div>
      </div>
    </ModalLayout>
  );
};
