import {Dispatch} from 'react';

import {OperationsDashboardTabEnum} from 'src/constants/TabLists';
import {catchApiError} from 'src/helpers/catchApiError';
import {GetTypeParam} from 'src/helpers/dashboards';
import {fetchWithToken} from 'src/helpers/fetch';
import {toQueryBoolean, toQueryString} from 'src/helpers/generalFunctions';
import {OperationsDashboardActionType} from 'src/state/action_types/OperationsDashboardActionType';
import {
  OperationsDashboardAction,
  SetSelectorsPayload,
} from 'src/state/actions/operationsDashboards';
import {mapApiResponseOperationDashboardTherapist} from 'src/helpers/api_response/operationsDashboard';
import {
  OperationDashboardTherapist,
  OperationDashboardTherapistStorage,
} from 'src/types/OperationsDashboard';

type DispatchResult = OperationsDashboardAction | any;

export const getSelectors = (type: OperationsDashboardTabEnum) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: OperationsDashboardActionType.SET_SELECTORS_LOADING});

    const resp = await fetchWithToken(
      `admin/operation_dashboards/selectors?type=${GetTypeParam(type)}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {
        total_therapists: totalTherapist,
        total_spots: totalSpot,
        continents,
        countries,
        languages,
        specialities,
      }: {
        continents: string[];
        countries: string[];
        languages: string[];
        specialities: string[];
        total_spots: number;
        total_therapists: number;
      } = body;
      const payload: SetSelectorsPayload = {
        totalTherapist,
        totalSpot,
        continents,
        countries,
        languages,
        specialities,
      };
      dispatch({
        type: OperationsDashboardActionType.SET_SELECTORS,
        payload,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};
export const getCountriesForContinent = (
  type: OperationsDashboardTabEnum,
  continent: string,
) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/operation_dashboards/selectors?type=${GetTypeParam(
        type,
      )}&continent=${continent}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {countries} = body;

      dispatch({
        type: OperationsDashboardActionType.SET_COUNTRIES_FOR_CONTINENT,
        payload: countries,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export interface OperationsDashboardDataParams {
  continent?: string | undefined;
  country: string | undefined;
  language: string | undefined;
  page?: number;
  referal?: boolean | '';
  speciality?: string | undefined;
  supervisor?: boolean | '';
  type: OperationsDashboardTabEnum;
}

export const getOperationsDashboardData = ({
  type,
  continent,
  country,
  language,
  speciality,
  referal,
  supervisor,
  page,
}: OperationsDashboardDataParams) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const params =
      type === OperationsDashboardTabEnum.THERAPISTS
        ? `${toQueryString(
            {
              country,
              language,
              speciality,
              page,
            },
            false,
          )}${toQueryBoolean({referal, supervisor})}`
        : `${toQueryString(
            {
              continent,
              country,
              language,
            },
            false,
          )}`;

    const resp = await fetchWithToken(
      `admin/operation_dashboards?type=${GetTypeParam(type)}${params}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      dispatch({
        type:
          type === OperationsDashboardTabEnum.THERAPISTS
            ? OperationsDashboardActionType.SET_TOTAL_THERAPISTS
            : OperationsDashboardActionType.SET_TOTAL_SPOTS,
        payload:
          type === OperationsDashboardTabEnum.THERAPISTS
            ? body.total_therapists
            : body.total_spots,
      });
      if (type === OperationsDashboardTabEnum.THERAPISTS) {
        const therapists: OperationDashboardTherapist[] = body.therapists.map(
          (therapist: OperationDashboardTherapistStorage) =>
            mapApiResponseOperationDashboardTherapist(therapist),
        );
        return therapists;
      }
      return body;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const exportOperationsDashboardData = (
  params: OperationsDashboardDataParams,
) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/operation_dashboards/export`,
      {...params, type: GetTypeParam(params.type)},
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};
