import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {
  ArrowBendRightDown,
  ArrowBendRightUp,
  ArrowSquareOut,
  Funnel,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';

import {
  exportVideoSessions,
  getSelectors,
  getVideoSessions,
} from 'src/state/action_creators/videoSessionCreator';
import {PaginationComponent} from 'src/components/shared/PaginationComponent';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {BADGE_TYPE} from 'src/helpers/set_classes/badgeClasses';
import {ExportModal} from 'src/components/shared/ExportModal';
import {CustomTable} from 'src/components/tables/CustomTable';
import {camelCaseToWords} from 'src/helpers/generalFunctions';
import {PageHeader} from 'src/components/layout/PageHeader';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {NoResults} from 'src/components/tables/NoResults';
import {Loading} from 'src/components/shared/Loading';
import {VideoSession} from 'src/types/VideoSession';
import Button from 'src/components/buttons/Button';
import {Badge} from 'src/components/badge/Badge';
import {useAppDispatch} from 'src/hooks/hooks';
import {formatDate} from 'src/helpers/dates';
import {RBAC} from 'src/helpers/Rbac';
import {RootState} from 'src/state';

import {PopOverFilters} from './components/PopOverFilters';

const initValues = {
  page: 1,
  orderBy: 'id',
  orderDesc: true,
  startTime: {
    checked: false,
    values: {
      start: dayjs().subtract(7, 'days').toDate(),
      end: new Date(),
    },
  },
  validated: {
    checked: false,
    value: false,
  },
  partner: {
    checked: false,
    values: [],
  },
};

export const VideoSessionsTable = ({
  controllerName,
  setOverflowHidden,
}: {
  controllerName: string;
  setOverflowHidden: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch = useAppDispatch();
  const columns = [
    'validated',
    'startTime',
    'metric',
    'patientEmail',
    'partner',
    'patientPlan',
    'therapistId',
  ];
  const columnsReport = [
    'validated',
    'start_time',
    'metric',
    'patient_email',
    'partner',
    'patient_plan',
    'therapist_id',
  ];
  const {status, videoSessions, total, partnersListSelector} = useSelector(
    (state: RootState) => state.videoSessions,
  );
  const [page, setPage] = useState<number>(initValues.page);
  const [orderBy, setOrderBy] = useState<string>(initValues.orderBy);
  const [orderDesc, setOrderDesc] = useState<boolean>(initValues.orderDesc);
  const [filterSelected, setFilterSelected] = useState<FixMeLater>(null);
  const [toggleShowPopOver, setToggleShowPopOver] = useState(false);
  const [startTimeCheck, setStartTimeCheck] = useState(false);
  const [startTimeStart, setStartTimeStart] = useState<Date>(
    initValues.startTime.values.start,
  );
  const [startTimeEnd, setStartTimeEnd] = useState(
    initValues.startTime.values.end,
  );
  const [validatedCheck, setValidatedCheck] = useState(false);
  const [validatedValue, setValidatedValue] = useState<FixMeLater>(
    initValues.validated.value,
  );

  const [fieldsFiltered, setFieldsFiltered] = useState<FixMeLater>([]);
  const [modalExportOpen, setModalExportOpen] = useState(false);
  const [allFilterParams, setAllFilterParams] = useState<FixMeLater>(null);
  const [partnerCheck, setPartnerCheck] = useState(false);
  const [partnerValues, setPartnerValues] = useState([]);

  const handleOrderBy = (newOrderBy: string) => {
    setOrderBy(newOrderBy);
    setOrderDesc(!orderDesc);
  };
  const handleRowClick = () => {
    // TO-DO
    // console.log(`conversation ${id}`);
  };

  const dispatchGetVideoSessions = () => {
    const newFilterParams = {
      page,
      orderBy,
      orderDir: orderDesc ? 'desc' : 'asc',
      start_time: startTimeCheck
        ? {
            from: startTimeStart.toISOString(),
            to: startTimeEnd.toISOString(),
          }
        : null,
      validated: validatedCheck ? validatedValue : null,
      partner: partnerCheck ? partnerValues : null,
    };
    setAllFilterParams(newFilterParams);
    dispatch(getVideoSessions(newFilterParams));
  };

  const handleToggleFilter = () => {
    setToggleShowPopOver(!toggleShowPopOver);
    setOverflowHidden(toggleShowPopOver ? '' : 'overflowHidden');
  };

  const handleExport = () => {
    setModalExportOpen(true);
  };

  useEffect(() => {
    dispatchGetVideoSessions();
    if (!partnersListSelector) {
      dispatch(getSelectors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, orderDesc, filterSelected]);

  useEffect(() => {
    if (filterSelected !== null) {
      const filtersFiltered: FixMeLater = Object.keys(filterSelected).filter(
        (key) => filterSelected[key].checked,
      );
      const fieldFiltersSelected: FixMeLater = [];
      filtersFiltered.forEach((f: FixMeLater) => {
        fieldFiltersSelected.push(filterSelected[f].field);
      });
      setFieldsFiltered(fieldFiltersSelected);
    }
  }, [filterSelected]);

  return status === SharedActionType.LOADING ? (
    <Loading />
  ) : (
    <div>
      <PageHeader pageTitle="Video Sessions">
        <div className="flex">
          <div className="popover__container">
            <Button
              buttonType={ButtonType.WHITE}
              size="small"
              className={`${filterSelected ? 'active' : ''}`}
              onClick={handleToggleFilter}>
              <Funnel size={18} className="mr-1" /> Filter
              {filterSelected && (
                <p>
                  |{' '}
                  <span>
                    {
                      Object.keys(filterSelected).filter(
                        (key) => filterSelected[key].checked,
                      ).length
                    }
                  </span>
                </p>
              )}
            </Button>

            <PopOverFilters
              show={toggleShowPopOver}
              initValues={initValues}
              setPage={setPage}
              setOrderBy={setOrderBy}
              setOrderDesc={setOrderDesc}
              handleToggleFilter={handleToggleFilter}
              filterSelected={filterSelected}
              setFilterSelected={setFilterSelected}
              startTimeCheck={startTimeCheck}
              setStartTimeCheck={setStartTimeCheck}
              startTimeStart={startTimeStart}
              setStartTimeStart={setStartTimeStart}
              startTimeEnd={startTimeEnd}
              setStartTimeEnd={setStartTimeEnd}
              validatedCheck={validatedCheck}
              setValidatedCheck={setValidatedCheck}
              validatedValue={validatedValue}
              setValidatedValue={setValidatedValue}
              partnerCheck={partnerCheck}
              setPartnerCheck={setPartnerCheck}
              partnerValues={partnerValues}
              setPartnerValues={setPartnerValues}
            />
            {toggleShowPopOver && (
              <div
                className="overlay-popover"
                onClick={handleToggleFilter}></div>
            )}
          </div>
          <RBAC controllerName={controllerName} actionName="export">
            {videoSessions &&
              filterSelected &&
              videoSessions.length < 10000 && (
                <Button
                  buttonType={ButtonType.WHITE}
                  size="small"
                  onClick={handleExport}>
                  <ArrowSquareOut size={18} className="mr-1" /> Export
                </Button>
              )}
          </RBAC>
        </div>
      </PageHeader>
      {videoSessions && (
        <>
          {total > 0 ? (
            <CustomTable
              headChildren={
                <tr>
                  {columns.map((c, index) => (
                    <th
                      key={index}
                      className={`${c === 'status' || 'cursor-pointer'} ${
                        filterSelected && fieldsFiltered.includes(c)
                          ? 'text-active'
                          : ''
                      }`}
                      onClick={() => handleOrderBy(c)}>
                      <div className="flex justify-start">
                        {filterSelected && fieldsFiltered.includes(c) && (
                          <Funnel size={18} />
                        )}
                        {camelCaseToWords(c)}
                        {orderBy === c &&
                          (orderDesc ? (
                            <ArrowBendRightDown size={10} />
                          ) : (
                            <ArrowBendRightUp size={10} />
                          ))}
                      </div>
                    </th>
                  ))}
                </tr>
              }
              bodyChildren={videoSessions.map(
                (videoSession: VideoSession, index: number) => (
                  <tr
                    className="cursor-pointer"
                    key={index}
                    onClick={() => handleRowClick()}>
                    {columns.map((column, index) => {
                      switch (column) {
                        case 'validated':
                          return (
                            <td key={index}>
                              <Badge
                                badgeType={
                                  videoSession.validated
                                    ? BADGE_TYPE.SUCCESS
                                    : BADGE_TYPE.BUTTON
                                }>
                                {videoSession.validated
                                  ? 'Validated'
                                  : 'Pending validation'}
                              </Badge>
                            </td>
                          );
                        case 'startTime':
                          return (
                            <td key={index}>
                              {formatDate(videoSession.startTime)}
                            </td>
                          );
                        default:
                          return (
                            <td key={index}>
                              {videoSession[column as keyof VideoSession]}
                            </td>
                          );
                      }
                    })}
                  </tr>
                ),
              )}
            />
          ) : (
            <NoResults />
          )}
        </>
      )}

      <PaginationComponent total={total} page={page} setPage={setPage} />
      {videoSessions && filterSelected && videoSessions.length < 10000 && (
        <ExportModal
          show={modalExportOpen}
          setModalOpen={setModalExportOpen}
          allFilterParams={allFilterParams}
          columnsVisible={columnsReport}
          title="Export video sessions"
          exportReport={exportVideoSessions}
        />
      )}
    </div>
  );
};
