import React from 'react';

import {useSelector} from 'react-redux';

import {Autocomplete, TextField} from '@mui/material';

import {GenericFiltersToggle} from 'src/components/generic_table/filters/GenericFiltersToggle';
import {capitalizeFirstLetter} from 'src/helpers/generalFunctions';
import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';
import {Pill, PillArea} from 'src/types/Pill';
import {RootState} from 'src/state';

import {FormValuesPill} from '../../PillsTable';

export const AreaFilter = ({
  formValues,
  updateFormValue,
}: {
  formValues: GenericTableProps<Pill, FormValuesPill>['formValues'];
  updateFormValue: <
    K extends keyof GenericTableProps<Pill, FormValuesPill>['formValues'],
  >(
    field: K,
    subField: keyof GenericTableProps<Pill, FormValuesPill>['formValues'][K],
    value: any,
  ) => void;
}) => {
  const {pillsOptions} = useSelector((state: RootState) => state.pills);

  return (
    <GenericFiltersToggle
      checked={formValues.area_id?.checked}
      onChange={() =>
        updateFormValue('area_id', 'checked', !formValues.area_id.checked)
      }
      label="Area">
      {formValues.area_id?.checked && (
        <div className=" bg-grey-soft p-3">
          <Autocomplete
            id="area-selector"
            options={pillsOptions.areas}
            size="small"
            getOptionLabel={(option: PillArea) =>
              capitalizeFirstLetter(option.title)
            }
            value={
              pillsOptions?.areas?.find(
                (option: PillArea) => option.id === formValues.area_id.value,
              ) || null
            }
            onChange={(event, newValue) => {
              updateFormValue('area_id', 'value', newValue?.id || null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '& input': {
                    height: '24px',
                    width: 'auto',
                    flex: '1 1 auto',
                    fontSize: '13px',
                    padding: '0px 7px !important',
                    borderRadius: '4px !important',
                  },
                  '& legend': {display: 'none'},
                  '& fieldset': {top: 0},
                }}
              />
            )}
          />
        </div>
      )}
    </GenericFiltersToggle>
  );
};
