import React from 'react';

import {CustomTable} from 'src/components/tables/CustomTable';
import {SPECIALITY_LEVELS} from 'src/types/Therapist';

import {TherapistSpecialitiesEditModalProps} from '../../TherapistSpecialitiesEditModal';

import {SpecialityRow} from './SpecialityRow';
import {NewSpecialityInputField} from './NewSpecialityInputField';

export const TableSpecialities = ({
  title,
  specialities,
  handleCheckboxChange,
  handleDeleteSpeciality,
  isOthersSpecialities,
  setSpecialitiesTableOther,
}: {
  handleCheckboxChange: (speciality: string, levelOption: string) => void;
  handleDeleteSpeciality: (speciality: string) => void;
  isOthersSpecialities: boolean;
  setSpecialitiesTableOther?: React.Dispatch<
    React.SetStateAction<
      TherapistSpecialitiesEditModalProps['specialitiesTableOther']
    >
  >;
  specialities:
    | TherapistSpecialitiesEditModalProps['specialitiesTable']
    | TherapistSpecialitiesEditModalProps['specialitiesTableOther'];
  title: string;
}) => {
  return (
    <div>
      <h4 className="flex justify-start">{title}</h4>
      <CustomTable
        className="border bg-white shadow-specialitiesTable"
        headChildren={
          <tr className="border-b-3 group border-table-border">
            <th className="min-w-26">Speciality</th>
            {SPECIALITY_LEVELS.map((specialityLevel, index: number) => (
              <th key={index + specialityLevel.text}>{specialityLevel.text}</th>
            ))}
            {isOthersSpecialities && <th className="w-2.5"></th>}
          </tr>
        }
        bodyChildren={specialities.map((value, index: number) => (
          <SpecialityRow
            key={index}
            speciality={value}
            handleCheckboxChange={handleCheckboxChange}
            isOthersSpecialities={isOthersSpecialities}
            handleDeleteSpeciality={handleDeleteSpeciality}
          />
        ))}
      />
      {isOthersSpecialities && setSpecialitiesTableOther && (
        <NewSpecialityInputField
          specialitiesTableOther={specialities}
          setSpecialitiesTableOther={setSpecialitiesTableOther}
        />
      )}
    </div>
  );
};
