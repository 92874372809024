import React from 'react';

import {Prohibit} from '@phosphor-icons/react';

import {getStatusPatient} from 'src/helpers/getStatusPatient';
import {PatientStatus} from 'src/types/Patient';

export const PatientProgressSteps = ({
  patientStatus,
  deleted,
}: {
  deleted: boolean;
  patientStatus: number | null;
}) => {
  function getStatusProgress(patientStatus: number | null) {
    switch (patientStatus) {
      case PatientStatus.UNDER_AGE:
        return (
          <>
            <p className="badge-text error">
              <Prohibit size={20} /> Under Age
            </p>
          </>
        );

      default:
        return (
          <ul className="progressbar div-5">
            {[...Array(5)].map((x, i) => (
              <li
                key={i}
                className={
                  patientStatus && patientStatus >= i + 1 ? 'active' : ''
                }>
                {getStatusPatient(i + 1)}
              </li>
            ))}
          </ul>
        );
    }
  }
  return (
    <div className="progressbar__container">
      {deleted ? (
        <p className="badge-text badge-deleted error">
          <Prohibit size={20} /> Deleted
        </p>
      ) : (
        getStatusProgress(patientStatus)
      )}
    </div>
  );
};
