import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import {Form} from 'react-bootstrap';
import toast from 'react-hot-toast';

import {cancelSubscription} from 'src/state/action_creators/subscriptionsCreator';
import {getPatient} from 'src/state/action_creators/patientCreator';
import {useSubscription} from 'src/utils/subscriptions-provider';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {Loading} from 'src/components/shared/Loading';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

export const CancelSubscriptionModal = () => {
  const dispatch = useAppDispatch();
  const {patient} = useSelector((state: RootState) => state.patient);
  const [atPeriodEnd, setAtPeriodEnd] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const {
    isModalCancelSubscriptionOpen,
    setIsModalCancelSubscriptionOpen,
    isSetToCancel,
  } = useSubscription();

  useEffect(() => {
    if (isModalCancelSubscriptionOpen) setAtPeriodEnd(true);
  }, [isModalCancelSubscriptionOpen]);
  const closeModal = () => setIsModalCancelSubscriptionOpen(false);

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadingSubmit(true);
    if (patient) {
      const data: FixMeLater = {
        id: patient.id,
      };
      if (!atPeriodEnd) data['end'] = 'at_period_end';

      dispatch(cancelSubscription(data)).then((res) => {
        if (res) {
          toast.success('Subscription cancelled successfully');
          dispatch(getPatient(patient.id));
          closeModal();
        }
        setLoadingSubmit(false);
      });
    }
  };

  return (
    <ModalLayout
      show={isModalCancelSubscriptionOpen}
      closeModal={closeModal}
      className="modal-height-auto">
      {patient ? (
        <form className="allInputsBlock container" onSubmit={handleSubmitForm}>
          <ModalLayout.Header>
            <h3>Cancel subscription</h3>
          </ModalLayout.Header>
          <ModalLayout.Body>
            <div className="form-group">
              <label>Current plan</label>
              <input
                type="text"
                disabled={true}
                name="current_plan"
                autoComplete="off"
                value={patient.planName || ''}
              />
            </div>
            <div className="form-group">
              <label>Cancel subscription</label>
              <Form.Check
                checked={atPeriodEnd}
                onChange={() => setAtPeriodEnd(true)}
                label="Immediately"
                name="atPeriodEnd"
                type="radio"
                id="inline-radio-1"
              />
              {!isSetToCancel && (
                <Form.Check
                  checked={!atPeriodEnd}
                  onChange={() => setAtPeriodEnd(false)}
                  label="End of the current period"
                  name="atPeriodEnd"
                  type="radio"
                  id="inline-radio-2"
                />
              )}
            </div>
          </ModalLayout.Body>
          <ModalLayout.Footer>
            <ButtonSubmit loadingSubmit={loadingSubmit} text="Confirm" />
          </ModalLayout.Footer>
        </form>
      ) : (
        <Loading />
      )}
    </ModalLayout>
  );
};
