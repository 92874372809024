import {QueryData, toQueryString} from '@ifeelonline/chat-core';

import {mapApiResponseIncomingPatients} from 'src/helpers/api_response/incoming_patients';
import {
  IncomingPatient,
  IncomingPatientStorage,
} from 'src/types/IncomingPatient';
import {PsychologistGuideForSelector} from 'src/types/PsychologistGuide';
import {fetchWithToken} from 'src/helpers/fetch';

class IncomingPatientsRepository {
  async get(
    params: QueryData,
  ): Promise<{items: IncomingPatient[]; total: number} | null> {
    const response = await fetchWithToken(
      `admin/incoming_patients?${toQueryString(params)}`,
      null,
    );

    const body = await response?.json();

    if (!body || !body.success) return null;
    const {
      patients,
      total_count,
    }: {patients: IncomingPatientStorage[]; total_count: number} = body;

    const incomingPatients: IncomingPatient[] =
      mapApiResponseIncomingPatients(patients);

    return {
      items: incomingPatients,
      total: total_count,
    };
  }

  async setPsychologistGuideSelector(): Promise<
    PsychologistGuideForSelector[] | null
  > {
    const response = await fetchWithToken(
      `admin/incoming_patients/psychologist_guide_selector`,
      null,
    );
    const body = await response?.json();
    if (!body || !body.success) return null;

    const {psychologist_guides} = body;
    return psychologist_guides;
  }

  async update(
    patientId: number,
    psychologistGuideId: number,
    appointmentDate?: string,
  ) {
    const data: {
      appointment?: string;
      patient_id: number;
      psychologist_guide_id: number;
    } = {
      patient_id: patientId,
      psychologist_guide_id: psychologistGuideId,
    };
    if (appointmentDate) data.appointment = appointmentDate;

    const response = await fetchWithToken(
      `admin/incoming_patients/${patientId}`,
      data,
      'PUT',
    );
    const body = await response?.json();
    if (!body || !body.success) return null;
    return body;
  }

  async export(params: QueryData) {
    const response = await fetchWithToken(
      `admin/incoming_patients/export?${toQueryString(params)}`,
      null,
      'POST',
    );
    const body = await response?.json();
    if (!body || !body.success) return null;
    return body;
  }
}

export default new IncomingPatientsRepository();
