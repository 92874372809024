import React, {ReactNode} from 'react';

import {
  ArrowBendRightDown,
  ArrowBendRightUp,
  ArrowSquareOut,
  Columns,
  Funnel,
  Plus,
} from '@phosphor-icons/react';

import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import Button from 'src/components/buttons/Button';
import {RBAC} from 'src/helpers/Rbac';

import {
  PaginationComponent,
  PaginationComponentProps,
} from '../shared/PaginationComponent';
import {PageHeader} from '../layout/PageHeader';
import {Column} from '../tables/types/Column';
import {NoResults} from '../tables/NoResults';
import {Order} from '../tables/types/Order';
import {Loading} from '../shared/Loading';

export const GenericTable = ({
  children,
}: React.ComponentPropsWithoutRef<'div'>) => {
  return <div>{children}</div>;
};

interface GenericTableHeaderProps<T>
  extends React.ComponentPropsWithoutRef<'div'> {
  controllerName?: string;
  data: {items: T[]; total: number};
  filtersSelected: (keyof T)[];
  handleExport?: () => void;
  handleNew?: () => void;
  handleToggleColumnPopOver?: () => void;
  handleToggleFilter: () => void;
  isColumnPopOverOpen?: boolean;
  isFilterPopOverOpen: boolean;
  newButtonText?: string;
  pageTitle: string;
}

const GenericTableHeader = <T,>({
  filtersSelected,
  handleToggleFilter,
  isFilterPopOverOpen,
  handleToggleColumnPopOver,
  isColumnPopOverOpen,
  data,
  controllerName,
  children,
  handleExport,
  handleNew,
  newButtonText,
  pageTitle,
}: GenericTableHeaderProps<T>) => (
  <PageHeader pageTitle={pageTitle}>
    <div className="flex">
      {handleNew && newButtonText && (
        <Button buttonType={ButtonType.SAVE} size="small" onClick={handleNew}>
          <Plus size={18} className="mr-1" /> {`Add ${newButtonText}`}
        </Button>
      )}
      {handleToggleColumnPopOver !== undefined && (
        <div className="popover__column_container">
          <Button
            buttonType={ButtonType.WHITE}
            size="small"
            onClick={handleToggleColumnPopOver}>
            <Columns size={18} className="mr-1" /> Columns
          </Button>

          {children}
          {isColumnPopOverOpen && (
            <div
              className="overlay-popover"
              onClick={() => handleToggleColumnPopOver()}></div>
          )}
        </div>
      )}
      <div className="popover__filter_container">
        <Button
          buttonType={ButtonType.WHITE}
          size="small"
          className={`${filtersSelected.length > 0 ? 'active' : ''}`}
          onClick={handleToggleFilter}>
          <Funnel size={18} className="mr-1" /> Filter
          {filtersSelected.length > 0 && (
            <p>
              | <span>{filtersSelected.length}</span>
            </p>
          )}
        </Button>

        {children}
        {isFilterPopOverOpen && (
          <div
            className="overlay-popover"
            onClick={() => handleToggleFilter()}></div>
        )}
      </div>
      {data &&
        filtersSelected.length > 0 &&
        data.items.length < 10000 &&
        handleExport &&
        controllerName && (
          <RBAC controllerName={controllerName} actionName="export">
            <Button
              buttonType={ButtonType.WHITE}
              size="small"
              onClick={handleExport}>
              <ArrowSquareOut size={18} className="mr-1" /> Export
            </Button>
          </RBAC>
        )}
    </div>
  </PageHeader>
);

interface GenericTableBodyProps<T>
  extends React.ComponentPropsWithoutRef<'div'> {
  data: T[];
  dataTestId?: string;
  isLoading: boolean;
}

export const GenericTableBody = <T,>({
  children,
  isLoading,
  dataTestId,
  data,
}: GenericTableBodyProps<T>) => {
  if (isLoading) return <Loading />;

  return data && data.length > 0 ? (
    <table className="table-custom" data-testid={dataTestId ?? undefined}>
      {children}
    </table>
  ) : (
    <NoResults />
  );
};

interface GenericTableBodyHeadProps<T> {
  columns: Column<T>[];
  filtersSelected?: string[];
  handleSetOrder?: (newOrder: Order<T>) => void;
  order?: Order<T>;
}

const GenericTableBodyHead = <T,>({
  columns,
  filtersSelected,
  handleSetOrder,
  order,
}: GenericTableBodyHeadProps<T>) => {
  const handleColumnClick = (columnKey: keyof T) => {
    if (handleSetOrder) {
      const isCurrentColumn = order?.by === columnKey;
      const newDirection =
        isCurrentColumn && order.direction === 'asc' ? 'desc' : 'asc';
      handleSetOrder({
        by: columnKey,
        direction: newDirection,
      });
    }
  };
  return (
    <thead className="table-head border-b-text">
      <tr role="row-head">
        {columns.map((column, index) => (
          <th
            key={`thead-${index}`}
            role="columnheader"
            className={`${
              filtersSelected && filtersSelected.includes(column.key as string)
                ? 'text-active'
                : ''
            } ${column.sortable ? 'cursor-pointer' : ''}`}
            onClick={() => {
              if (column.sortable) handleColumnClick(column.key);
            }}>
            {filtersSelected &&
              filtersSelected.includes(column.key as string) && (
                <Funnel size={18} />
              )}
            {column.name}
            {order?.by === column.key &&
              (order.direction === 'desc' ? (
                <ArrowBendRightDown size={10} />
              ) : (
                <ArrowBendRightUp size={10} />
              ))}
          </th>
        ))}
      </tr>
    </thead>
  );
};

interface GenericTableBodyMainProps<T> {
  columns: Column<T>[];
  data: T[];
  renderCell: (column: Column<T>, item: T) => ReactNode;
}

const GenericTableBodyMain = <T,>({
  data,
  columns,
  renderCell,
}: GenericTableBodyMainProps<T>) => (
  <tbody>
    {data.map((item, index) => (
      <tr key={`row-${index}`} role="row-body">
        {columns.map((column) => (
          <td key={`row-${String(column.key)}-${index}`}>
            {renderCell(column, item)}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

const GenericTableFooter = ({
  total,
  page,
  setPage,
}: PaginationComponentProps) => (
  <PaginationComponent total={total} page={page} setPage={setPage} />
);

GenericTable.Header = GenericTableHeader;
GenericTable.Body = GenericTableBody;
GenericTable.Footer = GenericTableFooter;
GenericTableBody.Head = GenericTableBodyHead;
GenericTableBody.Main = GenericTableBodyMain;

export default GenericTable;
