import React, {useEffect, useRef, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';

import {SharedActionType} from '@ifeelonline/chat-core';

import {CalendarBlank} from '@phosphor-icons/react';
import dayjs from 'dayjs';

import {getRecentActivity} from 'src/state/action_creators/eventsCreator';
import ButtonLoadMore from 'src/components/buttons/ButtonLoadMore';
import {parseUnderscore} from 'src/helpers/generalFunctions';
import {PageHeader} from 'src/components/layout/PageHeader';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {PageTitle} from 'src/components/layout/PageTitle';
import {Loading} from 'src/components/shared/Loading';
import {Layout} from 'src/components/layout/Layout';
import {datesAreOnSameDay} from 'src/helpers/dates';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

type Params = {
  id: string;
};

export const RecentActivityPage = () => {
  const {id} = useParams<Params>();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {status, recentActivity} = useSelector(
    (state: RootState) => state.events,
  );
  const [userNickname, setUserNickname] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const eventsEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    eventsEndRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  const handleGoToPatientProfile = () => navigate(`/patient/${id}`);

  useEffect(() => {
    dispatch(getRecentActivity(parseInt(id!), 1)).then((res: string) =>
      setUserNickname(res),
    );
  }, [dispatch, id]);

  useEffect(() => {
    if (page > 1) {
      setLoadingMore(true);
      dispatch(getRecentActivity(parseInt(id!), page)).then((res) => {
        if (res > 0) {
          setLoadingMore(false);
          scrollToBottom();
        } else {
          setLoadingMore(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Layout>
      {status === SharedActionType.LOADING ? (
        <Loading />
      ) : (
        <div className="user__section user__recent_activity">
          <PageHeader>
            <PageTitle>
              Recent activity -{' '}
              <span
                className="btn btn-simple"
                onClick={(e) => {
                  e.preventDefault();
                  handleGoToPatientProfile();
                }}>
                {userNickname}
              </span>
            </PageTitle>
          </PageHeader>
          {recentActivity && (
            <div className="recent_activity_container">
              {recentActivity.length > 0 ? (
                <>
                  {recentActivity.map((event: FixMeLater, index: number) => (
                    <div key={`box_${index}`}>
                      {(index === 0 ||
                        !datesAreOnSameDay(
                          new Date(recentActivity[index].createdAt),
                          new Date(recentActivity[index - 1].createdAt),
                        )) && (
                        <>
                          <h3 key={index}>
                            <CalendarBlank size={18} weight="bold" />{' '}
                            {dayjs(event.createdAt).format('LL')}
                          </h3>
                        </>
                      )}

                      <div
                        key={`box_${index}`}
                        className="event_row flex justify-start">
                        <span className="date">
                          {dayjs(event.createdAt).format('LTS')}
                        </span>
                        <p>{parseUnderscore(event.typeOfEvent)}</p>
                      </div>
                    </div>
                  ))}
                  {recentActivity.length === 25 && loadingMore !== null && (
                    <ButtonLoadMore
                      loadingMore={loadingMore}
                      setPage={setPage}
                      page={page}
                    />
                  )}
                  <div ref={eventsEndRef} />
                </>
              ) : (
                <p>No events saved for this user yet</p>
              )}
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};
