import React from 'react';

import {Warning} from '@phosphor-icons/react';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {Form} from 'src/components/form/Form';

import {useEditTherapistAccountForm} from '../hooks/useEditTherapistAccountForm';
import FormInputLoading from '../../../../components/form/FormInputLoading';

interface Props {
  closeModal: () => void;
}

export const EditTherapistAccountForm = ({closeModal}: Props) => {
  const {
    control,
    onSubmit,
    isSubmitting,
    hasErrors,
    accountSettings,
    cities,
    isLoadingCities,
    setFileAvatar,
    orientationOthers,
    setOrientationOthers,
    uploadFile,
    isLoading,
    setValue,
    isRoleAllowed,
    valuesHaveChanged,
  } = useEditTherapistAccountForm({
    closeModal,
  });

  return (
    <Form
      onSubmit={onSubmit}
      className="flex size-full flex-col justify-between ">
      <ModalLayout.Header>
        <h3>Edit Account</h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        <Form.CheckboxGroup
          control={control}
          name="disable"
          options={['Disabled']}
          label=""
        />
        <Form.InputText
          control={control}
          name="initials"
          label="Ref"
          placeholder="Enter your ref"
        />
        <Form.DatePicker
          control={control}
          name="birthday"
          label="Birthday"
          maxDate={new Date()}
        />
        <Form.DatePicker
          control={control}
          name="incorporatedAt"
          label="Incorporation"
        />

        <Form.SelectChip
          control={control}
          setValue={setValue}
          tooltipText="You can select more than one"
          placeholder="Select Main Language"
          name="mainLanguage"
          label="Languages: Mains"
          options={
            accountSettings
              ? Object.values(accountSettings.languages.main.sort())
              : []
          }
        />
        <Form.SelectChip
          control={control}
          setValue={setValue}
          name="extraLanguage"
          label="Languages: Extra"
          placeholder="Select Extra Languages"
          tooltipText="You can select more than one"
          options={
            accountSettings
              ? Object.values(accountSettings.languages.extras.sort())
              : []
          }
        />
        <Form.DatePicker
          control={control}
          name="experienceStartedAt"
          label="Experience Started At"
          maxDate={new Date()}
        />
        <Form.SelectAutocomplete
          control={control}
          name="nationality"
          label="Nationality"
          options={accountSettings ? accountSettings.countries : []}
          placeholder="Select Nationality"
        />
        <Form.SelectAutocomplete
          control={control}
          name="country"
          label="Country"
          options={accountSettings ? accountSettings.countries : []}
          placeholder="Select Country"
        />
        {isLoadingCities ? (
          <FormInputLoading />
        ) : (
          <Form.SelectAutocomplete
            control={control}
            name="city"
            label="City"
            options={cities}
            placeholder="Select City"
          />
        )}

        <Form.SelectAutocomplete
          control={control}
          name="collaboration"
          label="Collaboration"
          options={accountSettings?.collaborations || []}
          placeholder="Select Collaboration"
        />

        <Form.InputText
          control={control}
          name="school"
          label="School"
          placeholder="Enter your school"
        />

        <Form.InputFile
          control={control}
          name="avatar"
          label="School avatar"
          shouldShowPreview={true}
          setFile={setFileAvatar}
          urlFile={uploadFile || undefined}
          isLoading={isLoading}
        />

        <Form.InputText
          control={control}
          name="license"
          label="License"
          placeholder="Enter your license"
        />

        <Form.SelectAutocomplete
          control={control}
          name="gender"
          label="gender"
          options={accountSettings ? Object.values(accountSettings.gender) : []}
          placeholder="Select gender"
        />

        <Form.CheckboxGroup
          control={control}
          name="partnerConfidentiality"
          options={['Partner confidentiality']}
          label=""
        />

        <Form.SelectChip
          control={control}
          name="orientation"
          label="Orientation: Mains"
          tooltipText="You can select more than one"
          placeholder="Select Orientation"
          options={
            accountSettings ? Object.values(accountSettings.orientation) : []
          }
          setValue={setValue}
        />
        <Form.InputChip
          control={control}
          name="orientationOthers"
          label="Orientation: Others"
          tooltipText="You can select more than one. Only English words"
          setValue={setValue}
          selectedValues={orientationOthers}
          setSelectedValues={setOrientationOthers}
        />

        {isRoleAllowed && (
          <>
            <Form.SelectAutocomplete
              control={control}
              name="fee"
              label="Fee"
              options={accountSettings?.fees || []}
              placeholder="Select Fee"
            />

            <Form.SelectAutocomplete
              control={control}
              name="billingRole"
              label="Billing role"
              options={accountSettings?.billingRoles || []}
              placeholder="Select billing role"
            />
          </>
        )}

        <Form.InputTextArea
          control={control}
          name="note"
          label="Notes"
          placeholder="Write Notes"
        />
      </ModalLayout.Body>
      <ModalLayout.Footer>
        {hasErrors && <Warning color="red" />}
        <ButtonSubmit
          loadingSubmit={isSubmitting}
          disabled={valuesHaveChanged()}
        />
      </ModalLayout.Footer>
    </Form>
  );
};
