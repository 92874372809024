import React from 'react';

import {useSelector} from 'react-redux';

import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';

import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {RootState} from 'src/state';

export default function LocaleSelector({
  locale,
  handleChangeLocale,
  showDefaultItem = true,
}: {
  handleChangeLocale: FixMeLater;
  locale: string;
  showDefaultItem?: boolean;
}) {
  const {languages} = useSelector((state: RootState) => state.settings);
  return (
    <FormControl style={{minWidth: 120, height: 36}}>
      <InputLabel id="locale-selector">Language</InputLabel>
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        name="locale"
        value={locale}
        onChange={handleChangeLocale}
        label="Locale">
        {showDefaultItem && <MenuItem value="">-</MenuItem>}
        {languages &&
          languages.map((language, i) => (
            <MenuItem key={i} value={language.locale}>
              {language.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
