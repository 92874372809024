import React, {useState} from 'react';

import {useController} from 'react-hook-form';

import {Eye, EyeDisabledHidden2} from '@ifeelonline/icons';

import {IconButton, InputAdornment} from '@mui/material';

import {ErrorText} from './ErrorText';
import {BaseControlledComponentProps} from './type';
import {CustomTextField} from './StylesFormControlled';

export const InputPassword = ({
  control,
  name,
  label,
  labelError,
  placeholder,
}: BaseControlledComponentProps) => {
  const [show, setShow] = useState(false);

  const {field, fieldState} = useController({
    control,
    name,
  });

  const isInvalid = fieldState.invalid;

  return (
    <div className="mb-3 min-h-12">
      <label className="mb-1 block">
        {isInvalid && labelError ? labelError : label}
      </label>
      <CustomTextField
        error={isInvalid}
        InputProps={{
          type: show ? 'text' : 'password',
          placeholder,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShow(!show);
                }}
                edge="end">
                <div
                  className={`flex h-4 ${
                    isInvalid && 'text-brandSecondary-700'
                  }`}>
                  {show ? (
                    <Eye fullHeight />
                  ) : (
                    <EyeDisabledHidden2 fullHeight />
                  )}
                </div>
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          autoComplete: 'off',
        }}
        type="text"
        size="small"
        style={{width: '100%'}}
        {...field}
      />
      {fieldState.error?.message && (
        <ErrorText error={fieldState.error?.message} size="small" />
      )}
    </div>
  );
};
