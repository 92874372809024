import React from 'react';

import {Layout} from 'src/components/layout/Layout';

import {RolesTable} from '../roles/RolesTable';

export const RolesPage = () => {
  return (
    <Layout>
      <RolesTable />
    </Layout>
  );
};
