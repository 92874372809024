import React from 'react';

import {Tooltip} from '@mui/material';

interface Props {
  children?: React.ReactNode;
  content: string;
  onClick?: React.MouseEventHandler;
}

export const TdWithTooltip = ({content, onClick, children, ...rest}: Props) => {
  return content.length > 40 ? (
    <Tooltip
      {...rest}
      title={content}
      arrow
      placement="top-start"
      classes={{
        tooltip:
          'absolute bottom-[-5px] bg-white text-text-primary border border-grey-light',
        arrow:
          'bg-white before:bg-white before:border before:border-grey-light',
      }}>
      <td onClick={onClick} className="cursor-pointer">
        <p className="td-ellipsis">{content}</p>
        {children}
      </td>
    </Tooltip>
  ) : (
    <td {...rest} onClick={onClick} className="cursor-pointer">
      {content} {children}
    </td>
  );
};
