import {useEffect, useState} from 'react';

import {useForm, SubmitHandler} from 'react-hook-form';
import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {yupResolver} from '@hookform/resolvers/yup';

import {RootState} from 'src/state';
import {
  createPartner,
  getPartnersOptions,
} from 'src/state/action_creators/partnerCreator';
import {PlanSelector} from 'src/types/Partner';
import {useAppDispatch} from 'src/hooks/hooks';
import {createFormData} from 'src/helpers/form';
import {
  FileType,
  FileTypeStatus,
  fileInit,
} from 'src/components/form/controlled/type';

import {partnersModalAddNewSchema} from '../schemas/partnersSchema';
import {PartnerNewFormFields} from '../components/types';

interface Params {
  closeModal: () => void;
  isNewCompany: boolean;
}

export const usePartnerNewForm = ({closeModal, isNewCompany}: Params) => {
  const dispatch = useAppDispatch();
  const [fileAvatar, setFileAvatar] = useState<FileType>(fileInit);

  const {planSelectorList, companySelectorList} = useSelector(
    (state: RootState) => state.partners,
  );

  const initialValues: PartnerNewFormFields = {
    boughtVideoSessions: 0,
    companyName: '',
    multiplier: 1000,
    name: '',
    partnerImage: null,
    planNumber: '',
  };
  const {
    control,
    handleSubmit,
    formState: {isSubmitting, errors},
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(partnersModalAddNewSchema),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (!planSelectorList || !companySelectorList) {
      dispatch(getPartnersOptions());
    }
  }, []);

  const onSubmit: SubmitHandler<PartnerNewFormFields> = (data) => {
    if (!planSelectorList) return;
    const planId = getPlanId(planSelectorList, data.planNumber);
    const companyId =
      isNewCompany && data.companyName
        ? filterCompanyId(data.companyName)
        : null;

    const formData = createFormData(data);
    formData.set('planNumber', planId);
    if (companyId) formData.append('companyId', companyId);
    if (fileAvatar.status !== FileTypeStatus.ORIGINAL)
      formData.set('partnerImage', fileAvatar.file || '');

    dispatch(createPartner(formData)).then((res) => {
      if (res) toast.success('Partner successfully created');
      dispatch(getPartnersOptions());
      closeModal();
    });
  };

  const getPlanId = (planList: PlanSelector[], planName: string) => {
    return (
      planList
        .filter((plan) =>
          planName.toLowerCase().includes(plan.name.toLowerCase()),
        )
        .map((plan) => plan.id)[0] || ''
    );
  };

  const filterCompanyId = (companyName: string) => {
    const filteredCompany = companySelectorList?.find(
      (company) => companyName === company.name,
    );
    return filteredCompany ? String(filteredCompany.id) : null;
  };

  const valuesHaveChanged = () => {
    const currentValues = watch();

    const boughtVideoSessionsSameValue =
      currentValues.boughtVideoSessions !== undefined
        ? Number(currentValues.boughtVideoSessions) === 0
        : currentValues.boughtVideoSessions === undefined;
    const planNumberNameSameValue = currentValues.planNumber === '';
    const multiplierSameValue =
      currentValues.multiplier !== undefined
        ? Number(currentValues.multiplier) === 1000
        : currentValues.multiplier === undefined;
    const nameSameValue = currentValues.name === '';
    const partnerImageSameValue = fileAvatar.status === 'original';

    return (
      !isNewCompany &&
      boughtVideoSessionsSameValue &&
      planNumberNameSameValue &&
      multiplierSameValue &&
      nameSameValue &&
      partnerImageSameValue
    );
  };

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    setFileAvatar,
    isSubmitting,
    hasErrors: Object.keys(errors).length > 0,
    valuesHaveChanged,
  };
};
