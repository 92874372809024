import {Column} from 'src/components/tables/types/Column';
import {IncomingPatient} from 'src/types/IncomingPatient';

export const columnsToShow: Column<IncomingPatient>[] = [
  {
    key: 'email',
    name: 'email',
  },
  {
    key: 'id',
    name: 'id',
  },
  {
    key: 'eligibleId',
    name: 'eligible id',
  },
  {
    key: 'name',
    name: 'name',
  },
  {
    key: 'appointmentStatus',
    name: 'status',
  },
  {
    key: 'isHighRisk',
    name: 'risk level',
  },
  {
    key: 'appointment',
    name: 'scheduled for',
  },
  {
    key: 'phone',
    name: 'phone number',
  },
  {
    key: 'psychologistGuide',
    name: 'therapist',
  },
  {
    key: 'conversationUrl',
    name: 'URL',
  },
];
