import {useState} from 'react';

import {useForm, SubmitHandler} from 'react-hook-form';

import {yupResolver} from '@hookform/resolvers/yup';

import {useAppDispatch} from 'src/hooks/hooks';
import {useQuery} from 'src/hooks/useQuery';
import {startLogin} from 'src/state/action_creators/authActionCreator';

import {loginFormSchema} from '../components/loginFormSchema';

interface LoginFormFields {
  email: string;
  password: string;
}

export const useLoginForm = () => {
  const dispatch = useAppDispatch();

  const [isLoginError, setIsLoginError] = useState(false);

  const query = useQuery();
  const emailUrl = query.get('email');

  const initialValues: LoginFormFields = {
    email: emailUrl || '',
    password: '',
  };

  const {
    control,
    handleSubmit,
    formState: {isSubmitting},
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(loginFormSchema),
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<LoginFormFields> = ({email, password}) => {
    setIsLoginError(false);
    dispatch(startLogin(email, password)).then((res) => {
      if (!res || !res.success) {
        setIsLoginError(true);
      }
    });
  };

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    isLoginError,
    isSubmitting,
  };
};
