import React, {ReactNode} from 'react';

interface Props {
  label: string;
  value: ReactNode;
}

export const LabelValueDisplay = ({label, value = null}: Props) => {
  return (
    <div className="my-1 flex items-start justify-start">
      {label && <span className="w-1/3 font-bold">{label}</span>}
      <div className="w-3/4 text-left">
        {typeof value === 'string' || typeof value === 'number' ? (
          <span
            className={`black whitespace-pre-wrap ${
              label !== 'Confirmed at' && 'capitalize'
            }`}>
            {value}
          </span>
        ) : (
          value
        )}
      </div>
    </div>
  );
};
