import React, {useState} from 'react';

import {useSelector} from 'react-redux';

import {Warning} from '@phosphor-icons/react';

import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import Button from 'src/components/buttons/Button';
import {Form} from 'src/components/form/Form';
import {RootState} from 'src/state';

import {usePartnerNewForm} from '../hooks/usePartnerNewForm';

interface Props {
  closeModal: () => void;
}

export const PartnerNewForm = ({closeModal}: Props) => {
  const [isNewCompany, setIsNewCompany] = useState<boolean>(false);
  const {
    control,
    onSubmit,
    isSubmitting,
    hasErrors,
    setFileAvatar,
    valuesHaveChanged,
  } = usePartnerNewForm({
    isNewCompany,
    closeModal,
  });
  const {planSelectorList, companySelectorList} = useSelector(
    (state: RootState) => state.partners,
  );

  return (
    <Form onSubmit={onSubmit} className="flex h-full flex-col justify-between">
      <ModalLayout.Header>
        <h3>Add Partner</h3>
      </ModalLayout.Header>

      <ModalLayout.Body>
        <Form.InputText
          name="name"
          label="Name"
          control={control}
          placeholder="Enter your name"
        />
        <Form.Autocomplete
          name="planNumber"
          label="Plan"
          control={control}
          options={planSelectorList?.map((plan) => plan.name) || []}
          placeholder="Select your plan"
        />
        <Form.InputNumber
          name="multiplier"
          label="Multiplier"
          control={control}
        />
        <Form.InputNumber
          name="boughtVideoSessions"
          label="Bought video sessions"
          control={control}
        />

        <Form.InputFile
          name="partnerImage"
          label="Avatar"
          control={control}
          shouldShowPreview={true}
          setFile={setFileAvatar}
        />
        {companySelectorList && (
          <>
            {isNewCompany ? (
              <>
                <Form.Autocomplete
                  name="companyName"
                  label="Company"
                  control={control}
                  options={companySelectorList
                    .filter((company) => company.name)
                    .map((company) => company.name)}
                />

                <Button
                  buttonType={ButtonType.SIMPLE}
                  size="medium"
                  onClick={() => setIsNewCompany(false)}>
                  Hide company selector
                </Button>
              </>
            ) : (
              <Button
                buttonType={ButtonType.SIMPLE}
                size="medium"
                onClick={() => setIsNewCompany(true)}>
                Add partner to existing company
              </Button>
            )}
          </>
        )}
      </ModalLayout.Body>
      <ModalLayout.Footer>
        {hasErrors && <Warning color="red" />}
        <ButtonSubmit
          loadingSubmit={isSubmitting}
          disabled={valuesHaveChanged()}
        />
      </ModalLayout.Footer>
    </Form>
  );
};
