import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';
import {Filter} from 'src/components/navbar/SearchBar';

import {SearchActionType} from '../action_types/SearchActionType';
import {SearchAction} from '../actions/search';
import {FixMeLater} from '../interfaces/FixMeLater';

type DispatchResult = SearchAction | any;

export const getFilters = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: SearchActionType.FILTERS_LOADING});
    const resp = await fetchWithToken('admin/users/type_of_search_selector');
    const body = await resp?.json();
    if (body && body.success) {
      const {filters} = body;
      dispatch(showFiltersResults(filters));
      return filters;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const searchUsers = (
  paramsSearch: string,
  filter_id: string,
  page: number,
) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: SearchActionType.SEARCH_LOADING});

    const resp = await fetchWithToken(
      `admin/users/search?text=${encodeURIComponent(
        paramsSearch,
      )}&filter_id=${filter_id}&page=${page}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {patients, total_count} = body;

      dispatch(
        showSearchResults({
          results: patients,
          total: total_count,
        }),
      );
    } else {
      dispatch(catchApiError(body));
    }
  };
};

const showSearchResults = (resultsObj: FixMeLater) => ({
  type: SearchActionType.SHOW_SEARCH_RESULTS,
  payload: resultsObj,
});

const showFiltersResults = (FiltersObj: Filter[]) => ({
  type: SearchActionType.SHOW_FILTERS_RESULTS,
  payload: FiltersObj,
});
