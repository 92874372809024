import React, {useRef} from 'react';

import {UseFormSetValue, useController} from 'react-hook-form';

import {Chip} from '@mui/material';

import {CustomTooltip} from 'src/components/shared/CustomTooltip';
import {COLOR_BORDER, COLOR_ERROR} from 'src/styles/colors/colors';

import {BaseControlledComponentProps} from './type';
import {CustomTextField} from './StylesFormControlled';

interface Props extends BaseControlledComponentProps {
  selectedValues: string[];
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  setValue: UseFormSetValue<any>;
  tooltipText: string;
}
export const InputChip = ({
  label,
  name,
  control,
  setValue,
  selectedValues,
  setSelectedValues,
  tooltipText,
}: Props) => {
  const {field, fieldState} = useController({
    control,
    name,
  });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const isInvalid = fieldState.invalid;

  const handleChipDelete = (valueToDelete: string) => {
    const updatedValues = selectedValues.filter(
      (value) => value !== valueToDelete,
    );
    setSelectedValues(updatedValues);
  };
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && field.value.trim() !== '') {
      event.preventDefault();
      if (!selectedValues.includes(field.value.trim())) {
        setSelectedValues([...selectedValues, field.value.trim()]);
      }

      if (inputRef.current) {
        inputRef.current.focus();
        setValue(name, []);
      }
    }
  };

  const handleInputBlur = () => {
    if (field.value && field.value.length && field.value.trim() !== '') {
      if (!selectedValues.includes(field.value.trim())) {
        setSelectedValues([...selectedValues, field.value.trim()]);
      }

      setValue(name, []);
    }
  };

  return (
    <div className="mt-5 w-full">
      <label className="mb-1 block">
        <div className="flex w-full items-center justify-start gap-1">
          {label}
          <CustomTooltip title={tooltipText} />
        </div>
      </label>
      <CustomTextField
        {...field}
        fullWidth
        variant="outlined"
        size="small"
        onKeyDown={handleInputKeyDown}
        onBlur={handleInputBlur}
        ref={inputRef}
        sx={{
          borderColor: isInvalid ? COLOR_ERROR : COLOR_BORDER,
        }}
      />
      <div className="mt-2 flex flex-wrap justify-start gap-2">
        {selectedValues.map(
          (value, index) =>
            value !== '' && (
              <Chip
                key={index + value}
                label={value}
                onDelete={() => handleChipDelete(value)}
              />
            ),
        )}
      </div>
    </div>
  );
};
