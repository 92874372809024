import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {SharedActionType} from '@ifeelonline/chat-core';

import {PencilLine} from '@phosphor-icons/react';

import {ChangePasswordModal} from 'src/pages/profile/modals/ChangePasswordModal';
import {getMyInfo, updateMy} from 'src/state/action_creators/myCreator';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {PageHeader} from 'src/components/layout/PageHeader';
import {Loading} from 'src/components/shared/Loading';
import {Layout} from 'src/components/layout/Layout';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

export const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const [editing, setEditing] = useState(false);
  const [formValues, setFormValues] = useState({
    nickname: '',
  });
  const {nickname} = formValues;
  const [showModal, setShowModal] = useState(false);
  const {status, my} = useSelector((state: RootState) => state.my);

  const handleInputChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleSubmitForm = () => {
    dispatch(updateMy(my.id, formValues)).then(() => {
      toast.success('Nickname changed successfully');
      setEditing(false);
      dispatch(getMyInfo());
    });
  };

  useEffect(() => {
    dispatch(getMyInfo()).then((res) => {
      setFormValues({
        ...formValues,
        nickname: res.nickname,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout id="profile">
      {status === SharedActionType.LOADING ? (
        <Loading />
      ) : (
        <>
          <div className="max-w-110">
            <div className="mb-4 flex justify-between">
              <PageHeader pageTitle="Profile" />
              {editing ? (
                <div className="flex justify-end">
                  <Button
                    buttonType={ButtonType.WHITE}
                    size="small"
                    onClick={() => setEditing(false)}>
                    Cancel
                  </Button>
                  <Button
                    buttonType={ButtonType.SAVE}
                    size="small"
                    onClick={handleSubmitForm}
                    disabled={my.nickname === formValues.nickname}>
                    Save
                  </Button>
                </div>
              ) : (
                <Button
                  buttonType={ButtonType.ACTIONS}
                  size="small"
                  onClick={() => setEditing(true)}>
                  <PencilLine size="14" weight="fill" className="mr-1" /> Edit
                </Button>
              )}
            </div>
            <>
              {my && (
                <form>
                  {['Email', 'Nickname', 'Password'].map((field, index) => (
                    <div
                      key={index}
                      className="flex min-h-12 items-center py-2">
                      <label className="w-1/4 text-3.5 font-medium text-blue-dark">
                        {field}
                      </label>
                      {field === 'Email' ? (
                        <p className="w-3/4">{my.email}</p>
                      ) : field === 'Nickname' ? (
                        editing ? (
                          <input
                            className="w-3/4 border border-solid border-black"
                            type="text"
                            placeholder="Nickname"
                            name="nickname"
                            autoComplete="off"
                            value={nickname}
                            onChange={handleInputChange}
                          />
                        ) : (
                          <p className="w-3/4">{my.nickname}</p>
                        )
                      ) : editing ? (
                        <Button
                          className="!ml-0 w-3/4"
                          buttonType={ButtonType.WHITE}
                          size="small"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowModal(true);
                          }}>
                          Change Password...
                        </Button>
                      ) : (
                        <p className="w-3/4">*****</p>
                      )}
                    </div>
                  ))}
                </form>
              )}
            </>
          </div>
          <ChangePasswordModal
            show={showModal}
            setShow={setShowModal}
            setEditing={setEditing}
          />
        </>
      )}
    </Layout>
  );
};
