import {SharedActionType} from '@ifeelonline/chat-core';

import {Event} from 'src/types/Event';

import {EventsActionType} from '../action_types/EventsActionType';
import {EventsAction} from '../actions/events';
import {FixMeLater} from '../interfaces/FixMeLater';

interface EventsState {
  error: null | string;
  events: Event[];
  recentActivity: null | FixMeLater;
  status: SharedActionType;
  total: number;
  typeOfEventsList: undefined | Array<{name: string; value: string}>;
}

const initialState = {
  events: [],
  recentActivity: [],
  status: SharedActionType.IDLE,
  error: null,
  typeOfEventsList: undefined,
  total: 0,
};

export const eventsReducer = (
  state: EventsState = initialState,
  action: EventsAction,
) => {
  switch (action.type) {
    case EventsActionType.EVENTS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case EventsActionType.SET_EVENTS:
      return {
        ...state,
        events: action.payload.events,
        total: action.payload.total,
        status: SharedActionType.COMPLETED,
      };

    case EventsActionType.SET_RECENT_ACTIVITY:
      return {
        ...state,
        recentActivity: action.payload,
        status: SharedActionType.COMPLETED,
      };

    case EventsActionType.LOAD_MORE_RECENT_ACTIVITY:
      return {
        ...state,
        recentActivity: [...state.recentActivity, ...action.payload],
        status: SharedActionType.COMPLETED,
      };
    case EventsActionType.SET_EVENTS_TYPE:
      return {
        ...state,
        typeOfEventsList: action.payload,
      };

    default:
      return state;
  }
};
