import React, {useState} from 'react';

import toast from 'react-hot-toast';

import {InputLabel, TextField, Tooltip} from '@mui/material';
import {Info} from '@phosphor-icons/react';

import {toSnakeCase} from 'src/helpers/generalFunctions';

import {TherapistSpecialitiesEditModalProps} from '../../TherapistSpecialitiesEditModal';
/*TO-DO replace for new input when created */
export const NewSpecialityInputField = ({
  specialitiesTableOther,
  setSpecialitiesTableOther,
}: {
  setSpecialitiesTableOther: React.Dispatch<
    React.SetStateAction<
      TherapistSpecialitiesEditModalProps['specialitiesTableOther']
    >
  >;
  specialitiesTableOther: TherapistSpecialitiesEditModalProps['specialitiesTableOther'];
}) => {
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      event.preventDefault();
      const valueAlreadyExist = specialitiesTableOther
        .map((item) => item.text)
        .includes(inputValue);

      if (valueAlreadyExist) {
        toast.error('The speciality already exist');
        setInputValue('');
        return false;
      }

      setSpecialitiesTableOther([
        ...specialitiesTableOther,
        {
          level: 0,
          originalKey: toSnakeCase(inputValue),
          text: inputValue,
        },
      ]);
      setInputValue('');
    }
  };
  return (
    <div className="mt-5">
      <InputLabel
        sx={{
          display: 'flex',
          flexDirection: 'row',
          fontSize: '11px',
          color: '#8792a2',
          margin: 0,
          marginBottom: '7px',
          gap: '5px',
          alignItems: 'center',
        }}>
        Add speciality
        <Tooltip
          sx={{
            background: 'rgb(0 0 0 / 0 %)',
            border: '1px solid #bdbdbd',
            borderRadius: '100%',
            height: '11px',
            width: '11px',
            fontSize: '8px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            boxSizing: 'content-box',
          }}
          title="Only English words">
          <Info />
        </Tooltip>
      </InputLabel>
      <TextField
        fullWidth
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
      />
    </div>
  );
};
