import * as React from 'react';

import {Tab} from '@headlessui/react';

export default function TabComp({
  setValue,
  tabList,
}: {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  tabList: string[];
}) {
  return (
    <Tab.Group onChange={(index: number) => setValue(tabList[index])}>
      <Tab.List className="flex items-center justify-start border-b border-border-blueLight">
        {tabList.map((tab: string, i: number) => (
          <Tab key={i}>
            {({selected}) => (
              <span
                className={`mr-7.5 block border-b-2 py-4 text-4 font-normal
                  ${
                    selected
                      ? 'border-b-blue-light69 text-active'
                      : 'text-text-600 border-transparent'
                  }`}>
                {tab}
              </span>
            )}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
}
