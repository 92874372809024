import React, {useEffect} from 'react';

import {Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {settingsStartUp} from 'src/state/action_creators/settingsActionCreator';
import {startChecking} from 'src/state/action_creators/authActionCreator';
import {Loading} from 'src/components/shared/Loading';
import {RoutesConfig} from 'src/config/RoutesConfig';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {NoConnection} from './components/NoConnection';
import {PrivateRoute} from './components/PrivateRoute';
import {PublicRoute} from './components/PublicRoute';

export const AppRouter = () => {
  const dispatch = useAppDispatch();
  const {checking, connection} = useSelector((state: RootState) => state.auth);
  const {languages} = useSelector((state: RootState) => state.settings);

  useEffect(() => {
    dispatch(startChecking());
    if (!languages) {
      dispatch(settingsStartUp());
    }
  }, [dispatch]);

  if (checking) {
    return <Loading title="Waiting for connection with backend..." />;
  }
  if (!connection) {
    return <NoConnection />;
  }

  return (
    <Routes>
      {RoutesConfig.private.map(
        ({
          path,
          controllerName,
          component: Component,
          shouldShareControllerName,
        }) => (
          <Route
            key={path}
            path={path}
            element={
              <PrivateRoute
                controllerName={controllerName}
                component={Component}
                shouldShareControllerName={shouldShareControllerName}
              />
            }
          />
        ),
      )}
      {RoutesConfig.public.map(({path, component: Component}) => (
        <Route
          key={path}
          path={path}
          element={<PublicRoute component={Component} />}
        />
      ))}
    </Routes>
  );
};
