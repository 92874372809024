import {VideoSession, VideoSessionStorage} from 'src/types/VideoSession';

export function mapApiResponseVideoSession(jsonBack: VideoSessionStorage) {
  const videoSession: VideoSession = {
    conversationId: jsonBack.conversation_id,
    comment: jsonBack.comment,
    duration: jsonBack.duration,
    id: jsonBack.id,
    metric: jsonBack.metric || '',
    nickname: jsonBack.nickname,
    partner: jsonBack.partner,
    patientEmail: jsonBack.patient_email,
    patientId: jsonBack.patient_id,
    patientPlan: jsonBack.patient_plan,
    startTime: jsonBack.start_time,
    therapistId: jsonBack.therapist_id,
    timeEnd: jsonBack.time_end,
    updatedAt: jsonBack.updated_at,
    validated: jsonBack.validated,
    validatedStatus: jsonBack.validated_status,
    validatedStatusString: jsonBack.validated_status_string,
    validatedString: jsonBack.validated_string,
  };

  return videoSession;
}
