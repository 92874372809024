import React, {ComponentProps, ReactNode} from 'react';

import {Dot} from 'src/components/shared/Dot';

interface Props extends ComponentProps<'div'> {
  description: string | ReactNode;
  title: string;
  value: number;
}
export const LegendContainer = ({title, description, value}: Props) => {
  return (
    <div className="flex-1 text-blue-dark">
      <h2 className="text-4.5 font-semibold">{title}</h2>
      <span className="font-sofia-medium mb-4 mt-2 block text-3xl font-bold">
        {value}
      </span>
      <div>{description}</div>
    </div>
  );
};

export const Legend = ({
  color,
  title,
  value,
}: {
  color: string;
  title: string;
  value: number;
}) => {
  return (
    <div className="flex justify-start">
      <Dot size={8} backgroundColor={color} />
      <p>
        {title} <span className="ml-2 inline-block font-bold">{value}</span>
      </p>
    </div>
  );
};
