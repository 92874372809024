import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {ModalLayout} from '../layout/ModalLayout';
import Button from '../buttons/Button';

interface ExportModalInterface {
  allFilterParams?: FixMeLater;
  columnsVisible?: FixMeLater;
  endUrl?: string;
  exportReport: FixMeLater;
  formValues?: FixMeLater;
  id?: number;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
  title: string;
  type?: string;
}

export const ExportModal = ({
  show,
  setModalOpen,
  allFilterParams,
  columnsVisible,
  title,
  exportReport,
  formValues,
  endUrl,
  id,
  type = 'report',
}: ExportModalInterface) => {
  const dispatch = useAppDispatch();
  const {user} = useSelector((state: RootState) => state.auth);
  const [emailValue, setEmailValue] = useState<string>('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleInputChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(target.value);
  };

  useEffect(() => {
    if (user) {
      setEmailValue(user.email);
    }
  }, [user]);

  const responseEventExport = () => {
    setLoadingSubmit(false);
    setModalOpen(false);
    toast.success(
      'The system is generating the CSV, you will receive it by email soon',
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadingSubmit(true);
    switch (type) {
      case 'B2bReport':
        dispatch(
          exportReport(
            {
              ...formValues,
              email: emailValue,
            },
            endUrl,
          ),
        ).then(responseEventExport);
        break;
      case 'patientReport':
        dispatch(
          exportReport({
            id: id,
            email: emailValue,
          }),
        ).then(responseEventExport);
        break;
      default:
        dispatch(
          exportReport({
            ...allFilterParams,
            email: emailValue,
            columns_to_show: columnsVisible,
          }),
        ).then(responseEventExport);
    }
  };

  return (
    <ModalLayout
      show={show}
      closeModal={() => setModalOpen(false)}
      className="modal__export">
      <form className="container" onSubmit={handleSubmit}>
        <ModalLayout.Header>
          <h3>{title}</h3>
        </ModalLayout.Header>
        <ModalLayout.Body>
          <div className="py-5">
            <p>This CSV will be sent to:</p>
            <div className="form-group">
              <input
                type="email"
                placeholder="email"
                name="email"
                autoComplete="off"
                value={emailValue}
                readOnly
                onChange={handleInputChange}
              />
            </div>
          </div>
        </ModalLayout.Body>
        <ModalLayout.Footer>
          <Button
            buttonType={ButtonType.SAVE}
            loading={loadingSubmit}
            size="small">
            Send
          </Button>
        </ModalLayout.Footer>
      </form>
    </ModalLayout>
  );
};
