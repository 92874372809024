import {SharedActionType} from '@ifeelonline/chat-core';

import {SearchActionType} from '../action_types/SearchActionType';
import {SearchAction} from '../actions/search';
import {FixMeLater} from '../interfaces/FixMeLater';

interface SearchState {
  results: FixMeLater;
  status: SharedActionType;
  total?: number;
}

const initialState = {
  results: [],
  status: SharedActionType.IDLE,
};
export const searchReducer = (
  state: SearchState = initialState,
  action: SearchAction,
) => {
  switch (action.type) {
    case SearchActionType.SEARCH_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case SearchActionType.SHOW_SEARCH_RESULTS:
      return {
        ...state,
        results: action.payload.results,
        total: action.payload.total,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
