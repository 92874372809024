import React, {useState} from 'react';

import {useSelector} from 'react-redux';

import {ModalLayout} from 'src/components/layout/ModalLayout';
import {Loading} from 'src/components/shared/Loading';
import {ProgressStepVertical} from 'src/components/shared/ProgressStepVertical';
import {RootState} from 'src/state';
import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';

import {CSVWithPreviewForm} from '../forms/CSVWithPreviewForm';

interface Props extends ModalSimpleInterface {
  canSelectPartner: boolean;
  dispatchPreview: any;
  dispatchSave: any;
  formatPreviewRes: any;
  isEdit?: boolean;
  title: string;
}

export const CSVWithPreviewModal = ({
  show,
  setShow,
  title,
  dispatchPreview,
  dispatchSave,
  formatPreviewRes,
  canSelectPartner,
  isEdit,
}: Props) => {
  const {partnersListSelector} = useSelector(
    (state: RootState) => state.email_authorizations,
  );
  const [stepForm, setStepForm] = useState(1);

  const closeModal = () => {
    setShow && setShow(false);
    setStepForm(1);
  };

  return (
    <ModalLayout show={show} closeModal={closeModal}>
      {partnersListSelector ? (
        <div className="flex h-full">
          <ProgressStepVertical
            steps={['Upload file', 'Confirm']}
            activeStep={stepForm}
          />
          <CSVWithPreviewForm
            isModalOpen={show}
            canSelectPartner={canSelectPartner}
            dispatchPreview={dispatchPreview}
            dispatchSave={dispatchSave}
            formatPreviewRes={formatPreviewRes}
            isEdit={isEdit}
            closeModal={closeModal}
            title={title}
            setStepForm={setStepForm}
            stepForm={stepForm}
          />
        </div>
      ) : (
        <Loading />
      )}
    </ModalLayout>
  );
};
