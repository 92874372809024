import React from 'react';

import {Warning} from '@phosphor-icons/react';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FormTextInfo} from 'src/components/form/FormText';
import {Form} from 'src/components/form/Form';

import {useNewPillForm} from '../hook/useNewPillForm';
import {Areas} from '../types';

interface Props {
  closeModal: () => void;
}

export const NewPillForm = ({closeModal}: Props) => {
  const {
    control,
    onSubmit,
    isSubmitting,
    hasErrors,
    setFileLogo,
    setFileBackground,
    setFileThumbnail,
    pillsOptions,
    valuesHaveChanged,
  } = useNewPillForm({
    closeModal,
  });

  return (
    <Form
      onSubmit={onSubmit}
      className="flex size-full flex-col justify-between ">
      <ModalLayout.Header>
        <h3>Add Pill</h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        <Form.SelectAutocomplete
          control={control}
          name="areaId"
          label="Area"
          options={pillsOptions.areas.map((a: Areas) => a.title)}
        />
        <Form.SelectAutocomplete
          control={control}
          name="typeOfPill"
          label="Type Of Pill"
          options={pillsOptions.typeOfPills.map((p: {name: string}) => p.name)}
        />

        <Form.InputFile
          control={control}
          name="logo"
          label="Logo"
          shouldShowPreview={true}
          setFile={setFileLogo}
        />

        <Form.InputFile
          control={control}
          name="background"
          label="Background"
          shouldShowPreview={true}
          setFile={setFileBackground}
        />

        <Form.InputFile
          control={control}
          name="thumbnail"
          label="Thumbnail"
          shouldShowPreview={true}
          setFile={setFileThumbnail}
        />

        <Form.CheckboxGroup
          control={control}
          name="activePill"
          options={['Active']}
          label=""
        />

        <div className="flex w-full flex-col items-start gap-3 rounded-1 border p-2.5 text-start">
          <FormTextInfo
            text=" These fields can be edited for different languages. Add the
                    English fields first. All other languages can be added from
                    the pill edit view, access from the table "
          />

          <label>
            Header in <b>english</b>
          </label>
          <Form.InputText
            control={control}
            name="header"
            label="Header"
            placeholder="Enter your header"
          />
          <Form.InputText
            control={control}
            name="title"
            label="Title"
            placeholder="Enter your title"
          />
          <Form.RichText
            control={control}
            name="description"
            label="Description"
            placeholder="Enter your description"
          />
        </div>
      </ModalLayout.Body>
      <ModalLayout.Footer>
        {hasErrors && <Warning color="red" />}
        <ButtonSubmit
          loadingSubmit={isSubmitting}
          disabled={valuesHaveChanged()}
        />
      </ModalLayout.Footer>
    </Form>
  );
};
