import React from 'react';

import {useSelector} from 'react-redux';

import {Warning} from '@phosphor-icons/react';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {Form} from 'src/components/form/Form';
import {RootState} from 'src/state';

import {useNewDashboardUserForm} from '../../hooks/useNewDashboardUserForm';
import {GetUsersAndRolesSorting} from '../../types';

interface Props {
  closeModal: () => void;
  sorting: GetUsersAndRolesSorting;
}

export const NewDashboardUserForm = ({sorting, closeModal}: Props) => {
  const {rolesListSelector} = useSelector((state: RootState) => state.uar);

  const {control, onSubmit, isSubmitting, hasErrors, valuesHaveChanged} =
    useNewDashboardUserForm({
      sorting,
      closeModal,
    });

  return (
    <Form
      onSubmit={onSubmit}
      className="flex size-full flex-col justify-between">
      <ModalLayout.Header>
        <h3>Add User</h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        <Form.InputText
          control={control}
          name="nickname"
          label="Nickname"
          placeholder="Enter your nickname"
        />
        <Form.InputText
          control={control}
          name="email"
          label="Email"
          placeholder="email@domain.com"
        />
        <Form.InputText
          control={control}
          name="name"
          label="Name"
          placeholder="Enter your name"
        />
        <Form.InputText
          control={control}
          name="surname"
          label="Surname"
          placeholder="Enter your surname"
        />
        <Form.CheckboxGroup
          control={control}
          name="roles"
          label="Roles"
          options={rolesListSelector?.map(({name}) => name) || []}
        />
      </ModalLayout.Body>
      <ModalLayout.Footer>
        {hasErrors && <Warning color="red" />}
        <ButtonSubmit
          loadingSubmit={isSubmitting}
          disabled={valuesHaveChanged()}
        />
      </ModalLayout.Footer>
    </Form>
  );
};
