import {SharedActionType} from '@ifeelonline/chat-core';

import {SupervisorsActionType} from '../action_types/SupervisorsActionType';
import {SupervisorAction} from '../actions/supervisors';
import {FixMeLater} from '../interfaces/FixMeLater';

interface SupervisorsState {
  error: null | string;
  status: SharedActionType;
  supervisorListSelector: FixMeLater;
  supervisors: FixMeLater;
  total: null | number;
}

const initialState = {
  supervisors: null,
  total: null,
  status: SharedActionType.IDLE,
  supervisorListSelector: null,
  error: null,
};

export const supervisorsReducer = (
  state: SupervisorsState = initialState,
  action: SupervisorAction,
) => {
  switch (action.type) {
    case SupervisorsActionType.SUPERVISORS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case SupervisorsActionType.SET_SUPERVISORS:
      return {
        ...state,
        supervisors: action.payload.supervisors,
        total: action.payload.total,
        status: SharedActionType.COMPLETED,
      };

    case SupervisorsActionType.SUPERVISOR_ADD_NEW:
      return {
        ...state,
        supervisors: [...state.supervisors, action.payload],
        total: state.total ? state.total + 1 : 1,
        status: SharedActionType.COMPLETED,
      };

    case SupervisorsActionType.SET_SUPERVISOR_LIST_SELECTOR:
      return {
        ...state,
        supervisorListSelector: action.payload,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
