import React, {useEffect, useState} from 'react';

import {Form} from 'react-bootstrap';

import {useTherapists} from 'src/providers/TherapistsTableProvider';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';

export const PopOverColumns = () => {
  const {
    toggleShowColumPopOver,
    columnsVisible,
    setColumnsVisible,
    filterSelected,
  } = useTherapists();
  const [formValues, setFormValues] = useState({
    email: columnsVisible.includes('email'),
    nickname: columnsVisible.includes('nickname'),
    invitation_sent_at: columnsVisible.includes('invitation_sent_at'),
    invitation_accepted_at: columnsVisible.includes('invitation_accepted_at'),
    languages: columnsVisible.includes('languages'),
    plan_type: columnsVisible.includes('plan_type'),
    country: columnsVisible.includes('country'),
  });
  const {
    email,
    nickname,
    invitation_sent_at,
    invitation_accepted_at,
    languages,
    plan_type,
    country,
  } = formValues;

  useEffect(() => {
    if (filterSelected !== null) {
      const filtersFiltered = Object.keys(filterSelected).filter(
        (key) => filterSelected[key].checked,
      );
      const fieldFiltersSelected: FixMeLater = [];
      filtersFiltered.forEach((f) => {
        fieldFiltersSelected.push(filterSelected[f].field);
      });
      setColumnsVisible([
        ...new Set([...columnsVisible, ...fieldFiltersSelected]),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelected]);

  const handleCheckChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const newFormValues: FixMeLater = {
      ...formValues,
      [target.name]: target.checked,
    };
    setFormValues(newFormValues);
    const checkedKeys: FixMeLater = Object.keys(newFormValues).filter(
      (key) => newFormValues[key],
    );
    setColumnsVisible(checkedKeys);
  };

  return (
    <div
      className={
        toggleShowColumPopOver ? 'popover-custom on' : 'popover-custom'
      }>
      <div className="content">
        <form>
          <div className="popover__header">
            <h4>Columns</h4>
          </div>
          <div className="popover__body">
            <div>
              <li>
                <Form.Check
                  checked={columnsVisible.includes('email')}
                  onChange={handleCheckChange}
                  id="popover__columns_email"
                  label="Email"
                  name="email"
                  type="checkbox"
                  disabled={email}
                />
              </li>
              <li>
                <Form.Check
                  checked={nickname}
                  onChange={handleCheckChange}
                  id="popover__columns_nickname"
                  label="Nickname"
                  name="nickname"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={invitation_sent_at}
                  onChange={handleCheckChange}
                  id="popover__columns_invitation_sent"
                  label="Invitation sent"
                  name="invitation_sent_at"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={invitation_accepted_at}
                  onChange={handleCheckChange}
                  id="popover__columns_invitation_accepted"
                  label="Invitation accepted"
                  name="invitation_accepted_at"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={languages}
                  onChange={handleCheckChange}
                  id="popover__columns_languages"
                  label="Languages"
                  name="languages"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={plan_type}
                  onChange={handleCheckChange}
                  id="popover__columns_planType"
                  label="Plan Type"
                  name="plan_type"
                  type="checkbox"
                />
              </li>
              <li>
                <Form.Check
                  checked={country}
                  onChange={handleCheckChange}
                  id="popover__columns_country"
                  label="Country"
                  name="country"
                  type="checkbox"
                />
              </li>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
