import {SharedActionType} from '@ifeelonline/chat-core';

import {HomeActionType} from '../action_types/HomeActionType';
import {HomeAction} from '../actions/home';
import {FixMeLater} from '../interfaces/FixMeLater';

interface HomeState {
  error: null | string;
  graphics14days?: FixMeLater;
  graphics7days?: FixMeLater;
  graphicsDay?: FixMeLater;
  statusHome: SharedActionType;
}

const initialState = {
  statusHome: SharedActionType.IDLE,
  error: null,
};

export const homeReducer = (
  state: HomeState = initialState,
  action: HomeAction,
) => {
  switch (action.type) {
    case HomeActionType.HOME_DATA_LOADING:
      return {
        ...state,
        statusHome: SharedActionType.LOADING,
      };

    case HomeActionType.SET_HOME_DATA_7_DAYS:
      return {
        ...state,
        graphics7days: action.payload.data,
        statusHome: SharedActionType.COMPLETED,
      };

    case HomeActionType.SET_HOME_DATA_14_DAYS:
      return {
        ...state,
        graphics14days: action.payload.data,
        statusHome: SharedActionType.COMPLETED,
      };

    case HomeActionType.SET_HOME_DATA_DAY:
      return {
        ...state,
        graphicsDay: action.payload.data,
        statusHome: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
