import React from 'react';

import {PencilLine} from '@phosphor-icons/react';

import {getTherapistInfo} from 'src/helpers/data_generation/therapist';
import {LabelValueDisplay} from 'src/components/LabelValueDisplay';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import Button from 'src/components/buttons/Button';
import {Therapist} from 'src/types/Therapist';

export const UserComponent = ({
  therapist,
  setModalEditUser,
}: {
  setModalEditUser: React.Dispatch<React.SetStateAction<boolean>>;
  therapist: Therapist;
}) => {
  const therapistInfo = getTherapistInfo(therapist);
  return (
    <div className="flex w-full flex-col">
      <div className="section_header flex w-full ">
        <h3>User</h3>
        <Button
          buttonType={ButtonType.ACTIONS}
          size="small"
          onClick={() => setModalEditUser(true)}>
          <PencilLine size="14" />
        </Button>
      </div>
      <div className="w-full">
        {therapistInfo &&
          therapistInfo.map((item) => {
            return (
              <LabelValueDisplay
                key={item.label}
                value={item.value || ''}
                label={item.label}
              />
            );
          })}
      </div>
    </div>
  );
};
