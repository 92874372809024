import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchFormData, fetchWithToken} from 'src/helpers/fetch';
import {PartnerSelector} from 'src/types/Partner';

import {EmailAuthorizationsActionType} from '../action_types/EmailAuthorizationsType';
import {EmailAuthorizationAction} from '../actions/emailsAuthorizations';
import {FixMeLater} from '../interfaces/FixMeLater';

export const getEmailAuthorizationsOptions = () => {
  return async (dispatch: Dispatch<EmailAuthorizationAction | FixMeLater>) => {
    dispatch({
      type: EmailAuthorizationsActionType.EMAIL_AUTHORIZATIONS_LOADING,
    });
    const resp = await fetchWithToken(`admin/email_authorizations/selectors`);
    const body = await resp?.json();
    if (body && body.success) {
      const {partners}: {partners: PartnerSelector[]} = body;
      dispatch({
        type: EmailAuthorizationsActionType.SET_EMAIL_AUTHORIZATIONS_OPTIONS,
        payload: partners,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getEmailAuthorizations = (
  page: number,
  email: string | null,
  partner_id?: number,
) => {
  return async (dispatch: Dispatch<EmailAuthorizationAction | FixMeLater>) => {
    dispatch({
      type: EmailAuthorizationsActionType.EMAIL_AUTHORIZATIONS_LOADING,
    });
    const emailParams = email ? `&email=${email}` : '';
    const partner_idParams = partner_id ? `&partner_id=${partner_id}` : '';
    const resp = await fetchWithToken(
      `admin/email_authorizations?page=${page}${emailParams}${partner_idParams}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {email_authorizations: emailAuthorizations, total_count: total} =
        body;
      dispatch({
        type: EmailAuthorizationsActionType.SET_EMAIL_AUTHORIZATIONS,
        payload: {emailAuthorizations, total},
      });
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const uploadCSV = (data: FixMeLater, partnerId: number) => {
  return async (dispatch: Dispatch<EmailAuthorizationAction | FixMeLater>) => {
    const bodyData = {
      partner_id: partnerId,
      emails: data,
    };
    const resp = await fetchWithToken(
      `admin/email_authorizations/upload_csv`,
      bodyData,
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const previewCSV = (formData: FixMeLater) => {
  return async (dispatch: Dispatch<EmailAuthorizationAction | FixMeLater>) => {
    const resp = await fetchFormData(
      `admin/email_authorizations/preview_csv`,
      formData,
      'POST',
    );
    const body = await resp?.json();
    if (body?.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const uploadCSVEdit = (data: FixMeLater) => {
  return async (dispatch: Dispatch<EmailAuthorizationAction | FixMeLater>) => {
    const bodyData = {
      emails: data,
    };
    const resp = await fetchWithToken(
      `admin/email_authorizations/update_csv`,
      bodyData,
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const previewLeaversCSV = (formData: FixMeLater) => {
  return async (dispatch: Dispatch<EmailAuthorizationAction | FixMeLater>) => {
    const resp = await fetchFormData(
      `admin/email_authorizations/preview_unsubscribe_csv`,
      formData,
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const previewUpdateCSV = (formData: FixMeLater) => {
  return async (dispatch: Dispatch<EmailAuthorizationAction | FixMeLater>) => {
    const resp = await fetchFormData(
      `admin/email_authorizations/preview_update_csv`,
      formData,
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const unsubscribeEA = (emailsObj: {email: string}[]) => {
  return async (dispatch: Dispatch<EmailAuthorizationAction | FixMeLater>) => {
    const bodyData = {
      emails: emailsObj.map((m: {email: string}) => m.email),
    };

    const resp = await fetchWithToken(
      `admin/email_authorizations/unsubscribe_partners_from_users`,
      bodyData,
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
    }
  };
};
