import {
  Dashboard,
  DashboardGraphic,
  DashboardGraphicStorage,
  DashboardStorage,
} from 'src/types/Dashboard';

import {formatFloatButInt} from '../generalFunctions';

export function mapApiResponseDashboard(jsonBack: DashboardStorage) {
  const dashboard: Dashboard = {
    chat: jsonBack.chat
      ? {
          series: jsonBack.chat.graphic_data,
          total: jsonBack.chat.total,
        }
      : null,
    selfCare: jsonBack.self_care
      ? {
          series: jsonBack.self_care.graphic_data,
          total: jsonBack.self_care.total,
        }
      : null,
    video: jsonBack.video
      ? {
          series: jsonBack.video.graphic_data,
          total: jsonBack.video.total,
        }
      : null,
  };
  return dashboard;
}

export function mapApiResponseDashboardGraphic(
  jsonBack: DashboardGraphicStorage,
) {
  const dashboardGraphic: DashboardGraphic = {
    series: jsonBack.graphic_data || null,
    total: jsonBack.total
      ? (formatFloatButInt(jsonBack.total, 3, true) as number)
      : 0,
  };

  return dashboardGraphic;
}
