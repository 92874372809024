export enum ButtonType {
  ACTIONS = 'actions',
  DASHBOARDS = 'dashboards',
  DEFAULT = 'Default',
  ONE_OPTION = 'one-option',
  OUTLINE = 'outline',
  OUTLINE_PRIMARY = 'outline-primary',
  PRIMARY = 'primary',
  SAVE = 'save',
  SIMPLE = 'Simple',
  UPLOAD_FILE = 'upload-file',
  WARNING = 'warning',
  WHITE = 'white',
}

export function buttonClasses(buttonType: ButtonType) {
  switch (buttonType) {
    case ButtonType.SIMPLE:
      return 'btn-simple';
    case ButtonType.PRIMARY:
      return 'btn-primary';
    case ButtonType.DASHBOARDS:
      return 'btn-dashboards';
    case ButtonType.ACTIONS:
      return 'btn-actions btn-white';
    case ButtonType.SAVE:
      return 'btn-save';
    case ButtonType.WHITE:
      return 'btn-white';
    case ButtonType.ONE_OPTION:
      return 'btn-one-option btn-save';
    case ButtonType.OUTLINE:
      return 'btn-outline';
    case ButtonType.OUTLINE_PRIMARY:
      return 'btn-outline-primary';
    case ButtonType.WARNING:
      return 'btn-warning';
    case ButtonType.UPLOAD_FILE:
      return 'btn-upload-file';

    case ButtonType.DEFAULT:
      return 'btn';

    default:
      break;
  }
}
