import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';
import {WebHook} from 'src/types/WebHook';

import {WebHooksActionType} from '../action_types/WebHooksActionType';
import {SetWebhooksPayload, WebHookAction} from '../actions/webHooks';

type DispatchResult = WebHookAction | any;

export const getWebhooks = (page: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: WebHooksActionType.WEB_HOOKS_LOADING});

    const resp = await fetchWithToken(`admin/webhooks?page=${page}`);
    const body = await resp?.json();
    if (body && body.success) {
      const {
        webhooks,
        total_count: total,
      }: {total_count: number; webhooks: WebHook[] | null} = body;
      const payload: SetWebhooksPayload = {
        webhooks,
        total,
      };
      dispatch({
        type: WebHooksActionType.SET_WEB_HOOKS,
        payload,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};
