import React from 'react';

import {useSelector} from 'react-redux';

import {
  uiHideSidebar,
  uiShowSidebar,
} from 'src/state/action_creators/uiCreator';
import {rolesPermissionFilter} from 'src/helpers/rolesFunctions';
import {EXTERNAL_ROUTES} from 'src/constants/Routes';
import {MENU_LI_LIST} from 'src/constants/Menus';
import {useAppDispatch} from 'src/hooks/hooks';
import {MenuLiList} from 'src/types/Menus';
import {RBAC} from 'src/helpers/Rbac';
import {RootState} from 'src/state';

import {LiNavbar} from '../navbar/LiNavbar';

export const Sidebar = () => {
  const dispatch = useAppDispatch();
  const {sidebarHidden} = useSelector((state: RootState) => state.ui);
  const {user} = useSelector((state: RootState) => state.auth);

  const handleToggleSidebar = () => {
    sidebarHidden ? dispatch(uiHideSidebar()) : dispatch(uiShowSidebar());
  };

  const getMenuList = () => {
    const filterList: MenuLiList[] = [];
    if (user) {
      MENU_LI_LIST.forEach((item) => {
        if (
          rolesPermissionFilter(
            user.rolesPermissions,
            item.controllerName || '',
          ).length > 0
        )
          filterList.push(item);
      });
    }
    return filterList;
  };

  return (
    <aside
      className={`duration-8000 group relative z-30 h-full overflow-hidden text-blue-600 transition-all ${
        sidebarHidden ? 'w-20' : 'w-69'
      }`}>
      <div className="flex h-full flex-row">
        <div className="h-full flex-1 border-r border-table-border bg-grey-soft px-4 pb-5">
          <div>
            <div className="flex h-14 items-center py-2">
              <img
                src={`${EXTERNAL_ROUTES.AMAZON_S3_IMAGES}/Ifeel_pos_rgb@0.5x-min.png`}
                alt="ifeel logo"
                className="!max-h-12 w-full !max-w-12"
              />
            </div>
            <ul className="mt-5">
              {getMenuList().map((item: MenuLiList, index: number) =>
                item.controllerName ? (
                  <RBAC key={index} controllerName={item.controllerName}>
                    <LiNavbar
                      name={item.name}
                      url={item.url}
                      isSidebarHidden={sidebarHidden}>
                      {item.svg}
                    </LiNavbar>
                  </RBAC>
                ) : (
                  <LiNavbar
                    key={index}
                    name={item.name}
                    url={item.url}
                    isSidebarHidden={sidebarHidden}>
                    {item.svg}
                  </LiNavbar>
                ),
              )}
            </ul>
          </div>
        </div>
        <div className="h-full w-5 bg-white">
          <div
            className="group absolute top-1/2 z-50 ml-1 h-8 cursor-pointer p-2 text-primary"
            onClick={() => handleToggleSidebar()}>
            <span
              className={`${sidebarHidden ? 'group-hover:-rotate-45' : 'group-hover:rotate-45'} absolute top-2 h-2.5 rotate-0 border-l-2 bg-grey-softDarker transition-all duration-200 group-hover:border-grey-dark`}></span>
            <span
              className={`${sidebarHidden ? 'group-hover:rotate-45' : 'group-hover:-rotate-45'} absolute top-3.5 h-2.5 border-l-2 bg-grey-softDarker transition-all duration-200 group-hover:border-grey-dark`}></span>
          </div>
        </div>
      </div>
    </aside>
  );
};
