import {Checkbox, TextField, styled} from '@mui/material';

export const styles = {
  textField: {
    width: '100%',
    outline: 'none important',
  },
  input: {
    height: '40px',
    paddingLeft: '20px',
    outline: 'none important',
  },
};

export const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
    width: '100% !important',
  },
  '& .MuiOutlinedInput-root': {
    width: '100% !important',
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    paddingLeft: '10px',

    '& fieldset': {
      borderColor: '#E0E3E7',
      width: '100%',
      height: '46px',
      paddingLeft: '20px',
    },
    '&:hover fieldset, &.Mui-focused fieldset': {
      borderColor: '#9ca3af',
      borderStyle: 'solid',
      borderWidth: '1px',
    },
  },
  '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    paddingLeft: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
});

export const CustomTextFieldTextArea = styled(TextField)({
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
    width: '100% !important',
  },
  '& .MuiOutlinedInput-root': {
    width: '100% !important',
    display: 'flex',
    alignItems: 'center',
    padding: '0px',

    '& fieldset': {
      borderColor: '#E0E3E7',
      width: '100%',
      borderWidth: '1px',
    },
    '&:hover fieldset, &.Mui-focused fieldset': {
      borderColor: '#9ca3af',
      borderStyle: 'solid',
      borderWidth: '1px',
    },
    '& textarea': {
      height: '100%',
      paddingLeft: '10px',
      paddingTop: '10px',
    },
  },
});

export const CustomCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    borderWidth: '1px',
    backgroundColor: 'transparent',
    padding: '0px',
    borderColor: 'transparent',
  },
});
