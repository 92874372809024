import React from 'react';

import {Email, PreviewInfo, Preview} from '../../types';

import {PreviewEmail} from './PreviewEmail';

interface Props {
  isEdit?: boolean;
  previewInfo: PreviewInfo | null;
  setEmailListPost: React.Dispatch<React.SetStateAction<Email[]>>;
}

export const Step2 = ({previewInfo, setEmailListPost, isEdit}: Props) => {
  return (
    previewInfo && (
      <>
        <h6 className="flex-1">Total email checked: {previewInfo.total} </h6>
        {previewInfo.preview.map((previewEmail: Preview, index: number) => {
          return (
            <div key={index} className="min-h-8 p-3 pr-0">
              <PreviewEmail
                emails={previewEmail.emails}
                isSuccessful={previewEmail.success}
                title={previewEmail.title}
                setEmailListPost={setEmailListPost}
                isEdit={isEdit}
              />
            </div>
          );
        })}
      </>
    )
  );
};
