import React, {ReactNode} from 'react';

import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import Button from 'src/components/buttons/Button';

interface GenericFiltersFormProps
  extends React.ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
  isOpen: boolean;
}

export const GenericFiltersForm = ({
  isOpen,
  children,
}: GenericFiltersFormProps) => {
  return (
    <div className={isOpen ? 'popover-custom on' : 'popover-custom'}>
      <div className="content">{children}</div>
    </div>
  );
};

const GenericFiltersFormHeader = ({
  clearForm,
}: {
  clearForm: (e: React.MouseEvent) => void;
}) => (
  <div className="flex items-center justify-between bg-grey-soft p-2 shadow-modalHeader">
    <Button
      buttonType={ButtonType.WHITE}
      size="small"
      onClick={clearForm}
      type="button">
      Clear
    </Button>
    <h4>Filters</h4>
    <Button buttonType={ButtonType.PRIMARY} size="small" type="submit">
      Done
    </Button>
  </div>
);

const GenericFiltersFormBody = ({children}: React.ComponentProps<'div'>) => (
  <div className="max-h-popoverBody overflow-hidden overflow-y-scroll">
    {children}
  </div>
);

GenericFiltersForm.Header = GenericFiltersFormHeader;
GenericFiltersForm.Body = GenericFiltersFormBody;
