import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';

import {SupportSettingsType} from '../action_types/SupportSettingsType';
import {SupportAction} from '../actions/supportSettings';

type DispatchResult = SupportAction | any;

export const getChatStatus = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: SupportSettingsType.SET_CHATS_LOADING});
    const resp = await fetchWithToken(`admin/support`, null, 'POST');
    const body = await resp?.json();
    if (body && body.success) {
      dispatch({
        type: SupportSettingsType.SET_CHATS_STATUS,
        payload: body.supports,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const changeStatusChat = (id: number, state: boolean) => {
  return async (dispatch: Dispatch<SupportAction | any>) => {
    dispatch({type: SupportSettingsType.UPDATE_CHAT_STATUS_LOADING});
    const resp = await fetchWithToken(
      `admin/support/change_status`,
      {user_ids: [id.toString()], state},
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      dispatch({
        type: SupportSettingsType.UPDATE_CHAT_STATUS,
        payload: {id, state},
      });
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};
