import React from 'react';

import {
  InputPassword,
  InputText,
  InputTextArea,
  InputNumber,
  CheckboxGroup,
  SelectAutocomplete,
  DatePicker,
  RichText,
  InputFile,
  InputChip,
  SelectChip,
} from './controlled';

export const Form = (props: React.ComponentProps<'form'>) => (
  <form {...props} />
);

Form.CheckboxGroup = CheckboxGroup;
Form.DatePicker = DatePicker;
Form.InputFile = InputFile;
Form.InputNumber = InputNumber;
Form.Autocomplete = SelectAutocomplete;
Form.InputPassword = InputPassword;
Form.InputText = InputText;
Form.InputTextArea = InputTextArea;
Form.SelectAutocomplete = SelectAutocomplete;
Form.RichText = RichText;
Form.InputChip = InputChip;
Form.SelectChip = SelectChip;
