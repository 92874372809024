import React from 'react';

import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';

import {RootState} from 'src/state';

export const PublicRoute = ({
  component: Component,
}: {
  component: React.ComponentType<any>;
}) => {
  const {user} = useSelector((state: RootState) => state.auth);
  const isAuthenticated = !!user;
  return isAuthenticated ? <Navigate to="/" /> : <Component />;
};
