import dayjs from 'dayjs';

import {parseUnderscore} from 'src/helpers/generalFunctions';
import {Event} from 'src/types/Event';

export function renderTableCell(
  column: {key: keyof Event; name: string},
  event: Event,
) {
  switch (column.key) {
    case 'createdAt':
      return dayjs(event[column.key]).format('LLL');
    case 'typeOfEvent':
      return parseUnderscore(event[column.key]);
    default:
      return event[column.key];
  }
}
