import React from 'react';

import {
  GenericTableProps,
  useGenericFiltersForm,
} from 'src/hooks/useGenericFiltersForm';
import {GenericFiltersForm} from 'src/components/generic_table/filters/GenericFiltersForm';
import {Event} from 'src/types/Event';

import {FormValuesEvent} from '../EventsTable';

import {TypeOfEventFilter} from './popover_filters/PsychologistGuideFilter';
import {CreatedAtFilter} from './popover_filters/CreatedAtFilter';
import {UserIdFilter} from './popover_filters/UserIdFilter';

import 'react-datepicker/dist/react-datepicker.css';

export const EventsFiltersForm = ({
  formValues,
  handleToggleFilter,
  initValues,
  setFiltersSelected,
  setFormValues,
  setPage,
  isPopOverOpen,
}: Pick<
  GenericTableProps<Event, FormValuesEvent>,
  | 'formValues'
  | 'handleToggleFilter'
  | 'initValues'
  | 'setFiltersSelected'
  | 'setFormValues'
  | 'setPage'
  | 'isPopOverOpen'
>) => {
  const {handleSubmit, clearForm, updateFormValue} = useGenericFiltersForm({
    formValues,
    setFormValues,
    setPage,
    setFiltersSelected,
    handleToggleFilter,
    initValues,
  });

  return (
    <GenericFiltersForm isOpen={isPopOverOpen}>
      <form onSubmit={handleSubmit} className="popover-filter-patients">
        <GenericFiltersForm.Header clearForm={clearForm} />
        <GenericFiltersForm.Body>
          <CreatedAtFilter
            formValues={formValues}
            updateFormValue={updateFormValue}
          />

          <TypeOfEventFilter
            formValues={formValues}
            updateFormValue={updateFormValue}
          />

          <UserIdFilter
            formValues={formValues}
            updateFormValue={updateFormValue}
          />
        </GenericFiltersForm.Body>
      </form>
    </GenericFiltersForm>
  );
};
