import React, {ComponentProps} from 'react';

import {tableClasses, TableType} from 'src/helpers/set_classes/tableClasses';

import 'src/styles/components/customTable.scss';

interface CustomTableInterface extends ComponentProps<'table'> {
  bodyChildren: React.ReactNode;
  headChildren: React.ReactNode;
  tableType?: TableType;
}

export const CustomTable = ({
  headChildren,
  bodyChildren,
  tableType,
  className,
  ...rest
}: CustomTableInterface) => {
  const typeClasses = tableType ? tableClasses(tableType) : '';
  return (
    <table
      className={`table-custom ${typeClasses} ${className || ''}`}
      {...rest}>
      <thead className="table-head border-b-text ">{headChildren}</thead>
      <tbody>{bodyChildren}</tbody>
    </table>
  );
};
