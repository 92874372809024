import React from 'react';

import toast from 'react-hot-toast';

import {Info} from '@phosphor-icons/react';

import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import {refreshSubscription} from 'src/state/action_creators/patientCreator';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {useSubscription} from 'src/utils/subscriptions-provider';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';

interface Props extends ModalSimpleInterface {
  patientId: number;
}

export const RefreshSubscriptionModal = ({show, setShow, patientId}: Props) => {
  const closeModal = () => setShow!(false);
  const dispatch = useAppDispatch();
  const {setIsReactivateSubscription} = useSubscription();

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(refreshSubscription(patientId)).then(() => {
      toast.success('Subscription refreshed successfully!'),
        setIsReactivateSubscription(false);
    });
  };
  return (
    <ModalLayout
      show={show}
      closeModal={closeModal}
      className="max-h-100 min-h-max max-w-100">
      <form
        className="flex size-full flex-col items-center justify-center gap-8 p-8 text-center"
        onSubmit={handleSubmitForm}>
        <Info color="#5469d4" size={48} />
        <h1 className="text-4.5 font-bold">Sync to Stripe</h1>
        <p className="text-body-16">
          This action can not be undone, are you sure you want to proceed?
        </p>

        <div className="center flex">
          <Button buttonType={ButtonType.SAVE} size="small" type="submit">
            Yes
          </Button>
          <Button
            buttonType={ButtonType.WHITE}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}>
            No
          </Button>
        </div>

        <span className="text-3.5 font-bold text-grey-light">
          This operation can fail if Stripe does not have an active subscription
        </span>
      </form>
    </ModalLayout>
  );
};
