import React, {useState} from 'react';

import {useNavigate} from 'react-router-dom';

import {UserCircle} from '@phosphor-icons/react';

import {startLogout} from 'src/state/action_creators/authActionCreator';
import {ControllerNames} from 'src/types/ControllerNames';
import {useAppDispatch} from 'src/hooks/hooks';
import {RBAC} from 'src/helpers/Rbac';

import {DropdownMenu, MenuListInterface} from '../shared/dropdown/DropdownMenu';
import {SearchBar} from '../navbar/SearchBar';

interface NavbarDropdownItem extends MenuListInterface {
  svg?: React.ReactNode | null;
}
export const Navbar = ({
  sidebarHidden,
  searchParam,
}: {
  searchParam?: string;
  sidebarHidden: boolean;
}) => {
  const [search, setSearch] = useState(searchParam ? searchParam : '');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(startLogout());
  };

  const NAVBAR_DROPDOWN_LI: NavbarDropdownItem[] = [
    {
      name: 'Profile',
      controllerName: ControllerNames.USERS,
      actionName: 'update',
      svg: null,
      onClick: () => navigate('/profile'),
    },
    {
      name: 'Logout',
      onClick: () => handleLogout(),
    },
  ];

  return (
    <div
      className={`home__navbar transition.all fixed top-0 z-10 flex h-14 min-w-204 items-center justify-end bg-white px-12.5 shadow-sm duration-500 ${
        sidebarHidden ? 'w-full' : 'w-full-minus-67.5'
      }`}>
      <RBAC controllerName={ControllerNames.USERS} actionName="index">
        <SearchBar search={search} setSearch={setSearch} />
      </RBAC>
      <DropdownMenu
        dropdownList={NAVBAR_DROPDOWN_LI}
        svg={<UserCircle size={26} weight="thin" />}
        className="text-blue-light hover:text-blue-light69"
      />
    </div>
  );
};
