import React from 'react';

import {Provider as ProviderRedux} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {Toaster} from 'react-hot-toast';

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {ErrorBoundary, Provider} from '@rollbar/react';

import {useImportScript} from 'src/hooks/useImportScript';
import {AppRouter} from 'src/router/AppRouter';

import {SubscriptionProvider} from './utils/subscriptions-provider';
import {configureRollbar} from './config/rollbarConfig';
import {store} from './state';

const rollbarConfig = configureRollbar();

export const DashboardApp = () => {
  useImportScript();

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <ProviderRedux store={store}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SubscriptionProvider>
              <BrowserRouter>
                <AppRouter />
                <Toaster />
              </BrowserRouter>
            </SubscriptionProvider>
          </LocalizationProvider>
        </ProviderRedux>
      </ErrorBoundary>
    </Provider>
  );
};
