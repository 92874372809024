import React from 'react';

import {ArrowsClockwise} from '@phosphor-icons/react';

import {useSubscription} from 'src/utils/subscriptions-provider';
import {ControllerNames} from 'src/types/ControllerNames';
import VideoSvg from 'src/assets/images/icon-video.svg';
import ChatSvg from 'src/assets/images/icon-chat.svg';
import {formatDate} from 'src/helpers/dates';
import {Patient} from 'src/types/Patient';
import {PlanType} from 'src/types/User';
import {RBAC} from 'src/helpers/Rbac';

import {RenderActionsForSubscription} from './RenderActionsForSubscription';

export const PatientSubscriptionStatus = ({patient}: {patient: Patient}) => {
  const {isPlanActive, isPlanPaused, isSetToCancel, isReactivateSubscription} =
    useSubscription();

  return (
    <>
      {patient.currentSubscription ? (
        <div className="flex items-center justify-center">
          <img
            src={patient.planType === PlanType.VIDEO ? VideoSvg : ChatSvg}
            alt="plan img"
          />
          <div className="flex-auto">
            <p>
              {patient.planName}
              {isPlanActive ? (
                <>
                  <span className="badge-text success">
                    {isSetToCancel ? 'STC' : 'Active'}
                  </span>
                  {patient.ghost && (
                    <span className="badge-text inactive">Ghost</span>
                  )}
                </>
              ) : isPlanPaused ? (
                <span className="badge-text paused">Paused</span>
              ) : (
                <span className="badge-text inactive">Cancelled</span>
              )}
            </p>
            <div className="flex gap-2">
              <span>
                {patient.activeUntil
                  ? formatDate(patient.currentSubscription?.current_period_end)
                  : ''}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div>
            <b>{patient.nickname || 'Patient'}</b> does not have any
            subscription yet
          </div>
        </>
      )}
      {isReactivateSubscription ? (
        <div className="flex max-w-75">
          If you have reactivated your subscription, please wait a few seconds
          and reload in .<ArrowsClockwise size={16} />
        </div>
      ) : (
        <RBAC
          controllerName={ControllerNames.SUBSCRIPTIONS}
          actionName="update">
          <RenderActionsForSubscription />
        </RBAC>
      )}
    </>
  );
};
