import React from 'react';

export const ProgressStepVertical = ({
  steps,
  activeStep,
}: {
  activeStep: number;
  steps: string[];
}) => {
  const getActiveStep = (index: number) => {
    if (activeStep === index + 1) return 'active';
    if (activeStep > index) return 'completed';
    return '';
  };
  return (
    // TODO: Replace 'steps-container' with Tailwind CSS classes
    <div className="steps-container">
      <ul className="">
        {steps.map((x, i) => (
          <li key={i} className={getActiveStep(i)}>
            {x}
          </li>
        ))}
      </ul>
    </div>
  );
};
