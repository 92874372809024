export enum RoutesPath {
  ACCEPT_INVITATION = '/accept_invitation',
  DASHBOARD = '/dashboard',
  EMAIL_AUTHORIZATIONS = '/email_authorizations',
  EVENTS = '/events',
  HOME = '/',
  INCOMING_PATIENTS = '/incoming_patients',
  LOGIN = '/login',
  OPERATION_DASHBOARD = '/operation_dashboard',
  PARTNERS = '/partners',
  PATIENT = '/patient/:id',
  PATIENTS = '/patients',
  PILLS = '/pills',
  PLANS = '/plans',
  PROFILE = '/profile',
  RECENT_ACTIVITY = '/recent_activity/:id',
  ROLES = '/roles',
  SEARCH = '/search',
  SUPERVISORS = '/supervisors',
  SUPPORT_SETTINGS = '/support_settings',
  THERAPIST = '/therapist/:id',
  THERAPISTS = '/therapists',
  TOOLS = '/tools',
  USERS_AND_ROLES = '/users_and_roles',
  VIDEO_SESSIONS = '/video_sessions',
  WEBHOOKS = '/webhooks',
}
