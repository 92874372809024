import {SharedActionType} from '@ifeelonline/chat-core';

import {DashboardActionType} from '../action_types/DashboardActionType';
import {DashboardAction} from '../actions/dashboards';
import {FixMeLater} from '../interfaces/FixMeLater';

interface DashboardState {
  partnersListSelector: FixMeLater;
  status: SharedActionType;
}

const initialState = {
  status: SharedActionType.IDLE,
  partnersListSelector: null,
};

export const dashboardReducer = (
  state: DashboardState = initialState,
  action: DashboardAction,
) => {
  switch (action.type) {
    case DashboardActionType.SET_SELECTORS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case DashboardActionType.SET_SELECTORS:
      return {
        ...state,
        partnersListSelector: action.payload.partners,
      };

    default:
      return state;
  }
};
