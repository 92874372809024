import React, {useState} from 'react';

import {Layout} from 'src/components/layout/Layout';
import {TherapistsTable} from 'src/pages/therapists/TherapistsTable';
import {TherapistsProvider} from 'src/providers/TherapistsTableProvider';
import {Page} from 'src/types/Page';

export const TherapistsPage = ({controllerName}: Page) => {
  const [overflowHidden, setOverflowHidden] = useState('');

  return (
    <Layout className={overflowHidden}>
      <TherapistsProvider>
        <TherapistsTable
          controllerName={controllerName}
          setOverflowHidden={setOverflowHidden}
        />
      </TherapistsProvider>
    </Layout>
  );
};
