import React from 'react';

import {EXTERNAL_ROUTES} from 'src/constants/Routes';

import {LoginForm} from './components/LoginForm';

export const LoginPage = () => (
  <div className="login">
    <div className="login__decoration_bg"></div>
    <div className="login__container">
      <div className="login__logo">
        <img
          src={`${EXTERNAL_ROUTES.AMAZON_S3_IMAGES}/Ifeel_pos_rgb@0.5x-min.png`}
          alt="ifeel logo"
        />
      </div>
      <LoginForm />
    </div>
  </div>
);
