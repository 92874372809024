import {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';
import {
  getEmailAuthorizations,
  getEmailAuthorizationsOptions,
} from 'src/state/action_creators/emailAuthorizationsCreator';
import {PartnerSelector} from 'src/types/Partner';

interface Filters {
  emailToFilter: string;
  partnerToFilter: PartnerSelector | null;
}

interface Params {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const useEmailAuthorizationFiltersForm = ({page, setPage}: Params) => {
  const dispatch = useAppDispatch();
  const {partnersListSelector} = useSelector(
    (state: RootState) => state.email_authorizations,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailToFilter, setEmailToFilter] = useState('');
  const [partnerToFilter, setPartnerToFilter] =
    useState<PartnerSelector | null>(null);
  const [activeFilters, setActiveFilters] = useState<Filters | null>(null);

  useEffect(() => {
    if (!partnersListSelector) {
      dispatch(getEmailAuthorizationsOptions());
    }
    handleSubmitFilters(emailToFilter, partnerToFilter);
  }, [partnersListSelector]);

  const handleClearFilter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    field: string,
  ) => {
    event.preventDefault();
    setPage(1);
    if (field === 'email') {
      setEmailToFilter('');
      handleSubmitFilters('', partnerToFilter);
    } else {
      setPartnerToFilter(null);
      handleSubmitFilters(emailToFilter, null);
    }
  };

  const handleSubmitFilters = (
    emailValue: string,
    partnerObjValue: PartnerSelector | null,
  ) => {
    setIsSubmitting(true);
    dispatch(
      getEmailAuthorizations(
        page,
        emailValue !== '' ? emailValue : null,
        partnerObjValue?.id,
      ),
    )
      .then(() => {
        if (emailValue || partnerObjValue) {
          setActiveFilters({
            emailToFilter: decodeURIComponent(emailValue),
            partnerToFilter: partnerObjValue,
          });
        } else {
          setActiveFilters(null);
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const onSubmitFilters = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(1);
    handleSubmitFilters(encodeURIComponent(emailToFilter), partnerToFilter);
  };

  return {
    onSubmitFilters,
    handleClearFilter,
    activeFilters,
    isSubmitting,
    partnerToFilter,
    setPartnerToFilter,
    emailToFilter,
    setEmailToFilter,
  };
};
