import React from 'react';

import {Patient} from 'src/types/Patient';
import {LabelValueDisplay} from 'src/components/LabelValueDisplay';
import {getPatientGeneralDetails} from 'src/helpers/data_generation/patient';

export const PatientDetailsSection = ({patient}: {patient: Patient}) => {
  const patientDetails = getPatientGeneralDetails(patient);
  return (
    <div className="user__plan user__section">
      <h3>Details</h3>
      <div className="mt-5 grid grid-cols-2 gap-2">
        {patientDetails &&
          patientDetails.map((item) => {
            return (
              <LabelValueDisplay
                key={item.label}
                label={item.label}
                value={item.value}
              />
            );
          })}
      </div>
    </div>
  );
};
