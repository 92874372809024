import {PartnerStorage, Partner} from 'src/types/Partner';

export const mapApiResponsePartner = (jsonBack: PartnerStorage): Partner => ({
  id: jsonBack.id || 0,
  name: jsonBack.name || '',
  code: jsonBack.code || '',
  companyId: jsonBack.company_id || 0,
  boughtVideoSessions: jsonBack.bought_video_sessions || 0,
  multiplier: jsonBack.multiplier || 0,
  companyName: jsonBack.company_name || '',
  companyImage: jsonBack.company_image || null,
  partnerImage: jsonBack.partner_image || null,
});
