import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import {Form} from 'react-bootstrap';
import toast from 'react-hot-toast';

import {createSubscription} from 'src/state/action_creators/subscriptionsCreator';
import {getPatientsOptions} from 'src/state/action_creators/patientsCreator';
import {getPatient} from 'src/state/action_creators/patientCreator';
import {useSubscription} from 'src/utils/subscriptions-provider';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {Loading} from 'src/components/shared/Loading';
import {useAppDispatch} from 'src/hooks/hooks';
import {Plan} from 'src/types/Plan';
import {RootState} from 'src/state';

export const CreateSubscriptionModal = () => {
  const dispatch = useAppDispatch();
  const {patient, plansList} = useSelector((state: RootState) => state.patient);

  const [newPlanSelectedID, setNewPlanSelectedID] = useState<number | null>(
    null,
  );
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const {isModalCreateSubscriptionOpen, setIsModalCreateSubscriptionOpen} =
    useSubscription();

  useEffect(() => {
    if (!plansList) {
      dispatch(getPatientsOptions());
    } else {
      setNewPlanSelectedID(plansList[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansList]);

  const closeModal = () => setIsModalCreateSubscriptionOpen(false);

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (patient) {
      setLoadingSubmit(true);
      const planSelected = plansList?.find(
        (plan: Plan) => plan.id === newPlanSelectedID,
      );
      if (!planSelected) return;
      const data = {
        id: patient.id,
        stripePlan: planSelected.externalId,
        planId: planSelected.id,
      };

      dispatch(createSubscription(data)).then((res) => {
        if (res) {
          toast.success('Subscription created successfully');
          closeModal();
          dispatch(getPatient(patient.id));
        }
        setLoadingSubmit(false);
      });
    }
  };

  return (
    <ModalLayout show={isModalCreateSubscriptionOpen} closeModal={closeModal}>
      {patient && plansList ? (
        <form className="allInputsBlock container" onSubmit={handleSubmitForm}>
          <ModalLayout.Header>
            <h3>Create subscription</h3>
          </ModalLayout.Header>
          <ModalLayout.Body>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label>Select new plan</Form.Label>
              <Form.Control
                as="select"
                name="plan"
                onChange={(e) => setNewPlanSelectedID(Number(e.target.value))}>
                {plansList.map((plan: Plan, i: number) => (
                  <option key={i} value={plan.id}>
                    {plan.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </ModalLayout.Body>
          <ModalLayout.Footer>
            <ButtonSubmit loadingSubmit={loadingSubmit} text="Confirm" />
          </ModalLayout.Footer>
        </form>
      ) : (
        <Loading />
      )}
    </ModalLayout>
  );
};
