import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import Button from 'src/components/buttons/Button';
import {Form} from 'src/components/form/Form';

import {Loading} from '../../../components/shared/Loading';
import {useRolesForm} from '../hooks/useRolesForm';
import {RolesTableStrings} from '../types';
import {RootState} from '../../../state';

import {CheckableTable} from './CheckableTable';

interface NewRoleModal extends ModalSimpleInterface {
  orderBy: string;
  orderDir: string;
  page: number;
}

export const NewRoleModal = ({
  show,
  setShow,
  page,
  orderBy,
  orderDir,
}: NewRoleModal) => {
  const closeModal = () => setShow!(false);
  const {control, onSubmit, isSubmitting, isLoadingOnSubmit} = useRolesForm({
    closeModal,
    page,
    orderBy,
    orderDir,
  });
  const [actionListComplete, setActionListComplete] =
    useState<Array<string> | null>(null);
  const {status, controllables, actionsList} = useSelector(
    (state: RootState) => state.roles,
  );
  useEffect(() => {
    if (show) {
      if (actionListComplete === null) {
        setActionListComplete([
          RolesTableStrings.CONTROLLER,
          RolesTableStrings.ALL,
          ...actionsList,
        ]);
      }
    }
  }, [show]);

  return (
    <ModalLayout show={show} closeModal={closeModal}>
      <div className="rounded-1 bg-white">
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit(event);
          }}>
          <ModalLayout.Header>
            <h3>Create new role</h3>
          </ModalLayout.Header>
          <ModalLayout.Body>
            <Form.InputText
              name="name"
              label="Role Name"
              control={control}
              placeholder="Enter the role name"
            />
            {status === SharedActionType.LOADING ? (
              <Loading />
            ) : (
              <CheckableTable
                label="Select permissions"
                indexName="controller"
                checkableValues={actionsList}
                control={control}
                selectAllOption={true}
                checkableRows={controllables?.sort()}
              />
            )}
          </ModalLayout.Body>
          <ModalLayout.Footer>
            <Button
              buttonType={ButtonType.SAVE}
              size="small"
              type="submit"
              loading={isLoadingOnSubmit}>
              {isLoadingOnSubmit ? 'Saving...' : 'Save'}
            </Button>
          </ModalLayout.Footer>
        </Form>
      </div>
    </ModalLayout>
  );
};
