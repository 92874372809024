import React from 'react';

import {Warning} from '@phosphor-icons/react';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FormTextInfo} from 'src/components/form/FormText';
import {ErrorText} from 'src/components/forms/ErrorText';
import {Form} from 'src/components/form/Form';

import {useEditPillForm} from '../hook/useEditPillForm';
import {Areas} from '../types';

interface Props {
  closeModal: () => void;
  pillId: number;
}

export const EditPillForm = ({closeModal, pillId}: Props) => {
  const {
    control,
    onSubmit,
    isSubmitting,
    hasErrors,
    setFileLogo,
    setFileBackground,
    setFileThumbnail,
    setLocaleName,
    pillEdit,
    deprecatedArea,
    languageSelected,
    pillsOptions,
    languages,
    valuesHaveChanged,
  } = useEditPillForm({
    closeModal,
    pillId,
  });

  if (!pillEdit) return <></>;

  return (
    <Form
      onSubmit={onSubmit}
      className="flex size-full flex-col justify-between">
      <ModalLayout.Header>
        <h3>Edit Pill</h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        <Form.SelectAutocomplete
          control={control}
          name="areaId"
          label="Area"
          options={pillsOptions.areas.map((a: Areas) => a.title)}
        />

        {deprecatedArea && (
          <ErrorText
            error={`This pill uses an outdated area (${deprecatedArea}). Before editing, please select a valid area. By default, it will be classified as ${pillsOptions.areas[0].title}.`}
          />
        )}
        <Form.SelectAutocomplete
          control={control}
          name="typeOfPill"
          label="Type Of Pill"
          options={pillsOptions.typeOfPills.map((p: {name: string}) => p.name)}
        />

        <Form.InputFile
          control={control}
          name="logo"
          label="Logo"
          shouldShowPreview={true}
          setFile={setFileLogo}
          urlFile={pillEdit?.logoUrl || undefined}
        />

        <Form.InputFile
          control={control}
          name="background"
          label="Background"
          shouldShowPreview={true}
          setFile={setFileBackground}
          urlFile={pillEdit?.backgroundUrl || undefined}
        />

        <Form.InputFile
          control={control}
          name="thumbnail"
          label="Thumbnail"
          shouldShowPreview={true}
          setFile={setFileThumbnail}
          urlFile={pillEdit?.thumbnailUrl || undefined}
        />

        <Form.CheckboxGroup
          control={control}
          name="activePill"
          options={['Active']}
          label=""
        />

        <div className="flex w-full flex-col items-start gap-3 rounded-1 border p-2.5 text-start">
          <FormTextInfo text=" These fields can be edited for different languages. Remember to save before switching to another language" />
          {languages && (
            <Form.SelectAutocomplete
              control={control}
              name="locale"
              label="Language"
              options={languages.map((p: {name: string}) => p.name)}
              setField={setLocaleName}
            />
          )}

          <Form.InputText
            control={control}
            name="header"
            label={`* Header in ${languageSelected}`}
          />
          <Form.InputText
            control={control}
            name="title"
            label={`* Title in ${languageSelected}`}
          />
          <Form.RichText
            control={control}
            name="description"
            label={`* Description in ${languageSelected}`}
          />
        </div>
      </ModalLayout.Body>
      <ModalLayout.Footer>
        {hasErrors && <Warning color="red" />}
        <ButtonSubmit
          loadingSubmit={isSubmitting}
          disabled={valuesHaveChanged()}
        />
      </ModalLayout.Footer>
    </Form>
  );
};
