import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {SharedActionType} from '@ifeelonline/chat-core';

import {
  ArrowSquareUpRight,
  Copy,
  Pencil,
  Plus,
  Trash,
} from '@phosphor-icons/react';
import Swal from 'sweetalert2';

import {
  deletePartner,
  getPartners,
} from 'src/state/action_creators/partnerCreator';
import {PaginationComponent} from 'src/components/shared/PaginationComponent';
import {PartnersActionType} from 'src/state/action_types/PartnersActionType';
import {copyToClipboard, openInNewTab} from 'src/helpers/generalFunctions';
import ButtonActionTable from 'src/components/buttons/ButtonActionTable';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {CustomTable} from 'src/components/tables/CustomTable';
import {PageHeader} from 'src/components/layout/PageHeader';
import {partnerLandingUrl} from 'src/constants/Routes';
import {Loading} from 'src/components/shared/Loading';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';
import {Partner} from 'src/types/Partner';
import {RBAC} from 'src/helpers/Rbac';
import {RootState} from 'src/state';

import {PartnerEditForm} from './components/PartnerEditForm';
import {PartnerNewForm} from './components/PartnerNewForm';

export const PartnersTable = ({controllerName}: {controllerName: string}) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [isAddPartnerModalOpen, setIsAddPartnerModalOpen] = useState(false);
  const [isEditPartnerModalOpen, setIsEditPartnerModalOpen] = useState(false);

  const {status, partners, total} = useSelector(
    (state: RootState) => state.partners,
  );

  useEffect(() => {
    dispatch(getPartners(page));
  }, [page]);

  const handleEditClick = (partner: Partner) => {
    dispatch({
      type: PartnersActionType.SET_PARTNER_ACTIVE,
      payload: partner,
    });
    setIsEditPartnerModalOpen(true);
  };

  const handleDeleteClick = (partner: Partner) => {
    Swal.fire({
      icon: 'warning',
      title: `Disable partner ${partner.name}`,
      text: 'You are about to disable a partner. This action is irreversible. Please make sure the associated users have been previously turn into B2C.',
      showCancelButton: true,
      allowOutsideClick: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      backdrop: true,
    }).then(({isConfirmed}) => {
      if (isConfirmed) {
        dispatch(deletePartner(partner.id)).then((res) => {
          if (res) {
            toast.success('Partner deleted successfully');
            dispatch(getPartners(page));
          }
        });
      }
    });
  };

  return status === SharedActionType.LOADING ? (
    <Loading />
  ) : (
    <div>
      <PageHeader pageTitle="Partners">
        <div className="flex">
          <RBAC controllerName={controllerName} actionName="create">
            <Button
              buttonType={ButtonType.SAVE}
              size="small"
              onClick={() => {
                setIsAddPartnerModalOpen(true);
              }}>
              <Plus size={18} className="mr-1" /> Add partner
            </Button>
          </RBAC>
        </div>
      </PageHeader>
      {partners && (
        <CustomTable
          headChildren={
            <tr>
              <th>id</th>
              <th>Name</th>
              <th>Code</th>
              <th>Company</th>
              <th className="text-center">Actions</th>
              <th>URL</th>
            </tr>
          }
          bodyChildren={partners.map((partner: Partner, index: number) => (
            <tr key={index}>
              <td>{partner.id}</td>
              <td>{partner.name}</td>
              <td>{partner.code}</td>
              <td>{partner.companyName}</td>
              <td className="actions text-center">
                <ButtonActionTable onClick={() => handleEditClick(partner)}>
                  <Pencil size={18} />
                </ButtonActionTable>
                <RBAC controllerName={controllerName} actionName="destroy">
                  <ButtonActionTable onClick={() => handleDeleteClick(partner)}>
                    <Trash size={18} />
                  </ButtonActionTable>
                </RBAC>
              </td>
              <td className="actions text-center">
                <ButtonActionTable
                  onClick={() =>
                    copyToClipboard(partnerLandingUrl(partner.name))
                  }>
                  <Copy size={18} />
                </ButtonActionTable>
                <ButtonActionTable
                  className="mx-1 transition-all hover:text-primary"
                  onClick={() => openInNewTab(partnerLandingUrl(partner.name))}>
                  <ArrowSquareUpRight size={18} />
                </ButtonActionTable>
              </td>
            </tr>
          ))}
        />
      )}
      {total && (
        <PaginationComponent total={total} page={page} setPage={setPage} />
      )}

      <ModalLayout
        show={isAddPartnerModalOpen}
        closeModal={() => setIsAddPartnerModalOpen(false)}>
        <PartnerNewForm closeModal={() => setIsAddPartnerModalOpen(false)} />
      </ModalLayout>

      <ModalLayout
        show={isEditPartnerModalOpen}
        closeModal={() => setIsEditPartnerModalOpen(false)}>
        <PartnerEditForm closeModal={() => setIsEditPartnerModalOpen(false)} />
      </ModalLayout>
    </div>
  );
};
