import {SharedActionType} from '@ifeelonline/chat-core';

import {Plan} from 'src/types/Plan';

import {PlansActionType} from '../action_types/PlansActionType';
import {PlansAction} from '../actions/plans';

interface PlansState {
  error: null | string;
  plans: Plan[];
  status: SharedActionType;
}

const initialState = {
  plans: [],
  status: SharedActionType.IDLE,
  error: null,
};

export const plansReducer = (
  state: PlansState = initialState,
  action: PlansAction,
) => {
  switch (action.type) {
    case PlansActionType.PLANS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case PlansActionType.SET_PLANS:
      return {
        ...state,
        plans: action.payload.plans,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
