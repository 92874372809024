import React, {ComponentProps} from 'react';

import 'src/styles/components/shared/buttons/buttons.scss';

export default function ButtonSimple({
  className,
  children,
  onClick,
  title,
  ...props
}: ComponentProps<'button'>) {
  return (
    <button className={className} title={title} onClick={onClick} {...props}>
      {children}
    </button>
  );
}
