import React, {useState} from 'react';

import {VideoSessionsTable} from 'src/pages/video_sessions/VideoSessionsTable';
import {Layout} from 'src/components/layout/Layout';
import {Page} from 'src/types/Page';

export const VideoSessionsPage = ({controllerName}: Page) => {
  const [overflowHidden, setOverflowHidden] = useState('');
  return (
    <Layout className={overflowHidden}>
      <VideoSessionsTable
        controllerName={controllerName}
        setOverflowHidden={setOverflowHidden}
      />
    </Layout>
  );
};
