import React from 'react';

import {useSelector} from 'react-redux';

import {Check} from '@phosphor-icons/react';

import {rolesPermissionFilter} from 'src/helpers/rolesFunctions';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {RootState} from 'src/state';

interface CRMInterface {
  action: string;
  controllerName: string;
  handleCheckboxChange: FixMeLater;
  rolesPermissionsSelected: FixMeLater;
}

export const CheckRolesModal = ({
  rolesPermissionsSelected,
  controllerName,
  action,
  handleCheckboxChange,
}: CRMInterface) => {
  const {actionsList} = useSelector((state: RootState) => state.roles);
  const includeController = () =>
    rolesPermissionFilter(rolesPermissionsSelected, controllerName).length > 0;

  const includeAction = () => {
    if (action === 'all') {
      return (
        rolesPermissionFilter(rolesPermissionsSelected, controllerName)[0]
          .actions.length === actionsList.length
      );
    } else {
      return rolesPermissionFilter(
        rolesPermissionsSelected,
        controllerName,
      )[0].actions.includes(action);
    }
  };

  return (
    <td
      onClick={(e) => handleCheckboxChange(e, controllerName, action)}
      className="w-19 cursor-pointer">
      {includeController() && includeAction() && <Check size={12} />}
    </td>
  );
};
