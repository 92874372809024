import {AuthActionType} from 'src/state/action_types/AuthActionType';
import {AuthAction} from 'src/state/actions/auth';
import {AuthUser} from 'src/types/Auth';

interface AuthState {
  checking: boolean;
  connection: boolean;
  user: AuthUser | null;
}

const initialState = {
  checking: true,
  connection: true,
  user: null,
};

export const authReducer = (
  state: AuthState = initialState,
  action: AuthAction,
) => {
  switch (action.type) {
    case AuthActionType.AUTH_LOGIN:
      return {
        user: action.payload,
        checking: false,
        connection: true,
      };

    case AuthActionType.AUTH_CHECKING_FINISH:
      return {
        checking: false,
        connection: true,
        user: null,
      };

    case AuthActionType.AUTH_LOGOUT:
      return {
        checking: false,
        connection: true,
        user: null,
      };

    default:
      return state;
  }
};
