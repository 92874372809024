import React, {useEffect, useState} from 'react';

import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';

import {NameValue} from '@ifeelonline/chat-core';

import Button from 'src/components/buttons/Button';
import {ErrorText} from 'src/components/forms/ErrorText';
import {InputPassword} from 'src/components/forms/InputPassword';
import {EXTERNAL_ROUTES} from 'src/constants/Routes';
import {passwordRegExp} from 'src/helpers/regularExp';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {validatePasswords} from 'src/helpers/validatePassword';
import {useAppDispatch} from 'src/hooks/hooks';
import {useForm} from 'src/hooks/useForm';
import {useQuery} from 'src/hooks/useQuery';
import {acceptInvitation} from 'src/state/action_creators/authActionCreator';

export const AcceptInvitationPage = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const invitationToken = query.get('invitation_token');
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | null>(null);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const [formValues, handleInputChange] = useForm({
    password: '',
    repeatPassword: '',
  });

  const {password, repeatPassword} = formValues;

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    const passwordConfirm = validatePasswords(
      password,
      repeatPassword,
      setError,
    );
    if (passwordConfirm) {
      dispatch(
        acceptInvitation(invitationToken!, password, repeatPassword),
      ).then((res) => {
        if (res.success) {
          toast.success(
            'Your password has been successfully updated, you can now login',
          );
          setTimeout(() => {
            navigate(`/login?email=${encodeURIComponent(res.email)}`);
          }, 1000);
        } else {
          setError(null);
        }
      });
    }
  };

  useEffect(() => {
    setDisableSubmit(
      !passwordRegExp.test(password) || !passwordRegExp.test(repeatPassword),
    );
  }, [formValues]);

  const PasswordInputs: NameValue[] = [
    {label: 'Create password', name: 'password', value: password},
    {
      label: 'Confirm password',
      name: 'repeatPassword',
      value: repeatPassword,
    },
  ];

  return (
    <div className="relative h-screen w-screen bg-white">
      <div className="login__decoration_bg"></div>
      <div className="m-auto flex h-full w-150 items-center justify-center">
        <div className="login__container px-0 py-12.5">
          <div className="login__logo">
            <img
              src={`${EXTERNAL_ROUTES.AMAZON_S3_IMAGES}/Ifeel_pos_rgb@0.5x-min.png`}
              alt="ifeel logo"
            />
          </div>
          <div className={`login__box-white ${error ? 'shake' : ''}`}>
            <h3 className="auth__title">Accept invitation</h3>
            <p className="mb-5 rounded-2.5 border border-table-border bg-blue-light px-5 py-4 text-blue-primary">
              You have been invited to join the ifeel dashboard. Add a new
              password to enter after accepting the invitation.
            </p>
            <form
              onSubmit={handleLogin}
              className="animate__animated animate__fadeIn animate__faster">
              {PasswordInputs.map(
                ({label, name, value}: NameValue, i: number) => (
                  <InputPassword
                    key={i}
                    name={name}
                    value={value}
                    handleInputChange={(event) => {
                      setError(null);
                      handleInputChange(event);
                    }}
                    label={label as string}
                  />
                ),
              )}
              {error && <ErrorText error={error} />}

              <div className="mt-5">
                <Button
                  buttonType={ButtonType.PRIMARY}
                  disabled={disableSubmit}
                  size="medium">
                  Accept
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
