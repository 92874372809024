import {useState} from 'react';

import {useForm, SubmitHandler} from 'react-hook-form';
import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {yupResolver} from '@hookform/resolvers/yup';

import {RootState} from 'src/state';
import {editPartner} from 'src/state/action_creators/partnerCreator';
import {useAppDispatch} from 'src/hooks/hooks';
import {createFormData} from 'src/helpers/form';
import {
  FileType,
  FileTypeStatus,
  fileInit,
} from 'src/components/form/controlled/type';

import {partnersModalEditSchema} from '../schemas/partnersSchema';
import {PartnerEditFormFields} from '../components/types';

interface Params {
  closeModal: () => void;
}

export const usePartnerEditForm = ({closeModal}: Params) => {
  const dispatch = useAppDispatch();
  const [fileAvatar, setFileAvatar] = useState<FileType>(fileInit);
  const {partnerActive} = useSelector((state: RootState) => state.partners);

  const initialValues: PartnerEditFormFields = {
    boughtVideoSessions: partnerActive?.boughtVideoSessions || 0,
    multiplier: partnerActive?.multiplier || 1000,
    partnerImage: partnerActive?.partnerImage || null,
  };

  const {
    control,
    handleSubmit,
    formState: {isSubmitting, errors},
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(partnersModalEditSchema),
    mode: 'onBlur',
  });

  const valuesHaveChanged = () => {
    const currentValues = watch();

    const boughtVideoSessionsSameValue =
      partnerActive?.boughtVideoSessions !== undefined
        ? Number(currentValues.boughtVideoSessions) ===
          partnerActive?.boughtVideoSessions
        : currentValues.boughtVideoSessions === 0;
    const multiplierSameValue =
      partnerActive?.multiplier !== undefined
        ? Number(currentValues.multiplier) === partnerActive?.multiplier
        : currentValues.multiplier === 1000;

    return (
      boughtVideoSessionsSameValue &&
      multiplierSameValue &&
      fileAvatar.status === 'original'
    );
  };

  const onSubmit: SubmitHandler<PartnerEditFormFields> = (data) => {
    const formData = createFormData(data);
    if (fileAvatar.status !== FileTypeStatus.ORIGINAL)
      formData.set('partnerImage', fileAvatar.file || '');

    if (!partnerActive?.id) return;

    dispatch(editPartner(partnerActive?.id, formData)).then((res) => {
      if (res) {
        toast.success('Partner successfully edited');
        closeModal();
      }
    });
  };

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    isSubmitting,
    hasErrors: Object.keys(errors).length > 0,
    urlFileAvatar: partnerActive?.partnerImage,
    setFileAvatar,
    valuesHaveChanged,
  };
};
