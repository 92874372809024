import {Age} from 'src/types/Patient';

const ageRange: Record<Age, string> = {
  'age-18': '(0 - 17)',
  'age-24': '(18 - 24)',
  'age-34': '(25 - 34)',
  'age-44': '(35 - 44)',
  'age-54': '(45 - 54)',
  'age-64': '(55 - 64)',
  'age-65': '(65 +)',
};

export const getAgeRange = (age: Age) => {
  return ageRange[age];
};
