import {useEffect, useState} from 'react';

import {Control, SubmitHandler, useForm} from 'react-hook-form';
import toast from 'react-hot-toast';
import {useSelector} from 'react-redux';

import {yupResolver} from '@hookform/resolvers/yup';

import {
  FileType,
  FileTypeStatus,
  fileInit,
} from 'src/components/form/controlled/type';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';
import {getEmailAuthorizations} from 'src/state/action_creators/emailAuthorizationsCreator';

import {FormValues} from '../components/forms/CSVWithPreviewForm';
import {
  csvSchema,
  csvSchemaWithPartner,
} from '../components/forms/schemas/csvSchemas';
import {Email, Preview, PreviewInfo} from '../types';

const initialValues: FormValues = {
  partner_name: undefined,
  csv: null,
};

interface Params {
  canSelectPartner: boolean;
  closeModal: () => void;
  dispatchPreview: any;
  dispatchSave: any;
  formatPreviewRes: any;
  isModalOpen: boolean;
  setStepForm: React.Dispatch<React.SetStateAction<number>>;
  stepForm: number;
}

export interface UseCSVWithPreviewFormReturn {
  control: Control<FormValues, any>;
  hasErrors: boolean;
  isSubmitting: boolean;
  onSubmit: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>;
  previewInfo: PreviewInfo | null;
  setEmailListPost: React.Dispatch<React.SetStateAction<Email[]>>;
  setUploadedCsv: React.Dispatch<React.SetStateAction<FileType>>;
  valuesHaveChanged: () => boolean;
}

export const useCSVWithPreviewForm = ({
  canSelectPartner,
  closeModal,
  dispatchPreview,
  dispatchSave,
  formatPreviewRes,
  isModalOpen,
  setStepForm,
  stepForm,
}: Params): UseCSVWithPreviewFormReturn => {
  const dispatch = useAppDispatch();
  const {partnersListSelector} = useSelector(
    (state: RootState) => state.email_authorizations,
  );
  const [uploadedCsv, setUploadedCsv] = useState<FileType>(fileInit);
  const [previewInfo, setPreviewInfo] = useState<PreviewInfo | null>(null);
  const [emailListPost, setEmailListPost] = useState<Email[]>([]);

  useEffect(() => {
    if (isModalOpen) {
      setUploadedCsv(fileInit);
      setPreviewInfo(null);
    }
  }, [isModalOpen, partnersListSelector]);

  const handleProcessEmails = (values: FormValues) => {
    const newPreviewInfo = {
      preview:
        previewInfo?.preview.map((categories: Preview) => {
          if (
            categories.emails === emailListPost ||
            categories.emails.length === 0
          ) {
            categories.emails = [];
            categories.success = false;
          }
          return categories;
        }) || [],
      total: previewInfo?.total || 0,
    };
    setPreviewInfo(newPreviewInfo);

    const selectedPartnerId = filterPartnerId(values.partner_name);

    const processMissing = newPreviewInfo.preview.filter(
      ({success}: Preview) => success === true,
    );

    if (newPreviewInfo.preview)
      dispatch(dispatchSave(emailListPost, selectedPartnerId)).then(
        (res: any) => {
          if (res) {
            toast.success('Emails have been successfully processed');
            dispatch(getEmailAuthorizations(1, null));
            if (processMissing.length === 0) {
              closeModal();
            }
          }
        },
      );
  };

  const filterPartnerId = (partnerName: string | undefined) => {
    if (partnersListSelector) {
      const partnersId = partnersListSelector
        .filter((partner) => partnerName === partner.name.toLowerCase())
        .map(({id}) => id);
      return (partnersId[0] ? partnersId[0] : 0).toString();
    }
    return null;
  };

  const onSubmit: SubmitHandler<FormValues> = (values: FormValues) => {
    const formData = new FormData();
    if (uploadedCsv.status !== FileTypeStatus.ORIGINAL) {
      formData.append('csv', uploadedCsv.file || '');
    }
    if (values.partner_name) {
      const partnerId = filterPartnerId(values.partner_name);
      partnerId && formData.append('partner_id', partnerId);
    }
    if (stepForm === 1) {
      dispatch(dispatchPreview(formData)).then((res: {[key: string]: any}) => {
        if (res) {
          setPreviewInfo(formatPreviewRes(res));
          setStepForm(stepForm + 1);
        }
      });
    } else {
      handleProcessEmails(values);
    }
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: {dirtyFields},
  } = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(canSelectPartner ? csvSchemaWithPartner : csvSchema),
  });

  const valuesHaveChanged = () => {
    const currentValues = watch();

    const cvsSameValue = !dirtyFields.csv;
    const chatSameValue = initialValues.partner_name
      ? currentValues.partner_name === initialValues.partner_name
      : currentValues.partner_name === '' ||
        currentValues.partner_name === undefined;

    return cvsSameValue && chatSameValue;
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    previewInfo,
    setEmailListPost,
    setUploadedCsv,
    hasErrors: false,
    control,
    isSubmitting: false,
    valuesHaveChanged,
  };
};
