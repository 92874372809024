import React, {useEffect} from 'react';

import {Form} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import {useSelector} from 'react-redux';

import {Autocomplete, TextField} from '@mui/material';

import Button from 'src/components/buttons/Button';
import {capitalizeFirstLetter} from 'src/helpers/generalFunctions';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';
import {getPatientsOptions} from 'src/state/action_creators/patientsCreator';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';

import 'react-datepicker/dist/react-datepicker.css';
import {TherapistSelector} from 'src/types/Therapist';
import {PartnerSelector} from 'src/types/Partner';

interface Props {
  activeUntilCheck: boolean;
  activeUntilEnd: Date;
  activeUntilStart: Date;
  businessCheck: boolean;
  businessValue: FixMeLater;
  createdAtCheck: boolean;
  createdAtEnd: Date;
  createdAtStart: Date;
  filterSelected: FixMeLater;
  handleToggleFilter: FixMeLater;
  initValues: FixMeLater;
  partnerCheck: boolean;
  partnerValues: FixMeLater;
  setActiveUntilCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveUntilEnd: React.Dispatch<React.SetStateAction<Date>>;
  setActiveUntilStart: React.Dispatch<React.SetStateAction<Date>>;
  setBusinessCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setBusinessValue: React.Dispatch<React.SetStateAction<FixMeLater>>;
  setCreatedAtCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setCreatedAtEnd: React.Dispatch<React.SetStateAction<Date>>;
  setCreatedAtStart: React.Dispatch<React.SetStateAction<Date>>;
  setFilterSelected: React.Dispatch<React.SetStateAction<FixMeLater>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setOrderDesc: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPartnerCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setPartnerValues: React.Dispatch<React.SetStateAction<FixMeLater>>;
  setTherapistCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setTherapistValue: React.Dispatch<React.SetStateAction<FixMeLater>>;
  show: boolean;
  therapistCheck: boolean;
  therapistValue: FixMeLater;
}

export const PopOverFilters = ({
  show,
  initValues,
  setPage,
  setOrderBy,
  setOrderDesc,
  handleToggleFilter,
  filterSelected,
  setFilterSelected,
  activeUntilCheck,
  activeUntilStart,
  activeUntilEnd,
  setActiveUntilCheck,
  setActiveUntilStart,
  setActiveUntilEnd,
  createdAtCheck,
  createdAtStart,
  createdAtEnd,
  setCreatedAtCheck,
  setCreatedAtStart,
  setCreatedAtEnd,
  businessCheck,
  setBusinessCheck,
  businessValue,
  setBusinessValue,
  partnerCheck,
  setPartnerCheck,
  partnerValues,
  setPartnerValues,
  therapistCheck,
  setTherapistCheck,
  therapistValue,
  setTherapistValue,
}: Props) => {
  const dispatch = useAppDispatch();
  const {partnersList, therapistsList} = useSelector(
    (state: RootState) => state.patients,
  );

  const handleCheckChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    let newPartner = partnerValues;
    if (!target.checked) {
      newPartner = partnerValues.filter(
        (item: FixMeLater) => item !== parseInt(target.name),
      );
    } else if (partnerValues.indexOf(target.name) < 0) {
      newPartner = [...partnerValues, parseInt(target.name)];
    }
    setPartnerValues(newPartner);
  };

  useEffect(() => {
    if (!partnersList || !therapistsList) {
      dispatch(getPatientsOptions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !activeUntilCheck &&
      !createdAtCheck &&
      !businessCheck &&
      !partnerCheck &&
      !therapistCheck
    ) {
      toast.error('You must select some filter');
    } else if (
      createdAtEnd.getTime() < createdAtStart.getTime() ||
      activeUntilEnd.getTime() < activeUntilStart.getTime()
    ) {
      toast.error(
        'Review the selected dates. The initial cannot be later than the final',
      );
    } else {
      setPage(1);
      setOrderBy('id');
      setOrderDesc(true);
      const filtersObj: FixMeLater = {
        activeUntil: {
          checked: activeUntilCheck,
          values: {
            start: activeUntilStart,
            end: activeUntilEnd,
          },
          field: 'active_until',
          badge: 'Active Until',
        },
        createdAt: {
          checked: createdAtCheck,
          values: {
            start: createdAtStart,
            end: createdAtEnd,
          },
          field: 'created_at',
          badge: 'Created At',
        },
        business: {
          checked: businessCheck,
          values: businessValue,
          field: 'business',
          badge: 'Business',
        },
        partner: {
          checked: partnerCheck,
          values: partnerValues,
          field: 'partner',
          badge: 'Partner',
        },
        therapist: {
          checked: therapistCheck,
          values: therapistValue,
          field: 'therapist',
          badge: 'Therapist',
        },
      };
      setFilterSelected(filtersObj);
      handleToggleFilter('filter');
    }
  };

  const clearForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setPage(initValues.page);
    setOrderBy(initValues.orderBy);
    setOrderDesc(initValues.orderDesc);
    setCreatedAtCheck(false);
    setCreatedAtStart(initValues.createdAt.values.start);
    setCreatedAtEnd(initValues.createdAt.values.end);
    setActiveUntilCheck(false);
    setActiveUntilStart(initValues.activeUntil.values.start);
    setActiveUntilEnd(initValues.activeUntil.values.end);
    setBusinessCheck(false);
    setBusinessValue(null);
    setPartnerCheck(false);
    setPartnerValues([]);
    setFilterSelected(null);
    setTherapistCheck(false);
    setTherapistValue(null);
    handleToggleFilter('filter');
  };

  useEffect(() => {
    if (filterSelected) {
      if (filterSelected.activeUntil && filterSelected.activeUntil.checked) {
        setActiveUntilCheck(true);
        setActiveUntilStart(filterSelected.activeUntil.values.start);
        setActiveUntilEnd(filterSelected.activeUntil.values.end);
      }
      if (filterSelected.createdAt && filterSelected.createdAt.checked) {
        setCreatedAtCheck(true);
        setCreatedAtStart(filterSelected.createdAt.values.start);
        setCreatedAtEnd(filterSelected.createdAt.values.end);
      }
      if (filterSelected.business && filterSelected.business.checked) {
        setBusinessCheck(true);
      }
      if (filterSelected.partner && filterSelected.partner.checked) {
        setPartnerCheck(true);
      }
      if (filterSelected.therapist && filterSelected.therapist.checked) {
        setTherapistCheck(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={show ? 'popover-custom on' : 'popover-custom'}>
      <div className="content">
        <form onSubmit={handleSubmit} className="popover-filter-patients">
          <div className="popover__header">
            <Button
              buttonType={ButtonType.WHITE}
              size="small"
              onClick={clearForm}
              type="submit">
              Clear
            </Button>
            <h4>Filters</h4>
            <Button buttonType={ButtonType.PRIMARY} size="small" type="submit">
              Done
            </Button>
          </div>
          <div className="popover__body">
            <div>
              <li>
                <Form.Check
                  checked={createdAtCheck}
                  onChange={() => setCreatedAtCheck(!createdAtCheck)}
                  id="popover__filter_created_at"
                  label="Created at"
                  type="checkbox"
                />
              </li>
              {createdAtCheck && (
                <div className="popover__filter_field">
                  <span>is between</span>
                  <div className="two_datepicker flex">
                    <DatePicker
                      name="createdAtStart"
                      className="border border-solid border-black"
                      selected={createdAtStart}
                      onChange={(date) => setCreatedAtStart(date!)}
                      maxDate={new Date()}
                    />
                    <DatePicker
                      name="createdAtEnd"
                      className="border border-solid border-black"
                      selected={createdAtEnd}
                      onChange={(date) => setCreatedAtEnd(date!)}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              <li>
                <Form.Check
                  checked={activeUntilCheck}
                  onChange={() => setActiveUntilCheck(!activeUntilCheck)}
                  id="popover__filter_active_until"
                  label="Active Until"
                  type="checkbox"
                />
              </li>
              {activeUntilCheck && (
                <div className="popover__filter_field">
                  <span>is between</span>
                  <div className="two_datepicker flex">
                    <DatePicker
                      name="activeUntilStart"
                      className="border border-solid border-black"
                      selected={activeUntilStart}
                      onChange={(date) => setActiveUntilStart(date!)}
                    />
                    <DatePicker
                      name="activeUntilEnd"
                      className="border border-solid border-black"
                      selected={activeUntilEnd}
                      onChange={(date) => setActiveUntilEnd(date!)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              <li>
                <Form.Check
                  checked={businessCheck}
                  onChange={() => setBusinessCheck(!businessCheck)}
                  id="popover__filter_business"
                  label="Business"
                  type="checkbox"
                />
              </li>
              {businessCheck && (
                <div className="popover__filter_field">
                  <Form.Check
                    checked={businessValue && businessValue === 'b2b'}
                    onChange={() => setBusinessValue('b2b')}
                    inline
                    label="B2B"
                    name="business"
                    type="radio"
                    id="inline-radio-1"
                  />
                  <Form.Check
                    checked={businessValue && businessValue === 'b2c'}
                    onChange={() => setBusinessValue('b2c')}
                    inline
                    label="B2C"
                    name="business"
                    type="radio"
                    id="inline-radio-2"
                  />
                </div>
              )}
            </div>
            <div>
              <li>
                <Form.Check
                  checked={partnerCheck}
                  onChange={() => setPartnerCheck(!partnerCheck)}
                  id="popover__filter_partner"
                  label="Partner"
                  type="checkbox"
                />
              </li>
              {partnerCheck && (
                <div className="popover__filter_field">
                  <span>belongs to</span>
                  <div className="">
                    <ul>
                      {partnersList &&
                        partnersList.map(
                          (partner: PartnerSelector, i: number) => (
                            <li key={i}>
                              <Form.Check
                                checked={
                                  partnerValues &&
                                  partnerValues.indexOf(partner.id) > -1
                                }
                                onChange={handleCheckChange}
                                id={`popover__filter_partner_${partner.name}`}
                                label={partner.name}
                                name={partner.id?.toString()}
                                type="checkbox"
                              />
                            </li>
                          ),
                        )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div>
              <li>
                <Form.Check
                  checked={therapistCheck}
                  onChange={() => setTherapistCheck(!therapistCheck)}
                  id="popover__filter_therapist"
                  label="Therapist"
                  type="checkbox"
                />
              </li>
              {therapistCheck && therapistsList && (
                <div className="popover__filter_field">
                  <div className="">
                    <ul>
                      {therapistsList && (
                        <Autocomplete
                          id="therapist-selector"
                          options={therapistsList}
                          size="small"
                          getOptionLabel={(option: TherapistSelector) =>
                            capitalizeFirstLetter(option.fullName)
                          }
                          value={
                            therapistsList?.find(
                              (option: TherapistSelector) =>
                                option.id === therapistValue,
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            setTherapistValue(newValue?.id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                '& legend': {display: 'none'},
                                '& fieldset': {top: 0},
                              }}
                            />
                          )}
                        />
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
