import {NameSelector} from '@ifeelonline/chat-core';

import {Dispatch} from 'redux';

import {Partner, PartnerStorage, PlanSelector} from 'src/types/Partner';
import {mapApiResponsePartner} from 'src/helpers/api_response/partner';
import {fetchFormData, fetchWithToken} from 'src/helpers/fetch';
import {catchApiError} from 'src/helpers/catchApiError';

import {PartnersActionType} from '../action_types/PartnersActionType';
import {CompanySelector} from '../../types/Partner';
import {PartnersAction} from '../actions/partners';

type DispatchResult = PartnersAction | any;

export const getPartners = (page: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: PartnersActionType.PARTNERS_LOADING});
    const resp = await fetchWithToken(`admin/partners?page=${page}`);
    const body = await resp?.json();
    if (body && body.success) {
      const {partners: unmappedPartners, partners_count: total} = body;
      const partners: Partner[] = unmappedPartners.map(
        (partner: PartnerStorage) => mapApiResponsePartner(partner),
      );
      dispatch({
        type: PartnersActionType.SET_PARTNERS,
        payload: {partners, total},
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getMetricPartner = (
  partner_id: number,
  apiController: 'tools' | 'dashboards',
) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/${apiController}/partner_metric?partner_id=${partner_id}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      if (body.metrics) {
        const metricsObj: NameSelector[] = [];
        body.metrics.forEach((metric: string) => {
          metricsObj.push({
            name: metric,
          });
        });
        return metricsObj;
      } else {
        return null;
      }
    } else {
      dispatch(catchApiError(body));
      return null;
    }
  };
};

export const getPartnersOptions = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken('admin/partners/new');
    const body = await resp?.json();
    if (body && body.success) {
      const {
        companies,
        plan_number,
      }: {companies: CompanySelector[]; plan_number: PlanSelector[]} = body;

      dispatch({
        type: PartnersActionType.SET_PARTNER_OPTIONS,
        payload: {
          companySelectorList: companies,
          planSelectorList: plan_number,
        },
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const createPartner = (formData: FormData) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchFormData('admin/partners', formData, 'POST');
    const body = await resp?.json();
    if (body && body.success && body.partner) {
      dispatch({
        type: PartnersActionType.PARTNER_ADD_NEW,
        payload: mapApiResponsePartner(body.partner),
      });
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const editPartner = (partnerId: number, formData: FormData) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchFormData(
      `admin/partners/${partnerId}`,
      formData,
      'PATCH',
    );
    const body = await resp?.json();
    if (body && body.success && body.partner) {
      dispatch({
        type: PartnersActionType.PARTNER_EDITED,
        payload: mapApiResponsePartner(body.partner),
      });
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const deletePartner = (partnerId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/partners/${partnerId}`,
      {id: partnerId},
      'DELETE',
    );
    const body = await resp?.json();
    if (body && body.success) {
      dispatch({
        type: PartnersActionType.PARTNER_DELETED,
        payload: {partnerId},
      });
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};
