import {SharedActionType} from '@ifeelonline/chat-core';

import {ToolsActionType} from '../action_types/ToolsActionType';
import {ToolsAction} from '../actions/tools';
import {FixMeLater} from '../interfaces/FixMeLater';

interface ToolsState {
  error: null | string;
  partnersListSelector: FixMeLater;
  status: SharedActionType;
  toolsB2bReportList: FixMeLater;
}

const initialState = {
  partnersListSelector: null,
  toolsB2bReportList: null,
  status: SharedActionType.IDLE,
  error: null,
};

export const toolsReducer = (
  state: ToolsState = initialState,
  action: ToolsAction,
) => {
  switch (action.type) {
    case ToolsActionType.SET_TOOLS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case ToolsActionType.SET_TOOLS_OPTIONS:
      return {
        ...state,
        status: SharedActionType.COMPLETED,
        partnersListSelector: action.payload.partners,
        toolsB2bReportList: action.payload.tools,
      };

    default:
      return state;
  }
};
