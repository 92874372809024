import React, {useState} from 'react';

import toast from 'react-hot-toast';

import {unCancelSubscription} from 'src/state/action_creators/subscriptionsCreator';
import {getPatient} from 'src/state/action_creators/patientCreator';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {useSubscription} from 'src/utils/subscriptions-provider';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';
import {Patient} from 'src/types/Patient';

export const UndoCancelSubscriptionModal = ({patient}: {patient: Patient}) => {
  const dispatch = useAppDispatch();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const {
    isModalUndoCancelSubscriptionOpen,
    setIsModalUndoCancelSubscriptionOpen,
  } = useSubscription();

  const closeModal = () => setIsModalUndoCancelSubscriptionOpen(false);

  const handleSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    setLoadingSubmit(true);
    if (patient.currentSubscription?.id)
      dispatch(
        unCancelSubscription(patient.id, patient.currentSubscription.id),
      ).then((res) => successUndoCancel(res));
  };

  const successUndoCancel = (res: FixMeLater) => {
    if (res) {
      toast.success(`Undo subscription cancel successfully`);
      closeModal();
      dispatch(getPatient(patient.id!));
    }
    setLoadingSubmit(false);
  };

  return (
    <ModalLayout
      show={isModalUndoCancelSubscriptionOpen}
      closeModal={closeModal}
      className="modal-height-auto">
      <form className="allInputsBlock container" onSubmit={handleSubmitForm}>
        <ModalLayout.Header>
          <h3>Reverse Scheduled Cancellation at Period End</h3>
        </ModalLayout.Header>
        <ModalLayout.Body>
          <p>
            This user&apos;s subscription is scheduled to be canceled at the end
            of the current billing period. If this was made in error or needs to
            be reversed, please confirm the action below.
          </p>
        </ModalLayout.Body>
        <ModalLayout.Footer>
          <Button
            buttonType={ButtonType.WHITE}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}>
            Cancel
          </Button>
          <ButtonSubmit
            loadingSubmit={loadingSubmit}
            text="Reverse Cancellation"
          />
        </ModalLayout.Footer>
      </form>
    </ModalLayout>
  );
};
