import React, {useState} from 'react';

import {EventsTable} from 'src/pages/events/EventsTable';
import {Layout} from 'src/components/layout/Layout';
import {Page} from 'src/types/Page';

export const EventsPage = ({controllerName}: Page) => {
  const [overflowHidden, setOverflowHidden] = useState('');
  return (
    <Layout className={overflowHidden}>
      <EventsTable
        setOverflowHidden={setOverflowHidden}
        controllerName={controllerName}
      />
    </Layout>
  );
};
