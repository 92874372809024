export enum BADGE_TYPE {
  B2B = 'b2b',
  B2C = 'b2c',
  BUTTON = 'button',
  DELETED = 'deleted',
  ERROR = 'error',
  HIGH_RISK = 'high-risk',
  INACTIVE = 'inactive',
  INFO = 'info',
  MEDIUM_LOW_RISK = 'medium-low-risk',
  PAUSED = 'paused',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export function badgeClassesByType(badgeType: BADGE_TYPE) {
  const badge = 'border border-solid px-1 py-0.5 rounded-1 whitespace-nowrap';
  const badge_bg = 'text-white';
  switch (badgeType) {
    case BADGE_TYPE.HIGH_RISK:
      return `${badge} border-red-primary bg-red-light text-red-primary text-sm`;
    case BADGE_TYPE.MEDIUM_LOW_RISK:
      return `${badge} border-yellow-primary bg-yellow-light text-yellow-primary text-sm`;
    case BADGE_TYPE.B2B:
      return `${badge} border-primary text-primary`;
    case BADGE_TYPE.B2C:
      return `${badge} border-badge-B2C text-badge-B2C`;
    case BADGE_TYPE.BUTTON:
      return `${badge} border-primary text-primary`;
    case BADGE_TYPE.SUCCESS:
      return `${badge} bg-badge-success ${badge_bg}`;
    case BADGE_TYPE.WARNING:
      return `${badge} bg-badge-warning ${badge_bg}`;
    case BADGE_TYPE.ERROR:
      return `${badge} bg-badge-error${badge_bg}`;
    case BADGE_TYPE.INFO:
      return `${badge} bg-badge-info ${badge_bg}`;
    case BADGE_TYPE.PAUSED:
      return `${badge} bg-badge-paused ${badge_bg}`;
    case BADGE_TYPE.INACTIVE:
      return `${badge} bg-badge-inactive ${badge_bg}`;
    case BADGE_TYPE.DELETED:
      return `${badge} bg-badge-deleted ${badge_bg}`;
    default:
      break;
  }
}

export function checkBadgeType(status: number) {
  switch (status) {
    case 1:
      return BADGE_TYPE.SUCCESS;
    case 2:
      return BADGE_TYPE.WARNING;
    case 3:
      return BADGE_TYPE.ERROR;
    case 4:
      return BADGE_TYPE.INFO;
    case 5:
      return BADGE_TYPE.PAUSED;
    case 6:
      return BADGE_TYPE.INACTIVE;

    default:
      return BADGE_TYPE.INACTIVE;
  }
}
