import React from 'react';

import {TextField} from '@mui/material';

import {GenericFiltersToggle} from 'src/components/generic_table/filters/GenericFiltersToggle';
import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';
import {Pill} from 'src/types/Pill';

import {FormValuesPill} from '../../PillsTable';

export const TitleFilter = ({
  formValues,
  updateFormValue,
}: {
  formValues: GenericTableProps<Pill, FormValuesPill>['formValues'];
  updateFormValue: <
    K extends keyof GenericTableProps<Pill, FormValuesPill>['formValues'],
  >(
    field: K,
    subField: keyof GenericTableProps<Pill, FormValuesPill>['formValues'][K],
    value: any,
  ) => void;
}) => {
  return (
    <GenericFiltersToggle
      checked={formValues.title?.checked}
      onChange={() =>
        updateFormValue('title', 'checked', !formValues.title.checked)
      }
      label="Title">
      {formValues.title?.checked && (
        <TextField
          type="text"
          className="focus:border-none"
          value={formValues.title.value || null}
          onChange={(event) => {
            updateFormValue('title', 'value', event.target?.value || null);
          }}
          required
        />
      )}
    </GenericFiltersToggle>
  );
};
