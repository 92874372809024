import React from 'react';

import {NameValue} from '@ifeelonline/chat-core';

import {Dot} from 'src/components/shared/Dot';

export const PlansTable = ({
  title,
  plans,
  color,
}: {
  color: string;
  plans: NameValue[] | null;
  title: string;
}) => {
  return (
    <div>
      <h2 className="mb-2.5 flex items-center justify-start border-b border-blue-light pb-2.5 font-bold text-blue-dark">
        <Dot size={11} backgroundColor={color} /> {title}
      </h2>
      {plans &&
        plans.map((plan: NameValue, i: number) => (
          <div
            key={i}
            className="flex h-10 items-center justify-between border-b border-b-border-blueLight">
            <p className="text-blue-dark">{plan.name}</p>
            <p className="font-bold">{plan.value}</p>
          </div>
        ))}
    </div>
  );
};
