import React, {useEffect, useState} from 'react';

import DatePicker from 'react-datepicker';
import {useSelector} from 'react-redux';

import {SharedActionType, NameSelector} from '@ifeelonline/chat-core';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

import {
  generateReportB2b,
  getToolsOptions,
} from 'src/state/action_creators/toolsCreator';
import {getMetricPartner} from 'src/state/action_creators/partnerCreator';
import LocaleSelector from 'src/components/shared/LocaleSelector';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {adjustTimezone, firstOfMonth} from 'src/helpers/dates';
import {ExportModal} from 'src/components/shared/ExportModal';
import {PageHeader} from 'src/components/layout/PageHeader';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {Loading} from 'src/components/shared/Loading';
import {Layout} from 'src/components/layout/Layout';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

const initValues = {
  partner_id: '',
  metric: '',
  multiplier: '',
  start_time: firstOfMonth(),
  time_end: new Date(),
  locale: '',
};

export default function ToolsB2bReportsPage() {
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState(initValues);
  const [endUrl, setEndUrl] = useState<string | null>(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const [metrics, setMetrics] = useState<FixMeLater>(null);

  const {partner_id, metric, start_time, time_end, locale} = formValues;
  const {status, partnersListSelector, toolsB2bReportList} = useSelector(
    (state: RootState) => state.tools,
  );

  const [partner, setPartner] = useState(false);
  const [report, setReport] = useState(false);

  useEffect(() => {
    if (!partnersListSelector) {
      dispatch(getToolsOptions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (partner_id) {
      setPartner(false);
    }
    if (endUrl) {
      setReport(false);
    }
  }, [partner_id, endUrl]);

  const getMetrics = (partner_id: number) => {
    dispatch(getMetricPartner(partner_id, 'tools')).then(
      (res: NameSelector[] | null) => {
        setMetrics(res && res.length > 0 ? res : null);
      },
    );
  };
  const handleChangeLocale = ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>) => {
    setFormValues({
      ...formValues,
      locale: target.value,
    });
  };

  const setFormValuesFunction = (name: string, value: FixMeLater) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!partner_id) {
      setPartner(true);
    }
    if (!endUrl) {
      setReport(true);
    }
    if (partner_id && endUrl) {
      setPartner(false);
      setReport(false);
      setShowExportModal(true);
    }
  };

  const valuesHaveChanged = () => {
    const partnerIdSameValue = formValues.partner_id === initValues.partner_id;
    const metricSameValue = formValues.metric === initValues.metric;
    const multiplierSameValue = formValues.multiplier === initValues.multiplier;
    const localeSameValue = formValues.locale === initValues.locale;
    const startTimeSameValue =
      dayjs(formValues.start_time).format('YYYY-MM-DD') ===
      dayjs(initValues.start_time).format('YYYY-MM-DD');
    const endTimeSameValue =
      dayjs(formValues.time_end).format('YYYY-MM-DD') ===
      dayjs(initValues.time_end).format('YYYY-MM-DD');

    return (
      partnerIdSameValue &&
      metricSameValue &&
      multiplierSameValue &&
      localeSameValue &&
      startTimeSameValue &&
      endTimeSameValue
    );
  };

  return (
    <Layout>
      {status === SharedActionType.LOADING ? (
        <Loading />
      ) : (
        <>
          <PageHeader pageTitle="B2B reports" />
          <form className="custom-form" onSubmit={handleSubmit}>
            <div className="report-selector-box">
              <div>
                <div className="row-selectors">
                  <div className="width230 partner group">
                    {partnersListSelector && (
                      <Autocomplete
                        id="partner-selector"
                        options={partnersListSelector}
                        size="small"
                        getOptionLabel={(option: FixMeLater) => option.name}
                        style={{width: 150, marginBottom: 10}}
                        onChange={(event, newValue: FixMeLater) => {
                          setFormValuesFunction(
                            'partner_id',
                            newValue ? newValue.id : '',
                          );
                          if (newValue !== null && newValue !== '') {
                            getMetrics(newValue.id);
                          } else {
                            setMetrics(null);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Partner"
                            error={partner}
                            helperText={partner ? 'Partner is required' : ''}
                          />
                        )}
                      />
                    )}
                  </div>
                  {partner_id && metrics && (
                    <div className="group">
                      <Autocomplete
                        id="partner-metric"
                        options={metrics}
                        size="small"
                        getOptionLabel={(option: FixMeLater) => option.name}
                        style={{width: 150, marginBottom: 10}}
                        inputValue={metric}
                        onInputChange={(event, newInputValue) => {
                          setFormValuesFunction('metric', newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Metric" />
                        )}
                      />
                    </div>
                  )}
                </div>

                <div className="row-selectors">
                  <div className="datepicker-like-material-ui group">
                    <p className="label">From</p>
                    <DatePicker
                      name="start_time"
                      selected={start_time}
                      onChange={(date: Date) =>
                        setFormValuesFunction('start_time', date)
                      }
                      className="height40"
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="datepicker-like-material-ui group">
                    <p className="label">To</p>
                    <DatePicker
                      name="time_end"
                      selected={time_end}
                      onChange={(date: Date) =>
                        setFormValuesFunction('time_end', date)
                      }
                      className="height40"
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="group">
                    <LocaleSelector
                      locale={locale}
                      handleChangeLocale={handleChangeLocale}
                    />
                  </div>
                  {toolsB2bReportList && (
                    <div className="width270 group">
                      <Autocomplete
                        id="type_of_report"
                        size="small"
                        options={toolsB2bReportList}
                        getOptionLabel={(option: FixMeLater) => option.name}
                        style={{width: '100%'}}
                        onChange={(event, newValue) => {
                          setEndUrl(newValue ? newValue.callUrl : '');
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Report"
                            error={report}
                            helperText={report ? 'Report is required' : ''}
                          />
                        )}
                      />
                    </div>
                  )}
                  {endUrl && endUrl === 'aggregated_data_export' && (
                    <div className="group">
                      <TextField
                        id="multiplier-input"
                        label="Monthly payment"
                        onChange={(e) => {
                          setFormValuesFunction('multiplier', e.target.value);
                        }}
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                      />
                    </div>
                  )}
                  <ButtonSubmit
                    loadingSubmit={false}
                    disabled={valuesHaveChanged()}
                  />
                </div>
              </div>
            </div>
          </form>

          <ExportModal
            show={showExportModal}
            setModalOpen={setShowExportModal}
            formValues={{
              ...formValues,
              start_time: adjustTimezone({date: formValues.start_time}),
              time_end: adjustTimezone({date: formValues.time_end}),
            }}
            endUrl={endUrl!}
            exportReport={generateReportB2b}
            title="Export data"
            type="B2bReport"
          />
        </>
      )}
    </Layout>
  );
}
