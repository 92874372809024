import React from 'react';

import {CaretCircleDown, Spinner} from '@phosphor-icons/react';

import {FixMeLater} from 'src/state/interfaces/FixMeLater';

import 'src/styles/components/shared/buttons/buttons.scss';

export default function ButtonLoadMore({
  loadingMore,
  setPage,
  page,
}: {
  loadingMore: FixMeLater;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) {
  return (
    <div className="load-more">
      {loadingMore ? (
        <Spinner weight="duotone" size={20}>
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="4s"
            repeatCount="indefinite"></animate>
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="5s"
            from="0 0 0"
            to="360 0 0"
            repeatCount="indefinite"></animateTransform>
        </Spinner>
      ) : (
        <button onClick={() => setPage(page + 1)}>
          <CaretCircleDown size={20} />
        </button>
      )}
    </div>
  );
}
