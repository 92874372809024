import React from 'react';

import {useNavigate} from 'react-router-dom';

import {ActionSpan} from './ActionSpan';

export const NavigateToButton = ({to, text}: {text: string; to: string}) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigate(to);
  };

  return <ActionSpan onClick={handleClick}>{text}</ActionSpan>;
};
