import {DASHBOARD_PERIOD_ENUM} from 'src/types/Dashboard';

export const OverSLAValue = 120; //seconds

export const TIMES_SELECTOR: {name: string; value: DASHBOARD_PERIOD_ENUM}[] = [
  {value: DASHBOARD_PERIOD_ENUM.DAY, name: 'Day'},
  {value: DASHBOARD_PERIOD_ENUM.LAST_WEEK, name: 'Last Week'},
  {value: DASHBOARD_PERIOD_ENUM.LAST_7_DAYS, name: 'Last 7 days'},
  {value: DASHBOARD_PERIOD_ENUM.LAST_14_DAYS, name: 'Last 14 days'},
  {value: DASHBOARD_PERIOD_ENUM.MONTH, name: 'Month'},
];
