import toast from 'react-hot-toast';

export const createFormData = (values: Record<string, any>) => {
  const formData = new FormData();
  Object.entries(values).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (value instanceof File) formData.append(key, value);
      else formData.append(key, value.toString());
    }
  });

  return formData;
};

export async function fetchBinaryFile(url: string) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    return blob;
  } catch (error) {
    toast.error('Error fetching binary file:');
    return null;
  }
}
