import {QueryData} from '@ifeelonline/chat-core';

import {Dispatch} from 'redux';

import IncomingPatientsRepository from 'src/services/IncomingPatientsRepository';

import {IncomingPatientsActionType} from '../action_types/IncomingPatientsActionType';
import {IncomingPatientsAction} from '../actions/incomingPatients';

type DispatchResult = IncomingPatientsAction | any;

export const getPsychologistGuideSelector = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp =
      await IncomingPatientsRepository.setPsychologistGuideSelector();

    if (resp)
      dispatch({
        type: IncomingPatientsActionType.SET_PSYCHOLOGIST_GUIDE_LIST,
        payload: resp,
      });

    /* TO-DO show error if request fail */
  };
};

export const exportIncomingPatients = (newFilterParams: QueryData) => {
  return async () => {
    try {
      await IncomingPatientsRepository.export(newFilterParams);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('There was an error during export:', error);
    }
  };
};
