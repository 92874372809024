import React, {useState} from 'react';

import {Layout} from 'src/components/layout/Layout';
import {PatientsTable} from 'src/pages/patients/PatientsTable';
import {Page} from 'src/types/Page';

export const PatientsPage = ({controllerName}: Page) => {
  const [overflowHidden, setOverflowHidden] = useState('');

  return (
    <Layout className={overflowHidden}>
      <PatientsTable
        controllerName={controllerName}
        setOverflowHidden={setOverflowHidden}
      />
    </Layout>
  );
};
