import React, {useEffect, useState} from 'react';

import {CameraSlash} from '@phosphor-icons/react';

import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import {viewAsPatient} from 'src/state/action_creators/therapistsCreator';
import LocaleSelector from 'src/components/shared/LocaleSelector';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {useAppDispatch} from 'src/hooks/hooks';
import {Therapist} from 'src/types/Therapist';

interface Props extends ModalSimpleInterface {
  therapist: Therapist;
}

export const ViewAsPatientModal = ({show, setShow, therapist}: Props) => {
  const dispatch = useAppDispatch();

  const [texts, setTexts] = useState<FixMeLater>(null);
  const [locale, setLocale] = useState('en');

  const closeModal = () => setShow!(false);

  const handleChangeLocale = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setLocale(target.value);
  };

  useEffect(() => {
    if (show) {
      dispatch(viewAsPatient(therapist.id!, locale)).then((res) => {
        setTexts(res.texts);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, show]);

  return (
    <ModalLayout show={show} closeModal={closeModal}>
      {texts && (
        <>
          <ModalLayout.Body isLargeBody={true}>
            <div className="view-as-patient">
              {therapist && (
                <>
                  {therapist.avatar?.url ? (
                    <img
                      title={therapist.fullName || ''}
                      alt={therapist.fullName || ''}
                      src={therapist.avatar?.url}
                    />
                  ) : (
                    <CameraSlash size={48} />
                  )}
                  <h3 className="font-sofia font-medium">
                    {therapist.fullName}
                  </h3>
                  <h5>{texts.therapist}</h5>
                  <h4 className="font-sofia font-medium">
                    {texts.title.about_me}
                  </h4>
                  <p>{texts.bio}</p>
                  <div className="flex-row-center university">
                    {texts.logo.url && (
                      <img src={texts.logo.url} alt="Logo school" />
                    )}
                    <div>
                      <span>{texts.school}</span>
                    </div>
                  </div>
                  <h4>{texts.title.titles}</h4>
                  <p>{texts.titles}</p>
                  <h4>{texts.title.experience}</h4>
                  <p>{texts.experience}</p>
                </>
              )}
            </div>
          </ModalLayout.Body>

          <ModalLayout.Footer>
            <div className="locale-selector-footer w-full text-center">
              <LocaleSelector
                locale={locale}
                handleChangeLocale={handleChangeLocale}
                showDefaultItem={false}
              />
            </div>
          </ModalLayout.Footer>
        </>
      )}
    </ModalLayout>
  );
};
