import React from 'react';

import ReactApexChart from 'react-apexcharts';

import {ApexOptions} from 'apexcharts';
import dayjs from 'dayjs';

import {OverSLAValue} from 'src/constants/Dashboard';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {DASHBOARD_PERIOD_ENUM} from 'src/types/Dashboard';
import {formatFloatButInt} from 'src/helpers/generalFunctions';

export const LineChart = ({
  data,
  colors,
  height,
  showGrid = false,
  period,
  gradient = false,
  annotation = false,
  offsetGradient,
}: {
  annotation?: boolean;
  colors: string[];
  data: FixMeLater;
  gradient?: boolean;
  height: number;
  offsetGradient?: number;
  period: DASHBOARD_PERIOD_ENUM;
  showGrid?: boolean;
}) => {
  const series = [
    {
      name: '',
      type: 'area',
      data: data.map((d: FixMeLater) => d.y),
    },
  ];
  const options: ApexOptions = {
    chart: {
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: showGrid,
      borderColor: '#DDE1E8',
    },
    markers: {
      size: 1,
      strokeColors: 'transparent',
      hover: {
        size: 3,
      },
    },
    noData: {
      text: 'Loading',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined,
      },
    },
    stroke: {
      show: true,
      curve: 'smooth',
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    xaxis: {
      type: 'datetime',
      categories: data.map((d: FixMeLater) => d.x),
      //floating: true,
      labels: {
        maxHeight: 10,
        show: false,
        formatter: function (value: string) {
          return dayjs(value).format(
            period === DASHBOARD_PERIOD_ENUM.DAY ? 'MMMM DD HH:mm' : 'LL',
          );
        },
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: true,
      forceNiceScale: true,
      labels: {
        style: {
          colors: '#747F94',
          fontSize: '8px',
          fontFamily: 'sofia, sans-serif',
          fontWeight: 400,
        },
        formatter: (value) => formatFloatButInt(value, 1, false) as string,
      },
      tickAmount: 4,
    },
    tooltip: {
      y: {
        formatter: (value) => formatFloatButInt(value, 3, false) as string,
      },
    },
  };

  if (gradient) {
    options.fill = {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 0,
        colorStops: [
          {
            offset: offsetGradient ? 100 - offsetGradient : 40,
            color: '#EC958D',
            opacity: 0.7,
          },
          {
            offset: 100,
            color: '#E7EEFF',
            opacity: 1,
          },
        ],
      },
    };
  }
  if (annotation) {
    options.annotations = {
      yaxis: [
        {
          y: OverSLAValue,
          borderColor: '#EC958D',
          label: {
            borderColor: '#EC958D',
            style: {
              color: '#fff',
              background: '#EC958D',
            },
            text: `Over SLA ${OverSLAValue}''`,
          },
        },
      ],
    };
  }
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height={height}
    />
  );
};
