import React from 'react';

import {CustomTable} from 'src/components/tables/CustomTable';
import {
  capitalizeFirstLetter,
  parseUnderscore,
} from 'src/helpers/generalFunctions';
import {TableType} from 'src/helpers/set_classes/tableClasses';
import {SpotsTable, SpotsTableColumns} from 'src/types/OperationsDashboard';

export const OperationsDashboardMainTable = ({data}: {data: SpotsTable[]}) => {
  const columns: SpotsTableColumns = [
    'country',
    'derive',
    'free_spots',
    'languages',
    'no_derive',
    'total',
  ];
  return (
    <>
      <CustomTable
        tableType={TableType.DASHBOARD}
        headChildren={
          <tr>
            {columns.map((c: string, index: number) => {
              switch (c) {
                case 'total':
                  return <th key={index}>Therapists</th>;
                case 'free_spots':
                  return <th key={index}>Patients free</th>;

                default:
                  return (
                    <th key={index}>
                      {capitalizeFirstLetter(parseUnderscore(c))}
                    </th>
                  );
              }
            })}
            <th key={columns.length}></th>
          </tr>
        }
        bodyChildren={data.map((t: any, index: number) => (
          <tr key={index}>
            {columns.map((c: string, index: number) => {
              switch (c) {
                default:
                  return (
                    <td key={index} className="cursor-pointer">
                      {t[c]}
                    </td>
                  );
              }
            })}
          </tr>
        ))}
      />
    </>
  );
};
