import React from 'react';

import {
  Dashboard,
  DASHBOARD_PERIOD_ENUM,
  DashboardGraphic,
} from 'src/types/Dashboard';
import {GetOffsetPerOverSLA, RegistrationTooltip} from 'src/helpers/dashboards';
import SelfCareIcon from 'src/assets/images/dashboard/icon_self_care.png';
import VideoIcon from 'src/assets/images/dashboard/icon_video.png';
import ChatIcon from 'src/assets/images/dashboard/icon_chat.png';
import {Loading} from 'src/components/shared/Loading';
import {ChartOptionsValue} from 'src/types/Graphics';

import {ChartCardDropDown} from './ChartCardDropDown';
import {ChartCard} from './ChartCard';

export const DashboardMain = ({
  loading,
  graphics,
  period,
  dropDownGraphic,
  dropDownOption,
  setDropDownOption,
  loadingOptions,
  setLoadingOptions,
}: {
  dropDownGraphic: DashboardGraphic;
  dropDownOption: ChartOptionsValue;
  graphics: Dashboard | null;
  loading: boolean;
  loadingOptions: boolean;
  period: DASHBOARD_PERIOD_ENUM;
  setDropDownOption: React.Dispatch<React.SetStateAction<ChartOptionsValue>>;
  setLoadingOptions: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const heightSmallCharts = 84;
  const heightBigCharts = 180;
  const chartUsesExtras = (chart: ChartOptionsValue) => {
    if (chart === ChartOptionsValue.INTAKES_WAITING_TIME) {
      return true;
    }
  };
  return loading ? (
    <Loading />
  ) : (
    graphics && (
      <div>
        <div>
          <h3 style={{margin: '20px 0', fontSize: '14px'}}>Interactions</h3>
          <div className="flex items-center justify-center">
            <ChartCard
              periodSelected={period}
              total={graphics.video?.total as number}
              graphic_data={graphics?.video?.series}
              data_tip="Nº of videocalls"
              title="Video"
              colors={['#5C62C5']}
              height={heightSmallCharts}
              style={{
                background: '#F3F4FF',
                border: '1px solid #BEC7FA',
              }}
              icon={<img src={VideoIcon} alt="Video icon" />}
            />
            <ChartCard
              periodSelected={period}
              total={graphics?.chat?.total || 0}
              graphic_data={graphics?.chat?.series}
              data_tip="Nº of accesses to the chat"
              title="Chat"
              colors={['#E0A851']}
              height={heightSmallCharts}
              style={{
                background: '#FFF3DD',
                border: '1px solid #F8DAA5',
              }}
              icon={<img src={ChatIcon} alt="Chat icon" />}
            />

            <ChartCard
              periodSelected={period}
              total={graphics?.selfCare?.total || 0}
              graphic_data={graphics?.selfCare?.series}
              data_tip="Nº of monthly wellbeing tests, mood trackers, tools, exercises and meditations done"
              title="Self care"
              colors={['#FF9090']}
              height={heightSmallCharts}
              style={{
                background: '#FFF0E9',
                border: '1px solid #FFD4C7',
              }}
              icon={<img src={SelfCareIcon} alt="Self care icon" />}
            />
          </div>
        </div>
        <div
          className="flex items-center justify-center"
          style={{marginTop: '60px'}}>
          <ChartCardDropDown
            periodSelected={period}
            total={
              chartUsesExtras(dropDownOption)
                ? `${dropDownGraphic?.total || 0}''`
                : dropDownGraphic?.total
            }
            graphic_data={dropDownGraphic?.series}
            data_tip={RegistrationTooltip(dropDownOption)}
            colors={['#3E63BA']}
            height={heightBigCharts}
            style={{
              background: 'white',
              border: '1px solid #E3E8EE',
            }}
            showGrid={true}
            dropDownOption={dropDownOption}
            setDropDownOption={setDropDownOption}
            loadingOptions={loadingOptions}
            setLoadingOptions={setLoadingOptions}
            gradient={chartUsesExtras(dropDownOption)}
            offsetGradient={
              chartUsesExtras(dropDownOption) && dropDownGraphic.series
                ? GetOffsetPerOverSLA(dropDownGraphic.series)
                : 0
            }
            annotation={chartUsesExtras(dropDownOption)}
          />
        </div>
      </div>
    )
  );
};
