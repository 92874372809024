import React, {useState} from 'react';

import {CaretDown, CheckCircle, Minus, XCircle} from '@phosphor-icons/react';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import CollapseComp from 'src/components/shared/CollapseComp';

import {Email} from '../../types';

interface Props {
  emails: Email[];
  isEdit?: boolean;
  isSuccessful: boolean;
  setEmailListPost: React.Dispatch<React.SetStateAction<Email[]>>;
  title: string;
}

export const PreviewEmail = ({
  emails,
  isEdit,
  isSuccessful,
  setEmailListPost,
  title,
}: Props) => {
  const [isTabOpen, setIsTabOpen] = useState(false);

  dayjs.extend(utc);

  const titleAndEmailsLengthText = `${title}: ${emails.length}`;

  return (
    <>
      {emails.length === 0 ? (
        <span className="flex items-center gap-2">
          <Minus />
          <p>{titleAndEmailsLengthText}</p>
        </span>
      ) : (
        <CollapseComp
          className=""
          buttonChildren={
            <>
              <span
                className="flex cursor-pointer items-center justify-center gap-2"
                onClick={() => setIsTabOpen(!isTabOpen)}>
                <CaretDown className={isTabOpen ? 'rotate-180' : ''} />
                <p>{titleAndEmailsLengthText}</p>
              </span>
              {isSuccessful && (
                <ButtonSubmit
                  loadingSubmit={false}
                  onClick={() => setEmailListPost(emails)}
                  text={`Process ${emails.length}`}
                />
              )}
            </>
          }
          collapseChildren={
            <ul>
              {emails.map((email: Email, index: number) => (
                <li
                  key={index}
                  className={`flex flex-wrap items-center gap-2 pt-2 ${
                    isSuccessful ? 'text-success' : 'text-error'
                  }`}>
                  {isSuccessful ? (
                    <CheckCircle size={18} className="text-success" />
                  ) : (
                    <XCircle size={18} className="text-error" />
                  )}
                  <span className="">{email.email}</span>
                  {email.partner && <span> - Partner: {email.partner} </span>}
                  {email.active_until && (
                    <span>
                      {' '}
                      - Active until:{' '}
                      {dayjs.utc(email.active_until).format('DD/MM/YYYY')}{' '}
                    </span>
                  )}
                  {isSuccessful && isEdit && (
                    <span>
                      {' '}
                      - New metric: {email.metric
                        ? email.metric
                        : '<empty>'}{' '}
                    </span>
                  )}
                  {isSuccessful && isEdit && (
                    <span>
                      {' '}
                      - Old metric:{' '}
                      {email.old_metric ? email.old_metric : '<empty>'}{' '}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          }
        />
      )}
    </>
  );
};
