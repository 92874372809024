import React from 'react';

import {useSelector} from 'react-redux';

import {RootState} from 'src/state';
import {PartnerSelector} from 'src/types/Partner';
import {Form} from 'src/components/form/Form';

import {UseCSVWithPreviewFormReturn} from '../../hooks/useCSVWithPreviewForm';

interface Props {
  canSelectPartner: boolean;
  control: UseCSVWithPreviewFormReturn['control'];
  setUploadedCsv: UseCSVWithPreviewFormReturn['setUploadedCsv'];
}

export const Step1 = ({control, setUploadedCsv, canSelectPartner}: Props) => {
  const {partnersListSelector} = useSelector(
    (state: RootState) => state.email_authorizations,
  );

  const partnerOptions =
    partnersListSelector?.map((partner: PartnerSelector) => partner.name) || [];

  return (
    <>
      {canSelectPartner && (
        <Form.SelectAutocomplete
          control={control}
          name="partner_name"
          label="Partner"
          options={partnerOptions}
        />
      )}
      <Form.InputFile
        control={control}
        label="Emails list file"
        name="csv"
        setFile={setUploadedCsv}
        allowedFormats={['csv']}
        shouldShowPreview={false}
      />
    </>
  );
};
