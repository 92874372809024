import React from 'react';

import {GenericFiltersToggle} from 'src/components/generic_table/filters/GenericFiltersToggle';
import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';

import {FormValuesEvent} from '../../EventsTable';

export const UserIdFilter = ({
  formValues,
  updateFormValue,
}: Pick<
  GenericTableProps<Event, FormValuesEvent>,
  'formValues' | 'updateFormValue'
>) => (
  <GenericFiltersToggle
    checked={formValues.userId?.checked}
    onChange={() =>
      updateFormValue('userId', 'checked', !formValues.userId.checked)
    }
    label="User Id">
    {formValues.userId?.checked && (
      <div className="popover__filter_field">
        <input
          className="border border-solid border-black"
          type="number"
          min="0"
          step="1"
          name="user_id"
          value={formValues.userId.value ?? ''}
          onChange={({target}) =>
            updateFormValue('userId', 'value', target.value)
          }
        />
      </div>
    )}
  </GenericFiltersToggle>
);
