import React from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {TherapistAccount} from 'src/types/Therapist';
import {RootState} from 'src/state';

export const AccountAttributesModal = ({
  show,
  setShow,
}: ModalSimpleInterface) => {
  const {status, therapist} = useSelector(
    (state: RootState) => state.therapist,
  );

  const closeModal = () => setShow!(false);

  return status !== SharedActionType.LOADING ? (
    <ModalLayout show={show} closeModal={closeModal}>
      <ModalLayout.Header>
        <h3>User account attributes</h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        {therapist && therapist.account && (
          <ul className="list-style-circle">
            {Object.keys(therapist.account).map((key, i) => (
              <li key={i}>
                {key}:{' '}
                <pre>
                  {JSON.stringify(
                    therapist.account![key as keyof TherapistAccount],
                  )}
                </pre>
              </li>
            ))}
          </ul>
        )}
      </ModalLayout.Body>
    </ModalLayout>
  ) : (
    <></>
  );
};
