import {SharedActionType} from '@ifeelonline/chat-core';

import {GraphicsActionType} from '../action_types/GraphicsActionType';
import {GraphicsAction} from '../actions/graphics';
import {FixMeLater} from '../interfaces/FixMeLater';

interface GraphicsState {
  error: null | string;
  graphics: FixMeLater;
  status: SharedActionType;
}

const initialState = {
  graphics: [],
  status: SharedActionType.IDLE,
  error: null,
};

export const graphicsTimeZoneReducer = (
  state: GraphicsState = initialState,
  action: GraphicsAction,
) => {
  switch (action.type) {
    case GraphicsActionType.GET_GRAPHIC_PATIENTS_BY_TIMEZONE_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case GraphicsActionType.GET_GRAPHIC_PATIENTS_BY_TIMEZONE:
      return {
        ...state,
        graphics: action.payload.graphics,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
