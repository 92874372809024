import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';

import {ToolsActionType} from '../action_types/ToolsActionType';
import {ToolsAction} from '../actions/tools';
import {FixMeLater} from '../interfaces/FixMeLater';

type DispatchResult = ToolsAction | any;

export const getToolsOptions = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: ToolsActionType.SET_TOOLS_LOADING});
    const resp = await fetchWithToken(`admin/tools/selectors`);
    const body = await resp?.json();
    if (body && body.success) {
      const {partners, tools} = body;
      dispatch({
        type: ToolsActionType.SET_TOOLS_OPTIONS,
        payload: {
          partners,
          tools,
        },
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const generateReportB2b = (params: FixMeLater, end_url: string) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(`admin/tools/${end_url}`, params, 'POST');
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};
