import {LanguageAvailable} from '@ifeelonline/chat-core';

import {Dispatch} from 'redux';

import {fetchNoToken} from '../../helpers/fetch';
import {SettingsAction} from '../actions/settings';
import {SettingsActionType} from '../action_types/SettingsActionType';

type DispatchResult = SettingsAction | any;

export const settingsStartUp = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchNoToken(
      'admin/settings/startup_settings',
      null,
      'GET',
    );

    const body = await resp?.json();
    if (body && body.success) {
      dispatch({
        type: SettingsActionType.SETTINGS_LANGUAGES,
        payload: body.result.languages as LanguageAvailable[],
      });
      return true;
    } else {
      return false;
    }
  };
};
