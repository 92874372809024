import {QueryData, camelCaseToSnakeCase} from '@ifeelonline/chat-core';

import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';
import {Order} from 'src/components/tables/types/Order';
import {Event} from 'src/types/Event';

import {FormValuesEvent} from '../EventsTable';

export function buildRequestParams(
  page: number,
  order: Order<Event>,
  formValues: GenericTableProps<Event, FormValuesEvent>['formValues'],
): QueryData {
  const newFilterParams: QueryData = {
    page,
  };

  if (formValues?.typeOfEvent?.checked && formValues.typeOfEvent.value !== null)
    newFilterParams.type_of_event = formValues.typeOfEvent.value.toString();

  if (formValues?.userId.checked && formValues?.userId.value)
    newFilterParams.user_id = formValues?.userId.value?.toString();

  if (formValues.createdAt?.checked && formValues.createdAt.value !== null) {
    if (formValues.createdAt.value.from && formValues.createdAt.value.to) {
      newFilterParams.created_at = {
        from: formValues.createdAt.value.from.toISOString(),
        to: formValues.createdAt.value.to.toISOString(),
      };
    }
  }

  if (order) {
    newFilterParams.orderBy = camelCaseToSnakeCase(order.by); // To-DO: replace camelCase to snakeCase (orderBy to order_by) when the API is ready
    newFilterParams.orderDir = order.direction; // To-DO: replace camelCase to snakeCase (orderDir to order_dir) when the API is ready
  }
  return newFilterParams;
}
