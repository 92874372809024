import toast from 'react-hot-toast';

import {Order} from 'src/components/tables/types/Order';

export interface GenericTableProps<T, FV extends Record<string, any>> {
  filtersSelected: (keyof T)[];
  formValues: FV;
  handleSetOrder: (newOrder: Order<T>) => void;
  handleToggleFilter: () => void;
  initValues: FV;
  isPopOverOpen: boolean;
  setFiltersSelected: React.Dispatch<React.SetStateAction<(keyof T)[]>>;
  setFormValues: React.Dispatch<React.SetStateAction<FV>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  updateFormValue: <K extends keyof FV>(
    field: K,
    subField: keyof FV[K],
    value: any,
  ) => void;
}

export function useGenericFiltersForm<T, FV extends Record<string, any>>({
  formValues,
  setFormValues,
  setPage,
  setFiltersSelected,
  handleToggleFilter,
  initValues,
}: Pick<
  GenericTableProps<T, FV>,
  | 'formValues'
  | 'setFormValues'
  | 'setPage'
  | 'setFiltersSelected'
  | 'handleToggleFilter'
  | 'initValues'
>) {
  const isAnyFilterChecked = (formValues: FV) => {
    return Object.values(formValues).some((formValue) => formValue.checked);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isAnyFilterChecked(formValues)) {
      toast.error('You must select some filter');
      return;
    }

    setPage(1);
    const selectedFilters: (keyof T)[] = Object.entries(formValues)
      .filter(([, value]) => value && value.checked)
      .map(([key]) => key as keyof T);

    setFiltersSelected(selectedFilters);

    handleToggleFilter();
  };

  const clearForm = (e: React.MouseEvent) => {
    e.preventDefault();
    setPage(1);

    setFormValues(initValues);

    setFiltersSelected([]);
  };

  const updateFormValue = <K extends keyof FV>(
    field: K,
    subField: keyof FV[K],
    value: any,
  ) => {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        [subField]: value,
      },
    }));
  };

  return {handleSubmit, clearForm, updateFormValue};
}
