import React from 'react';

import {UsersAndRolesTable} from 'src/pages/users_and_roles/components/UsersAndRolesTable';
import {Layout} from 'src/components/layout/Layout';

export const UsersAndRolesPage = () => {
  return (
    <Layout>
      <UsersAndRolesTable />
    </Layout>
  );
};
