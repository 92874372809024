import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';
import {mapApiResponsePlan} from 'src/helpers/api_response/plan';
import {Plan, PlanStorage} from 'src/types/Plan';

import {PlansActionType} from '../action_types/PlansActionType';
import {PlansAction, PlansPayload} from '../actions/plans';

type DispatchResult = PlansAction | any;

export const getPlans = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: PlansActionType.PLANS_LOADING});

    const resp = await fetchWithToken('admin/plans');
    const body = await resp?.json();
    if (body && body.success) {
      const {plans} = body;
      const plansMapped: Plan[] = plans.map((plan: PlanStorage) =>
        mapApiResponsePlan(plan),
      );
      dispatch(setPlans({plans: plansMapped}));
    } else {
      dispatch(catchApiError(body));
    }
  };
};

const setPlans = (plans: PlansPayload) => ({
  type: PlansActionType.SET_PLANS,
  payload: plans,
});
