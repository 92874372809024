import {useSelector} from 'react-redux';

import {
  roleHasActionPermission,
  rolesPermissionFilter,
} from 'src/helpers/rolesFunctions';
import {RootState} from 'src/state';

interface Params {
  actionName: string | null;
  controllerName: string;
}

export const useHasActionPermissions = ({
  controllerName,
  actionName,
}: Params): boolean => {
  const {user} = useSelector((state: RootState) => state.auth);
  if (!actionName) return true;

  const roleFiltered = rolesPermissionFilter(
    user!.rolesPermissions,
    controllerName,
  );

  const hasActionPermission =
    roleFiltered.length > 0 &&
    roleHasActionPermission(roleFiltered, actionName);

  return hasActionPermission;
};
