import React from 'react';

import {Form} from 'react-bootstrap';

import {GenericFiltersToggle} from 'src/components/generic_table/filters/GenericFiltersToggle';
import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';
import {IncomingPatient} from 'src/types/IncomingPatient';

import {FormValuesIncomingPatients} from '../../IncomingPatientsPage';

export const RiskLevelFilter = ({
  formValues,
  updateFormValue,
}: {
  formValues: GenericTableProps<
    IncomingPatient,
    FormValuesIncomingPatients
  >['formValues'];
  updateFormValue: <
    K extends keyof GenericTableProps<
      IncomingPatient,
      FormValuesIncomingPatients
    >['formValues'],
  >(
    field: K,
    subField: keyof GenericTableProps<
      IncomingPatient,
      FormValuesIncomingPatients
    >['formValues'][K],
    value: any,
  ) => void;
}) => {
  return (
    <GenericFiltersToggle
      checked={formValues.isHighRisk?.checked}
      onChange={() =>
        updateFormValue('isHighRisk', 'checked', !formValues.isHighRisk.checked)
      }
      label="Risk Level">
      <Form.Check
        checked={formValues.isHighRisk?.value === true}
        onChange={() => updateFormValue('isHighRisk', 'value', true)}
        inline
        label="High"
        name="isHighRisk"
        type="radio"
        id="inline-radio-1"
      />
      <Form.Check
        checked={formValues.isHighRisk?.value === false}
        onChange={() => updateFormValue('isHighRisk', 'value', false)}
        inline
        label="Medium/Low"
        name="isHighRisk"
        type="radio"
        id="inline-radio-2"
      />
    </GenericFiltersToggle>
  );
};
