import React from 'react';

import {Flashlight} from '@phosphor-icons/react';

export const NoResults = ({
  title,
  subtitle,
}: {
  subtitle?: string;
  title?: React.ReactNode | string;
}) => {
  return (
    <div className="m-10 flex flex-col items-center justify-center">
      <Flashlight size={48} />
      <h3 className="m-2 text-6">{title || 'No results found'}</h3>
      <p>{subtitle || "There aren't any results."}</p>
    </div>
  );
};
