import React, {ComponentProps} from 'react';

interface Props extends ComponentProps<'div'> {
  backgroundColor: string;
  size: number;
}
export const Dot = ({size, className, backgroundColor}: Props) => {
  return (
    <div
      className={`${className || ''} dot mr-1 rounded-full`}
      style={{
        width: size,
        height: size,
        backgroundColor: backgroundColor,
      }}></div>
  );
};
