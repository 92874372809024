import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import Select, {SelectChangeEvent} from '@mui/material/Select';
import {Autocomplete, TextField} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import {
  getOperationsDashboardData,
  getSelectors,
  OperationsDashboardDataParams,
} from 'src/state/action_creators/operationsDashboardCreator';
import {
  capitalizeFirstLetter,
  parseUnderscore,
} from 'src/helpers/generalFunctions';
import {OperationDashboardTherapist} from 'src/types/OperationsDashboard';
import {OperationsDashboardTabEnum} from 'src/constants/TabLists';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {Loading} from 'src/components/shared/Loading';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

interface InitValuesProps {
  country: string | undefined;
  language: string | undefined;
  referal: boolean | '';
  speciality: string | undefined;
  supervisor: boolean | '';
}

const initValues: InitValuesProps = {
  country: undefined,
  language: undefined,
  speciality: undefined,
  referal: '',
  supervisor: '',
};

interface Props {
  loading: boolean;
  page: number;
  setAllFilterParams: React.Dispatch<
    React.SetStateAction<OperationsDashboardDataParams | null>
  >;
  setData: React.Dispatch<
    React.SetStateAction<OperationDashboardTherapist[] | null>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  tabVal: OperationsDashboardTabEnum;
}

export const OperationsDashboardHeader = ({
  loading,
  setLoading,
  setData,
  setAllFilterParams,
  tabVal,
  page,
  setPage,
}: Props) => {
  const {status, totalTherapist, countries, languages, specialities} =
    useSelector((state: RootState) => state.operations_dashboard);
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState(initValues);
  const {country, language, speciality, referal, supervisor} = formValues;

  const setFormValuesFunction = (name: string, value: FixMeLater) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    getDashboardData();
  };

  function getDashboardData() {
    dispatch(
      getOperationsDashboardData({
        type: tabVal,
        ...formValues,
        page,
      }),
    ).then((res: OperationDashboardTherapist[]) => {
      setLoading(false);
      setPage(1);
      setData(res);
    });
    setAllFilterParams({
      type: tabVal,
      ...formValues,
      page,
    });
  }

  useEffect(() => {
    if (country || language || speciality || referal || supervisor)
      getDashboardData();
  }, [page]);

  useEffect(() => {
    if (!countries) {
      dispatch(getSelectors(tabVal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === SharedActionType.COMPLETED) {
    return (
      <div className="header-selector mb-7.5 flex items-start justify-between">
        <h2 className="w-full">
          Total <span className="pl-2 text-6 font-bold">{totalTherapist}</span>
        </h2>
        {countries && (
          <Autocomplete
            id="country-selected"
            fullWidth={true}
            options={countries}
            size="small"
            onChange={(_, newValue: FixMeLater) => {
              setFormValuesFunction(
                'country',
                newValue ? newValue.toLowerCase() : newValue,
              );
            }}
            renderInput={(params) => <TextField {...params} label="Country" />}
          />
        )}
        {languages && (
          <Autocomplete
            id="lang-selected"
            fullWidth={true}
            options={languages}
            size="small"
            onChange={(_, newValue: FixMeLater) => {
              setFormValuesFunction('language', newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Language" />}
          />
        )}
        {specialities && (
          <Autocomplete
            id="speciality-selected"
            fullWidth={true}
            options={specialities}
            size="small"
            getOptionLabel={(option: FixMeLater) =>
              capitalizeFirstLetter(parseUnderscore(option))
            }
            onChange={(_, newValue: FixMeLater) => {
              setFormValuesFunction('speciality', newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Disorder" />}
          />
        )}
        <FormControl size="small" fullWidth={true}>
          <InputLabel id="referal-selected">Referal</InputLabel>
          <Select
            labelId="referal-select"
            id="referal-select"
            label="Referal"
            defaultValue=""
            onChange={(event: SelectChangeEvent) => {
              setFormValuesFunction('referal', event.target.value);
            }}>
            <MenuItem value="">None</MenuItem>
            <MenuItem value={true as any}>Yes</MenuItem>
            <MenuItem value={false as any}>No</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" fullWidth={true}>
          <InputLabel id="rol-selected">Rol</InputLabel>
          <Select
            labelId="rol-select"
            id="rol-select"
            label="Rol"
            defaultValue=""
            onChange={(event: SelectChangeEvent) => {
              setFormValuesFunction('supervisor', event.target.value);
            }}>
            <MenuItem value="">None</MenuItem>
            <MenuItem value={true as any}>Yes</MenuItem>
            <MenuItem value={false as any}>No</MenuItem>
          </Select>
        </FormControl>

        <Button
          buttonType={ButtonType.DASHBOARDS}
          size="medium"
          disabled={
            loading ||
            (!country && !language && !speciality && !referal && !supervisor)
          }
          loading={loading}
          onClick={handleSubmit}>
          Save
        </Button>
      </div>
    );
  }
  return <Loading />;
};
