import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {Info} from '@phosphor-icons/react';

export default function BasicTooltip({title}: {title: string}) {
  return (
    <Tooltip title={title}>
      <IconButton>
        <Info />
      </IconButton>
    </Tooltip>
  );
}
