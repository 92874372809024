import React, {useState} from 'react';

import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';

import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {IncomingPatient} from 'src/types/IncomingPatient';
import {ErrorText} from 'src/components/forms/ErrorText';
import {getDateTimeNextHour} from 'src/helpers/dates';
import Button from 'src/components/buttons/Button';
import {RootState} from 'src/state';

import {IncomingPatientFormValues} from './IncomingPatientEditModal';

interface Props {
  incomingPatient: IncomingPatient;
  onSubmit: (data: IncomingPatientFormValues) => void;
}

export const IncomingPatientEditModalForm = ({
  incomingPatient,
  onSubmit,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IncomingPatientFormValues>({
    defaultValues: {
      psychologistGuideId: incomingPatient.psychologistGuide
        ? incomingPatient.psychologistGuide.id
        : null,
      appointmentDate: incomingPatient.appointment
        ? undefined
        : getDateTimeNextHour(),
    },
  });

  const {psychologistGuideList} = useSelector(
    (state: RootState) => state.incoming_patients,
  );

  const onSubmitForm = async (data: IncomingPatientFormValues) => {
    setIsLoading(true);
    try {
      await onSubmit(data);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmitForm)}
        className="flex h-full flex-col"
        data-testid="incoming-patients-modal-form">
        <ModalLayout.Header>
          <h3 className="text-base font-medium text-blue-dark">
            Edit incoming patient
          </h3>
        </ModalLayout.Header>
        <ModalLayout.Body>
          {incomingPatient.appointment === null && (
            <div className="mb-3">
              <label htmlFor="appointmentDateInput">Appointment Date</label>
              <input
                id="appointmentDateInput"
                type="datetime-local"
                {...register('appointmentDate', {
                  validate: (value) => {
                    if (!value) return 'Appointment Date is required';
                    const selectedDate = new Date(value);
                    return (
                      selectedDate > new Date() ||
                      'Please choose a future date and time'
                    );
                  },
                })}
                min={getDateTimeNextHour()}
                defaultValue={getDateTimeNextHour()}
              />

              {errors.appointmentDate && (
                <ErrorText error={errors.appointmentDate.message ?? ''} />
              )}
            </div>
          )}

          <div className="mb-3">
            <label htmlFor="psychologistGuideSelect">Therapist</label>
            <select
              id="psychologistGuideSelect"
              className="my-2 cursor-pointer"
              {...register('psychologistGuideId', {
                required: 'Psychologist is required',
              })}
              defaultValue={incomingPatient.psychologistGuide?.id ?? ''}>
              <option value="" disabled>
                Select a Therapist
              </option>
              {psychologistGuideList &&
                psychologistGuideList.map((psychologist) => (
                  <option key={psychologist.id} value={psychologist.id}>
                    {psychologist.nickname}
                  </option>
                ))}
            </select>
            {errors.psychologistGuideId && (
              <ErrorText error={errors.psychologistGuideId.message ?? ''} />
            )}
          </div>
        </ModalLayout.Body>
        <ModalLayout.Footer>
          <Button
            buttonType={ButtonType.SAVE}
            size="small"
            type="submit"
            loading={isLoading}
            disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </ModalLayout.Footer>
      </form>
    </>
  );
};
