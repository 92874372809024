import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {
  getPills,
  getPillsOptions,
} from 'src/state/action_creators/pillsCreator';
import GenericTable from 'src/components/generic_table/GenericTable';
import {GenericTableProps} from 'src/hooks/useGenericFiltersForm';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {isOrderDesc} from 'src/components/tables/helper';
import {Order} from 'src/components/tables/types/Order';
import {Loading} from 'src/components/shared/Loading';
import {Pill, PillActions} from 'src/types/Pill';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

import {PillsFiltersForm} from './components/forms/PillsFilterForm';
import {EditPillForm} from './components/forms/EditPillForm';
import {NewPillForm} from './components/forms/NewPillForm';
import {PillPreviewModal} from './modals/PillPreviewModal';
import {renderTableCell} from './utils/renderTableCell';
import {columnsToShow} from './utils/columnsToShow';

type FilterableKeys = 'title' | 'area_id';

type ValueType<K extends FilterableKeys> = K extends 'title'
  ? string | null
  : K extends 'area_id'
    ? number | null
    : never;

export type FormValuesPill = {
  [K in FilterableKeys]: {
    checked: boolean;
    value: ValueType<K>;
  };
};

export const PillsTable = () => {
  const dispatch = useAppDispatch();
  const {status, pills, total, pillsOptions} = useSelector(
    (state: RootState) => state.pills,
  );
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [pillToEdit, setPillToEdit] = useState<number | null>(null);
  const [modalPreviewPill, setModalPreviewPill] = useState(false);
  const [pillToPreview, setPillToPreview] = useState<FixMeLater>(null);
  const [isModalNewOpen, setIsModalNewOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<Order<PillActions>>({
    by: 'id',
    direction: 'asc',
  });
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [filtersSelected, setFiltersSelected] = useState<
    GenericTableProps<Pill, FormValuesPill>['filtersSelected']
  >([]);
  const initValues: GenericTableProps<Pill, FormValuesPill>['formValues'] = {
    title: {
      checked: false,
      value: null,
    },
    area_id: {
      checked: false,
      value: null,
    },
  };
  const [formValues, setFormValues] =
    useState<GenericTableProps<Pill, FormValuesPill>['formValues']>(initValues);

  useEffect(() => {
    dispatch(
      getPills({
        page,
        orderBy: order.by,
        orderDesc: isOrderDesc(order),
        formValues,
      }),
    );
  }, [page, order, filtersSelected]);

  useEffect(() => {
    if (pillsOptions === null) {
      dispatch(getPillsOptions());
    }
  }, []);

  const handleSetOrder = (newOrder: Order<PillActions>) => {
    setOrder(newOrder);
  };
  const handleEdit = (pill: Pill) => {
    setPillToEdit(pill.id);
    setIsModalEditOpen(true);
  };
  const handlePreviewPill = (pill: Pill) => {
    setPillToPreview(pill.id);
    setModalPreviewPill(true);
  };
  const handleNewPill = () => {
    setIsModalNewOpen(true);
  };
  const handleToggleFilter = () => setIsPopOverOpen(!isPopOverOpen);

  return (
    <>
      {status === SharedActionType.LOADING && <Loading />}
      <GenericTable>
        <GenericTable.Header
          filtersSelected={filtersSelected}
          handleToggleFilter={handleToggleFilter}
          isFilterPopOverOpen={isPopOverOpen}
          data={{items: pills || [], total: total}}
          pageTitle="Pills"
          newButtonText="Pill"
          handleNew={handleNewPill}>
          <PillsFiltersForm
            handleToggleFilter={handleToggleFilter}
            setFiltersSelected={setFiltersSelected}
            setPage={setPage}
            isPopOverOpen={isPopOverOpen}
            formValues={formValues}
            setFormValues={setFormValues}
            initValues={initValues}
          />
        </GenericTable.Header>
        <GenericTable.Body
          isLoading={status === SharedActionType.LOADING}
          data={pills || []}>
          <GenericTable.Body.Head
            columns={columnsToShow}
            handleSetOrder={handleSetOrder}
            order={order}
          />
          <GenericTable.Body.Main
            data={pills || []}
            columns={columnsToShow}
            renderCell={(column, pill) =>
              renderTableCell(column, pill, handleEdit, handlePreviewPill)
            }
          />
        </GenericTable.Body>
        <GenericTable.Footer total={total} page={page} setPage={setPage} />
      </GenericTable>

      <ModalLayout
        show={isModalNewOpen}
        closeModal={() => setIsModalNewOpen(false)}>
        <NewPillForm closeModal={() => setIsModalNewOpen(false)} />
      </ModalLayout>

      {pillToEdit && (
        <ModalLayout
          show={isModalEditOpen}
          closeModal={() => setIsModalEditOpen(false)}>
          <EditPillForm
            closeModal={() => setIsModalEditOpen(false)}
            pillId={pillToEdit}
          />
        </ModalLayout>
      )}

      <PillPreviewModal
        show={modalPreviewPill}
        setShow={setModalPreviewPill}
        pillId={pillToPreview}
      />
    </>
  );
};
