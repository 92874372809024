import React from 'react';

import ReactApexChart from 'react-apexcharts';

import {ApexOptions} from 'apexcharts';

export const DonutChart = ({
  series,
  labels,
  colors,
}: {
  colors: string[];
  labels: string[];
  series: number[];
}) => {
  const options: ApexOptions = {
    chart: {
      type: 'donut',
      toolbar: {
        show: false,
      },
    },
    colors,
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val + '%';
      },
      dropShadow: {},
    },
    fill: {
      colors,
    },
    labels,
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        customScale: 1,
      },
    },

    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return y.toFixed(0) + ' spots';
          }
          return y;
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height={200}
      width={200}
    />
  );
};
