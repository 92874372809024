import {Series} from './Graphics';

export enum DASHBOARD_PERIOD_ENUM {
  DAY = 'day',
  LAST_14_DAYS = 'last14days',
  LAST_7_DAYS = 'last7days',
  LAST_WEEK = 'lastWeek',
  MONTH = 'month',
}

export interface DashboardStorage {
  chat: DashboardGraphicStorage | null;
  self_care: DashboardGraphicStorage | null;
  video: DashboardGraphicStorage | null;
}
export interface Dashboard {
  chat: DashboardGraphic | null;
  selfCare: DashboardGraphic | null;
  video: DashboardGraphic | null;
}

export interface DashboardGraphicStorage {
  graphic_data: Series[];
  total: number;
}
export interface DashboardGraphic {
  series: Series[];
  total: number;
}
