import {Dispatch} from 'redux';

import {mapApiResponseVideoSession} from 'src/helpers/api_response/videoSession';
import {toQueryString} from 'src/helpers/generalFunctions';
import {VideoSessionStorage} from 'src/types/VideoSession';
import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';

import {
  SetSelectorsPayload,
  VideoSessionAction,
  VideoSessionPayload,
} from '../actions/videoSessions';
import {VideoSessionsActionType} from '../action_types/VideoSessionsActionType';
import {FixMeLater} from '../interfaces/FixMeLater';

type DispatchResult = VideoSessionAction | any;

export const getSelectors = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: VideoSessionsActionType.SET_SELECTORS_LOADING});
    const resp = await fetchWithToken(`admin/video_sessions/selectors`);
    const body = await resp?.json();

    if (body && body.success) {
      const {partners} = body;
      const payload: SetSelectorsPayload = {
        partners,
      };
      dispatch({
        type: VideoSessionsActionType.SET_SELECTORS,
        payload,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const getVideoSessions = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: VideoSessionsActionType.VIDEO_SESSIONS_LOADING});

    const resp = await fetchWithToken(
      `admin/video_sessions?${toQueryString(params)}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {video_sessions, total_count: totalCount} = body;
      const videoSessions = video_sessions.map(
        (videoSession: VideoSessionStorage) =>
          mapApiResponseVideoSession(videoSession),
      );
      dispatch(
        setVideoSessions({
          videoSessions,
          totalCount,
        }),
      );
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const exportVideoSessions = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      `admin/video_sessions/export`,
      params,
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      return true;
    } else {
      dispatch(catchApiError(body));
    }
  };
};

const setVideoSessions = (videoSessionsObj: VideoSessionPayload) => ({
  type: VideoSessionsActionType.SET_VIDEO_SESSIONS,
  payload: videoSessionsObj,
});
