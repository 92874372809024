import React from 'react';

import {Spinner} from '@phosphor-icons/react';

import 'src/styles/components/shared/buttons/buttons.scss';

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  loadingSubmit: boolean;
  text?: string;
}

export default function ButtonSubmit({
  loadingSubmit,
  text,
  disabled = false,
  ...rest
}: Props) {
  return (
    <button
      type="submit"
      className="btn btn-save btn-small"
      disabled={disabled || loadingSubmit}
      {...rest}>
      {loadingSubmit && (
        <Spinner weight="duotone" size={20} className="mr-1">
          <animate
            attributeName="opacity"
            values="0;1;0"
            dur="4s"
            repeatCount="indefinite"></animate>
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="5s"
            from="0 0 0"
            to="360 0 0"
            repeatCount="indefinite"></animateTransform>
        </Spinner>
      )}
      {text ? text : 'Save'}
    </button>
  );
}
