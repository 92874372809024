import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';

import {
  GenericTableProps,
  useGenericFiltersForm,
} from 'src/hooks/useGenericFiltersForm';
import {GenericFiltersForm} from 'src/components/generic_table/filters/GenericFiltersForm';
import {IncomingPatient} from 'src/types/IncomingPatient';

import {FormValuesIncomingPatients} from '../IncomingPatientsPage';

import {PsychologistGuideFilter} from './popover_filters/PsychologistGuideFilter';
import {UnassignedCallsFilter} from './popover_filters/UnassignedCallsFilter';
import {AppointmentDateFilter} from './popover_filters/AppointmentDateFilter';
import {RiskLevelFilter} from './popover_filters/RiskLevelFilter';

export const IncomingPatientsFiltersForm = ({
  handleToggleFilter,
  setFiltersSelected,
  setPage,
  isPopOverOpen,
  formValues,
  setFormValues,
  initValues,
}: Pick<
  GenericTableProps<IncomingPatient, FormValuesIncomingPatients>,
  | 'handleToggleFilter'
  | 'setFiltersSelected'
  | 'setPage'
  | 'isPopOverOpen'
  | 'formValues'
  | 'setFormValues'
  | 'initValues'
>) => {
  const {handleSubmit, clearForm, updateFormValue} = useGenericFiltersForm({
    formValues,
    setFormValues,
    setPage,
    setFiltersSelected,
    handleToggleFilter,
    initValues,
  });

  return (
    <GenericFiltersForm isOpen={isPopOverOpen}>
      <form onSubmit={handleSubmit} className="popover-filter-patients">
        <GenericFiltersForm.Header clearForm={clearForm} />
        <GenericFiltersForm.Body>
          <RiskLevelFilter
            formValues={formValues}
            updateFormValue={updateFormValue}
          />
          <PsychologistGuideFilter
            formValues={formValues}
            updateFormValue={updateFormValue}
          />
          <AppointmentDateFilter
            formValues={formValues}
            updateFormValue={updateFormValue}
          />
          <UnassignedCallsFilter
            formValues={formValues}
            updateFormValue={updateFormValue}
          />
        </GenericFiltersForm.Body>
      </form>
    </GenericFiltersForm>
  );
};
