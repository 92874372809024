import React from 'react';

import Modal from 'react-modal';

import {type ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';

Modal.setAppElement('#root');

interface Props
  extends React.ComponentPropsWithoutRef<'div'>,
    ModalSimpleInterface {}

export const ModalLayout = ({
  show,
  closeModal,
  id,
  className,
  children,
}: Props) => {
  return (
    <Modal
      isOpen={show}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
      className={`modal absolute ${className} bottom-auto left-1/2 right-auto top-1/2 -mr-5/10  size-full -translate-x-1/2 -translate-y-1/2 rounded-1 bg-white !p-0 outline-none`}
      overlayClassName="modal-bg"
      id={id}>
      {children}
    </Modal>
  );
};

const ModalHeader = ({children}: React.ComponentProps<'div'>) => (
  <div className="flex min-h-14 w-full items-center justify-between border-b px-5 text-4 font-semibold text-blue-dark shadow-modalHeader">
    {children}
  </div>
);

interface ModalBodyProps extends React.ComponentProps<'div'> {
  isBgWhite?: boolean;
  isExtendModal?: boolean;
  isLargeBody?: boolean;
}

const ModalBody = ({
  children,
  isBgWhite,
  isExtendModal,
  isLargeBody,
}: ModalBodyProps) => (
  <div
    className={`size-full min-h-15 overflow-y-auto ${isBgWhite ? 'bg-white' : 'bg-grey-soft'} px-8 py-3 ${isExtendModal ? 'h-90.5' : isLargeBody ? 'h-full-minus-15' : 'h-full-minus-29'}`}>
    {children}
  </div>
);

const ModalFooter = ({children}: React.ComponentProps<'div'>) => (
  <div className="flex min-h-15 w-full items-center justify-end border-t px-5 shadow-modalFooter">
    {children}
  </div>
);

ModalLayout.Footer = ModalFooter;
ModalLayout.Body = ModalBody;
ModalLayout.Header = ModalHeader;
