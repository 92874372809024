import {SharedActionType} from '@ifeelonline/chat-core';

import {Pill} from 'src/types/Pill';

import {PillsActionType} from '../action_types/PillsActionType';
import {PillsAction} from '../actions/pills';
import {FixMeLater} from '../interfaces/FixMeLater';

interface PillsState {
  error: null | FixMeLater;
  pillActive: Pill | null;
  pillPreview: null | FixMeLater;
  pills: Pill[] | null;
  pillsOptions: null | FixMeLater;
  status: SharedActionType;
  total: number;
}

const initialState = {
  pills: null,
  total: 0,
  pillActive: null,
  pillPreview: null,
  pillsOptions: null,
  status: SharedActionType.IDLE,
  error: null,
};

export const pillsReducer = (
  state: PillsState = initialState,
  action: PillsAction,
) => {
  switch (action.type) {
    case PillsActionType.PILLS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case PillsActionType.SET_PILLS:
      return {
        ...state,
        pills: action.payload.pills,
        total: action.payload.total,
        status: SharedActionType.COMPLETED,
      };

    case PillsActionType.SET_PILLS_OPTIONS:
      return {
        ...state,
        pillsOptions: action.payload,
        status: SharedActionType.COMPLETED,
      };

    case PillsActionType.NEW_PILL:
      return {
        ...state,
        pills: state.pills
          ? [action.payload, ...state.pills]
          : [action.payload],
        status: SharedActionType.COMPLETED,
        total: state.total + 1,
      };

    case PillsActionType.EDIT_PILL:
      return {
        ...state,
        pills: state.pills
          ? state.pills.map((pill: Pill) =>
              pill.id === action.payload.id ? action.payload : pill,
            )
          : [action.payload],
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
