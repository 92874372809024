import React, {useState} from 'react';

import {useController} from 'react-hook-form';

import {ErrorText} from './ErrorText';
import {CustomTextFieldTextArea, styles} from './StylesFormControlled';
import {BaseControlledComponentProps} from './type';

interface Props extends BaseControlledComponentProps {
  minHeight?: string;
  rows?: number;
}

export const InputTextArea = ({
  control,
  label,
  labelError,
  name,
  placeholder,
  rows = 4,
}: Props) => {
  const {field, fieldState} = useController({control, name});
  const [isClicked, setIsClicked] = useState(false);

  const isInvalid = fieldState.invalid;

  return (
    <div className="mb-3 min-h-12 w-full">
      <label className="mb-1 block">
        {isInvalid && labelError ? labelError : label}
      </label>
      <CustomTextFieldTextArea
        sx={styles.textField}
        error={isInvalid}
        multiline
        InputProps={{
          sx: {
            resize: 'vertical',
          },
          readOnly: isClicked ? false : true, //Hack to prevent autofill when a password input is used
        }}
        style={{width: '100%'}}
        rows={rows}
        placeholder={placeholder}
        {...field}
        onBlur={() => {
          if (isClicked) setIsClicked(false);
        }}
        onClick={() => {
          if (!isClicked) setIsClicked(true);
        }}
      />
      {fieldState.error?.message && (
        <ErrorText error={fieldState.error?.message} size="small" />
      )}
    </div>
  );
};
