import {Dispatch} from 'redux';

import {catchApiError} from 'src/helpers/catchApiError';
import {fetchWithToken} from 'src/helpers/fetch';
import {toQueryString} from 'src/helpers/generalFunctions';
import {UserRole, UsersAndRolesStorage} from 'src/types/UsersAndRoles';

import {PatientActionType} from '../action_types/PatientActionType';
import {UsersAndRolesActionType} from '../action_types/UsersAndRolesActionType';
import {
  SetUsersAndRolesPayload,
  UsersAndRolesAction,
} from '../actions/usersAndRoles';
import {FixMeLater} from '../interfaces/FixMeLater';

type DispatchResult = UsersAndRolesAction | any;

export const getUsersAndRoles = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: UsersAndRolesActionType.USERS_AND_ROLES_LOADING});
    const resp = await fetchWithToken(
      `admin/user_roles?${toQueryString(params)}`,
    );
    const body = await resp?.json();
    if (body && body.success) {
      const {
        users,
        total_count: total,
      }: {total_count: number; users: UsersAndRolesStorage[]} = body;
      const payload: SetUsersAndRolesPayload = {
        usersAndRoles: users,
        total,
      };
      dispatch({
        type: UsersAndRolesActionType.SET_USERS_AND_ROLES,
        payload,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};
export const getRolesSelector = () => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: UsersAndRolesActionType.USERS_AND_ROLES_LOADING});
    const resp = await fetchWithToken(`admin/user_roles/selectors`);
    const body = await resp?.json();
    if (body && body.success) {
      const {roles}: {roles: UserRole[]} = body;
      dispatch({
        type: UsersAndRolesActionType.SET_ROLES_LIST_SELECTOR,
        payload: roles,
      });
    } else {
      dispatch(catchApiError(body));
    }
  };
};

export const createUserRole = (params: FixMeLater) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    const resp = await fetchWithToken(
      'admin/users',
      {
        user: params,
      },
      'POST',
    );
    const body = await resp?.json();
    if (body && body.success) {
      dispatch({
        type: UsersAndRolesActionType.SET_ADD_NEW_USER_ROLE,
        payload: {id: body.id, email: body.email, roles: body.roles},
      });
      return true;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const updateUserRoles = (
  patientId: number,
  fieldsToEdit: FixMeLater,
) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: PatientActionType.PATIENT_LOADING});
    const resp = await fetchWithToken(
      `admin/user_roles/${patientId}`,
      fieldsToEdit,
      'PUT',
    );
    const body = await resp?.json();
    if (body && body.success) {
      dispatch({
        type: UsersAndRolesActionType.SET_USER_ROLE_UPDATED,
        payload: {id: body.id, email: body.email, roles: body.roles},
      });
      return body;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};

export const reinviteUserRoles = (userId: number) => {
  return async (dispatch: Dispatch<DispatchResult>) => {
    dispatch({type: PatientActionType.PATIENT_LOADING});
    const resp = await fetchWithToken(`admin/users/reinvite?id=${userId}`);
    const body = await resp?.json();
    if (body && body.success) {
      return body;
    } else {
      dispatch(catchApiError(body));
      return false;
    }
  };
};
