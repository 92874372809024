import React, {useEffect} from 'react';

import {useSelector} from 'react-redux';

import {SharedActionType} from '@ifeelonline/chat-core';

import {Cube} from '@phosphor-icons/react';

import {getPlans} from 'src/state/action_creators/plansCreator';
import {CustomTable} from 'src/components/tables/CustomTable';
import {PageHeader} from 'src/components/layout/PageHeader';
import {NoResults} from 'src/components/tables/NoResults';
import {Loading} from 'src/components/shared/Loading';
import {useAppDispatch} from 'src/hooks/hooks';
import {Plan} from 'src/types/Plan';
import {RootState} from 'src/state';

export const PlansTable = () => {
  const dispatch = useAppDispatch();

  const {status, plans} = useSelector((state: RootState) => state.plans);

  useEffect(() => {
    dispatch(getPlans());
  }, [dispatch]);

  return status === SharedActionType.LOADING ? (
    <Loading />
  ) : (
    <div>
      <PageHeader pageTitle="Plans" />
      {plans ? (
        <CustomTable
          headChildren={
            <tr>
              <td>Name</td>
              <td>id</td>
              <td>External id</td>
              <td>Price week</td>
              <td>Price total</td>
            </tr>
          }
          bodyChildren={plans.map((plan: Plan, index: number) => (
            <tr key={index} className="group">
              <td>
                <Cube size={20} weight="fill" className="mr-1.5 inline-block" />{' '}
                {plan.name}
              </td>
              <td>{plan.id}</td>
              <td>{plan.externalId}</td>
              <td>{plan.priceWeek}€</td>
              <td>{plan.priceTotal}€</td>
            </tr>
          ))}
        />
      ) : (
        <NoResults />
      )}
    </div>
  );
};
