import React from 'react';


import {Orientation, Therapist} from 'src/types/Therapist';
import {AuthUser} from 'src/types/Auth';

import {arrayToCommaList} from '../generalFunctions';
import {formatDate} from '../dates';

export const getOrientationTherapist = (therapist: Therapist): string[] => {
  const selectedTexts: string[] = [];
  const orientation = therapist.account?.orientation;

  if (!orientation) return selectedTexts;

  Object.keys(orientation).forEach((key) => {
    const entry = orientation[key as keyof Orientation];
    if (entry?.has) {
      selectedTexts.push(entry.text);
    }
  });

  return selectedTexts;
};

export const isRoleAllowed = (
  allowedRoles: string[],
  user: AuthUser | null,
) => {
  return (
    user?.roles.some((role: string) => allowedRoles.includes(role)) || false
  );
};
//This is the order of the details in the page, if you want to change the order, change the order here because the order is not alphabetical
export const TherapistFieldsOrderRef = [
  'Disabled',
  'Ref',
  'Created',
  'Birthday',
  'Incorporation',
  'Years of clinical experience',
  'Locale',
  'Languages',
  'Nationality',
  'Residence',
  'Collaboration',
  'School',
  'License',
  'Gender',
  'Partner confidentiality',
  'Orientation: Mains',
  'Orientation: Others',
  'Fee',
  'Billing role',
  'Notes',
];

export const detailsSortFunction = (a: {label: string}, b: {label: string}) => {
  const indexA = TherapistFieldsOrderRef.indexOf(a.label);
  const indexB = TherapistFieldsOrderRef.indexOf(b.label);
  if (indexA === -1) return 1; // a comes after b
  if (indexB === -1) return -1; // b comes after a
  return indexA - indexB; // normal sorting
};

const checkValue = (value: boolean | null | string | undefined) =>
  value ? 'Yes' : 'No';

export const getTherapistDetailsSection = (
  therapist: Therapist,
  allowedRoles: string[] = [],
  user: AuthUser | null,
) => {
  const languagesInfo = () =>
    therapist.account ? (
      <>
        {therapist.account.languages &&
          arrayToCommaList(therapist.account.languages.sort(), 'black')}
        {/*The lenght check, controls if it should show a coma before the first value if there are mainLanguages or not*/}
        {therapist.account.extraLanguages &&
          arrayToCommaList(
            therapist.account.extraLanguages.sort(),
            'grey capitalize',
            therapist.account.languages?.length ? -1 : 0,
          )}
      </>
    ) : (
      ''
    );

  const residenceInfo = () => {
    if (therapist.account?.city && therapist.account?.country)
      return `${therapist.account?.city}, ${therapist.account?.country}`;
    if (therapist.account?.country) return therapist.account?.country;
    return '';
  };

  const orientationMainInfo = () =>
    getOrientationTherapist(therapist).length > 0
      ? getOrientationTherapist(therapist).sort().join(', ')
      : '';

  const orientationOthersInfo = () =>
    therapist.account?.orientationOthers &&
    therapist.account?.orientationOthers.length > 0 ? (
      <p>
        {arrayToCommaList(therapist.account.orientationOthers.sort(), 'grey')}
      </p>
    ) : (
      ''
    );
  const partnerConfidentialityInfo = () =>
    therapist.account && checkValue(therapist.account.partnerConfidentiality);

  const items = [
    {label: 'Ref', value: therapist.account?.initials},
    {
      label: 'Created',
      value: formatDate(therapist.createdAt),
    },
    {
      label: 'Incorporation',
      value: therapist.account?.incorporatedAt
        ? formatDate(therapist.account.incorporatedAt)
        : '',
    },
    {
      label: 'Years of clinical experience',
      value: therapist.account?.yearsOfExperience?.toString(),
    },
    {
      label: 'Languages',
      value: languagesInfo(),
    },
    {
      label: 'Locale',
      value: therapist.locale.toString(),
    },
    {
      label: 'Residence',
      value: residenceInfo(),
    },
    {
      label: 'School',
      value: therapist.account?.school,
    },
    {
      label: 'Nationality',
      value: therapist.account?.nationality,
    },
    {
      label: 'License',
      value: therapist.account?.license,
    },
    {
      label: 'Gender',
      value: therapist.account?.gender,
    },
    {
      label: 'Partner confidentiality',
      value: partnerConfidentialityInfo(),
    },
    {
      label: 'Orientation: Mains',
      value: orientationMainInfo(),
    },

    {
      label: 'Orientation: Others',
      value: orientationOthersInfo(),
    },
    {
      label: 'Collaboration',
      value: therapist.account?.collaboration,
    },
    {
      label: 'Birthday',
      value: therapist.account?.birthday
        ? formatDate(therapist.account.birthday)
        : '',
    },
    {
      label: 'Disabled',
      value: checkValue(therapist.account?.disable),
    },
    {
      label: 'Fee',
      value: therapist.account?.fee?.toUpperCase(),
    },
    {
      label: 'Billing role',
      value: therapist.account?.billingRole,
    },
    {
      label: 'Notes',
      /*Dont really like too much to use ! in class but there are some clases over tailwind and want to keep tailwind as standar*/
      value: therapist.account?.note ? (
        <div className="!whitespace-pre">{therapist.account.note}</div>
      ) : (
        ''
      ),
    },
  ];
  const itemsTemp = !isRoleAllowed(allowedRoles, user)
    ? items.filter(
        (item) => item.label !== 'Billing role' && item.label !== 'Fee',
      )
    : items;
  return itemsTemp.sort(detailsSortFunction);
};

export const getTherapistInfo = (therapist: Therapist) => {
  const items = [
    {label: 'ID', value: therapist.id},
    {
      label: 'Name',
      value: therapist.name,
    },
    {
      label: 'Surname',
      value: therapist.surname,
    },
    {
      label: 'Phone',
      value: therapist.phone,
    },
    {
      label: 'Video call',
      value: checkValue(therapist.videocallEnabled),
    },
  ];
  return items.sort(detailsSortFunction);
};

export const getTherapistPatientsSummaryInfo = (therapist: Therapist) => {
  return [
    {label: 'Derivation', value: checkValue(therapist.account?.derive)},
    {
      label: 'Total',
      value: therapist.account?.maxPatients?.toString(),
    },
    {
      label: 'Active',
      value: therapist.account?.activePatients,
    },
    {
      label: 'Trial',
      value: therapist.account?.trialsPatients,
    },
    {
      label: 'Free',
      value: therapist.account?.freeSlots,
    },
    {
      label: 'Today',
      value: therapist.account?.patientsToday?.toString(),
    },
    {
      label: 'Priority',
      value: checkValue(therapist.account?.patientsPriority),
    },
    {
      label: 'Total attended',
      value: therapist.account?.totalAttended?.toString(),
    },
    {
      label: 'Schedule',
      value: therapist.account?.schedule,
    },
  ];
};
export const getTherapistPlanSummaryInfo = (therapist: Therapist) => {
  return [
    {label: 'Chat', value: checkValue(therapist?.account?.chat)},
    {
      label: 'Video',
      value: checkValue(therapist.account?.video),
    },
    {
      label: 'Couple',
      value: checkValue(therapist.account?.couple),
    },
    {
      label: 'Under age',
      value: checkValue(therapist.account?.underAge),
    },
  ];
};
