import React, {useEffect, useState} from 'react';

import toast from 'react-hot-toast';

import {getSurveyAnswersPatient} from 'src/state/action_creators/patientCreator';
import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {useAppDispatch} from 'src/hooks/hooks';

interface SAMInterface extends ModalSimpleInterface {
  patientId: number;
  surveySelected: FixMeLater;
}

export const SurveyAnswersModal = ({
  show,
  setShow,
  patientId,
  surveySelected,
}: SAMInterface) => {
  const dispatch = useAppDispatch();
  const [answers, setAnswers] = useState<FixMeLater>(null);

  useEffect(() => {
    if (show) {
      dispatch(getSurveyAnswersPatient(patientId, surveySelected)).then(
        (res) => {
          if (res.answers) {
            setAnswers(res.answers);
          } else {
            toast.error(
              `The user has no answers for the ${surveySelected.name} survey`,
            );
            closeModal();
          }
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const closeModal = () => setShow!(false);

  return (
    answers && (
      <ModalLayout show={show} closeModal={closeModal}>
        <div className="flex h-14 items-center px-5 shadow-modalHeader">
          <h3 className="text-4 font-medium text-blue-dark">
            Survey {surveySelected.name}
          </h3>
        </div>
        <ModalLayout.Body>
          <ul className="list-style-circle px-7.5 py-2.5">
            {answers.map((answer: FixMeLater, i: number) => (
              <li key={i} className="mb-4">
                <h5 className="mb-0.5 text-4">{answer.question}</h5>
                {answer.option.length > 0 ? (
                  answer.option.map((o: FixMeLater, i: number) => (
                    <div key={i}>
                      <p>{o.body}</p>
                      {o.comment && <p>{o.comment}</p>}
                    </div>
                  ))
                ) : (
                  <p>{answer.option.body}</p>
                )}
              </li>
            ))}
          </ul>
        </ModalLayout.Body>
      </ModalLayout>
    )
  );
};
