import React from 'react';

import {Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {rolesPermissionFilter} from 'src/helpers/rolesFunctions';
import {ControllerNames} from 'src/types/ControllerNames';
import {RootState} from 'src/state';

interface Props {
  component: React.ComponentType<any>;
  controllerName: ControllerNames;
  shouldShareControllerName: boolean;
}

export const PrivateRoute = ({
  controllerName,
  component: Component,
  shouldShareControllerName,
}: Props) => {
  const {user} = useSelector((state: RootState) => state.auth);
  const isAuthenticated = !!user;

  if (
    isAuthenticated &&
    controllerName &&
    (controllerName === ControllerNames.HOME ||
      rolesPermissionFilter(user.rolesPermissions, controllerName).length > 0)
  ) {
    return shouldShareControllerName ? (
      <Component controllerName={controllerName} />
    ) : (
      <Component />
    );
  } else {
    return <Navigate to="/login" />;
  }
};
