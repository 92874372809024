import React from 'react';

import {Subscription} from 'src/types/Patient';
import {formatDate} from 'src/helpers/dates';

import {PatientSubscriptionItem} from './PatientSubscriptionItem';

export const PatientSubscriptionHistoryItem = ({
  subscription,
}: {
  subscription: Subscription;
}) => {
  return (
    <div className="flex gap-5 rounded-2 border border-blue-light69 px-5 py-2">
      {subscription.updated_at && (
        <div className="flex-1 flex-col items-start">
          <p>{subscription.plan_name} </p>
          <PatientSubscriptionItem
            value={formatDate(subscription.updated_at)}
          />
        </div>
      )}
      <div className="flex-1 flex-col">
        <PatientSubscriptionItem
          label="Pause date:"
          value={
            subscription.paused_at ? formatDate(subscription.paused_at) : ''
          }
        />
        <PatientSubscriptionItem
          label="Plan start:"
          value={formatDate(subscription.created_at)}
        />
      </div>
      <div className="flex-1 flex-col">
        <PatientSubscriptionItem
          label="Extension:"
          value={subscription.last_extension_details}
        />
        <PatientSubscriptionItem label="Notes:" value={subscription.note} />
      </div>
    </div>
  );
};
