import {SharedActionType} from '@ifeelonline/chat-core';

import {CompanySelector, Partner} from 'src/types/Partner';

import {PartnersActionType} from '../action_types/PartnersActionType';
import {PartnersAction} from '../actions/partners';
import {PlanSelector} from '../../types/Partner';

interface PartnerState {
  companySelectorList: CompanySelector[] | null;
  error: null | string;
  partnerActive: null | Partner;
  partners: Partner[];
  planSelectorList: PlanSelector[] | null;
  status: SharedActionType;
  total: null | number;
}

const initialState = {
  companySelectorList: null,
  partnerActive: null,
  partners: [],
  partnersListSelector: null,
  planSelectorList: null,
  status: SharedActionType.IDLE,
  total: null,
  error: null,
};

export const partnersReducer = (
  state: PartnerState = initialState,
  action: PartnersAction,
) => {
  switch (action.type) {
    case PartnersActionType.PARTNERS_LOADING:
      return {
        ...state,
        status: SharedActionType.LOADING,
      };

    case PartnersActionType.SET_PARTNERS:
      return {
        ...state,
        partners: action.payload.partners,
        status: SharedActionType.COMPLETED,
        total: action.payload.total,
      };

    case PartnersActionType.SET_PARTNER_ACTIVE:
      return {
        ...state,
        partnerActive: action.payload,
        status: SharedActionType.COMPLETED,
      };

    case PartnersActionType.SET_PARTNER_OPTIONS:
      return {
        ...state,
        planSelectorList: action.payload.planSelectorList,
        companySelectorList: action.payload.companySelectorList,
      };

    case PartnersActionType.PARTNER_ADD_NEW:
      return {
        ...state,
        partners: [action.payload, ...state.partners],
        status: SharedActionType.COMPLETED,
        total: state.total ? state.total + 1 : 1,
      };

    case PartnersActionType.PARTNER_EDITED:
      return {
        ...state,
        status: SharedActionType.COMPLETED,
        partners: state.partners.map((p) =>
          p.id === action.payload.id ? action.payload : p,
        ),
      };

    case PartnersActionType.PARTNER_DELETED:
      return {
        ...state,
        status: SharedActionType.COMPLETED,
      };

    default:
      return state;
  }
};
