import React from 'react';

import {Spinner} from '@phosphor-icons/react';

export const Loading = ({
  isSmall = false,
  title,
}: {
  isSmall?: boolean;
  title?: string;
}) => {
  return (
    <div
      className={
        isSmall
          ? ''
          : 'absolute left-0 top-0 flex size-full flex-col items-center justify-center text-blue-primary'
      }>
      <Spinner weight="duotone" size={isSmall ? 30 : 60}>
        <animate
          attributeName="opacity"
          values="0;1;0"
          dur="4s"
          repeatCount="indefinite"></animate>
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="5s"
          from="0 0 0"
          to="360 0 0"
          repeatCount="indefinite"></animateTransform>
      </Spinner>
      {title && <h5 className="mt-5">{title}</h5>}
    </div>
  );
};
