import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import {Form} from 'react-bootstrap';
import toast from 'react-hot-toast';

import {
  getPatient,
  getPatientPlansOptions,
} from 'src/state/action_creators/patientCreator';
import {updateSubscription} from 'src/state/action_creators/subscriptionsCreator';
import {SubscriptionFetchData} from 'src/state/interfaces/SubscriptionInterface';
import {useSubscription} from 'src/utils/subscriptions-provider';
import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {Loading} from 'src/components/shared/Loading';
import {useAppDispatch} from 'src/hooks/hooks';
import {Plan} from 'src/types/Plan';
import {RootState} from 'src/state';

export const UpdateSubscriptionModal = () => {
  const dispatch = useAppDispatch();
  const {patient} = useSelector((state: RootState) => state.patient);
  const {plansList, couponsList} = useSelector(
    (state: RootState) => state.patient,
  );

  const [newPlanSelected, setNewPlanSelected] = useState<string | undefined>(
    undefined,
  );
  const [plansListsFiltered, setPlansListsFiltered] = useState<Plan[] | null>(
    null,
  );
  const [couponSelected, setCouponSelected] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const isPatientB2B = patient && patient.partner ? true : false;
  const {isModalUpdateSubscriptionOpen, setIsModalUpdateSubscriptionOpen} =
    useSubscription();

  useEffect(() => {
    if (patient && isModalUpdateSubscriptionOpen) {
      if (!plansList) {
        dispatch(getPatientPlansOptions(patient.id));
      } else {
        const plansListWithoutCurrent = plansList.filter(
          (plan: Plan) => plan.externalId !== patient.currentPlan,
        );
        setPlansListsFiltered(plansListWithoutCurrent);
        setNewPlanSelected(
          plansListWithoutCurrent.length > 0
            ? plansListWithoutCurrent[0].externalId
            : '',
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalUpdateSubscriptionOpen, plansList]);

  const closeModal = () => setIsModalUpdateSubscriptionOpen(false);

  const handleSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    if (patient) {
      setLoadingSubmit(true);
      const data: SubscriptionFetchData = {
        id: patient.id,
        stripePlan: newPlanSelected || null,
        planId: plansList?.find(
          (plan: Plan) => plan.externalId === newPlanSelected,
        )?.id,
      };
      if (couponSelected !== '') data.coupon = couponSelected;

      if (!data.planId) setLoadingSubmit(false);
      else
        dispatch(updateSubscription(data)).then((res) => {
          if (res) {
            toast.success('Subscription updated successfully');
            closeModal();
            setNewPlanSelected(undefined);
            dispatch(getPatient(patient.id));
          }
          setLoadingSubmit(false);
        });
    }
  };

  return (
    <ModalLayout
      show={isModalUpdateSubscriptionOpen}
      closeModal={closeModal}
      className="modal-height-auto">
      {patient && plansListsFiltered ? (
        <form className="allInputsBlock container" onSubmit={handleSubmitForm}>
          <ModalLayout.Header>
            <h3>Update subscription</h3>
          </ModalLayout.Header>
          <ModalLayout.Body>
            <div className="form-group">
              <label>Current plan</label>
              <input
                type="text"
                disabled={true}
                name="current_plan"
                autoComplete="off"
                value={patient.planName || ''}
              />
            </div>

            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label>Select new plan</Form.Label>
              <Form.Control
                as="select"
                name="plan"
                disabled={newPlanSelected === ''}
                value={newPlanSelected}
                onChange={(e) => setNewPlanSelected(e.target.value)}>
                {plansListsFiltered.map((plan: Plan) => (
                  <option key={plan.id} value={plan.externalId}>
                    {plan.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {!isPatientB2B && couponsList && (
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Select coupon</Form.Label>
                <Form.Control
                  as="select"
                  name="coupon"
                  value={couponSelected}
                  onChange={(e) => setCouponSelected(e.target.value)}>
                  <option value="">-</option>
                  {couponsList.map((c: FixMeLater) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
          </ModalLayout.Body>
          <ModalLayout.Footer>
            <ButtonSubmit
              loadingSubmit={loadingSubmit}
              text="Confirm"
              disabled={newPlanSelected === patient.currentPlan}
            />
          </ModalLayout.Footer>
        </form>
      ) : (
        <Loading />
      )}
    </ModalLayout>
  );
};
