import React, {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import toast from 'react-hot-toast';

import {NameValue} from '@ifeelonline/chat-core';

import {ModalSimpleInterface} from 'src/state/interfaces/ModalSimpleInterface';
import {updatePassword} from 'src/state/action_creators/myCreator';
import {ButtonType} from 'src/helpers/set_classes/buttonClasses';
import {InputPassword} from 'src/components/forms/InputPassword';
import {validatePasswords} from 'src/helpers/validatePassword';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {FixMeLater} from 'src/state/interfaces/FixMeLater';
import {ErrorText} from 'src/components/forms/ErrorText';
import {passwordRegExp} from 'src/helpers/regularExp';
import Button from 'src/components/buttons/Button';
import {useAppDispatch} from 'src/hooks/hooks';
import {RootState} from 'src/state';

interface Props extends ModalSimpleInterface {
  setEditing: FixMeLater;
}

export const ChangePasswordModal = ({show, setShow, setEditing}: Props) => {
  const dispatch = useAppDispatch();
  const {my} = useSelector((state: RootState) => state.my);
  const [formValues, setFormValues] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });
  const {oldPassword, password, confirmPassword} = formValues;
  const [error, setError] = useState<string | null>(null);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const passwordConfirm = validatePasswords(
      password,
      confirmPassword,
      setError,
      oldPassword,
    );
    if (passwordConfirm) {
      dispatch(
        updatePassword(my.id, {old_password: oldPassword, password}),
      ).then((res) => {
        if (res.code) {
          if (res.code === 'wrong_old_password') {
            setError(res.error);
          }
        } else {
          toast.success('Password changed successfully');
          setEditing(false);
          closeModal();
        }
      });
    }
  };
  const handleInputChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    if (show) {
      setFormValues({
        oldPassword: '',
        password: '',
        confirmPassword: '',
      });
      setError(null);
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      setDisableSubmit(
        !passwordRegExp.test(password) ||
          !passwordRegExp.test(confirmPassword) ||
          !oldPassword,
      );
    }
  }, [formValues]);

  const closeModal = () => setShow!(false);
  const PasswordInputs: NameValue[] = [
    {label: 'Old password', name: 'oldPassword', value: oldPassword},
    {label: 'New password', name: 'password', value: password},
    {
      label: 'Confirm password',
      name: 'confirmPassword',
      value: confirmPassword,
    },
  ];

  return (
    <ModalLayout
      show={show}
      closeModal={closeModal}
      className="modal__change-password">
      <form onSubmit={handleSubmit} className="container">
        <ModalLayout.Header>
          <h3>Change password</h3>
        </ModalLayout.Header>
        <ModalLayout.Body isBgWhite={true}>
          {PasswordInputs.map(({label, name, value}: NameValue, i: number) => (
            <InputPassword
              key={i}
              label={label as string}
              name={name}
              value={value}
              handleInputChange={handleInputChange}
              checkPasswordValidation={value !== oldPassword}
            />
          ))}
          {error && <ErrorText error={error} />}
        </ModalLayout.Body>
        <ModalLayout.Footer>
          <div className="flex justify-end">
            <Button
              buttonType={ButtonType.WHITE}
              size="small"
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}>
              Cancel
            </Button>
            <Button
              buttonType={ButtonType.SAVE}
              size="small"
              type="submit"
              disabled={disableSubmit}>
              Save
            </Button>
          </div>
        </ModalLayout.Footer>
      </form>
    </ModalLayout>
  );
};
