import {
  convertFormDataKeysToSnakeCase,
  convertObjectKeysToSnakeCase,
} from '@ifeelonline/chat-core';

import Swal from 'sweetalert2';

export const BASE_URL = import.meta.env.VITE_APP_URL;
export const BASE_API_URL = import.meta.env.VITE_APP_API_URL;

export const fetchNoToken = async (
  endPoint: string,
  data: any,
  method = 'GET',
) => {
  try {
    const url = `${BASE_API_URL}/${endPoint}`;
    const body = method !== 'GET' ? JSON.stringify(data) : null;

    const res = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      ...(body ? {body} : {}),
    });
    if ([200, 201, 401].includes(res.status)) return res;

    return fetchError();
  } catch (error) {
    return fetchError();
  }
};

export const fetchWithToken = async (
  endPoint: string,
  data?: any,
  method = 'GET',
  notV3 = false,
) => {
  try {
    const url = notV3
      ? `${BASE_URL}/${endPoint}`
      : `${BASE_API_URL}/${endPoint}`;
    const token = localStorage.getItem('token') || '';
    const email = localStorage.getItem('email') || '';
    const body =
      method !== 'GET' && data
        ? JSON.stringify(convertObjectKeysToSnakeCase(data))
        : null;

    const res = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-User-Token': token,
        'X-User-Email': email,
      },
      ...(body ? {body} : {}),
    });
    if ([200, 201, 401].includes(res.status)) return res;

    return fetchError();
  } catch (error) {
    return fetchError();
  }
};

export const fetchFormData = async (
  endPoint: string,
  data: FormData,
  method = 'GET',
) => {
  try {
    const url = `${BASE_API_URL}/${endPoint}`;
    const token = localStorage.getItem('token') || '';
    const email = localStorage.getItem('email') || '';
    const body = method !== 'GET' ? convertFormDataKeysToSnakeCase(data) : null;

    const res = await fetch(url, {
      method,
      headers: {
        'X-User-Token': token,
        'X-User-Email': email,
      },
      ...(body ? {body} : {}),
    });
    if ([200, 201, 401].includes(res.status)) return res;

    return fetchError();
  } catch (error) {
    return fetchError();
  }
};

function fetchError() {
  Swal.fire(
    'Error',
    'There was an error, please contact the administrator',
    'error',
  );
  return null;
}
