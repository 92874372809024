import React from 'react';

import {Warning} from '@phosphor-icons/react';

import ButtonSubmit from 'src/components/buttons/ButtonSubmit';
import {ModalLayout} from 'src/components/layout/ModalLayout';
import {Form} from 'src/components/form/Form';

import {usePartnerEditForm} from '../hooks/usePartnerEditForm';

interface Props {
  closeModal: () => void;
}

export const PartnerEditForm = ({closeModal}: Props) => {
  const {
    control,
    onSubmit,
    isSubmitting,
    hasErrors,
    setFileAvatar,
    urlFileAvatar,
    valuesHaveChanged,
  } = usePartnerEditForm({
    closeModal,
  });

  return (
    <Form onSubmit={onSubmit} className="flex h-full flex-col justify-between">
      <ModalLayout.Header>
        <h3>Edit Partner</h3>
      </ModalLayout.Header>
      <ModalLayout.Body>
        <Form.InputNumber
          name="multiplier"
          label="Multiplier"
          control={control}
        />
        <Form.InputNumber
          name="boughtVideoSessions"
          label="Bought video sessions"
          control={control}
        />
        <Form.InputFile
          name="partnerImage"
          label="Avatar"
          control={control}
          shouldShowPreview={true}
          setFile={setFileAvatar}
          urlFile={urlFileAvatar || undefined}
        />
      </ModalLayout.Body>
      <ModalLayout.Footer>
        {hasErrors && <Warning color="red" />}
        <ButtonSubmit
          loadingSubmit={isSubmitting}
          disabled={valuesHaveChanged()}
        />
      </ModalLayout.Footer>
    </Form>
  );
};
